/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrPegawaiEmergency } from '../models/hr-pegawai-emergency';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrPegawaiEmergencyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrPegawaiEmergencyList
   */
  static readonly GetHrPegawaiEmergencyListPath = '/hr/pegawai_emergencies';

  /**
   * getpegawai_emergencyList.
   *
   * Get all pegawai_emergencies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiEmergencyList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiEmergencyList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiEmergency>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiEmergencyService.GetHrPegawaiEmergencyListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrPegawaiEmergency>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_emergencyList.
   *
   * Get all pegawai_emergencies
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiEmergencyList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiEmergencyList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrPegawaiEmergency>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrPegawaiEmergencyList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiEmergency>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrPegawaiEmergency>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrPegawaiEmergency
   */
  static readonly StoreHrPegawaiEmergencyPath = '/hr/pegawai_emergencies';

  /**
   * createpegawai_emergency.
   *
   * Create pegawai_emergency
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrPegawaiEmergency()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiEmergency$Response(params: {
    body: HrPegawaiEmergency
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiEmergencyService.StoreHrPegawaiEmergencyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiEmergency;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_emergency.
   *
   * Create pegawai_emergency
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrPegawaiEmergency$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiEmergency(params: {
    body: HrPegawaiEmergency
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}> {

    return this.storeHrPegawaiEmergency$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrPegawaiEmergencyId
   */
  static readonly GetHrPegawaiEmergencyIdPath = '/hr/pegawai_emergencies/{id}';

  /**
   * getpegawai_emergencyItem.
   *
   * Get pegawai_emergency
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiEmergencyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiEmergencyId$Response(params: {

    /**
     * id of pegawai_emergency
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiEmergencyService.GetHrPegawaiEmergencyIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiEmergency;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_emergencyItem.
   *
   * Get pegawai_emergency
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiEmergencyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiEmergencyId(params: {

    /**
     * id of pegawai_emergency
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}> {

    return this.getHrPegawaiEmergencyId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrPegawaiEmergencyId
   */
  static readonly PutHrPegawaiEmergencyIdPath = '/hr/pegawai_emergencies/{id}';

  /**
   * updatepegawai_emergency.
   *
   * Update pegawai_emergency
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrPegawaiEmergencyId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiEmergencyId$Response(params: {

    /**
     * id of pegawai_emergency
     */
    id: string;
    body: HrPegawaiEmergency
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiEmergencyService.PutHrPegawaiEmergencyIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiEmergency;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_emergency.
   *
   * Update pegawai_emergency
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrPegawaiEmergencyId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiEmergencyId(params: {

    /**
     * id of pegawai_emergency
     */
    id: string;
    body: HrPegawaiEmergency
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}> {

    return this.putHrPegawaiEmergencyId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiEmergency;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrPegawaiEmergenciesId
   */
  static readonly DeleteHrPegawaiEmergenciesIdPath = '/hr/pegawai_emergencies/{id}';

  /**
   * deletepegawai_emergency.
   *
   * Delete pegawai_emergency
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrPegawaiEmergenciesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiEmergenciesId$Response(params: {

    /**
     * id of pegawai_emergency
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiEmergencyService.DeleteHrPegawaiEmergenciesIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_emergency.
   *
   * Delete pegawai_emergency
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrPegawaiEmergenciesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiEmergenciesId(params: {

    /**
     * id of pegawai_emergency
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrPegawaiEmergenciesId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
