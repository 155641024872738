/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Rekap_setoran_detail } from '../models/rekap-_setoran-_detail';
@Injectable({
  providedIn: 'root',
})
class RekapSetoranDetailService extends __BaseService {
  static readonly getPenagihanReportRekapSetoranDetailsPath = '/penagihanReport/rekapSetoranDetails';
  static readonly postPenagihanReportRekapSetoranDetailsPath = '/penagihanReport/rekapSetoranDetails';
  static readonly getPenagihanReportRekapSetoranDetailsIdPath = '/penagihanReport/rekapSetoranDetails/{id}';
  static readonly putPenagihanReportRekapSetoranDetailsIdPath = '/penagihanReport/rekapSetoranDetails/{id}';
  static readonly deletePenagihanReportRekapSetoranDetailsIdPath = '/penagihanReport/rekapSetoranDetails/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Rekap_setoran_detail.
   *
   * Get all Rekap_setoran_detail
   * @param params The `RekapSetoranDetailService.GetPenagihanReportRekapSetoranDetailsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_setoran_detail
   *
   * @return successful operation
   */
  getPenagihanReportRekapSetoranDetailsResponse(params: RekapSetoranDetailService.GetPenagihanReportRekapSetoranDetailsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Rekap_setoran_detail>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapSetoranDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Rekap_setoran_detail>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Rekap_setoran_detail.
   *
   * Get all Rekap_setoran_detail
   * @param params The `RekapSetoranDetailService.GetPenagihanReportRekapSetoranDetailsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_setoran_detail
   *
   * @return successful operation
   */
  getPenagihanReportRekapSetoranDetails(params: RekapSetoranDetailService.GetPenagihanReportRekapSetoranDetailsParams): __Observable<{success?: boolean, data?: Array<Rekap_setoran_detail>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportRekapSetoranDetailsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Rekap_setoran_detail>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Rekap_setoran_detail in storage
   *
   * Store Rekap_setoran_detail
   * @param body Rekap_setoran_detail that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapSetoranDetailsResponse(body?: Rekap_setoran_detail): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_setoran_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/rekapSetoranDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_setoran_detail, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Rekap_setoran_detail in storage
   *
   * Store Rekap_setoran_detail
   * @param body Rekap_setoran_detail that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapSetoranDetails(body?: Rekap_setoran_detail): __Observable<{success?: boolean, data?: Rekap_setoran_detail, message?: string}> {
    return this.postPenagihanReportRekapSetoranDetailsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_setoran_detail, message?: string})
    );
  }

  /**
   * Display the specified Rekap_setoran_detail
   *
   * Get Rekap_setoran_detail
   * @param id id of Rekap_setoran_detail
   * @return successful operation
   */
  getPenagihanReportRekapSetoranDetailsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_setoran_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapSetoranDetails/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_setoran_detail, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Rekap_setoran_detail
   *
   * Get Rekap_setoran_detail
   * @param id id of Rekap_setoran_detail
   * @return successful operation
   */
  getPenagihanReportRekapSetoranDetailsId(id: number): __Observable<{success?: boolean, data?: Rekap_setoran_detail, message?: string}> {
    return this.getPenagihanReportRekapSetoranDetailsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_setoran_detail, message?: string})
    );
  }

  /**
   * Update the specified Rekap_setoran_detail in storage
   *
   * Update Rekap_setoran_detail
   * @param params The `RekapSetoranDetailService.PutPenagihanReportRekapSetoranDetailsIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_setoran_detail
   *
   * - `body`: Rekap_setoran_detail that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapSetoranDetailsIdResponse(params: RekapSetoranDetailService.PutPenagihanReportRekapSetoranDetailsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_setoran_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/rekapSetoranDetails/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_setoran_detail, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Rekap_setoran_detail in storage
   *
   * Update Rekap_setoran_detail
   * @param params The `RekapSetoranDetailService.PutPenagihanReportRekapSetoranDetailsIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_setoran_detail
   *
   * - `body`: Rekap_setoran_detail that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapSetoranDetailsId(params: RekapSetoranDetailService.PutPenagihanReportRekapSetoranDetailsIdParams): __Observable<{success?: boolean, data?: Rekap_setoran_detail, message?: string}> {
    return this.putPenagihanReportRekapSetoranDetailsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_setoran_detail, message?: string})
    );
  }

  /**
   * Remove the specified Rekap_setoran_detail from storage
   *
   * Delete Rekap_setoran_detail
   * @param id id of Rekap_setoran_detail
   * @return successful operation
   */
  deletePenagihanReportRekapSetoranDetailsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/rekapSetoranDetails/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Rekap_setoran_detail from storage
   *
   * Delete Rekap_setoran_detail
   * @param id id of Rekap_setoran_detail
   * @return successful operation
   */
  deletePenagihanReportRekapSetoranDetailsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportRekapSetoranDetailsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module RekapSetoranDetailService {

  /**
   * Parameters for getPenagihanReportRekapSetoranDetails
   */
  export interface GetPenagihanReportRekapSetoranDetailsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Rekap_setoran_detail
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportRekapSetoranDetailsId
   */
  export interface PutPenagihanReportRekapSetoranDetailsIdParams {

    /**
     * id of Rekap_setoran_detail
     */
    id: number;

    /**
     * Rekap_setoran_detail that should be updated
     */
    body?: Rekap_setoran_detail;
  }
}

export { RekapSetoranDetailService }
