/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminMedia } from '../models/admin-media';

@Injectable({
  providedIn: 'root',
})
export class AdminMediaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminMediaList
   */
  static readonly GetadminMediaListPath = '/admin/media';

  /**
   * Get list of media.
   *
   * Returns list of media
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminMediaList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMediaList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMedia>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMediaService.GetadminMediaListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminMedia>;
        }>;
      })
    );
  }

  /**
   * Get list of media.
   *
   * Returns list of media
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminMediaList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMediaList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMedia>;
}> {

    return this.getadminMediaList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMedia>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMedia>;
})
    );
  }

  /**
   * Path part for operation storeadminMedia
   */
  static readonly StoreadminMediaPath = '/admin/media';

  /**
   * Store new Media.
   *
   * Returns Media data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminMedia()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminMedia$Response(params: {
    body: AdminMedia
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMediaService.StoreadminMediaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMedia;
        }>;
      })
    );
  }

  /**
   * Store new Media.
   *
   * Returns Media data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminMedia$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminMedia(params: {
    body: AdminMedia
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}> {

    return this.storeadminMedia$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
})
    );
  }

  /**
   * Path part for operation getadminMediaId
   */
  static readonly GetadminMediaIdPath = '/admin/media/{id}';

  /**
   * Get  Media by id.
   *
   * Returns Media data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminMediaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMediaId$Response(params: {

    /**
     * id of Media
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMediaService.GetadminMediaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMedia;
        }>;
      })
    );
  }

  /**
   * Get  Media by id.
   *
   * Returns Media data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminMediaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMediaId(params: {

    /**
     * id of Media
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}> {

    return this.getadminMediaId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
})
    );
  }

  /**
   * Path part for operation putadminMedia
   */
  static readonly PutadminMediaPath = '/admin/media/{id}';

  /**
   * Update Media.
   *
   * Returns Media data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminMedia()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminMedia$Response(params: {

    /**
     * id of Media
     */
    id: string;
    body: AdminMedia
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMediaService.PutadminMediaPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMedia;
        }>;
      })
    );
  }

  /**
   * Update Media.
   *
   * Returns Media data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminMedia$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminMedia(params: {

    /**
     * id of Media
     */
    id: string;
    body: AdminMedia
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}> {

    return this.putadminMedia$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMedia;
})
    );
  }

  /**
   * Path part for operation deleteadminMedia
   */
  static readonly DeleteadminMediaPath = '/admin/media/{id}';

  /**
   * Store new Media.
   *
   * Returns Media data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminMedia$Response(params: {

    /**
     * id of Media
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMediaService.DeleteadminMediaPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Media.
   *
   * Returns Media data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminMedia(params: {

    /**
     * id of Media
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminMedia$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
