/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AndroidBacaMeterService } from './services/android-baca-meter.service';
import { AndroidBlokService } from './services/android-blok.service';
import { AndroidPelangganService } from './services/android-pelanggan.service';
import { AndroidStatusMeterService } from './services/android-status-meter.service';
import { CurrentuserService } from './services/currentuser.service';
import { LoginService } from './services/login.service';
import { LogoutService } from './services/logout.service';
import { RefreshService } from './services/refresh.service';
import { CollectorBayarService } from './services/collector-bayar.service';
import { CollectorBlokService } from './services/collector-blok.service';
import { CollectorTargetService } from './services/collector-target.service';
import { CollectorTargetFollowService } from './services/collector-target-follow.service';
import { PdfEkapSetoranService } from './services/pdf-ekap-setoran.service';
import { ListKwitansiPembayaranService } from './services/list-kwitansi-pembayaran.service';
import { PelangganPenagihanService } from './services/pelanggan-penagihan.service';
import { PelangganTertagihService } from './services/pelanggan-tertagih.service';
import { PenagihanByCaterService } from './services/penagihan-by-cater.service';
import { PenagihanByLokasiService } from './services/penagihan-by-lokasi.service';
import { PenagihanMapService } from './services/penagihan-map.service';
import { PenagihanMapReportService } from './services/penagihan-map-report.service';
import { RekapJanjiBayarService } from './services/rekap-janji-bayar.service';
import { RekapJanjiBayarByCaterService } from './services/rekap-janji-bayar-by-cater.service';
import { RekapKinerjaPenagihanService } from './services/rekap-kinerja-penagihan.service';
import { RekapSetoranService } from './services/rekap-setoran.service';
import { RekapSetoranDetailService } from './services/rekap-setoran-detail.service';
import { RekapSetoranDetailListService } from './services/rekap-setoran-detail-list.service';
import { RekapSetoranTagihanService } from './services/rekap-setoran-tagihan.service';
import { RekapTertagihKoperasiService } from './services/rekap-tertagih-koperasi.service';
import { TertagihByCaterService } from './services/tertagih-by-cater.service';
import { TertagihByLokasiService } from './services/tertagih-by-lokasi.service';
import { CollectorTargetFollowFotoService } from './services/collector-target-follow-foto.service';
import { PembayaranBelumTerekapService } from './services/pembayaran-belum-terekap.service';
import { TagihanBelumTerekapService } from './services/tagihan-belum-terekap.service';
import { PembatalanService } from './services/pembatalan.service';
import { TagihanService } from './services/tagihan.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AndroidBacaMeterService,
    AndroidBlokService,
    AndroidPelangganService,
    AndroidStatusMeterService,
    CurrentuserService,
    LoginService,
    LogoutService,
    RefreshService,
    CollectorBayarService,
    CollectorBlokService,
    CollectorTargetService,
    CollectorTargetFollowService,
    PdfEkapSetoranService,
    ListKwitansiPembayaranService,
    PelangganPenagihanService,
    PelangganTertagihService,
    PenagihanByCaterService,
    PenagihanByLokasiService,
    PenagihanMapService,
    PenagihanMapReportService,
    RekapJanjiBayarService,
    RekapJanjiBayarByCaterService,
    RekapKinerjaPenagihanService,
    RekapSetoranService,
    RekapSetoranDetailService,
    RekapSetoranDetailListService,
    RekapSetoranTagihanService,
    RekapTertagihKoperasiService,
    TertagihByCaterService,
    TertagihByLokasiService,
    CollectorTargetFollowFotoService,
    PembayaranBelumTerekapService,
    TagihanBelumTerekapService,
    PembatalanService,
    TagihanService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
