/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Maping_pembaca_meter_detail } from '../models/maping-_pembaca-_meter-_detail';
@Injectable({
  providedIn: 'root',
})
class MapingPembacaMeterDetailService extends __BaseService {
  static readonly getPelangganMapingPembacaMeterDetailsPath = '/pelanggan/mapingPembacaMeterDetails';
  static readonly postPelangganMapingPembacaMeterDetailsPath = '/pelanggan/mapingPembacaMeterDetails';
  static readonly getPelangganMapingPembacaMeterDetailsIdPath = '/pelanggan/mapingPembacaMeterDetails/{id}';
  static readonly putPelangganMapingPembacaMeterDetailsIdPath = '/pelanggan/mapingPembacaMeterDetails/{id}';
  static readonly deletePelangganMapingPembacaMeterDetailsIdPath = '/pelanggan/mapingPembacaMeterDetails/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Maping_pembaca_meter_detail.
   *
   * Get all Maping_pembaca_meter_detail
   * @param params The `MapingPembacaMeterDetailService.GetPelangganMapingPembacaMeterDetailsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Maping_pembaca_meter_detail
   *
   * @return successful operation
   */
  getPelangganMapingPembacaMeterDetailsResponse(params: MapingPembacaMeterDetailService.GetPelangganMapingPembacaMeterDetailsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Maping_pembaca_meter_detail>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/mapingPembacaMeterDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Maping_pembaca_meter_detail>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Maping_pembaca_meter_detail.
   *
   * Get all Maping_pembaca_meter_detail
   * @param params The `MapingPembacaMeterDetailService.GetPelangganMapingPembacaMeterDetailsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Maping_pembaca_meter_detail
   *
   * @return successful operation
   */
  getPelangganMapingPembacaMeterDetails(params: MapingPembacaMeterDetailService.GetPelangganMapingPembacaMeterDetailsParams): __Observable<{success?: boolean, data?: Array<Maping_pembaca_meter_detail>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganMapingPembacaMeterDetailsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Maping_pembaca_meter_detail>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Maping_pembaca_meter_detail in storage
   *
   * Store Maping_pembaca_meter_detail
   * @param body Maping_pembaca_meter_detail that should be stored
   * @return successful operation
   */
  postPelangganMapingPembacaMeterDetailsResponse(body?: Maping_pembaca_meter_detail): __Observable<__StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/mapingPembacaMeterDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Maping_pembaca_meter_detail in storage
   *
   * Store Maping_pembaca_meter_detail
   * @param body Maping_pembaca_meter_detail that should be stored
   * @return successful operation
   */
  postPelangganMapingPembacaMeterDetails(body?: Maping_pembaca_meter_detail): __Observable<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}> {
    return this.postPelangganMapingPembacaMeterDetailsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Maping_pembaca_meter_detail, message?: string})
    );
  }

  /**
   * Display the specified Maping_pembaca_meter_detail
   *
   * Get Maping_pembaca_meter_detail
   * @param id id of Maping_pembaca_meter_detail
   * @return successful operation
   */
  getPelangganMapingPembacaMeterDetailsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/mapingPembacaMeterDetails/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Maping_pembaca_meter_detail
   *
   * Get Maping_pembaca_meter_detail
   * @param id id of Maping_pembaca_meter_detail
   * @return successful operation
   */
  getPelangganMapingPembacaMeterDetailsId(id: number): __Observable<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}> {
    return this.getPelangganMapingPembacaMeterDetailsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Maping_pembaca_meter_detail, message?: string})
    );
  }

  /**
   * Update the specified Maping_pembaca_meter_detail in storage
   *
   * Update Maping_pembaca_meter_detail
   * @param params The `MapingPembacaMeterDetailService.PutPelangganMapingPembacaMeterDetailsIdParams` containing the following parameters:
   *
   * - `id`: id of Maping_pembaca_meter_detail
   *
   * - `body`: Maping_pembaca_meter_detail that should be updated
   *
   * @return successful operation
   */
  putPelangganMapingPembacaMeterDetailsIdResponse(params: MapingPembacaMeterDetailService.PutPelangganMapingPembacaMeterDetailsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/mapingPembacaMeterDetails/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Maping_pembaca_meter_detail in storage
   *
   * Update Maping_pembaca_meter_detail
   * @param params The `MapingPembacaMeterDetailService.PutPelangganMapingPembacaMeterDetailsIdParams` containing the following parameters:
   *
   * - `id`: id of Maping_pembaca_meter_detail
   *
   * - `body`: Maping_pembaca_meter_detail that should be updated
   *
   * @return successful operation
   */
  putPelangganMapingPembacaMeterDetailsId(params: MapingPembacaMeterDetailService.PutPelangganMapingPembacaMeterDetailsIdParams): __Observable<{success?: boolean, data?: Maping_pembaca_meter_detail, message?: string}> {
    return this.putPelangganMapingPembacaMeterDetailsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Maping_pembaca_meter_detail, message?: string})
    );
  }

  /**
   * Remove the specified Maping_pembaca_meter_detail from storage
   *
   * Delete Maping_pembaca_meter_detail
   * @param id id of Maping_pembaca_meter_detail
   * @return successful operation
   */
  deletePelangganMapingPembacaMeterDetailsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/mapingPembacaMeterDetails/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Maping_pembaca_meter_detail from storage
   *
   * Delete Maping_pembaca_meter_detail
   * @param id id of Maping_pembaca_meter_detail
   * @return successful operation
   */
  deletePelangganMapingPembacaMeterDetailsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganMapingPembacaMeterDetailsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module MapingPembacaMeterDetailService {

  /**
   * Parameters for getPelangganMapingPembacaMeterDetails
   */
  export interface GetPelangganMapingPembacaMeterDetailsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Maping_pembaca_meter_detail
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganMapingPembacaMeterDetailsId
   */
  export interface PutPelangganMapingPembacaMeterDetailsIdParams {

    /**
     * id of Maping_pembaca_meter_detail
     */
    id: number;

    /**
     * Maping_pembaca_meter_detail that should be updated
     */
    body?: Maping_pembaca_meter_detail;
  }
}

export { MapingPembacaMeterDetailService }
