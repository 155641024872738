<form nz-form [nzLayout]="'vertical'" id="ngForm">
    <div nz-row [nzGutter]="8">
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Type Pelanggan</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose "
                        [(ngModel)]="dataPelanggan.id_type_pelanggan" name="id_type_pelanggan"
                        >
                        <nz-option *ngFor="let item of listTipe" [nzValue]="item.id_type_pelanggan"
                            nzLabel="{{item.nama_type_pelanggan}}">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Cabang</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose " [(ngModel)]="dataPelanggan.id_cabang" name="id_cabang" (ngModelChange)="getWilayah()">
                        <nz-option *ngFor="let item of listCabang" [nzValue]="item.id_cabang"
                            nzLabel="{{item.nama_cabang}}">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Wilayah</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose " [(ngModel)]="dataPelanggan.id_wilayah"
                        name="id_wilayah" (ngModelChange)="getBlok()" >
                        <nz-option *ngFor="let item of listWilayah" [nzValue]="item.id_wilayah"
                            nzLabel="{{item.nama_wilayah}}">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Blok</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose " [(ngModel)]="dataPelanggan.id_blok"
                        name="id_blok" (ngModelChange)="getKdOld()">
                        <nz-option *ngFor="let item of listBlok" [nzValue]="item.id_blok" nzLabel="{{item.nama_blok}}">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Sumber Air</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose "
                        [(ngModel)]="dataPelanggan.id_sumber_air" name="id_sumber_air">
                        <nz-option *ngFor="let item of listSumber" [nzValue]="item.id_sumber_air"
                            nzLabel="{{item.nama_sumber_air}}">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Kode Indeks</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" disabled [(ngModel)]="dataPelanggan.kd_pelanggan_old" name="kd_pelanggan_old" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Kategori Sambungan</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose "
                        [(ngModel)]="dataPelanggan.tipe_pelanggan" name="tipe_pelanggan">
                        <nz-option nzValue="Reguler" nzLabel="Reguler"> </nz-option>
                        <nz-option nzValue="MBR" nzLabel="MBR"> </nz-option>
                        <nz-option nzValue="DAK" nzLabel="DAK"> </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8" *ngIf="dataPelanggan.tipe_pelanggan =='MBR'">
            <nz-form-item>
                <nz-form-label>Kode MBR</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.kd_tipe" name="kd_tipe" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8" >

        </div>
        <div nz-col nzSpan="16">
            <nz-form-item>
                <nz-form-label>Nama</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.nama_pelanggan" name="nama_pelanggan"
                        />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Jenis Kelamin</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose "
                        [(ngModel)]="dataPelanggan.jenis_kelamin" name="jenis_kelamin">
                        <nz-option nzValue="laki-laki" nzLabel="Laki-laki"> </nz-option>
                        <nz-option nzValue="perempuan" nzLabel="Perempuan"> </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label>No KTP</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.no_ktp" name="no_ktp" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label>Pekerjaan</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.pekerjaan" name="pekerjaan" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="24">
            <nz-form-item>
                <nz-form-label>Alamat</nz-form-label>
                <nz-form-control>
                    <textarea nz-input placeholder="Alamat" [(ngModel)]="dataPelanggan.alamat" name="alamat"
                        [nzAutosize]="{ minRows: 2, maxRows: 2 }"></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>No Telfon</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.telp" name="telp" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>No HP</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.hp" name="hp" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Email</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" type="email" [(ngModel)]="dataPelanggan.email" name="email" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Kode Pos</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.kode_pos" name="kode_pos" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Bebas Denda</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose " [(ngModel)]="dataPelanggan.bebas_denda"
                        name="bebas_denda">
                        <nz-option [nzValue]="1" nzLabel="Ya"> </nz-option>
                        <nz-option [nzValue]="0" nzLabel="Tidak"> </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <!-- <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Tahun Meteran</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" type="number" [(ngModel)]="dataPelanggan.kode_pos" name="kode_pos" />
                </nz-form-control>
            </nz-form-item>
        </div> -->
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>No Seri</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" [(ngModel)]="dataPelanggan.sn_meteran" name="sn_meteran" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <!-- <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label>Merk Meteran</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="" type="number" [(ngModel)]="dataPelanggan.kode_pos" name="kode_pos" />
                </nz-form-control>
            </nz-form-item>
        </div> -->
    </div>

    <!--<div nz-col nzSpan="8">
        <nz-form-item>
            <nz-form-label>Status</nz-form-label>
            <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose " [(ngModel)]="dataPelanggan.status"
                    name="status" [disabled]="status=='aktif' || status=='nonAktif' || status=='suspend'">
                    <nz-option nzValue="aktif" nzLabel="Aktif"></nz-option>
                    <nz-option nzValue="pending" nzLabel="Pending"></nz-option>
                    <nz-option nzValue="bayar" nzLabel="Bayar" nzDisabled></nz-option>
                    <nz-option nzValue="nonAktif" nzLabel="Tidak aktif" nzDisabled></nz-option>
                    <nz-option nzValue="pemasangan" nzLabel="Pemasangan" nzDisabled></nz-option>
                    <nz-option nzValue="suspend" nzLabel="Suspend" nzDisabled></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>-->

</form>


<div class="drawer-footer">
    <button type="button" (click)="goToBack()" class="ant-btn" style="margin-right: 8px;"><span>Cancel</span></button>
    <button type="submit" (click)="simpan()" class="ant-btn ant-btn-primary" form="ngForm"><span>Submit</span></button>
</div>
