/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminDaftarSambunganBaru } from '../models/admin-daftar-sambungan-baru';

@Injectable({
  providedIn: 'root',
})
export class AdminDaftarSambunganBaruService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminDaftarSambunganBarusList
   */
  static readonly GetadminDaftarSambunganBarusListPath = '/admin/daftarSambunganBarus';

  /**
   * Get list of daftarSambunganBarus.
   *
   * Returns list of daftarSambunganBarus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminDaftarSambunganBarusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminDaftarSambunganBarusList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminDaftarSambunganBaru>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminDaftarSambunganBaruService.GetadminDaftarSambunganBarusListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminDaftarSambunganBaru>;
        }>;
      })
    );
  }

  /**
   * Get list of daftarSambunganBarus.
   *
   * Returns list of daftarSambunganBarus
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminDaftarSambunganBarusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminDaftarSambunganBarusList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminDaftarSambunganBaru>;
}> {

    return this.getadminDaftarSambunganBarusList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminDaftarSambunganBaru>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminDaftarSambunganBaru>;
})
    );
  }

  /**
   * Path part for operation storeadminDaftarSambunganBaru
   */
  static readonly StoreadminDaftarSambunganBaruPath = '/admin/daftarSambunganBarus';

  /**
   * Store new Daftar_sambungan_baru.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminDaftarSambunganBaru()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminDaftarSambunganBaru$Response(params: {
    body: AdminDaftarSambunganBaru
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminDaftarSambunganBaruService.StoreadminDaftarSambunganBaruPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminDaftarSambunganBaru;
        }>;
      })
    );
  }

  /**
   * Store new Daftar_sambungan_baru.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminDaftarSambunganBaru$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminDaftarSambunganBaru(params: {
    body: AdminDaftarSambunganBaru
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}> {

    return this.storeadminDaftarSambunganBaru$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
})
    );
  }

  /**
   * Path part for operation getadminDaftarSambunganBaruId
   */
  static readonly GetadminDaftarSambunganBaruIdPath = '/admin/daftarSambunganBarus/{id}';

  /**
   * Get  Daftar_sambungan_baru by id.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminDaftarSambunganBaruId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminDaftarSambunganBaruId$Response(params: {

    /**
     * id of Daftar_sambungan_baru
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminDaftarSambunganBaruService.GetadminDaftarSambunganBaruIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminDaftarSambunganBaru;
        }>;
      })
    );
  }

  /**
   * Get  Daftar_sambungan_baru by id.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminDaftarSambunganBaruId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminDaftarSambunganBaruId(params: {

    /**
     * id of Daftar_sambungan_baru
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}> {

    return this.getadminDaftarSambunganBaruId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
})
    );
  }

  /**
   * Path part for operation putadminDaftarSambunganBaru
   */
  static readonly PutadminDaftarSambunganBaruPath = '/admin/daftarSambunganBarus/{id}';

  /**
   * Update Daftar_sambungan_baru.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminDaftarSambunganBaru()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminDaftarSambunganBaru$Response(params: {

    /**
     * id of Daftar_sambungan_baru
     */
    id: string;
    body: AdminDaftarSambunganBaru
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminDaftarSambunganBaruService.PutadminDaftarSambunganBaruPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminDaftarSambunganBaru;
        }>;
      })
    );
  }

  /**
   * Update Daftar_sambungan_baru.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminDaftarSambunganBaru$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminDaftarSambunganBaru(params: {

    /**
     * id of Daftar_sambungan_baru
     */
    id: string;
    body: AdminDaftarSambunganBaru
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}> {

    return this.putadminDaftarSambunganBaru$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminDaftarSambunganBaru;
})
    );
  }

  /**
   * Path part for operation deleteadminDaftarSambunganBaru
   */
  static readonly DeleteadminDaftarSambunganBaruPath = '/admin/daftarSambunganBarus/{id}';

  /**
   * Store new Daftar_sambungan_baru.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminDaftarSambunganBaru()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminDaftarSambunganBaru$Response(params: {

    /**
     * id of Daftar_sambungan_baru
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminDaftarSambunganBaruService.DeleteadminDaftarSambunganBaruPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Daftar_sambungan_baru.
   *
   * Returns Daftar_sambungan_baru data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminDaftarSambunganBaru$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminDaftarSambunganBaru(params: {

    /**
     * id of Daftar_sambungan_baru
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminDaftarSambunganBaru$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
