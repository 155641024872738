<a nz-dropdown class="dropdown" [nzDropdownMenu]="menu" [nzTrigger]="'click'">
    <i class="icon fe fe-database"></i>
    <span class="d-none d-xl-inline">
        Module
    </span>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-group>
            <span title>Billing</span>
            <ul class="list-unstyled">
                <li nz-menu-item style="width: 250px;">
                    <a [routerLink]="[ '/pelanggan']">Pelanggan</a>
                </li>
                <li nz-menu-item style="width: 250px;">
                    <a [routerLink]="[ '/penagihan']">Penagihan</a>
                </li>

                <li nz-menu-item style="width: 250px;">
                    <a [routerLink]="[ '/report']">Report</a>
                </li>
            </ul>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-group>
            <span title>Setting</span>
            <ul class="list-unstyled">
                <li nz-menu-item>
                    <a [routerLink]="[ '/admin']">
                        <i class="fe fe-settings mr-2"></i>
                        Admin
                    </a>
                </li>
                <li nz-menu-item>
                    <a [routerLink]="[ '/switching']">
                        <i class="fe fe-settings mr-2"></i>
                        Switching
                    </a>
                </li>
            </ul>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-group>
            <span title>HR</span>
            <ul class="list-unstyled">
                <ul class="list-unstyled">
                    <li nz-menu-item>
                        <a [routerLink]="[ '/company']">
                            <i class="fe fe-settings mr-2"></i>
                            Company
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="[ '/employee']">
                            <i class="fe fe-file mr-2"></i>
                            Employee
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="[ '/hr-management']">
                            <i class="fe fe-file mr-2"></i>
                            HR Management
                        </a>
                    </li>
                </ul>
            </ul>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-group>
            <span title>Asset</span>
            <ul class="list-unstyled">
                <li nz-menu-item>
                    <a [routerLink]="[ '/arsip']">
                        <i class="fe fe-file mr-2"></i>
                        Arsip Management
                    </a>
                </li>
            </ul>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-group>
            <span title>Cms</span>
            <ul class="list-unstyled">
                <li nz-menu-item>
                    <a [routerLink]="[ '/cms']">
                        <i class="fe fe-file mr-2"></i>
                        CMS Admin
                    </a>
                </li>
            </ul>
        </li>

        <li nz-menu-group>
            <span title>Sebaran </span>
            <ul class="list-unstyled">
                <li nz-menu-item>
                    <a [routerLink]="[ '/maping']">
                        <i class="fe fe-file mr-2"></i>
                        Maping
                    </a>
                </li>
            </ul>
        </li>





    </ul>
</nz-dropdown-menu>
