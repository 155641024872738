/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tagihan } from '../models/tagihan';
@Injectable({
  providedIn: 'root',
})
class TagihanService extends __BaseService {
  static readonly getPelangganTagihansPath = '/pelanggan/tagihans';
  static readonly postPelangganTagihansPath = '/pelanggan/tagihans';
  static readonly getPelangganTagihansIdPath = '/pelanggan/tagihans/{id}';
  static readonly putPelangganTagihansIdPath = '/pelanggan/tagihans/{id}';
  static readonly deletePelangganTagihansIdPath = '/pelanggan/tagihans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Tagihan.
   *
   * Get all Tagihan
   * @param params The `TagihanService.GetPelangganTagihansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan
   *
   * @return successful operation
   */
  getPelangganTagihansResponse(params: TagihanService.GetPelangganTagihansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Tagihan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/tagihans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Tagihan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Tagihan.
   *
   * Get all Tagihan
   * @param params The `TagihanService.GetPelangganTagihansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan
   *
   * @return successful operation
   */
  getPelangganTagihans(params: TagihanService.GetPelangganTagihansParams): __Observable<{success?: boolean, data?: Array<Tagihan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganTagihansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Tagihan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Tagihan in storage
   *
   * Store Tagihan
   * @param body Tagihan that should be stored
   * @return successful operation
   */
  postPelangganTagihansResponse(body?: Tagihan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/tagihans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Tagihan in storage
   *
   * Store Tagihan
   * @param body Tagihan that should be stored
   * @return successful operation
   */
  postPelangganTagihans(body?: Tagihan): __Observable<{success?: boolean, data?: Tagihan, message?: string}> {
    return this.postPelangganTagihansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan, message?: string})
    );
  }

  /**
   * Display the specified Tagihan
   *
   * Get Tagihan
   * @param id id of Tagihan
   * @return successful operation
   */
  getPelangganTagihansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/tagihans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Tagihan
   *
   * Get Tagihan
   * @param id id of Tagihan
   * @return successful operation
   */
  getPelangganTagihansId(id: number): __Observable<{success?: boolean, data?: Tagihan, message?: string}> {
    return this.getPelangganTagihansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan, message?: string})
    );
  }

  /**
   * Update the specified Tagihan in storage
   *
   * Update Tagihan
   * @param params The `TagihanService.PutPelangganTagihansIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan
   *
   * - `body`: Tagihan that should be updated
   *
   * @return successful operation
   */
  putPelangganTagihansIdResponse(params: TagihanService.PutPelangganTagihansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/tagihans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Tagihan in storage
   *
   * Update Tagihan
   * @param params The `TagihanService.PutPelangganTagihansIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan
   *
   * - `body`: Tagihan that should be updated
   *
   * @return successful operation
   */
  putPelangganTagihansId(params: TagihanService.PutPelangganTagihansIdParams): __Observable<{success?: boolean, data?: Tagihan, message?: string}> {
    return this.putPelangganTagihansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan, message?: string})
    );
  }

  /**
   * Remove the specified Tagihan from storage
   *
   * Delete Tagihan
   * @param params The `TagihanService.DeletePelangganTagihansIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan
   *
   * - `body`: Tagihan that should be updated
   *
   * @return successful operation
   */
  deletePelangganTagihansIdResponse(params: TagihanService.DeletePelangganTagihansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/tagihans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Tagihan from storage
   *
   * Delete Tagihan
   * @param params The `TagihanService.DeletePelangganTagihansIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan
   *
   * - `body`: Tagihan that should be updated
   *
   * @return successful operation
   */
  deletePelangganTagihansId(params: TagihanService.DeletePelangganTagihansIdParams): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganTagihansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module TagihanService {

  /**
   * Parameters for getPelangganTagihans
   */
  export interface GetPelangganTagihansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Tagihan
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganTagihansId
   */
  export interface PutPelangganTagihansIdParams {

    /**
     * id of Tagihan
     */
    id: number;

    /**
     * Tagihan that should be updated
     */
    body?: Tagihan;
  }

  /**
   * Parameters for deletePelangganTagihansId
   */
  export interface DeletePelangganTagihansIdParams {

    /**
     * id of Tagihan
     */
    id: number;

    /**
     * Tagihan that should be updated
     */
    body: {password?: string};
  }
}

export { TagihanService }
