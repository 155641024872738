/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientHayati } from '../models/client-hayati';

@Injectable({
  providedIn: 'root',
})
export class ClientHayatiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientgethayatisList
   */
  static readonly ClientgethayatisListPath = '/client/hayatis';

  /**
   * Get list of hayatis.
   *
   * Returns list of hayatis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgethayatisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgethayatisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayati>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiService.ClientgethayatisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientHayati>;
        }>;
      })
    );
  }

  /**
   * Get list of hayatis.
   *
   * Returns list of hayatis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgethayatisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgethayatisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayati>;
}> {

    return this.clientgethayatisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayati>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayati>;
})
    );
  }

  /**
   * Path part for operation clientgetHayatiId
   */
  static readonly ClientgetHayatiIdPath = '/client/hayatis/{id}';

  /**
   * Get  Hayati by id.
   *
   * Returns Hayati data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetHayatiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetHayatiId$Response(params: {

    /**
     * id of Hayati
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayati;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiService.ClientgetHayatiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayati;
        }>;
      })
    );
  }

  /**
   * Get  Hayati by id.
   *
   * Returns Hayati data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetHayatiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetHayatiId(params: {

    /**
     * id of Hayati
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayati;
}> {

    return this.clientgetHayatiId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayati;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayati;
})
    );
  }

}
