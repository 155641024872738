/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportJadwalTipeNormalDetail } from '../models/absensi-report-jadwal-tipe-normal-detail';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportJadwalTipeNormalDetailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportJadwalTipeNormalDetailList
   */
  static readonly GetAbsensiReportJadwalTipeNormalDetailListPath = '/absensiReport/jadwal_tipe_normal_details';

  /**
   * getjadwal_tipe_normal_detailList.
   *
   * Get all jadwal_tipe_normal_details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportJadwalTipeNormalDetailList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeNormalDetailList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeNormalDetailService.GetAbsensiReportJadwalTipeNormalDetailListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportJadwalTipeNormalDetail>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getjadwal_tipe_normal_detailList.
   *
   * Get all jadwal_tipe_normal_details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportJadwalTipeNormalDetailList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeNormalDetailList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportJadwalTipeNormalDetailList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportJadwalTipeNormalDetail
   */
  static readonly StoreAbsensiReportJadwalTipeNormalDetailPath = '/absensiReport/jadwal_tipe_normal_details';

  /**
   * createjadwal_tipe_normal_detail.
   *
   * Create jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportJadwalTipeNormalDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportJadwalTipeNormalDetail$Response(params: {
    body: AbsensiReportJadwalTipeNormalDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeNormalDetailService.StoreAbsensiReportJadwalTipeNormalDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportJadwalTipeNormalDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createjadwal_tipe_normal_detail.
   *
   * Create jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportJadwalTipeNormalDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportJadwalTipeNormalDetail(params: {
    body: AbsensiReportJadwalTipeNormalDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}> {

    return this.storeAbsensiReportJadwalTipeNormalDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportJadwalTipeNormalDetailId
   */
  static readonly GetAbsensiReportJadwalTipeNormalDetailIdPath = '/absensiReport/jadwal_tipe_normal_details/{id}';

  /**
   * getjadwal_tipe_normal_detailItem.
   *
   * Get jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportJadwalTipeNormalDetailId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeNormalDetailId$Response(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeNormalDetailService.GetAbsensiReportJadwalTipeNormalDetailIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportJadwalTipeNormalDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getjadwal_tipe_normal_detailItem.
   *
   * Get jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportJadwalTipeNormalDetailId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeNormalDetailId(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}> {

    return this.getAbsensiReportJadwalTipeNormalDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportJadwalTipeNormalDetailId
   */
  static readonly PutAbsensiReportJadwalTipeNormalDetailIdPath = '/absensiReport/jadwal_tipe_normal_details/{id}';

  /**
   * updatejadwal_tipe_normal_detail.
   *
   * Update jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportJadwalTipeNormalDetailId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportJadwalTipeNormalDetailId$Response(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
    body: AbsensiReportJadwalTipeNormalDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeNormalDetailService.PutAbsensiReportJadwalTipeNormalDetailIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportJadwalTipeNormalDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatejadwal_tipe_normal_detail.
   *
   * Update jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportJadwalTipeNormalDetailId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportJadwalTipeNormalDetailId(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
    body: AbsensiReportJadwalTipeNormalDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}> {

    return this.putAbsensiReportJadwalTipeNormalDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeNormalDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportJadwalTipeNormalDetailsId
   */
  static readonly DeleteAbsensiReportJadwalTipeNormalDetailsIdPath = '/absensiReport/jadwal_tipe_normal_details/{id}';

  /**
   * deletejadwal_tipe_normal_detail.
   *
   * Delete jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportJadwalTipeNormalDetailsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportJadwalTipeNormalDetailsId$Response(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeNormalDetailService.DeleteAbsensiReportJadwalTipeNormalDetailsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletejadwal_tipe_normal_detail.
   *
   * Delete jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportJadwalTipeNormalDetailsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportJadwalTipeNormalDetailsId(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportJadwalTipeNormalDetailsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
