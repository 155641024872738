/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportNotifikasi } from '../models/absensi-report-notifikasi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportNotifikasiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportNotifikasiList
   */
  static readonly GetAbsensiReportNotifikasiListPath = '/absensiReport/notifikasis';

  /**
   * getnotifikasiList.
   *
   * Get all notifikasis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportNotifikasiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportNotifikasiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportNotifikasi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportNotifikasiService.GetAbsensiReportNotifikasiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportNotifikasi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getnotifikasiList.
   *
   * Get all notifikasis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportNotifikasiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportNotifikasiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportNotifikasi>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportNotifikasiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportNotifikasi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportNotifikasi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportNotifikasiId
   */
  static readonly GetAbsensiReportNotifikasiIdPath = '/absensiReport/notifikasis/{id}';

  /**
   * getnotifikasiItem.
   *
   * Get notifikasi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportNotifikasiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportNotifikasiId$Response(params: {

    /**
     * id of notifikasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportNotifikasi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportNotifikasiService.GetAbsensiReportNotifikasiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportNotifikasi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getnotifikasiItem.
   *
   * Get notifikasi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportNotifikasiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportNotifikasiId(params: {

    /**
     * id of notifikasi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportNotifikasi;
'message'?: string;
}> {

    return this.getAbsensiReportNotifikasiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportNotifikasi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportNotifikasi;
'message'?: string;
})
    );
  }

}
