/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Collector_bayar } from '../models/collector-_bayar';
@Injectable({
  providedIn: 'root',
})
class CollectorBayarService extends __BaseService {
  static readonly getPenagihanCollectorBayarsPath = '/penagihan/collectorBayars';
  static readonly postPenagihanCollectorBayarsPath = '/penagihan/collectorBayars';
  static readonly getPenagihanCollectorBayarsIdPath = '/penagihan/collectorBayars/{id}';
  static readonly putPenagihanCollectorBayarsIdPath = '/penagihan/collectorBayars/{id}';
  static readonly deletePenagihanCollectorBayarsIdPath = '/penagihan/collectorBayars/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Collector_bayar.
   *
   * Get all Collector_bayar
   * @param params The `CollectorBayarService.GetPenagihanCollectorBayarsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Collector_bayar
   *
   * @return successful operation
   */
  getPenagihanCollectorBayarsResponse(params: CollectorBayarService.GetPenagihanCollectorBayarsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Collector_bayar>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihan/collectorBayars`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Collector_bayar>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Collector_bayar.
   *
   * Get all Collector_bayar
   * @param params The `CollectorBayarService.GetPenagihanCollectorBayarsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Collector_bayar
   *
   * @return successful operation
   */
  getPenagihanCollectorBayars(params: CollectorBayarService.GetPenagihanCollectorBayarsParams): __Observable<{success?: boolean, data?: Array<Collector_bayar>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanCollectorBayarsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Collector_bayar>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Collector_bayar in storage
   *
   * Store Collector_bayar
   * @param body Collector_bayar that should be stored
   * @return successful operation
   */
  postPenagihanCollectorBayarsResponse(body?: Collector_bayar): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_bayar, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihan/collectorBayars`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_bayar, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Collector_bayar in storage
   *
   * Store Collector_bayar
   * @param body Collector_bayar that should be stored
   * @return successful operation
   */
  postPenagihanCollectorBayars(body?: Collector_bayar): __Observable<{success?: boolean, data?: Collector_bayar, message?: string}> {
    return this.postPenagihanCollectorBayarsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_bayar, message?: string})
    );
  }

  /**
   * Display the specified Collector_bayar
   *
   * Get Collector_bayar
   * @param id id of Collector_bayar
   * @return successful operation
   */
  getPenagihanCollectorBayarsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_bayar, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihan/collectorBayars/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_bayar, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Collector_bayar
   *
   * Get Collector_bayar
   * @param id id of Collector_bayar
   * @return successful operation
   */
  getPenagihanCollectorBayarsId(id: number): __Observable<{success?: boolean, data?: Collector_bayar, message?: string}> {
    return this.getPenagihanCollectorBayarsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_bayar, message?: string})
    );
  }

  /**
   * Update the specified Collector_bayar in storage
   *
   * Update Collector_bayar
   * @param params The `CollectorBayarService.PutPenagihanCollectorBayarsIdParams` containing the following parameters:
   *
   * - `id`: id of Collector_bayar
   *
   * - `body`: Collector_bayar that should be updated
   *
   * @return successful operation
   */
  putPenagihanCollectorBayarsIdResponse(params: CollectorBayarService.PutPenagihanCollectorBayarsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_bayar, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihan/collectorBayars/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_bayar, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Collector_bayar in storage
   *
   * Update Collector_bayar
   * @param params The `CollectorBayarService.PutPenagihanCollectorBayarsIdParams` containing the following parameters:
   *
   * - `id`: id of Collector_bayar
   *
   * - `body`: Collector_bayar that should be updated
   *
   * @return successful operation
   */
  putPenagihanCollectorBayarsId(params: CollectorBayarService.PutPenagihanCollectorBayarsIdParams): __Observable<{success?: boolean, data?: Collector_bayar, message?: string}> {
    return this.putPenagihanCollectorBayarsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_bayar, message?: string})
    );
  }

  /**
   * Remove the specified Collector_bayar from storage
   *
   * Delete Collector_bayar
   * @param id id of Collector_bayar
   * @return successful operation
   */
  deletePenagihanCollectorBayarsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihan/collectorBayars/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Collector_bayar from storage
   *
   * Delete Collector_bayar
   * @param id id of Collector_bayar
   * @return successful operation
   */
  deletePenagihanCollectorBayarsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanCollectorBayarsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module CollectorBayarService {

  /**
   * Parameters for getPenagihanCollectorBayars
   */
  export interface GetPenagihanCollectorBayarsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Collector_bayar
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanCollectorBayarsId
   */
  export interface PutPenagihanCollectorBayarsIdParams {

    /**
     * id of Collector_bayar
     */
    id: number;

    /**
     * Collector_bayar that should be updated
     */
    body?: Collector_bayar;
  }
}

export { CollectorBayarService }
