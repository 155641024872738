/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportWidgetRekapKehadiranPegawai } from '../models/absensi-report-widget-rekap-kehadiran-pegawai';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportWidgetRekapKehadiranPegawaiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportWidgetRekapKehadiranPegawaiList
   */
  static readonly GetAbsensiReportWidgetRekapKehadiranPegawaiListPath = '/absensiReportWidget/rekap_kehadiran_pegawais';

  /**
   * getrekap_kehadiran_pegawaiList.
   *
   * Get all rekap_kehadiran_pegawais
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetRekapKehadiranPegawaiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetRekapKehadiranPegawaiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetRekapKehadiranPegawai>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetRekapKehadiranPegawaiService.GetAbsensiReportWidgetRekapKehadiranPegawaiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportWidgetRekapKehadiranPegawai>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getrekap_kehadiran_pegawaiList.
   *
   * Get all rekap_kehadiran_pegawais
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetRekapKehadiranPegawaiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetRekapKehadiranPegawaiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetRekapKehadiranPegawai>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportWidgetRekapKehadiranPegawaiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetRekapKehadiranPegawai>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetRekapKehadiranPegawai>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportWidgetRekapKehadiranPegawai
   */
  static readonly StoreAbsensiReportWidgetRekapKehadiranPegawaiPath = '/absensiReportWidget/rekap_kehadiran_pegawais';

  /**
   * createrekap_kehadiran_pegawai.
   *
   * Create rekap_kehadiran_pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportWidgetRekapKehadiranPegawai()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetRekapKehadiranPegawai$Response(params: {
    body: AbsensiReportWidgetRekapKehadiranPegawai
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetRekapKehadiranPegawaiService.StoreAbsensiReportWidgetRekapKehadiranPegawaiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createrekap_kehadiran_pegawai.
   *
   * Create rekap_kehadiran_pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportWidgetRekapKehadiranPegawai$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetRekapKehadiranPegawai(params: {
    body: AbsensiReportWidgetRekapKehadiranPegawai
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}> {

    return this.storeAbsensiReportWidgetRekapKehadiranPegawai$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportWidgetRekapKehadiranPegawaiId
   */
  static readonly GetAbsensiReportWidgetRekapKehadiranPegawaiIdPath = '/absensiReportWidget/rekap_kehadiran_pegawais/{id}';

  /**
   * getrekap_kehadiran_pegawaiItem.
   *
   * Get rekap_kehadiran_pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetRekapKehadiranPegawaiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetRekapKehadiranPegawaiId$Response(params: {

    /**
     * id of rekap_kehadiran_pegawai
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetRekapKehadiranPegawaiService.GetAbsensiReportWidgetRekapKehadiranPegawaiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getrekap_kehadiran_pegawaiItem.
   *
   * Get rekap_kehadiran_pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetRekapKehadiranPegawaiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetRekapKehadiranPegawaiId(params: {

    /**
     * id of rekap_kehadiran_pegawai
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}> {

    return this.getAbsensiReportWidgetRekapKehadiranPegawaiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportWidgetRekapKehadiranPegawaiId
   */
  static readonly PutAbsensiReportWidgetRekapKehadiranPegawaiIdPath = '/absensiReportWidget/rekap_kehadiran_pegawais/{id}';

  /**
   * updaterekap_kehadiran_pegawai.
   *
   * Update rekap_kehadiran_pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportWidgetRekapKehadiranPegawaiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetRekapKehadiranPegawaiId$Response(params: {

    /**
     * id of rekap_kehadiran_pegawai
     */
    id: string;
    body: AbsensiReportWidgetRekapKehadiranPegawai
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetRekapKehadiranPegawaiService.PutAbsensiReportWidgetRekapKehadiranPegawaiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updaterekap_kehadiran_pegawai.
   *
   * Update rekap_kehadiran_pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportWidgetRekapKehadiranPegawaiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetRekapKehadiranPegawaiId(params: {

    /**
     * id of rekap_kehadiran_pegawai
     */
    id: string;
    body: AbsensiReportWidgetRekapKehadiranPegawai
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}> {

    return this.putAbsensiReportWidgetRekapKehadiranPegawaiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetRekapKehadiranPegawai;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportWidgetRekapKehadiranPegawaisId
   */
  static readonly DeleteAbsensiReportWidgetRekapKehadiranPegawaisIdPath = '/absensiReportWidget/rekap_kehadiran_pegawais/{id}';

  /**
   * deleterekap_kehadiran_pegawai.
   *
   * Delete rekap_kehadiran_pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportWidgetRekapKehadiranPegawaisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetRekapKehadiranPegawaisId$Response(params: {

    /**
     * id of rekap_kehadiran_pegawai
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetRekapKehadiranPegawaiService.DeleteAbsensiReportWidgetRekapKehadiranPegawaisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleterekap_kehadiran_pegawai.
   *
   * Delete rekap_kehadiran_pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportWidgetRekapKehadiranPegawaisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetRekapKehadiranPegawaisId(params: {

    /**
     * id of rekap_kehadiran_pegawai
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportWidgetRekapKehadiranPegawaisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
