/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tunggakan_by_lokasi } from '../models/tunggakan-_by-_lokasi';
@Injectable({
  providedIn: 'root',
})
class TunggakanByLokasiService extends __BaseService {
  static readonly getPelangganReportTunggakanByLokasisPath = '/pelangganReport/tunggakanByLokasis';
  static readonly postPelangganReportTunggakanByLokasisPath = '/pelangganReport/tunggakanByLokasis';
  static readonly getPelangganReportTunggakanByLokasisIdPath = '/pelangganReport/tunggakanByLokasis/{id}';
  static readonly putPelangganReportTunggakanByLokasisIdPath = '/pelangganReport/tunggakanByLokasis/{id}';
  static readonly deletePelangganReportTunggakanByLokasisIdPath = '/pelangganReport/tunggakanByLokasis/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Tunggakan_by_lokasi.
   *
   * Get all Tunggakan_by_lokasi
   * @param params The `TunggakanByLokasiService.GetPelangganReportTunggakanByLokasisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tunggakan_by_lokasi
   *
   * @return successful operation
   */
  getPelangganReportTunggakanByLokasisResponse(params: TunggakanByLokasiService.GetPelangganReportTunggakanByLokasisParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Tunggakan_by_lokasi>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tunggakanByLokasis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Tunggakan_by_lokasi>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Tunggakan_by_lokasi.
   *
   * Get all Tunggakan_by_lokasi
   * @param params The `TunggakanByLokasiService.GetPelangganReportTunggakanByLokasisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tunggakan_by_lokasi
   *
   * @return successful operation
   */
  getPelangganReportTunggakanByLokasis(params: TunggakanByLokasiService.GetPelangganReportTunggakanByLokasisParams): __Observable<{success?: boolean, data?: Array<Tunggakan_by_lokasi>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportTunggakanByLokasisResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Tunggakan_by_lokasi>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Tunggakan_by_lokasi in storage
   *
   * Store Tunggakan_by_lokasi
   * @param body Tunggakan_by_lokasi that should be stored
   * @return successful operation
   */
  postPelangganReportTunggakanByLokasisResponse(body?: Tunggakan_by_lokasi): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/tunggakanByLokasis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Tunggakan_by_lokasi in storage
   *
   * Store Tunggakan_by_lokasi
   * @param body Tunggakan_by_lokasi that should be stored
   * @return successful operation
   */
  postPelangganReportTunggakanByLokasis(body?: Tunggakan_by_lokasi): __Observable<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}> {
    return this.postPelangganReportTunggakanByLokasisResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_by_lokasi, message?: string})
    );
  }

  /**
   * Display the specified Tunggakan_by_lokasi
   *
   * Get Tunggakan_by_lokasi
   * @param id id of Tunggakan_by_lokasi
   * @return successful operation
   */
  getPelangganReportTunggakanByLokasisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tunggakanByLokasis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Tunggakan_by_lokasi
   *
   * Get Tunggakan_by_lokasi
   * @param id id of Tunggakan_by_lokasi
   * @return successful operation
   */
  getPelangganReportTunggakanByLokasisId(id: number): __Observable<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}> {
    return this.getPelangganReportTunggakanByLokasisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_by_lokasi, message?: string})
    );
  }

  /**
   * Update the specified Tunggakan_by_lokasi in storage
   *
   * Update Tunggakan_by_lokasi
   * @param params The `TunggakanByLokasiService.PutPelangganReportTunggakanByLokasisIdParams` containing the following parameters:
   *
   * - `id`: id of Tunggakan_by_lokasi
   *
   * - `body`: Tunggakan_by_lokasi that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTunggakanByLokasisIdResponse(params: TunggakanByLokasiService.PutPelangganReportTunggakanByLokasisIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/tunggakanByLokasis/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Tunggakan_by_lokasi in storage
   *
   * Update Tunggakan_by_lokasi
   * @param params The `TunggakanByLokasiService.PutPelangganReportTunggakanByLokasisIdParams` containing the following parameters:
   *
   * - `id`: id of Tunggakan_by_lokasi
   *
   * - `body`: Tunggakan_by_lokasi that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTunggakanByLokasisId(params: TunggakanByLokasiService.PutPelangganReportTunggakanByLokasisIdParams): __Observable<{success?: boolean, data?: Tunggakan_by_lokasi, message?: string}> {
    return this.putPelangganReportTunggakanByLokasisIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_by_lokasi, message?: string})
    );
  }

  /**
   * Remove the specified Tunggakan_by_lokasi from storage
   *
   * Delete Tunggakan_by_lokasi
   * @param id id of Tunggakan_by_lokasi
   * @return successful operation
   */
  deletePelangganReportTunggakanByLokasisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/tunggakanByLokasis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Tunggakan_by_lokasi from storage
   *
   * Delete Tunggakan_by_lokasi
   * @param id id of Tunggakan_by_lokasi
   * @return successful operation
   */
  deletePelangganReportTunggakanByLokasisId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportTunggakanByLokasisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module TunggakanByLokasiService {

  /**
   * Parameters for getPelangganReportTunggakanByLokasis
   */
  export interface GetPelangganReportTunggakanByLokasisParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Tunggakan_by_lokasi
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportTunggakanByLokasisId
   */
  export interface PutPelangganReportTunggakanByLokasisIdParams {

    /**
     * id of Tunggakan_by_lokasi
     */
    id: number;

    /**
     * Tunggakan_by_lokasi that should be updated
     */
    body?: Tunggakan_by_lokasi;
  }
}

export { TunggakanByLokasiService }
