/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Baca_meter_by_status_lokasi } from '../models/baca-_meter-_by-_status-_lokasi';
@Injectable({
  providedIn: 'root',
})
class BacaMeterByStatusLokasiService extends __BaseService {
  static readonly getPelangganReportBacaMeterByStatusLokasisPath = '/pelangganReport/bacaMeterByStatusLokasis';
  static readonly postPelangganReportBacaMeterByStatusLokasisPath = '/pelangganReport/bacaMeterByStatusLokasis';
  static readonly getPelangganReportBacaMeterByStatusLokasisIdPath = '/pelangganReport/bacaMeterByStatusLokasis/{id}';
  static readonly putPelangganReportBacaMeterByStatusLokasisIdPath = '/pelangganReport/bacaMeterByStatusLokasis/{id}';
  static readonly deletePelangganReportBacaMeterByStatusLokasisIdPath = '/pelangganReport/bacaMeterByStatusLokasis/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Baca_meter_by_status_lokasi.
   *
   * Get all Baca_meter_by_status_lokasi
   * @param params The `BacaMeterByStatusLokasiService.GetPelangganReportBacaMeterByStatusLokasisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Baca_meter_by_status_lokasi
   *
   * @return successful operation
   */
  getPelangganReportBacaMeterByStatusLokasisResponse(params: BacaMeterByStatusLokasiService.GetPelangganReportBacaMeterByStatusLokasisParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_by_status_lokasi>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/bacaMeterByStatusLokasis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_by_status_lokasi>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Baca_meter_by_status_lokasi.
   *
   * Get all Baca_meter_by_status_lokasi
   * @param params The `BacaMeterByStatusLokasiService.GetPelangganReportBacaMeterByStatusLokasisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Baca_meter_by_status_lokasi
   *
   * @return successful operation
   */
  getPelangganReportBacaMeterByStatusLokasis(params: BacaMeterByStatusLokasiService.GetPelangganReportBacaMeterByStatusLokasisParams): __Observable<{success?: boolean, data?: Array<Baca_meter_by_status_lokasi>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportBacaMeterByStatusLokasisResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Baca_meter_by_status_lokasi>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Baca_meter_by_status_lokasi in storage
   *
   * Store Baca_meter_by_status_lokasi
   * @param body Baca_meter_by_status_lokasi that should be stored
   * @return successful operation
   */
  postPelangganReportBacaMeterByStatusLokasisResponse(body?: Baca_meter_by_status_lokasi): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/bacaMeterByStatusLokasis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Baca_meter_by_status_lokasi in storage
   *
   * Store Baca_meter_by_status_lokasi
   * @param body Baca_meter_by_status_lokasi that should be stored
   * @return successful operation
   */
  postPelangganReportBacaMeterByStatusLokasis(body?: Baca_meter_by_status_lokasi): __Observable<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}> {
    return this.postPelangganReportBacaMeterByStatusLokasisResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string})
    );
  }

  /**
   * Display the specified Baca_meter_by_status_lokasi
   *
   * Get Baca_meter_by_status_lokasi
   * @param id id of Baca_meter_by_status_lokasi
   * @return successful operation
   */
  getPelangganReportBacaMeterByStatusLokasisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/bacaMeterByStatusLokasis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Baca_meter_by_status_lokasi
   *
   * Get Baca_meter_by_status_lokasi
   * @param id id of Baca_meter_by_status_lokasi
   * @return successful operation
   */
  getPelangganReportBacaMeterByStatusLokasisId(id: number): __Observable<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}> {
    return this.getPelangganReportBacaMeterByStatusLokasisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string})
    );
  }

  /**
   * Update the specified Baca_meter_by_status_lokasi in storage
   *
   * Update Baca_meter_by_status_lokasi
   * @param params The `BacaMeterByStatusLokasiService.PutPelangganReportBacaMeterByStatusLokasisIdParams` containing the following parameters:
   *
   * - `id`: id of Baca_meter_by_status_lokasi
   *
   * - `body`: Baca_meter_by_status_lokasi that should be updated
   *
   * @return successful operation
   */
  putPelangganReportBacaMeterByStatusLokasisIdResponse(params: BacaMeterByStatusLokasiService.PutPelangganReportBacaMeterByStatusLokasisIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/bacaMeterByStatusLokasis/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Baca_meter_by_status_lokasi in storage
   *
   * Update Baca_meter_by_status_lokasi
   * @param params The `BacaMeterByStatusLokasiService.PutPelangganReportBacaMeterByStatusLokasisIdParams` containing the following parameters:
   *
   * - `id`: id of Baca_meter_by_status_lokasi
   *
   * - `body`: Baca_meter_by_status_lokasi that should be updated
   *
   * @return successful operation
   */
  putPelangganReportBacaMeterByStatusLokasisId(params: BacaMeterByStatusLokasiService.PutPelangganReportBacaMeterByStatusLokasisIdParams): __Observable<{success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string}> {
    return this.putPelangganReportBacaMeterByStatusLokasisIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_by_status_lokasi, message?: string})
    );
  }

  /**
   * Remove the specified Baca_meter_by_status_lokasi from storage
   *
   * Delete Baca_meter_by_status_lokasi
   * @param id id of Baca_meter_by_status_lokasi
   * @return successful operation
   */
  deletePelangganReportBacaMeterByStatusLokasisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/bacaMeterByStatusLokasis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Baca_meter_by_status_lokasi from storage
   *
   * Delete Baca_meter_by_status_lokasi
   * @param id id of Baca_meter_by_status_lokasi
   * @return successful operation
   */
  deletePelangganReportBacaMeterByStatusLokasisId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportBacaMeterByStatusLokasisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module BacaMeterByStatusLokasiService {

  /**
   * Parameters for getPelangganReportBacaMeterByStatusLokasis
   */
  export interface GetPelangganReportBacaMeterByStatusLokasisParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Baca_meter_by_status_lokasi
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportBacaMeterByStatusLokasisId
   */
  export interface PutPelangganReportBacaMeterByStatusLokasisIdParams {

    /**
     * id of Baca_meter_by_status_lokasi
     */
    id: number;

    /**
     * Baca_meter_by_status_lokasi that should be updated
     */
    body?: Baca_meter_by_status_lokasi;
  }
}

export { BacaMeterByStatusLokasiService }
