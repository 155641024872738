import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerService, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Cabang, Wilayah, Blok, Sumber_air, Type_pelanggan } from 'src/app/sdk/pelanggan/models';
import { PelangganService, TypePelangganService, CabangService, WilayahService, BlokService, SumberAirService } from 'src/app/sdk/pelanggan/services';
import { PesanService } from 'src/app/services/pesan.service';

@Component({
    selector: 'app-edit-data-pelanggan',
    templateUrl: './edit-data-pelanggan.component.html',
    styleUrls: ['./edit-data-pelanggan.component.scss']
})
export class EditDataPelangganComponent implements OnInit {

    @Input() id_pelanggan = null
    @Input() status = null
    constructor(
        private pesan: PesanService,
        private router: Router,
        private drawerService: NzDrawerService,
        private nzDrawerRef: NzDrawerRef<string>,
        private pelangganService: PelangganService,
        private typePelangganService: TypePelangganService,
        private cabangService: CabangService,
        private wilayahService: WilayahService,
        private blokService: BlokService,
        private sumberAirService: SumberAirService,
    ) { }


    ngOnInit(): void {
        this.getPreeData()
        this.getData()
        console.log(this.status)
    }

    getData() {
        this.pelangganService.getPelangganPelanggansId(this.id_pelanggan).subscribe(
            data => {
                this.dataPelanggan = data.data
            }
        )
    }

    dataPelanggan: any = {}

    listCabang: Cabang[] = []
    listWilayah: Wilayah[] = []
    listBlok: Blok[] = []
    listSumber: Sumber_air[] = []
    listTipe: Type_pelanggan[] = []

    getPreeData() {
        let param = {}
        this.cabangService.getPelangganCabangs(param).subscribe(
            data => {
                this.listCabang = data.data
            })
        this.sumberAirService.getPelangganSumberAirs(param).subscribe(
            data => {
                this.listSumber = data.data
            })
        this.typePelangganService.getPelangganTypePelanggans(param).subscribe(
            data => {
                this.listTipe = data.data
            })
    }

    getWilayah() {
        let params = {
            filter: JSON.stringify({id_cabang: this.dataPelanggan.id_cabang})
        }
        this.wilayahService.getPelangganWilayahs(params).subscribe(data => {
            this.listWilayah = data.data
        })
    }

    getBlok() {
        let param = {
            filter: JSON.stringify({
                id_wilayah: this.dataPelanggan.id_wilayah
            })
        }
        this.blokService.getPelangganBloks(param).subscribe(data => {
            this.listBlok = data.data
        })
    }



    simpan() {
        if (!this.dataPelanggan.nama_pelanggan) {
            this.pesan.pesanWarningForm('nama pelanggan tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.kd_pelanggan) {
            this.pesan.pesanWarningForm('kode pelanggan tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.alamat) {
            this.pesan.pesanWarningForm('alamat tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.hp) {
            this.pesan.pesanWarningForm('Nomor HP tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_type_pelanggan) {
            this.pesan.pesanWarningForm('type pelanggan tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_cabang) {
            this.pesan.pesanWarningForm('cabang tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_wilayah) {
            this.pesan.pesanWarningForm('wilayah tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_blok) {
            this.pesan.pesanWarningForm('blok tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_sumber_air) {
            this.pesan.pesanWarningForm('sumber air tidak boleh kosong')
            return
        }
        this.pelangganService.putPelangganPelanggansId({ id: this.id_pelanggan, body: this.dataPelanggan }).subscribe(
            data => {
                this.goToBack('berhasil')
            },
            err => {
                this.pesan.pesanWarningForm('Gagal di simpan')
            },
            () => {
                console.log('HTTP request completed.')
            },
        )
    }

    goToBack(data = null) {
        this.nzDrawerRef.close(data)
    }

}
