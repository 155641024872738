/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Switching_report } from '../models/switching-_report';
@Injectable({
  providedIn: 'root',
})
class SwitchingReportService extends __BaseService {
  static readonly getReportSwitchingReportsPath = '/report/switchingReports';
  static readonly postReportSwitchingReportsPath = '/report/switchingReports';
  static readonly getReportSwitchingReportsIdPath = '/report/switchingReports/{id}';
  static readonly putReportSwitchingReportsIdPath = '/report/switchingReports/{id}';
  static readonly deleteReportSwitchingReportsIdPath = '/report/switchingReports/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the switching_report.
   *
   * Get all switching_report
   * @param params The `SwitchingReportService.GetReportSwitchingReportsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter switching_report
   *
   * @return successful operation
   */
  getReportSwitchingReportsResponse(params: SwitchingReportService.GetReportSwitchingReportsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Switching_report>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/switchingReports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Switching_report>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the switching_report.
   *
   * Get all switching_report
   * @param params The `SwitchingReportService.GetReportSwitchingReportsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter switching_report
   *
   * @return successful operation
   */
  getReportSwitchingReports(params: SwitchingReportService.GetReportSwitchingReportsParams): __Observable<{success?: boolean, data?: Array<Switching_report>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportSwitchingReportsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Switching_report>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created switching_report in storage
   *
   * Store switching_report
   * @param body switching_report that should be stored
   * @return successful operation
   */
  postReportSwitchingReportsResponse(body?: Switching_report): __Observable<__StrictHttpResponse<{success?: boolean, data?: Switching_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report/switchingReports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Switching_report, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created switching_report in storage
   *
   * Store switching_report
   * @param body switching_report that should be stored
   * @return successful operation
   */
  postReportSwitchingReports(body?: Switching_report): __Observable<{success?: boolean, data?: Switching_report, message?: string}> {
    return this.postReportSwitchingReportsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Switching_report, message?: string})
    );
  }

  /**
   * Display the specified switching_report
   *
   * Get switching_report
   * @param id id of switching_report
   * @return successful operation
   */
  getReportSwitchingReportsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Switching_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/switchingReports/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Switching_report, message?: string}>;
      })
    );
  }
  /**
   * Display the specified switching_report
   *
   * Get switching_report
   * @param id id of switching_report
   * @return successful operation
   */
  getReportSwitchingReportsId(id: number): __Observable<{success?: boolean, data?: Switching_report, message?: string}> {
    return this.getReportSwitchingReportsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Switching_report, message?: string})
    );
  }

  /**
   * Update the specified switching_report in storage
   *
   * Update switching_report
   * @param params The `SwitchingReportService.PutReportSwitchingReportsIdParams` containing the following parameters:
   *
   * - `id`: id of switching_report
   *
   * - `body`: switching_report that should be updated
   *
   * @return successful operation
   */
  putReportSwitchingReportsIdResponse(params: SwitchingReportService.PutReportSwitchingReportsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Switching_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/report/switchingReports/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Switching_report, message?: string}>;
      })
    );
  }
  /**
   * Update the specified switching_report in storage
   *
   * Update switching_report
   * @param params The `SwitchingReportService.PutReportSwitchingReportsIdParams` containing the following parameters:
   *
   * - `id`: id of switching_report
   *
   * - `body`: switching_report that should be updated
   *
   * @return successful operation
   */
  putReportSwitchingReportsId(params: SwitchingReportService.PutReportSwitchingReportsIdParams): __Observable<{success?: boolean, data?: Switching_report, message?: string}> {
    return this.putReportSwitchingReportsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Switching_report, message?: string})
    );
  }

  /**
   * Remove the specified switching_report from storage
   *
   * Delete switching_report
   * @param id id of switching_report
   * @return successful operation
   */
  deleteReportSwitchingReportsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/report/switchingReports/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified switching_report from storage
   *
   * Delete switching_report
   * @param id id of switching_report
   * @return successful operation
   */
  deleteReportSwitchingReportsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportSwitchingReportsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module SwitchingReportService {

  /**
   * Parameters for getReportSwitchingReports
   */
  export interface GetReportSwitchingReportsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter switching_report
     */
    filter?: string;
  }

  /**
   * Parameters for putReportSwitchingReportsId
   */
  export interface PutReportSwitchingReportsIdParams {

    /**
     * id of switching_report
     */
    id: number;

    /**
     * switching_report that should be updated
     */
    body?: Switching_report;
  }
}

export { SwitchingReportService }
