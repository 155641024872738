/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportVPegawaiPosisi } from '../models/hr-report-v-pegawai-posisi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportVPegawaiPosisiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportVPegawaiPosisiList
   */
  static readonly GetHrReportVPegawaiPosisiListPath = '/hrReport/v_pegawai_posisis';

  /**
   * getv_pegawai_posisiList.
   *
   * Get all v_pegawai_posisis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportVPegawaiPosisiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportVPegawaiPosisiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportVPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportVPegawaiPosisiService.GetHrReportVPegawaiPosisiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportVPegawaiPosisi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getv_pegawai_posisiList.
   *
   * Get all v_pegawai_posisis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportVPegawaiPosisiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportVPegawaiPosisiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportVPegawaiPosisi>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportVPegawaiPosisiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportVPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportVPegawaiPosisi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportVPegawaiPosisi
   */
  static readonly StoreHrReportVPegawaiPosisiPath = '/hrReport/v_pegawai_posisis';

  /**
   * createv_pegawai_posisi.
   *
   * Create v_pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportVPegawaiPosisi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportVPegawaiPosisi$Response(params: {
    body: HrReportVPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportVPegawaiPosisiService.StoreHrReportVPegawaiPosisiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportVPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createv_pegawai_posisi.
   *
   * Create v_pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportVPegawaiPosisi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportVPegawaiPosisi(params: {
    body: HrReportVPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}> {

    return this.storeHrReportVPegawaiPosisi$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportVPegawaiPosisiId
   */
  static readonly GetHrReportVPegawaiPosisiIdPath = '/hrReport/v_pegawai_posisis/{id}';

  /**
   * getv_pegawai_posisiItem.
   *
   * Get v_pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportVPegawaiPosisiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportVPegawaiPosisiId$Response(params: {

    /**
     * id of v_pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportVPegawaiPosisiService.GetHrReportVPegawaiPosisiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportVPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getv_pegawai_posisiItem.
   *
   * Get v_pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportVPegawaiPosisiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportVPegawaiPosisiId(params: {

    /**
     * id of v_pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}> {

    return this.getHrReportVPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportVPegawaiPosisiId
   */
  static readonly PutHrReportVPegawaiPosisiIdPath = '/hrReport/v_pegawai_posisis/{id}';

  /**
   * updatev_pegawai_posisi.
   *
   * Update v_pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportVPegawaiPosisiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportVPegawaiPosisiId$Response(params: {

    /**
     * id of v_pegawai_posisi
     */
    id: string;
    body: HrReportVPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportVPegawaiPosisiService.PutHrReportVPegawaiPosisiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportVPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatev_pegawai_posisi.
   *
   * Update v_pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportVPegawaiPosisiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportVPegawaiPosisiId(params: {

    /**
     * id of v_pegawai_posisi
     */
    id: string;
    body: HrReportVPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}> {

    return this.putHrReportVPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportVPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportVPegawaiPosisisId
   */
  static readonly DeleteHrReportVPegawaiPosisisIdPath = '/hrReport/v_pegawai_posisis/{id}';

  /**
   * deletev_pegawai_posisi.
   *
   * Delete v_pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportVPegawaiPosisisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportVPegawaiPosisisId$Response(params: {

    /**
     * id of v_pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportVPegawaiPosisiService.DeleteHrReportVPegawaiPosisisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletev_pegawai_posisi.
   *
   * Delete v_pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportVPegawaiPosisisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportVPegawaiPosisisId(params: {

    /**
     * id of v_pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportVPegawaiPosisisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
