/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AndroidStatusMeter } from '../models/android-status-meter';
@Injectable({
  providedIn: 'root',
})
class AndroidStatusMeterService extends __BaseService {
  static readonly getAndroidAndroidStatusMetersPath = '/android/androidStatusMeters';
  static readonly postAndroidAndroidStatusMetersPath = '/android/androidStatusMeters';
  static readonly getAndroidAndroidStatusMetersIdPath = '/android/androidStatusMeters/{id}';
  static readonly putAndroidAndroidStatusMetersIdPath = '/android/androidStatusMeters/{id}';
  static readonly deleteAndroidAndroidStatusMetersIdPath = '/android/androidStatusMeters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the AndroidStatusMeter.
   *
   * Get all AndroidStatusMeter
   * @param params The `AndroidStatusMeterService.GetAndroidAndroidStatusMetersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter AndroidStatusMeter
   *
   * @return successful operation
   */
  getAndroidAndroidStatusMetersResponse(params: AndroidStatusMeterService.GetAndroidAndroidStatusMetersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<AndroidStatusMeter>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/android/androidStatusMeters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<AndroidStatusMeter>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the AndroidStatusMeter.
   *
   * Get all AndroidStatusMeter
   * @param params The `AndroidStatusMeterService.GetAndroidAndroidStatusMetersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter AndroidStatusMeter
   *
   * @return successful operation
   */
  getAndroidAndroidStatusMeters(params: AndroidStatusMeterService.GetAndroidAndroidStatusMetersParams): __Observable<{success?: boolean, data?: Array<AndroidStatusMeter>, message?: string, total_data?: number, total_page?: number}> {
    return this.getAndroidAndroidStatusMetersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<AndroidStatusMeter>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created AndroidStatusMeter in storage
   *
   * Store AndroidStatusMeter
   * @param body AndroidStatusMeter that should be stored
   * @return successful operation
   */
  postAndroidAndroidStatusMetersResponse(body?: AndroidStatusMeter): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidStatusMeter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/android/androidStatusMeters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidStatusMeter, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created AndroidStatusMeter in storage
   *
   * Store AndroidStatusMeter
   * @param body AndroidStatusMeter that should be stored
   * @return successful operation
   */
  postAndroidAndroidStatusMeters(body?: AndroidStatusMeter): __Observable<{success?: boolean, data?: AndroidStatusMeter, message?: string}> {
    return this.postAndroidAndroidStatusMetersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidStatusMeter, message?: string})
    );
  }

  /**
   * Display the specified AndroidStatusMeter
   *
   * Get AndroidStatusMeter
   * @param id id of AndroidStatusMeter
   * @return successful operation
   */
  getAndroidAndroidStatusMetersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidStatusMeter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/android/androidStatusMeters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidStatusMeter, message?: string}>;
      })
    );
  }
  /**
   * Display the specified AndroidStatusMeter
   *
   * Get AndroidStatusMeter
   * @param id id of AndroidStatusMeter
   * @return successful operation
   */
  getAndroidAndroidStatusMetersId(id: number): __Observable<{success?: boolean, data?: AndroidStatusMeter, message?: string}> {
    return this.getAndroidAndroidStatusMetersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidStatusMeter, message?: string})
    );
  }

  /**
   * Update the specified AndroidStatusMeter in storage
   *
   * Update AndroidStatusMeter
   * @param params The `AndroidStatusMeterService.PutAndroidAndroidStatusMetersIdParams` containing the following parameters:
   *
   * - `id`: id of AndroidStatusMeter
   *
   * - `body`: AndroidStatusMeter that should be updated
   *
   * @return successful operation
   */
  putAndroidAndroidStatusMetersIdResponse(params: AndroidStatusMeterService.PutAndroidAndroidStatusMetersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidStatusMeter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/android/androidStatusMeters/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidStatusMeter, message?: string}>;
      })
    );
  }
  /**
   * Update the specified AndroidStatusMeter in storage
   *
   * Update AndroidStatusMeter
   * @param params The `AndroidStatusMeterService.PutAndroidAndroidStatusMetersIdParams` containing the following parameters:
   *
   * - `id`: id of AndroidStatusMeter
   *
   * - `body`: AndroidStatusMeter that should be updated
   *
   * @return successful operation
   */
  putAndroidAndroidStatusMetersId(params: AndroidStatusMeterService.PutAndroidAndroidStatusMetersIdParams): __Observable<{success?: boolean, data?: AndroidStatusMeter, message?: string}> {
    return this.putAndroidAndroidStatusMetersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidStatusMeter, message?: string})
    );
  }

  /**
   * Remove the specified AndroidStatusMeter from storage
   *
   * Delete AndroidStatusMeter
   * @param id id of AndroidStatusMeter
   * @return successful operation
   */
  deleteAndroidAndroidStatusMetersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/android/androidStatusMeters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified AndroidStatusMeter from storage
   *
   * Delete AndroidStatusMeter
   * @param id id of AndroidStatusMeter
   * @return successful operation
   */
  deleteAndroidAndroidStatusMetersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteAndroidAndroidStatusMetersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module AndroidStatusMeterService {

  /**
   * Parameters for getAndroidAndroidStatusMeters
   */
  export interface GetAndroidAndroidStatusMetersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter AndroidStatusMeter
     */
    filter?: string;
  }

  /**
   * Parameters for putAndroidAndroidStatusMetersId
   */
  export interface PutAndroidAndroidStatusMetersIdParams {

    /**
     * id of AndroidStatusMeter
     */
    id: number;

    /**
     * AndroidStatusMeter that should be updated
     */
    body?: AndroidStatusMeter;
  }
}

export { AndroidStatusMeterService }
