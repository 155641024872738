/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportWidgetKehadiranDownload } from '../models/absensi-report-widget-kehadiran-download';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportWidgetKehadiranDownloadService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportWidgetKehadiranDownloadList
   */
  static readonly GetAbsensiReportWidgetKehadiranDownloadListPath = '/absensiReportWidget/kehadiran_downloads';

  /**
   * getkehadiran_downloadList.
   *
   * Get all kehadiran_downloads
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetKehadiranDownloadList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetKehadiranDownloadList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetKehadiranDownload>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetKehadiranDownloadService.GetAbsensiReportWidgetKehadiranDownloadListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportWidgetKehadiranDownload>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getkehadiran_downloadList.
   *
   * Get all kehadiran_downloads
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetKehadiranDownloadList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetKehadiranDownloadList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetKehadiranDownload>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportWidgetKehadiranDownloadList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetKehadiranDownload>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetKehadiranDownload>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportWidgetKehadiranDownload
   */
  static readonly StoreAbsensiReportWidgetKehadiranDownloadPath = '/absensiReportWidget/kehadiran_downloads';

  /**
   * createkehadiran_download.
   *
   * Create kehadiran_download
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportWidgetKehadiranDownload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetKehadiranDownload$Response(params: {
    body: AbsensiReportWidgetKehadiranDownload
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetKehadiranDownloadService.StoreAbsensiReportWidgetKehadiranDownloadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetKehadiranDownload;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createkehadiran_download.
   *
   * Create kehadiran_download
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportWidgetKehadiranDownload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetKehadiranDownload(params: {
    body: AbsensiReportWidgetKehadiranDownload
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}> {

    return this.storeAbsensiReportWidgetKehadiranDownload$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportWidgetKehadiranDownloadId
   */
  static readonly GetAbsensiReportWidgetKehadiranDownloadIdPath = '/absensiReportWidget/kehadiran_downloads/{id}';

  /**
   * getkehadiran_downloadItem.
   *
   * Get kehadiran_download
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetKehadiranDownloadId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetKehadiranDownloadId$Response(params: {

    /**
     * id of kehadiran_download
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetKehadiranDownloadService.GetAbsensiReportWidgetKehadiranDownloadIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetKehadiranDownload;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getkehadiran_downloadItem.
   *
   * Get kehadiran_download
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetKehadiranDownloadId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetKehadiranDownloadId(params: {

    /**
     * id of kehadiran_download
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}> {

    return this.getAbsensiReportWidgetKehadiranDownloadId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportWidgetKehadiranDownloadId
   */
  static readonly PutAbsensiReportWidgetKehadiranDownloadIdPath = '/absensiReportWidget/kehadiran_downloads/{id}';

  /**
   * updatekehadiran_download.
   *
   * Update kehadiran_download
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportWidgetKehadiranDownloadId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetKehadiranDownloadId$Response(params: {

    /**
     * id of kehadiran_download
     */
    id: string;
    body: AbsensiReportWidgetKehadiranDownload
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetKehadiranDownloadService.PutAbsensiReportWidgetKehadiranDownloadIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetKehadiranDownload;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatekehadiran_download.
   *
   * Update kehadiran_download
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportWidgetKehadiranDownloadId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetKehadiranDownloadId(params: {

    /**
     * id of kehadiran_download
     */
    id: string;
    body: AbsensiReportWidgetKehadiranDownload
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}> {

    return this.putAbsensiReportWidgetKehadiranDownloadId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetKehadiranDownload;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportWidgetKehadiranDownloadsId
   */
  static readonly DeleteAbsensiReportWidgetKehadiranDownloadsIdPath = '/absensiReportWidget/kehadiran_downloads/{id}';

  /**
   * deletekehadiran_download.
   *
   * Delete kehadiran_download
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportWidgetKehadiranDownloadsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetKehadiranDownloadsId$Response(params: {

    /**
     * id of kehadiran_download
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetKehadiranDownloadService.DeleteAbsensiReportWidgetKehadiranDownloadsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletekehadiran_download.
   *
   * Delete kehadiran_download
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportWidgetKehadiranDownloadsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetKehadiranDownloadsId(params: {

    /**
     * id of kehadiran_download
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportWidgetKehadiranDownloadsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
