/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pelanggan_tertagih } from '../models/pelanggan-_tertagih';
@Injectable({
  providedIn: 'root',
})
class PelangganTertagihService extends __BaseService {
  static readonly getPenagihanReportPelangganTertagihsPath = '/penagihanReport/pelangganTertagihs';
  static readonly postPenagihanReportPelangganTertagihsPath = '/penagihanReport/pelangganTertagihs';
  static readonly getPenagihanReportPelangganTertagihsIdPath = '/penagihanReport/pelangganTertagihs/{id}';
  static readonly putPenagihanReportPelangganTertagihsIdPath = '/penagihanReport/pelangganTertagihs/{id}';
  static readonly deletePenagihanReportPelangganTertagihsIdPath = '/penagihanReport/pelangganTertagihs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Pelanggan_tertagih.
   *
   * Get all Pelanggan_tertagih
   * @param params The `PelangganTertagihService.GetPenagihanReportPelangganTertagihsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pelanggan_tertagih
   *
   * @return successful operation
   */
  getPenagihanReportPelangganTertagihsResponse(params: PelangganTertagihService.GetPenagihanReportPelangganTertagihsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_tertagih>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/pelangganTertagihs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_tertagih>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Pelanggan_tertagih.
   *
   * Get all Pelanggan_tertagih
   * @param params The `PelangganTertagihService.GetPenagihanReportPelangganTertagihsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pelanggan_tertagih
   *
   * @return successful operation
   */
  getPenagihanReportPelangganTertagihs(params: PelangganTertagihService.GetPenagihanReportPelangganTertagihsParams): __Observable<{success?: boolean, data?: Array<Pelanggan_tertagih>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportPelangganTertagihsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pelanggan_tertagih>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Pelanggan_tertagih in storage
   *
   * Store Pelanggan_tertagih
   * @param body Pelanggan_tertagih that should be stored
   * @return successful operation
   */
  postPenagihanReportPelangganTertagihsResponse(body?: Pelanggan_tertagih): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_tertagih, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/pelangganTertagihs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_tertagih, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Pelanggan_tertagih in storage
   *
   * Store Pelanggan_tertagih
   * @param body Pelanggan_tertagih that should be stored
   * @return successful operation
   */
  postPenagihanReportPelangganTertagihs(body?: Pelanggan_tertagih): __Observable<{success?: boolean, data?: Pelanggan_tertagih, message?: string}> {
    return this.postPenagihanReportPelangganTertagihsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_tertagih, message?: string})
    );
  }

  /**
   * Display the specified Pelanggan_tertagih
   *
   * Get Pelanggan_tertagih
   * @param id id of Pelanggan_tertagih
   * @return successful operation
   */
  getPenagihanReportPelangganTertagihsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_tertagih, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/pelangganTertagihs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_tertagih, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Pelanggan_tertagih
   *
   * Get Pelanggan_tertagih
   * @param id id of Pelanggan_tertagih
   * @return successful operation
   */
  getPenagihanReportPelangganTertagihsId(id: number): __Observable<{success?: boolean, data?: Pelanggan_tertagih, message?: string}> {
    return this.getPenagihanReportPelangganTertagihsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_tertagih, message?: string})
    );
  }

  /**
   * Update the specified Pelanggan_tertagih in storage
   *
   * Update Pelanggan_tertagih
   * @param params The `PelangganTertagihService.PutPenagihanReportPelangganTertagihsIdParams` containing the following parameters:
   *
   * - `id`: id of Pelanggan_tertagih
   *
   * - `body`: Pelanggan_tertagih that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPelangganTertagihsIdResponse(params: PelangganTertagihService.PutPenagihanReportPelangganTertagihsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_tertagih, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/pelangganTertagihs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_tertagih, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Pelanggan_tertagih in storage
   *
   * Update Pelanggan_tertagih
   * @param params The `PelangganTertagihService.PutPenagihanReportPelangganTertagihsIdParams` containing the following parameters:
   *
   * - `id`: id of Pelanggan_tertagih
   *
   * - `body`: Pelanggan_tertagih that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPelangganTertagihsId(params: PelangganTertagihService.PutPenagihanReportPelangganTertagihsIdParams): __Observable<{success?: boolean, data?: Pelanggan_tertagih, message?: string}> {
    return this.putPenagihanReportPelangganTertagihsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_tertagih, message?: string})
    );
  }

  /**
   * Remove the specified Pelanggan_tertagih from storage
   *
   * Delete Pelanggan_tertagih
   * @param id id of Pelanggan_tertagih
   * @return successful operation
   */
  deletePenagihanReportPelangganTertagihsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/pelangganTertagihs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Pelanggan_tertagih from storage
   *
   * Delete Pelanggan_tertagih
   * @param id id of Pelanggan_tertagih
   * @return successful operation
   */
  deletePenagihanReportPelangganTertagihsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportPelangganTertagihsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PelangganTertagihService {

  /**
   * Parameters for getPenagihanReportPelangganTertagihs
   */
  export interface GetPenagihanReportPelangganTertagihsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Pelanggan_tertagih
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportPelangganTertagihsId
   */
  export interface PutPenagihanReportPelangganTertagihsIdParams {

    /**
     * id of Pelanggan_tertagih
     */
    id: number;

    /**
     * Pelanggan_tertagih that should be updated
     */
    body?: Pelanggan_tertagih;
  }
}

export { PelangganTertagihService }
