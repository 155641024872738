/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientTipePengaduan } from '../models/client-tipe-pengaduan';

@Injectable({
  providedIn: 'root',
})
export class ClientTipePengaduanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientTipePengaduansList
   */
  static readonly GetclientTipePengaduansListPath = '/client/tipePengaduans';

  /**
   * Get list of tipePengaduans.
   *
   * Returns list of tipePengaduans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientTipePengaduansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientTipePengaduansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientTipePengaduan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientTipePengaduanService.GetclientTipePengaduansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientTipePengaduan>;
        }>;
      })
    );
  }

  /**
   * Get list of tipePengaduans.
   *
   * Returns list of tipePengaduans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientTipePengaduansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientTipePengaduansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientTipePengaduan>;
}> {

    return this.getclientTipePengaduansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientTipePengaduan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientTipePengaduan>;
})
    );
  }

  /**
   * Path part for operation getclientTipePengaduanId
   */
  static readonly GetclientTipePengaduanIdPath = '/client/tipePengaduans/{id}';

  /**
   * Get  Tipe_pengaduan by id.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientTipePengaduanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientTipePengaduanId$Response(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientTipePengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientTipePengaduanService.GetclientTipePengaduanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientTipePengaduan;
        }>;
      })
    );
  }

  /**
   * Get  Tipe_pengaduan by id.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientTipePengaduanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientTipePengaduanId(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientTipePengaduan;
}> {

    return this.getclientTipePengaduanId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientTipePengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientTipePengaduan;
})
    );
  }

}
