/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrKalenderPerusahaan } from '../models/hr-kalender-perusahaan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrKalenderPerusahaanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrKalenderPerusahaanList
   */
  static readonly GetHrKalenderPerusahaanListPath = '/hr/kalender_perusahaans';

  /**
   * getkalender_perusahaanList.
   *
   * Get all kalender_perusahaans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrKalenderPerusahaanList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrKalenderPerusahaanList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrKalenderPerusahaan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrKalenderPerusahaanService.GetHrKalenderPerusahaanListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrKalenderPerusahaan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getkalender_perusahaanList.
   *
   * Get all kalender_perusahaans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrKalenderPerusahaanList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrKalenderPerusahaanList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrKalenderPerusahaan>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrKalenderPerusahaanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrKalenderPerusahaan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrKalenderPerusahaan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrKalenderPerusahaan
   */
  static readonly StoreHrKalenderPerusahaanPath = '/hr/kalender_perusahaans';

  /**
   * createkalender_perusahaan.
   *
   * Create kalender_perusahaan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrKalenderPerusahaan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrKalenderPerusahaan$Response(params: {
    body: HrKalenderPerusahaan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrKalenderPerusahaanService.StoreHrKalenderPerusahaanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrKalenderPerusahaan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createkalender_perusahaan.
   *
   * Create kalender_perusahaan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrKalenderPerusahaan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrKalenderPerusahaan(params: {
    body: HrKalenderPerusahaan
  }): Observable<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}> {

    return this.storeHrKalenderPerusahaan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrKalenderPerusahaanId
   */
  static readonly GetHrKalenderPerusahaanIdPath = '/hr/kalender_perusahaans/{id}';

  /**
   * getkalender_perusahaanItem.
   *
   * Get kalender_perusahaan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrKalenderPerusahaanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrKalenderPerusahaanId$Response(params: {

    /**
     * id of kalender_perusahaan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrKalenderPerusahaanService.GetHrKalenderPerusahaanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrKalenderPerusahaan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getkalender_perusahaanItem.
   *
   * Get kalender_perusahaan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrKalenderPerusahaanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrKalenderPerusahaanId(params: {

    /**
     * id of kalender_perusahaan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}> {

    return this.getHrKalenderPerusahaanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrKalenderPerusahaanId
   */
  static readonly PutHrKalenderPerusahaanIdPath = '/hr/kalender_perusahaans/{id}';

  /**
   * updatekalender_perusahaan.
   *
   * Update kalender_perusahaan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrKalenderPerusahaanId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrKalenderPerusahaanId$Response(params: {

    /**
     * id of kalender_perusahaan
     */
    id: string;
    body: HrKalenderPerusahaan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrKalenderPerusahaanService.PutHrKalenderPerusahaanIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrKalenderPerusahaan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatekalender_perusahaan.
   *
   * Update kalender_perusahaan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrKalenderPerusahaanId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrKalenderPerusahaanId(params: {

    /**
     * id of kalender_perusahaan
     */
    id: string;
    body: HrKalenderPerusahaan
  }): Observable<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}> {

    return this.putHrKalenderPerusahaanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrKalenderPerusahaan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrKalenderPerusahaansId
   */
  static readonly DeleteHrKalenderPerusahaansIdPath = '/hr/kalender_perusahaans/{id}';

  /**
   * deletekalender_perusahaan.
   *
   * Delete kalender_perusahaan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrKalenderPerusahaansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrKalenderPerusahaansId$Response(params: {

    /**
     * id of kalender_perusahaan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrKalenderPerusahaanService.DeleteHrKalenderPerusahaansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletekalender_perusahaan.
   *
   * Delete kalender_perusahaan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrKalenderPerusahaansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrKalenderPerusahaansId(params: {

    /**
     * id of kalender_perusahaan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrKalenderPerusahaansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
