<div class="resultContent" *ngFor="let item of listOfData; let i = index">
    <div class="resultThumb">{{i+1}}</div>
    <div class="result">
        <div class="resultText">
            <a (click)="goToPelanggan(item)">
                {{item.nama_pelanggan}}
            </a>
        </div>
        <div class="resultSource"> <a  (click)="goToPelanggan(item)">
                {{item.kd_pelanggan}} / {{item.kd_pelanggan_old}}
            </a>
        </div>
    </div>
</div>
