/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Lembaga } from '../models/lembaga';
@Injectable({
  providedIn: 'root',
})
class LembagaService extends __BaseService {
  static readonly getArsipLembagasPath = '/arsip/lembagas';
  static readonly postArsipLembagasPath = '/arsip/lembagas';
  static readonly getArsipLembagasIdPath = '/arsip/lembagas/{id}';
  static readonly putArsipLembagasIdPath = '/arsip/lembagas/{id}';
  static readonly deleteArsipLembagasIdPath = '/arsip/lembagas/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Lembaga.
   *
   * Get all Lembaga
   * @param params The `LembagaService.GetArsipLembagasParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Lembaga
   *
   * @return successful operation
   */
  getArsipLembagasResponse(params: LembagaService.GetArsipLembagasParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Lembaga>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/lembagas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Lembaga>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Lembaga.
   *
   * Get all Lembaga
   * @param params The `LembagaService.GetArsipLembagasParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Lembaga
   *
   * @return successful operation
   */
  getArsipLembagas(params: LembagaService.GetArsipLembagasParams): __Observable<{success?: boolean, data?: Array<Lembaga>, message?: string, total_data?: number, total_page?: number}> {
    return this.getArsipLembagasResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Lembaga>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Lembaga in storage
   *
   * Store Lembaga
   * @param body Lembaga that should be stored
   * @return successful operation
   */
  postArsipLembagasResponse(body?: Lembaga): __Observable<__StrictHttpResponse<{success?: boolean, data?: Lembaga, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/arsip/lembagas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Lembaga, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Lembaga in storage
   *
   * Store Lembaga
   * @param body Lembaga that should be stored
   * @return successful operation
   */
  postArsipLembagas(body?: Lembaga): __Observable<{success?: boolean, data?: Lembaga, message?: string}> {
    return this.postArsipLembagasResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Lembaga, message?: string})
    );
  }

  /**
   * Display the specified Lembaga
   *
   * Get Lembaga
   * @param id id of Lembaga
   * @return successful operation
   */
  getArsipLembagasIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Lembaga, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/lembagas/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Lembaga, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Lembaga
   *
   * Get Lembaga
   * @param id id of Lembaga
   * @return successful operation
   */
  getArsipLembagasId(id: number): __Observable<{success?: boolean, data?: Lembaga, message?: string}> {
    return this.getArsipLembagasIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Lembaga, message?: string})
    );
  }

  /**
   * Update the specified Lembaga in storage
   *
   * Update Lembaga
   * @param params The `LembagaService.PutArsipLembagasIdParams` containing the following parameters:
   *
   * - `id`: id of Lembaga
   *
   * - `body`: Lembaga that should be updated
   *
   * @return successful operation
   */
  putArsipLembagasIdResponse(params: LembagaService.PutArsipLembagasIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Lembaga, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/arsip/lembagas/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Lembaga, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Lembaga in storage
   *
   * Update Lembaga
   * @param params The `LembagaService.PutArsipLembagasIdParams` containing the following parameters:
   *
   * - `id`: id of Lembaga
   *
   * - `body`: Lembaga that should be updated
   *
   * @return successful operation
   */
  putArsipLembagasId(params: LembagaService.PutArsipLembagasIdParams): __Observable<{success?: boolean, data?: Lembaga, message?: string}> {
    return this.putArsipLembagasIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Lembaga, message?: string})
    );
  }

  /**
   * Remove the specified Lembaga from storage
   *
   * Delete Lembaga
   * @param id id of Lembaga
   * @return successful operation
   */
  deleteArsipLembagasIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/arsip/lembagas/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Lembaga from storage
   *
   * Delete Lembaga
   * @param id id of Lembaga
   * @return successful operation
   */
  deleteArsipLembagasId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteArsipLembagasIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module LembagaService {

  /**
   * Parameters for getArsipLembagas
   */
  export interface GetArsipLembagasParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Lembaga
     */
    filter?: string;
  }

  /**
   * Parameters for putArsipLembagasId
   */
  export interface PutArsipLembagasIdParams {

    /**
     * id of Lembaga
     */
    id: number;

    /**
     * Lembaga that should be updated
     */
    body?: Lembaga;
  }
}

export { LembagaService }
