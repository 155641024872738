/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPengaduanByStatus } from '../models/client-pengaduan-by-status';

@Injectable({
  providedIn: 'root',
})
export class ClientPengaduanByStatusService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientPengaduanByStatusesList
   */
  static readonly GetclientPengaduanByStatusesListPath = '/client/pengaduanByStatuses';

  /**
   * Get list of pengaduanByStatuses.
   *
   * Returns list of pengaduanByStatuses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengaduanByStatusesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByStatusesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByStatus>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByStatusService.GetclientPengaduanByStatusesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPengaduanByStatus>;
        }>;
      })
    );
  }

  /**
   * Get list of pengaduanByStatuses.
   *
   * Returns list of pengaduanByStatuses
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengaduanByStatusesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByStatusesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByStatus>;
}> {

    return this.getclientPengaduanByStatusesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByStatus>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByStatus>;
})
    );
  }

  /**
   * Path part for operation storeclientPengaduanByStatus
   */
  static readonly StoreclientPengaduanByStatusPath = '/client/pengaduanByStatuses';

  /**
   * Store new Pengaduan_by_status.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientPengaduanByStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengaduanByStatus$Response(params: {
    body: ClientPengaduanByStatus
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByStatusService.StoreclientPengaduanByStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduanByStatus;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_status.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientPengaduanByStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengaduanByStatus(params: {
    body: ClientPengaduanByStatus
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}> {

    return this.storeclientPengaduanByStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
})
    );
  }

  /**
   * Path part for operation getclientPengaduanByStatusId
   */
  static readonly GetclientPengaduanByStatusIdPath = '/client/pengaduanByStatuses/{id}';

  /**
   * Get  Pengaduan_by_status by id.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengaduanByStatusId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByStatusId$Response(params: {

    /**
     * id of Pengaduan_by_status
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByStatusService.GetclientPengaduanByStatusIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduanByStatus;
        }>;
      })
    );
  }

  /**
   * Get  Pengaduan_by_status by id.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengaduanByStatusId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByStatusId(params: {

    /**
     * id of Pengaduan_by_status
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}> {

    return this.getclientPengaduanByStatusId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
})
    );
  }

  /**
   * Path part for operation putclientPengaduanByStatus
   */
  static readonly PutclientPengaduanByStatusPath = '/client/pengaduanByStatuses/{id}';

  /**
   * Update Pengaduan_by_status.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientPengaduanByStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPengaduanByStatus$Response(params: {

    /**
     * id of Pengaduan_by_status
     */
    id: string;
    body: ClientPengaduanByStatus
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByStatusService.PutclientPengaduanByStatusPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduanByStatus;
        }>;
      })
    );
  }

  /**
   * Update Pengaduan_by_status.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientPengaduanByStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPengaduanByStatus(params: {

    /**
     * id of Pengaduan_by_status
     */
    id: string;
    body: ClientPengaduanByStatus
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}> {

    return this.putclientPengaduanByStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByStatus;
})
    );
  }

  /**
   * Path part for operation deleteclientPengaduanByStatus
   */
  static readonly DeleteclientPengaduanByStatusPath = '/client/pengaduanByStatuses/{id}';

  /**
   * Store new Pengaduan_by_status.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientPengaduanByStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPengaduanByStatus$Response(params: {

    /**
     * id of Pengaduan_by_status
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByStatusService.DeleteclientPengaduanByStatusPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_status.
   *
   * Returns Pengaduan_by_status data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientPengaduanByStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPengaduanByStatus(params: {

    /**
     * id of Pengaduan_by_status
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientPengaduanByStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
