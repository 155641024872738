
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { reducers, metaReducers } from './store/reducers'
import { UserEffects } from './store/user/effects'
import { jwtAuthService } from './services/jwt'
import { MockHttpCallInterceptor } from './services/fakeApi'

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';


// import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { registerLocaleData } from '@angular/common'
import { default as localeEn } from '@angular/common/locales/en'
import { NZ_I18N, en_US as localeZorro } from 'ng-zorro-antd/i18n'
import { ApiModule } from './sdk/pelanggan/api.module'
import { ApiModule as ApiModulePenagihan } from './sdk/penagihan/api.module'
import { ApiModule as ApiModuleHR } from './sdk/hr/api.module'
import { ApiModule as ApiModuleCms } from './sdk/cms/api.module'
import { environment } from 'src/environments/environment'
import { AuthInterceptor } from './services/jwt/auth.interceptor'
import { ErrorInterceptorService } from './services/jwt/error-interceptor.service'
import { AuthGuard } from './components/_role/auth.guard'
const LOCALE_PROVIDERS = [
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: NZ_I18N, useValue: localeZorro },
]
registerLocaleData(localeEn, 'en')

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,

        // translate
        TranslateModule.forRoot(),

        // ngrx
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([UserEffects]),
        StoreRouterConnectingModule.forRoot(),
        ApiModule.forRoot({ rootUrl: environment.pelanggan }),
        ApiModulePenagihan.forRoot({ rootUrl: environment.penagihan }),
        ApiModuleHR.forRoot({ rootUrl: environment.hr }),
        ApiModuleCms.forRoot({ rootUrl: environment.cms }),




        LoadingBarHttpClientModule,

        // for Router use:
        LoadingBarRouterModule,
    ],
    providers: [
        AuthGuard,
        // {
        //     provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
        //     useValue: {
        //         coreLibraryLoader: () => import('highlight.js/lib/core'),
        //         languages: {
        //             xml: () => import('highlight.js/lib/languages/xml'),
        //             typescript: () => import('highlight.js/lib/languages/typescript'),
        //             scss: () => import('highlight.js/lib/languages/scss'),
        //         }
        //     }
        // },

        // locale providers
        ...LOCALE_PROVIDERS,

        // firestore settings
        { provide: SETTINGS, useValue: { } },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
