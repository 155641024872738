/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pelanggan_perubahan } from '../models/pelanggan-_perubahan';
@Injectable({
  providedIn: 'root',
})
class PelangganPerubahanService extends __BaseService {
  static readonly getPelangganPelangganPerubahansPath = '/pelanggan/pelangganPerubahans';
  static readonly postPelangganPelangganPerubahansPath = '/pelanggan/pelangganPerubahans';
  static readonly getPelangganPelangganPerubahansIdPath = '/pelanggan/pelangganPerubahans/{id}';
  static readonly putPelangganPelangganPerubahansIdPath = '/pelanggan/pelangganPerubahans/{id}';
  static readonly deletePelangganPelangganPerubahansIdPath = '/pelanggan/pelangganPerubahans/{id}';
  static readonly getPelangganPdfPelangganPerubahansPath = '/pelangganPdf/pelangganPerubahans';
  static readonly postPelangganPdfPelangganPerubahansPath = '/pelangganPdf/pelangganPerubahans';
  static readonly getPelangganPdfPelangganPerubahansIdPath = '/pelangganPdf/pelangganPerubahans/{id}';
  static readonly putPelangganPdfPelangganPerubahansIdPath = '/pelangganPdf/pelangganPerubahans/{id}';
  static readonly deletePelangganPdfPelangganPerubahansIdPath = '/pelangganPdf/pelangganPerubahans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Pelanggan_perubahan.
   *
   * Get all Pelanggan_perubahan
   * @param params The `PelangganPerubahanService.GetPelangganPelangganPerubahansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pelanggan_perubahan
   *
   * @return successful operation
   */
  getPelangganPelangganPerubahansResponse(params: PelangganPerubahanService.GetPelangganPelangganPerubahansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/pelangganPerubahans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Pelanggan_perubahan.
   *
   * Get all Pelanggan_perubahan
   * @param params The `PelangganPerubahanService.GetPelangganPelangganPerubahansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pelanggan_perubahan
   *
   * @return successful operation
   */
  getPelangganPelangganPerubahans(params: PelangganPerubahanService.GetPelangganPelangganPerubahansParams): __Observable<{success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPelangganPerubahansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Pelanggan_perubahan in storage
   *
   * Store Pelanggan_perubahan
   * @param body Pelanggan_perubahan that should be stored
   * @return successful operation
   */
  postPelangganPelangganPerubahansResponse(body?: Pelanggan_perubahan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/pelangganPerubahans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Pelanggan_perubahan in storage
   *
   * Store Pelanggan_perubahan
   * @param body Pelanggan_perubahan that should be stored
   * @return successful operation
   */
  postPelangganPelangganPerubahans(body?: Pelanggan_perubahan): __Observable<{success?: boolean, data?: Pelanggan_perubahan, message?: string}> {
    return this.postPelangganPelangganPerubahansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan, message?: string})
    );
  }

  /**
   * Display the specified Pelanggan_perubahan
   *
   * Get Pelanggan_perubahan
   * @param id id of Pelanggan_perubahan
   * @return successful operation
   */
  getPelangganPelangganPerubahansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/pelangganPerubahans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Pelanggan_perubahan
   *
   * Get Pelanggan_perubahan
   * @param id id of Pelanggan_perubahan
   * @return successful operation
   */
  getPelangganPelangganPerubahansId(id: number): __Observable<{success?: boolean, data?: Pelanggan_perubahan, message?: string}> {
    return this.getPelangganPelangganPerubahansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan, message?: string})
    );
  }

  /**
   * Update the specified Pelanggan_perubahan in storage
   *
   * Update Pelanggan_perubahan
   * @param params The `PelangganPerubahanService.PutPelangganPelangganPerubahansIdParams` containing the following parameters:
   *
   * - `id`: id of Pelanggan_perubahan
   *
   * - `body`: Pelanggan_perubahan that should be updated
   *
   * @return successful operation
   */
  putPelangganPelangganPerubahansIdResponse(params: PelangganPerubahanService.PutPelangganPelangganPerubahansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/pelangganPerubahans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Pelanggan_perubahan in storage
   *
   * Update Pelanggan_perubahan
   * @param params The `PelangganPerubahanService.PutPelangganPelangganPerubahansIdParams` containing the following parameters:
   *
   * - `id`: id of Pelanggan_perubahan
   *
   * - `body`: Pelanggan_perubahan that should be updated
   *
   * @return successful operation
   */
  putPelangganPelangganPerubahansId(params: PelangganPerubahanService.PutPelangganPelangganPerubahansIdParams): __Observable<{success?: boolean, data?: Pelanggan_perubahan, message?: string}> {
    return this.putPelangganPelangganPerubahansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan, message?: string})
    );
  }

  /**
   * Remove the specified Pelanggan_perubahan from storage
   *
   * Delete Pelanggan_perubahan
   * @param id id of Pelanggan_perubahan
   * @return successful operation
   */
  deletePelangganPelangganPerubahansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/pelangganPerubahans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Pelanggan_perubahan from storage
   *
   * Delete Pelanggan_perubahan
   * @param id id of Pelanggan_perubahan
   * @return successful operation
   */
  deletePelangganPelangganPerubahansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPelangganPerubahansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }

  /**
   * Get a listing of the pelanggan_perubahan.
   *
   * Get all pelanggan_perubahan
   * @param params The `PelangganPerubahanService.GetPelangganPdfPelangganPerubahansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pelanggan_perubahan
   *
   * @return successful operation
   */
  getPelangganPdfPelangganPerubahansResponse(params: PelangganPerubahanService.GetPelangganPdfPelangganPerubahansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/pelangganPerubahans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pelanggan_perubahan.
   *
   * Get all pelanggan_perubahan
   * @param params The `PelangganPerubahanService.GetPelangganPdfPelangganPerubahansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pelanggan_perubahan
   *
   * @return successful operation
   */
  getPelangganPdfPelangganPerubahans(params: PelangganPerubahanService.GetPelangganPdfPelangganPerubahansParams): __Observable<{success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPdfPelangganPerubahansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pelanggan_perubahan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pelanggan_perubahan in storage
   *
   * Store pelanggan_perubahan
   * @param body pelanggan_perubahan that should be stored
   * @return successful operation
   */
  postPelangganPdfPelangganPerubahansResponse(body?: Pelanggan_perubahan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganPdf/pelangganPerubahans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pelanggan_perubahan in storage
   *
   * Store pelanggan_perubahan
   * @param body pelanggan_perubahan that should be stored
   * @return successful operation
   */
  postPelangganPdfPelangganPerubahans(body?: Pelanggan_perubahan): __Observable<{success?: boolean, data?: Pelanggan_perubahan, message?: string}> {
    return this.postPelangganPdfPelangganPerubahansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan, message?: string})
    );
  }

  /**
   * Display the specified pelanggan_perubahan
   *
   * Get pelanggan_perubahan
   * @param id id of pelanggan_perubahan
   * @return successful operation
   */
  getPelangganPdfPelangganPerubahansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/pelangganPerubahans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pelanggan_perubahan
   *
   * Get pelanggan_perubahan
   * @param id id of pelanggan_perubahan
   * @return successful operation
   */
  getPelangganPdfPelangganPerubahansId(id: number): __Observable<{success?: boolean, data?: Pelanggan_perubahan, message?: string}> {
    return this.getPelangganPdfPelangganPerubahansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan, message?: string})
    );
  }

  /**
   * Update the specified pelanggan_perubahan in storage
   *
   * Update pelanggan_perubahan
   * @param params The `PelangganPerubahanService.PutPelangganPdfPelangganPerubahansIdParams` containing the following parameters:
   *
   * - `id`: id of pelanggan_perubahan
   *
   * - `body`: pelanggan_perubahan that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfPelangganPerubahansIdResponse(params: PelangganPerubahanService.PutPelangganPdfPelangganPerubahansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganPdf/pelangganPerubahans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pelanggan_perubahan in storage
   *
   * Update pelanggan_perubahan
   * @param params The `PelangganPerubahanService.PutPelangganPdfPelangganPerubahansIdParams` containing the following parameters:
   *
   * - `id`: id of pelanggan_perubahan
   *
   * - `body`: pelanggan_perubahan that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfPelangganPerubahansId(params: PelangganPerubahanService.PutPelangganPdfPelangganPerubahansIdParams): __Observable<{success?: boolean, data?: Pelanggan_perubahan, message?: string}> {
    return this.putPelangganPdfPelangganPerubahansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan, message?: string})
    );
  }

  /**
   * Remove the specified pelanggan_perubahan from storage
   *
   * Delete pelanggan_perubahan
   * @param id id of pelanggan_perubahan
   * @return successful operation
   */
  deletePelangganPdfPelangganPerubahansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganPdf/pelangganPerubahans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pelanggan_perubahan from storage
   *
   * Delete pelanggan_perubahan
   * @param id id of pelanggan_perubahan
   * @return successful operation
   */
  deletePelangganPdfPelangganPerubahansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPdfPelangganPerubahansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PelangganPerubahanService {

  /**
   * Parameters for getPelangganPelangganPerubahans
   */
  export interface GetPelangganPelangganPerubahansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Pelanggan_perubahan
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPelangganPerubahansId
   */
  export interface PutPelangganPelangganPerubahansIdParams {

    /**
     * id of Pelanggan_perubahan
     */
    id: number;

    /**
     * Pelanggan_perubahan that should be updated
     */
    body?: Pelanggan_perubahan;
  }

  /**
   * Parameters for getPelangganPdfPelangganPerubahans
   */
  export interface GetPelangganPdfPelangganPerubahansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pelanggan_perubahan
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPdfPelangganPerubahansId
   */
  export interface PutPelangganPdfPelangganPerubahansIdParams {

    /**
     * id of pelanggan_perubahan
     */
    id: number;

    /**
     * pelanggan_perubahan that should be updated
     */
    body?: Pelanggan_perubahan;
  }
}

export { PelangganPerubahanService }
