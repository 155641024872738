/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Collector_blok } from '../models/collector-_blok';
@Injectable({
  providedIn: 'root',
})
class CollectorBlokService extends __BaseService {
  static readonly getPenagihanCollectorBloksPath = '/penagihan/collectorBloks';
  static readonly postPenagihanCollectorBloksPath = '/penagihan/collectorBloks';
  static readonly getPenagihanCollectorBloksIdPath = '/penagihan/collectorBloks/{id}';
  static readonly putPenagihanCollectorBloksIdPath = '/penagihan/collectorBloks/{id}';
  static readonly deletePenagihanCollectorBloksIdPath = '/penagihan/collectorBloks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Collector_blok.
   *
   * Get all Collector_blok
   * @param params The `CollectorBlokService.GetPenagihanCollectorBloksParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Collector_blok
   *
   * @return successful operation
   */
  getPenagihanCollectorBloksResponse(params: CollectorBlokService.GetPenagihanCollectorBloksParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Collector_blok>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihan/collectorBloks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Collector_blok>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Collector_blok.
   *
   * Get all Collector_blok
   * @param params The `CollectorBlokService.GetPenagihanCollectorBloksParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Collector_blok
   *
   * @return successful operation
   */
  getPenagihanCollectorBloks(params: CollectorBlokService.GetPenagihanCollectorBloksParams): __Observable<{success?: boolean, data?: Array<Collector_blok>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanCollectorBloksResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Collector_blok>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Collector_blok in storage
   *
   * Store Collector_blok
   * @param body Collector_blok that should be stored
   * @return successful operation
   */
  postPenagihanCollectorBloksResponse(body?: Collector_blok): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_blok, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihan/collectorBloks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_blok, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Collector_blok in storage
   *
   * Store Collector_blok
   * @param body Collector_blok that should be stored
   * @return successful operation
   */
  postPenagihanCollectorBloks(body?: Collector_blok): __Observable<{success?: boolean, data?: Collector_blok, message?: string}> {
    return this.postPenagihanCollectorBloksResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_blok, message?: string})
    );
  }

  /**
   * Display the specified Collector_blok
   *
   * Get Collector_blok
   * @param id id of Collector_blok
   * @return successful operation
   */
  getPenagihanCollectorBloksIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_blok, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihan/collectorBloks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_blok, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Collector_blok
   *
   * Get Collector_blok
   * @param id id of Collector_blok
   * @return successful operation
   */
  getPenagihanCollectorBloksId(id: number): __Observable<{success?: boolean, data?: Collector_blok, message?: string}> {
    return this.getPenagihanCollectorBloksIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_blok, message?: string})
    );
  }

  /**
   * Update the specified Collector_blok in storage
   *
   * Update Collector_blok
   * @param params The `CollectorBlokService.PutPenagihanCollectorBloksIdParams` containing the following parameters:
   *
   * - `id`: id of Collector_blok
   *
   * - `body`: Collector_blok that should be updated
   *
   * @return successful operation
   */
  putPenagihanCollectorBloksIdResponse(params: CollectorBlokService.PutPenagihanCollectorBloksIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_blok, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihan/collectorBloks/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_blok, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Collector_blok in storage
   *
   * Update Collector_blok
   * @param params The `CollectorBlokService.PutPenagihanCollectorBloksIdParams` containing the following parameters:
   *
   * - `id`: id of Collector_blok
   *
   * - `body`: Collector_blok that should be updated
   *
   * @return successful operation
   */
  putPenagihanCollectorBloksId(params: CollectorBlokService.PutPenagihanCollectorBloksIdParams): __Observable<{success?: boolean, data?: Collector_blok, message?: string}> {
    return this.putPenagihanCollectorBloksIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_blok, message?: string})
    );
  }

  /**
   * Remove the specified Collector_blok from storage
   *
   * Delete Collector_blok
   * @param id id of Collector_blok
   * @return successful operation
   */
  deletePenagihanCollectorBloksIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihan/collectorBloks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Collector_blok from storage
   *
   * Delete Collector_blok
   * @param id id of Collector_blok
   * @return successful operation
   */
  deletePenagihanCollectorBloksId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanCollectorBloksIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module CollectorBlokService {

  /**
   * Parameters for getPenagihanCollectorBloks
   */
  export interface GetPenagihanCollectorBloksParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Collector_blok
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanCollectorBloksId
   */
  export interface PutPenagihanCollectorBloksIdParams {

    /**
     * id of Collector_blok
     */
    id: number;

    /**
     * Collector_blok that should be updated
     */
    body?: Collector_blok;
  }
}

export { CollectorBlokService }
