/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Baca_meter_status_pelanggan } from '../models/baca-_meter-_status-_pelanggan';
@Injectable({
  providedIn: 'root',
})
class BacaMeterStatusPelangganService extends __BaseService {
  static readonly getPelangganPdfBacaMeterStatusPelanggansPath = '/pelangganPdf/bacaMeterStatusPelanggans';
  static readonly postPelangganPdfBacaMeterStatusPelanggansPath = '/pelangganPdf/bacaMeterStatusPelanggans';
  static readonly getPelangganPdfBacaMeterStatusPelanggansIdPath = '/pelangganPdf/bacaMeterStatusPelanggans/{id}';
  static readonly putPelangganPdfBacaMeterStatusPelanggansIdPath = '/pelangganPdf/bacaMeterStatusPelanggans/{id}';
  static readonly deletePelangganPdfBacaMeterStatusPelanggansIdPath = '/pelangganPdf/bacaMeterStatusPelanggans/{id}';
  static readonly getPelangganReportBacaMeterStatusPelanggansPath = '/pelangganReport/bacaMeterStatusPelanggans';
  static readonly postPelangganReportBacaMeterStatusPelanggansPath = '/pelangganReport/bacaMeterStatusPelanggans';
  static readonly getPelangganReportBacaMeterStatusPelanggansIdPath = '/pelangganReport/bacaMeterStatusPelanggans/{id}';
  static readonly putPelangganReportBacaMeterStatusPelanggansIdPath = '/pelangganReport/bacaMeterStatusPelanggans/{id}';
  static readonly deletePelangganReportBacaMeterStatusPelanggansIdPath = '/pelangganReport/bacaMeterStatusPelanggans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the baca_meter_status_pelanggan.
   *
   * Get all baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.GetPelangganPdfBacaMeterStatusPelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter baca_meter_status_pelanggan
   *
   * @return successful operation
   */
  getPelangganPdfBacaMeterStatusPelanggansResponse(params: BacaMeterStatusPelangganService.GetPelangganPdfBacaMeterStatusPelanggansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/bacaMeterStatusPelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the baca_meter_status_pelanggan.
   *
   * Get all baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.GetPelangganPdfBacaMeterStatusPelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter baca_meter_status_pelanggan
   *
   * @return successful operation
   */
  getPelangganPdfBacaMeterStatusPelanggans(params: BacaMeterStatusPelangganService.GetPelangganPdfBacaMeterStatusPelanggansParams): __Observable<{success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPdfBacaMeterStatusPelanggansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created baca_meter_status_pelanggan in storage
   *
   * Store baca_meter_status_pelanggan
   * @param body baca_meter_status_pelanggan that should be stored
   * @return successful operation
   */
  postPelangganPdfBacaMeterStatusPelanggansResponse(body?: Baca_meter_status_pelanggan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganPdf/bacaMeterStatusPelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created baca_meter_status_pelanggan in storage
   *
   * Store baca_meter_status_pelanggan
   * @param body baca_meter_status_pelanggan that should be stored
   * @return successful operation
   */
  postPelangganPdfBacaMeterStatusPelanggans(body?: Baca_meter_status_pelanggan): __Observable<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}> {
    return this.postPelangganPdfBacaMeterStatusPelanggansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_pelanggan, message?: string})
    );
  }

  /**
   * Display the specified baca_meter_status_pelanggan
   *
   * Get baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  getPelangganPdfBacaMeterStatusPelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/bacaMeterStatusPelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified baca_meter_status_pelanggan
   *
   * Get baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  getPelangganPdfBacaMeterStatusPelanggansId(id: number): __Observable<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}> {
    return this.getPelangganPdfBacaMeterStatusPelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_pelanggan, message?: string})
    );
  }

  /**
   * Update the specified baca_meter_status_pelanggan in storage
   *
   * Update baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.PutPelangganPdfBacaMeterStatusPelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of baca_meter_status_pelanggan
   *
   * - `body`: baca_meter_status_pelanggan that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfBacaMeterStatusPelanggansIdResponse(params: BacaMeterStatusPelangganService.PutPelangganPdfBacaMeterStatusPelanggansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganPdf/bacaMeterStatusPelanggans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified baca_meter_status_pelanggan in storage
   *
   * Update baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.PutPelangganPdfBacaMeterStatusPelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of baca_meter_status_pelanggan
   *
   * - `body`: baca_meter_status_pelanggan that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfBacaMeterStatusPelanggansId(params: BacaMeterStatusPelangganService.PutPelangganPdfBacaMeterStatusPelanggansIdParams): __Observable<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}> {
    return this.putPelangganPdfBacaMeterStatusPelanggansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_pelanggan, message?: string})
    );
  }

  /**
   * Remove the specified baca_meter_status_pelanggan from storage
   *
   * Delete baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  deletePelangganPdfBacaMeterStatusPelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganPdf/bacaMeterStatusPelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified baca_meter_status_pelanggan from storage
   *
   * Delete baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  deletePelangganPdfBacaMeterStatusPelanggansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPdfBacaMeterStatusPelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }

  /**
   * Get a listing of the baca_meter_status_pelanggan.
   *
   * Get all baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.GetPelangganReportBacaMeterStatusPelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter baca_meter_status_pelanggan
   *
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusPelanggansResponse(params: BacaMeterStatusPelangganService.GetPelangganReportBacaMeterStatusPelanggansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/bacaMeterStatusPelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the baca_meter_status_pelanggan.
   *
   * Get all baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.GetPelangganReportBacaMeterStatusPelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter baca_meter_status_pelanggan
   *
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusPelanggans(params: BacaMeterStatusPelangganService.GetPelangganReportBacaMeterStatusPelanggansParams): __Observable<{success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportBacaMeterStatusPelanggansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Baca_meter_status_pelanggan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created baca_meter_status_pelanggan in storage
   *
   * Store baca_meter_status_pelanggan
   * @param body baca_meter_status_pelanggan that should be stored
   * @return successful operation
   */
  postPelangganReportBacaMeterStatusPelanggansResponse(body?: Baca_meter_status_pelanggan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/bacaMeterStatusPelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created baca_meter_status_pelanggan in storage
   *
   * Store baca_meter_status_pelanggan
   * @param body baca_meter_status_pelanggan that should be stored
   * @return successful operation
   */
  postPelangganReportBacaMeterStatusPelanggans(body?: Baca_meter_status_pelanggan): __Observable<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}> {
    return this.postPelangganReportBacaMeterStatusPelanggansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_pelanggan, message?: string})
    );
  }

  /**
   * Display the specified baca_meter_status_pelanggan
   *
   * Get baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusPelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/bacaMeterStatusPelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified baca_meter_status_pelanggan
   *
   * Get baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusPelanggansId(id: number): __Observable<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}> {
    return this.getPelangganReportBacaMeterStatusPelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_pelanggan, message?: string})
    );
  }

  /**
   * Update the specified baca_meter_status_pelanggan in storage
   *
   * Update baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.PutPelangganReportBacaMeterStatusPelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of baca_meter_status_pelanggan
   *
   * - `body`: baca_meter_status_pelanggan that should be updated
   *
   * @return successful operation
   */
  putPelangganReportBacaMeterStatusPelanggansIdResponse(params: BacaMeterStatusPelangganService.PutPelangganReportBacaMeterStatusPelanggansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/bacaMeterStatusPelanggans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified baca_meter_status_pelanggan in storage
   *
   * Update baca_meter_status_pelanggan
   * @param params The `BacaMeterStatusPelangganService.PutPelangganReportBacaMeterStatusPelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of baca_meter_status_pelanggan
   *
   * - `body`: baca_meter_status_pelanggan that should be updated
   *
   * @return successful operation
   */
  putPelangganReportBacaMeterStatusPelanggansId(params: BacaMeterStatusPelangganService.PutPelangganReportBacaMeterStatusPelanggansIdParams): __Observable<{success?: boolean, data?: Baca_meter_status_pelanggan, message?: string}> {
    return this.putPelangganReportBacaMeterStatusPelanggansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_pelanggan, message?: string})
    );
  }

  /**
   * Remove the specified baca_meter_status_pelanggan from storage
   *
   * Delete baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  deletePelangganReportBacaMeterStatusPelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/bacaMeterStatusPelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified baca_meter_status_pelanggan from storage
   *
   * Delete baca_meter_status_pelanggan
   * @param id id of baca_meter_status_pelanggan
   * @return successful operation
   */
  deletePelangganReportBacaMeterStatusPelanggansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportBacaMeterStatusPelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module BacaMeterStatusPelangganService {

  /**
   * Parameters for getPelangganPdfBacaMeterStatusPelanggans
   */
  export interface GetPelangganPdfBacaMeterStatusPelanggansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter baca_meter_status_pelanggan
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPdfBacaMeterStatusPelanggansId
   */
  export interface PutPelangganPdfBacaMeterStatusPelanggansIdParams {

    /**
     * id of baca_meter_status_pelanggan
     */
    id: number;

    /**
     * baca_meter_status_pelanggan that should be updated
     */
    body?: Baca_meter_status_pelanggan;
  }

  /**
   * Parameters for getPelangganReportBacaMeterStatusPelanggans
   */
  export interface GetPelangganReportBacaMeterStatusPelanggansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter baca_meter_status_pelanggan
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportBacaMeterStatusPelanggansId
   */
  export interface PutPelangganReportBacaMeterStatusPelanggansIdParams {

    /**
     * id of baca_meter_status_pelanggan
     */
    id: number;

    /**
     * baca_meter_status_pelanggan that should be updated
     */
    body?: Baca_meter_status_pelanggan;
  }
}

export { BacaMeterStatusPelangganService }
