/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiNotifikasi } from '../models/absensi-notifikasi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiNotifikasiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiNotifikasiList
   */
  static readonly GetAbsensiNotifikasiListPath = '/absensi/notifikasis';

  /**
   * getnotifikasiList.
   *
   * Get all notifikasis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiNotifikasiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiNotifikasiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiNotifikasi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiNotifikasiService.GetAbsensiNotifikasiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiNotifikasi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getnotifikasiList.
   *
   * Get all notifikasis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiNotifikasiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiNotifikasiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiNotifikasi>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiNotifikasiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiNotifikasi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiNotifikasi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiNotifikasi
   */
  static readonly StoreAbsensiNotifikasiPath = '/absensi/notifikasis';

  /**
   * createnotifikasi.
   *
   * Create notifikasi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiNotifikasi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiNotifikasi$Response(params: {
    body: AbsensiNotifikasi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiNotifikasiService.StoreAbsensiNotifikasiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiNotifikasi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createnotifikasi.
   *
   * Create notifikasi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiNotifikasi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiNotifikasi(params: {
    body: AbsensiNotifikasi
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}> {

    return this.storeAbsensiNotifikasi$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiNotifikasiId
   */
  static readonly GetAbsensiNotifikasiIdPath = '/absensi/notifikasis/{id}';

  /**
   * getnotifikasiItem.
   *
   * Get notifikasi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiNotifikasiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiNotifikasiId$Response(params: {

    /**
     * id of notifikasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiNotifikasiService.GetAbsensiNotifikasiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiNotifikasi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getnotifikasiItem.
   *
   * Get notifikasi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiNotifikasiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiNotifikasiId(params: {

    /**
     * id of notifikasi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}> {

    return this.getAbsensiNotifikasiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiNotifikasiId
   */
  static readonly PutAbsensiNotifikasiIdPath = '/absensi/notifikasis/{id}';

  /**
   * updatenotifikasi.
   *
   * Update notifikasi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiNotifikasiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiNotifikasiId$Response(params: {

    /**
     * id of notifikasi
     */
    id: string;
    body: AbsensiNotifikasi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiNotifikasiService.PutAbsensiNotifikasiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiNotifikasi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatenotifikasi.
   *
   * Update notifikasi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiNotifikasiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiNotifikasiId(params: {

    /**
     * id of notifikasi
     */
    id: string;
    body: AbsensiNotifikasi
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}> {

    return this.putAbsensiNotifikasiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiNotifikasi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiNotifikasisId
   */
  static readonly DeleteAbsensiNotifikasisIdPath = '/absensi/notifikasis/{id}';

  /**
   * deletenotifikasi.
   *
   * Delete notifikasi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiNotifikasisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiNotifikasisId$Response(params: {

    /**
     * id of notifikasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiNotifikasiService.DeleteAbsensiNotifikasisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletenotifikasi.
   *
   * Delete notifikasi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiNotifikasisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiNotifikasisId(params: {

    /**
     * id of notifikasi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiNotifikasisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
