/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportPerjalananDinasDetail } from '../models/absensi-report-perjalanan-dinas-detail';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportPerjalananDinasDetailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportPerjalananDinasDetailList
   */
  static readonly GetAbsensiReportPerjalananDinasDetailListPath = '/absensiReport/perjalanan_dinas_details';

  /**
   * getperjalanan_dinas_detailList.
   *
   * Get all perjalanan_dinas_details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportPerjalananDinasDetailList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasDetailList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasDetail>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasDetailService.GetAbsensiReportPerjalananDinasDetailListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportPerjalananDinasDetail>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_detailList.
   *
   * Get all perjalanan_dinas_details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportPerjalananDinasDetailList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasDetailList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasDetail>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportPerjalananDinasDetailList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasDetail>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasDetail>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportPerjalananDinasDetail
   */
  static readonly StoreAbsensiReportPerjalananDinasDetailPath = '/absensiReport/perjalanan_dinas_details';

  /**
   * createperjalanan_dinas_detail.
   *
   * Create perjalanan_dinas_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportPerjalananDinasDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportPerjalananDinasDetail$Response(params: {
    body: AbsensiReportPerjalananDinasDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasDetailService.StoreAbsensiReportPerjalananDinasDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportPerjalananDinasDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createperjalanan_dinas_detail.
   *
   * Create perjalanan_dinas_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportPerjalananDinasDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportPerjalananDinasDetail(params: {
    body: AbsensiReportPerjalananDinasDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}> {

    return this.storeAbsensiReportPerjalananDinasDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportPerjalananDinasDetailId
   */
  static readonly GetAbsensiReportPerjalananDinasDetailIdPath = '/absensiReport/perjalanan_dinas_details/{id}';

  /**
   * getperjalanan_dinas_detailItem.
   *
   * Get perjalanan_dinas_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportPerjalananDinasDetailId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasDetailId$Response(params: {

    /**
     * id of perjalanan_dinas_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasDetailService.GetAbsensiReportPerjalananDinasDetailIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportPerjalananDinasDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_detailItem.
   *
   * Get perjalanan_dinas_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportPerjalananDinasDetailId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasDetailId(params: {

    /**
     * id of perjalanan_dinas_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}> {

    return this.getAbsensiReportPerjalananDinasDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportPerjalananDinasDetailId
   */
  static readonly PutAbsensiReportPerjalananDinasDetailIdPath = '/absensiReport/perjalanan_dinas_details/{id}';

  /**
   * updateperjalanan_dinas_detail.
   *
   * Update perjalanan_dinas_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportPerjalananDinasDetailId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportPerjalananDinasDetailId$Response(params: {

    /**
     * id of perjalanan_dinas_detail
     */
    id: string;
    body: AbsensiReportPerjalananDinasDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasDetailService.PutAbsensiReportPerjalananDinasDetailIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportPerjalananDinasDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updateperjalanan_dinas_detail.
   *
   * Update perjalanan_dinas_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportPerjalananDinasDetailId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportPerjalananDinasDetailId(params: {

    /**
     * id of perjalanan_dinas_detail
     */
    id: string;
    body: AbsensiReportPerjalananDinasDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}> {

    return this.putAbsensiReportPerjalananDinasDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportPerjalananDinasDetailsId
   */
  static readonly DeleteAbsensiReportPerjalananDinasDetailsIdPath = '/absensiReport/perjalanan_dinas_details/{id}';

  /**
   * deleteperjalanan_dinas_detail.
   *
   * Delete perjalanan_dinas_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportPerjalananDinasDetailsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportPerjalananDinasDetailsId$Response(params: {

    /**
     * id of perjalanan_dinas_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasDetailService.DeleteAbsensiReportPerjalananDinasDetailsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleteperjalanan_dinas_detail.
   *
   * Delete perjalanan_dinas_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportPerjalananDinasDetailsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportPerjalananDinasDetailsId(params: {

    /**
     * id of perjalanan_dinas_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportPerjalananDinasDetailsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
