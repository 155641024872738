/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pdf_pendapatan_by_unit } from '../models/pdf-_pendapatan-_by-_unit';
@Injectable({
  providedIn: 'root',
})
class PdfPendapatanByUnitService extends __BaseService {
  static readonly getPelangganPdfPdfPendapatanByUnitsPath = '/pelangganPdf/pdfPendapatanByUnits';
  static readonly postPelangganPdfPdfPendapatanByUnitsPath = '/pelangganPdf/pdfPendapatanByUnits';
  static readonly getPelangganPdfPdfPendapatanByUnitsIdPath = '/pelangganPdf/pdfPendapatanByUnits/{id}';
  static readonly putPelangganPdfPdfPendapatanByUnitsIdPath = '/pelangganPdf/pdfPendapatanByUnits/{id}';
  static readonly deletePelangganPdfPdfPendapatanByUnitsIdPath = '/pelangganPdf/pdfPendapatanByUnits/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the pdf_pendapatan_by_unit.
   *
   * Get all pdf_pendapatan_by_unit
   * @param params The `PdfPendapatanByUnitService.GetPelangganPdfPdfPendapatanByUnitsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pdf_pendapatan_by_unit
   *
   * @return successful operation
   */
  getPelangganPdfPdfPendapatanByUnitsResponse(params: PdfPendapatanByUnitService.GetPelangganPdfPdfPendapatanByUnitsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pdf_pendapatan_by_unit>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/pdfPendapatanByUnits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pdf_pendapatan_by_unit>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pdf_pendapatan_by_unit.
   *
   * Get all pdf_pendapatan_by_unit
   * @param params The `PdfPendapatanByUnitService.GetPelangganPdfPdfPendapatanByUnitsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pdf_pendapatan_by_unit
   *
   * @return successful operation
   */
  getPelangganPdfPdfPendapatanByUnits(params: PdfPendapatanByUnitService.GetPelangganPdfPdfPendapatanByUnitsParams): __Observable<{success?: boolean, data?: Array<Pdf_pendapatan_by_unit>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPdfPdfPendapatanByUnitsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pdf_pendapatan_by_unit>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pdf_pendapatan_by_unit in storage
   *
   * Store pdf_pendapatan_by_unit
   * @param body pdf_pendapatan_by_unit that should be stored
   * @return successful operation
   */
  postPelangganPdfPdfPendapatanByUnitsResponse(body?: Pdf_pendapatan_by_unit): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganPdf/pdfPendapatanByUnits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pdf_pendapatan_by_unit in storage
   *
   * Store pdf_pendapatan_by_unit
   * @param body pdf_pendapatan_by_unit that should be stored
   * @return successful operation
   */
  postPelangganPdfPdfPendapatanByUnits(body?: Pdf_pendapatan_by_unit): __Observable<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}> {
    return this.postPelangganPdfPdfPendapatanByUnitsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string})
    );
  }

  /**
   * Display the specified pdf_pendapatan_by_unit
   *
   * Get pdf_pendapatan_by_unit
   * @param id id of pdf_pendapatan_by_unit
   * @return successful operation
   */
  getPelangganPdfPdfPendapatanByUnitsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/pdfPendapatanByUnits/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pdf_pendapatan_by_unit
   *
   * Get pdf_pendapatan_by_unit
   * @param id id of pdf_pendapatan_by_unit
   * @return successful operation
   */
  getPelangganPdfPdfPendapatanByUnitsId(id: number): __Observable<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}> {
    return this.getPelangganPdfPdfPendapatanByUnitsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string})
    );
  }

  /**
   * Update the specified pdf_pendapatan_by_unit in storage
   *
   * Update pdf_pendapatan_by_unit
   * @param params The `PdfPendapatanByUnitService.PutPelangganPdfPdfPendapatanByUnitsIdParams` containing the following parameters:
   *
   * - `id`: id of pdf_pendapatan_by_unit
   *
   * - `body`: pdf_pendapatan_by_unit that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfPdfPendapatanByUnitsIdResponse(params: PdfPendapatanByUnitService.PutPelangganPdfPdfPendapatanByUnitsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganPdf/pdfPendapatanByUnits/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pdf_pendapatan_by_unit in storage
   *
   * Update pdf_pendapatan_by_unit
   * @param params The `PdfPendapatanByUnitService.PutPelangganPdfPdfPendapatanByUnitsIdParams` containing the following parameters:
   *
   * - `id`: id of pdf_pendapatan_by_unit
   *
   * - `body`: pdf_pendapatan_by_unit that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfPdfPendapatanByUnitsId(params: PdfPendapatanByUnitService.PutPelangganPdfPdfPendapatanByUnitsIdParams): __Observable<{success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string}> {
    return this.putPelangganPdfPdfPendapatanByUnitsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_pendapatan_by_unit, message?: string})
    );
  }

  /**
   * Remove the specified pdf_pendapatan_by_unit from storage
   *
   * Delete pdf_pendapatan_by_unit
   * @param id id of pdf_pendapatan_by_unit
   * @return successful operation
   */
  deletePelangganPdfPdfPendapatanByUnitsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganPdf/pdfPendapatanByUnits/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pdf_pendapatan_by_unit from storage
   *
   * Delete pdf_pendapatan_by_unit
   * @param id id of pdf_pendapatan_by_unit
   * @return successful operation
   */
  deletePelangganPdfPdfPendapatanByUnitsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPdfPdfPendapatanByUnitsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PdfPendapatanByUnitService {

  /**
   * Parameters for getPelangganPdfPdfPendapatanByUnits
   */
  export interface GetPelangganPdfPdfPendapatanByUnitsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pdf_pendapatan_by_unit
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPdfPdfPendapatanByUnitsId
   */
  export interface PutPelangganPdfPdfPendapatanByUnitsIdParams {

    /**
     * id of pdf_pendapatan_by_unit
     */
    id: number;

    /**
     * pdf_pendapatan_by_unit that should be updated
     */
    body?: Pdf_pendapatan_by_unit;
  }
}

export { PdfPendapatanByUnitService }
