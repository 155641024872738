/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pelanggan_perubahan_type_pelanggan } from '../models/pelanggan-_perubahan-_type-_pelanggan';
@Injectable({
  providedIn: 'root',
})
class PelangganPerubahanTypePelangganService extends __BaseService {
  static readonly getPelangganPelangganPerubahanTypePelanggansPath = '/pelanggan/pelangganPerubahanTypePelanggans';
  static readonly postPelangganPelangganPerubahanTypePelanggansPath = '/pelanggan/pelangganPerubahanTypePelanggans';
  static readonly getPelangganPelangganPerubahanTypePelanggansIdPath = '/pelanggan/pelangganPerubahanTypePelanggans/{id}';
  static readonly putPelangganPelangganPerubahanTypePelanggansIdPath = '/pelanggan/pelangganPerubahanTypePelanggans/{id}';
  static readonly deletePelangganPelangganPerubahanTypePelanggansIdPath = '/pelanggan/pelangganPerubahanTypePelanggans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Pelanggan_perubahan_type_pelanggan.
   *
   * Get all Pelanggan_perubahan_type_pelanggan
   * @param params The `PelangganPerubahanTypePelangganService.GetPelangganPelangganPerubahanTypePelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pelanggan_perubahan_type_pelanggan
   *
   * @return successful operation
   */
  getPelangganPelangganPerubahanTypePelanggansResponse(params: PelangganPerubahanTypePelangganService.GetPelangganPelangganPerubahanTypePelanggansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan_type_pelanggan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/pelangganPerubahanTypePelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan_type_pelanggan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Pelanggan_perubahan_type_pelanggan.
   *
   * Get all Pelanggan_perubahan_type_pelanggan
   * @param params The `PelangganPerubahanTypePelangganService.GetPelangganPelangganPerubahanTypePelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pelanggan_perubahan_type_pelanggan
   *
   * @return successful operation
   */
  getPelangganPelangganPerubahanTypePelanggans(params: PelangganPerubahanTypePelangganService.GetPelangganPelangganPerubahanTypePelanggansParams): __Observable<{success?: boolean, data?: Array<Pelanggan_perubahan_type_pelanggan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPelangganPerubahanTypePelanggansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pelanggan_perubahan_type_pelanggan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Pelanggan_perubahan_type_pelanggan in storage
   *
   * Store Pelanggan_perubahan_type_pelanggan
   * @param body Pelanggan_perubahan_type_pelanggan that should be stored
   * @return successful operation
   */
  postPelangganPelangganPerubahanTypePelanggansResponse(body?: Pelanggan_perubahan_type_pelanggan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/pelangganPerubahanTypePelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Pelanggan_perubahan_type_pelanggan in storage
   *
   * Store Pelanggan_perubahan_type_pelanggan
   * @param body Pelanggan_perubahan_type_pelanggan that should be stored
   * @return successful operation
   */
  postPelangganPelangganPerubahanTypePelanggans(body?: Pelanggan_perubahan_type_pelanggan): __Observable<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}> {
    return this.postPelangganPelangganPerubahanTypePelanggansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string})
    );
  }

  /**
   * Display the specified Pelanggan_perubahan_type_pelanggan
   *
   * Get Pelanggan_perubahan_type_pelanggan
   * @param id id of Pelanggan_perubahan_type_pelanggan
   * @return successful operation
   */
  getPelangganPelangganPerubahanTypePelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/pelangganPerubahanTypePelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Pelanggan_perubahan_type_pelanggan
   *
   * Get Pelanggan_perubahan_type_pelanggan
   * @param id id of Pelanggan_perubahan_type_pelanggan
   * @return successful operation
   */
  getPelangganPelangganPerubahanTypePelanggansId(id: number): __Observable<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}> {
    return this.getPelangganPelangganPerubahanTypePelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string})
    );
  }

  /**
   * Update the specified Pelanggan_perubahan_type_pelanggan in storage
   *
   * Update Pelanggan_perubahan_type_pelanggan
   * @param params The `PelangganPerubahanTypePelangganService.PutPelangganPelangganPerubahanTypePelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of Pelanggan_perubahan_type_pelanggan
   *
   * - `body`: Pelanggan_perubahan_type_pelanggan that should be updated
   *
   * @return successful operation
   */
  putPelangganPelangganPerubahanTypePelanggansIdResponse(params: PelangganPerubahanTypePelangganService.PutPelangganPelangganPerubahanTypePelanggansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/pelangganPerubahanTypePelanggans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Pelanggan_perubahan_type_pelanggan in storage
   *
   * Update Pelanggan_perubahan_type_pelanggan
   * @param params The `PelangganPerubahanTypePelangganService.PutPelangganPelangganPerubahanTypePelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of Pelanggan_perubahan_type_pelanggan
   *
   * - `body`: Pelanggan_perubahan_type_pelanggan that should be updated
   *
   * @return successful operation
   */
  putPelangganPelangganPerubahanTypePelanggansId(params: PelangganPerubahanTypePelangganService.PutPelangganPelangganPerubahanTypePelanggansIdParams): __Observable<{success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string}> {
    return this.putPelangganPelangganPerubahanTypePelanggansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan_type_pelanggan, message?: string})
    );
  }

  /**
   * Remove the specified Pelanggan_perubahan_type_pelanggan from storage
   *
   * Delete Pelanggan_perubahan_type_pelanggan
   * @param id id of Pelanggan_perubahan_type_pelanggan
   * @return successful operation
   */
  deletePelangganPelangganPerubahanTypePelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/pelangganPerubahanTypePelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Pelanggan_perubahan_type_pelanggan from storage
   *
   * Delete Pelanggan_perubahan_type_pelanggan
   * @param id id of Pelanggan_perubahan_type_pelanggan
   * @return successful operation
   */
  deletePelangganPelangganPerubahanTypePelanggansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPelangganPerubahanTypePelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PelangganPerubahanTypePelangganService {

  /**
   * Parameters for getPelangganPelangganPerubahanTypePelanggans
   */
  export interface GetPelangganPelangganPerubahanTypePelanggansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Pelanggan_perubahan_type_pelanggan
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPelangganPerubahanTypePelanggansId
   */
  export interface PutPelangganPelangganPerubahanTypePelanggansIdParams {

    /**
     * id of Pelanggan_perubahan_type_pelanggan
     */
    id: number;

    /**
     * Pelanggan_perubahan_type_pelanggan that should be updated
     */
    body?: Pelanggan_perubahan_type_pelanggan;
  }
}

export { PelangganPerubahanTypePelangganService }
