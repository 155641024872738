/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pdf_penerimaan_rekap } from '../models/pdf-_penerimaan-_rekap';
@Injectable({
  providedIn: 'root',
})
class PdfPenerimaanRekapService extends __BaseService {
  static readonly getReportPdfPdfPenerimaanRekapsPath = '/reportPdf/pdfPenerimaanRekaps';
  static readonly postReportPdfPdfPenerimaanRekapsPath = '/reportPdf/pdfPenerimaanRekaps';
  static readonly getReportPdfPdfPenerimaanRekapsIdPath = '/reportPdf/pdfPenerimaanRekaps/{id}';
  static readonly putReportPdfPdfPenerimaanRekapsIdPath = '/reportPdf/pdfPenerimaanRekaps/{id}';
  static readonly deleteReportPdfPdfPenerimaanRekapsIdPath = '/reportPdf/pdfPenerimaanRekaps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the pdf_penerimaan_rekap.
   *
   * Get all pdf_penerimaan_rekap
   * @param params The `PdfPenerimaanRekapService.GetReportPdfPdfPenerimaanRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pdf_penerimaan_rekap
   *
   * @return successful operation
   */
  getReportPdfPdfPenerimaanRekapsResponse(params: PdfPenerimaanRekapService.GetReportPdfPdfPenerimaanRekapsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pdf_penerimaan_rekap>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reportPdf/pdfPenerimaanRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pdf_penerimaan_rekap>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pdf_penerimaan_rekap.
   *
   * Get all pdf_penerimaan_rekap
   * @param params The `PdfPenerimaanRekapService.GetReportPdfPdfPenerimaanRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pdf_penerimaan_rekap
   *
   * @return successful operation
   */
  getReportPdfPdfPenerimaanRekaps(params: PdfPenerimaanRekapService.GetReportPdfPdfPenerimaanRekapsParams): __Observable<{success?: boolean, data?: Array<Pdf_penerimaan_rekap>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportPdfPdfPenerimaanRekapsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pdf_penerimaan_rekap>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pdf_penerimaan_rekap in storage
   *
   * Store pdf_penerimaan_rekap
   * @param body pdf_penerimaan_rekap that should be stored
   * @return successful operation
   */
  postReportPdfPdfPenerimaanRekapsResponse(body?: Pdf_penerimaan_rekap): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reportPdf/pdfPenerimaanRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pdf_penerimaan_rekap in storage
   *
   * Store pdf_penerimaan_rekap
   * @param body pdf_penerimaan_rekap that should be stored
   * @return successful operation
   */
  postReportPdfPdfPenerimaanRekaps(body?: Pdf_penerimaan_rekap): __Observable<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}> {
    return this.postReportPdfPdfPenerimaanRekapsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_penerimaan_rekap, message?: string})
    );
  }

  /**
   * Display the specified pdf_penerimaan_rekap
   *
   * Get pdf_penerimaan_rekap
   * @param id id of pdf_penerimaan_rekap
   * @return successful operation
   */
  getReportPdfPdfPenerimaanRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reportPdf/pdfPenerimaanRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pdf_penerimaan_rekap
   *
   * Get pdf_penerimaan_rekap
   * @param id id of pdf_penerimaan_rekap
   * @return successful operation
   */
  getReportPdfPdfPenerimaanRekapsId(id: number): __Observable<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}> {
    return this.getReportPdfPdfPenerimaanRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_penerimaan_rekap, message?: string})
    );
  }

  /**
   * Update the specified pdf_penerimaan_rekap in storage
   *
   * Update pdf_penerimaan_rekap
   * @param params The `PdfPenerimaanRekapService.PutReportPdfPdfPenerimaanRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of pdf_penerimaan_rekap
   *
   * - `body`: pdf_penerimaan_rekap that should be updated
   *
   * @return successful operation
   */
  putReportPdfPdfPenerimaanRekapsIdResponse(params: PdfPenerimaanRekapService.PutReportPdfPdfPenerimaanRekapsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/reportPdf/pdfPenerimaanRekaps/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pdf_penerimaan_rekap in storage
   *
   * Update pdf_penerimaan_rekap
   * @param params The `PdfPenerimaanRekapService.PutReportPdfPdfPenerimaanRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of pdf_penerimaan_rekap
   *
   * - `body`: pdf_penerimaan_rekap that should be updated
   *
   * @return successful operation
   */
  putReportPdfPdfPenerimaanRekapsId(params: PdfPenerimaanRekapService.PutReportPdfPdfPenerimaanRekapsIdParams): __Observable<{success?: boolean, data?: Pdf_penerimaan_rekap, message?: string}> {
    return this.putReportPdfPdfPenerimaanRekapsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_penerimaan_rekap, message?: string})
    );
  }

  /**
   * Remove the specified pdf_penerimaan_rekap from storage
   *
   * Delete pdf_penerimaan_rekap
   * @param id id of pdf_penerimaan_rekap
   * @return successful operation
   */
  deleteReportPdfPdfPenerimaanRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/reportPdf/pdfPenerimaanRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pdf_penerimaan_rekap from storage
   *
   * Delete pdf_penerimaan_rekap
   * @param id id of pdf_penerimaan_rekap
   * @return successful operation
   */
  deleteReportPdfPdfPenerimaanRekapsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportPdfPdfPenerimaanRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PdfPenerimaanRekapService {

  /**
   * Parameters for getReportPdfPdfPenerimaanRekaps
   */
  export interface GetReportPdfPdfPenerimaanRekapsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pdf_penerimaan_rekap
     */
    filter?: string;
  }

  /**
   * Parameters for putReportPdfPdfPenerimaanRekapsId
   */
  export interface PutReportPdfPdfPenerimaanRekapsIdParams {

    /**
     * id of pdf_penerimaan_rekap
     */
    id: number;

    /**
     * pdf_penerimaan_rekap that should be updated
     */
    body?: Pdf_penerimaan_rekap;
  }
}

export { PdfPenerimaanRekapService }
