/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RawRoutePrefixCurrentUser } from '../models/raw-route-prefix-current-user';

@Injectable({
  providedIn: 'root',
})
export class RawRoutePrefixCurrentUserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getcurrentUsersList
   */
  static readonly GetcurrentUsersListPath = '/currentUsers';

  /**
   * Get list of currentUsers.
   *
   * Returns list of currentUsers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getcurrentUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getcurrentUsersList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<RawRoutePrefixCurrentUser>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, RawRoutePrefixCurrentUserService.GetcurrentUsersListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<RawRoutePrefixCurrentUser>;
        }>;
      })
    );
  }

  /**
   * Get list of currentUsers.
   *
   * Returns list of currentUsers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getcurrentUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getcurrentUsersList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<RawRoutePrefixCurrentUser>;
}> {

    return this.getcurrentUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<RawRoutePrefixCurrentUser>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<RawRoutePrefixCurrentUser>;
})
    );
  }

}
