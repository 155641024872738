/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportWidgetCutiPengajuanTanggalTahunan } from '../models/absensi-report-widget-cuti-pengajuan-tanggal-tahunan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportWidgetCutiPengajuanTanggalTahunanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportWidgetCutiPengajuanTanggalTahunanList
   */
  static readonly GetAbsensiReportWidgetCutiPengajuanTanggalTahunanListPath = '/absensiReportWidget/cuti_pengajuan_tanggal_tahunans';

  /**
   * getcuti_pengajuan_tanggal_tahunanList.
   *
   * Get all cuti_pengajuan_tanggal_tahunans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetCutiPengajuanTanggalTahunanList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalTahunanList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalTahunan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalTahunanService.GetAbsensiReportWidgetCutiPengajuanTanggalTahunanListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalTahunan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuan_tanggal_tahunanList.
   *
   * Get all cuti_pengajuan_tanggal_tahunans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetCutiPengajuanTanggalTahunanList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalTahunanList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalTahunan>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportWidgetCutiPengajuanTanggalTahunanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalTahunan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalTahunan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportWidgetCutiPengajuanTanggalTahunan
   */
  static readonly StoreAbsensiReportWidgetCutiPengajuanTanggalTahunanPath = '/absensiReportWidget/cuti_pengajuan_tanggal_tahunans';

  /**
   * createcuti_pengajuan_tanggal_tahunan.
   *
   * Create cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportWidgetCutiPengajuanTanggalTahunan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetCutiPengajuanTanggalTahunan$Response(params: {
    body: AbsensiReportWidgetCutiPengajuanTanggalTahunan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalTahunanService.StoreAbsensiReportWidgetCutiPengajuanTanggalTahunanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createcuti_pengajuan_tanggal_tahunan.
   *
   * Create cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportWidgetCutiPengajuanTanggalTahunan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetCutiPengajuanTanggalTahunan(params: {
    body: AbsensiReportWidgetCutiPengajuanTanggalTahunan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}> {

    return this.storeAbsensiReportWidgetCutiPengajuanTanggalTahunan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportWidgetCutiPengajuanTanggalTahunanId
   */
  static readonly GetAbsensiReportWidgetCutiPengajuanTanggalTahunanIdPath = '/absensiReportWidget/cuti_pengajuan_tanggal_tahunans/{id}';

  /**
   * getcuti_pengajuan_tanggal_tahunanItem.
   *
   * Get cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetCutiPengajuanTanggalTahunanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalTahunanId$Response(params: {

    /**
     * id of cuti_pengajuan_tanggal_tahunan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalTahunanService.GetAbsensiReportWidgetCutiPengajuanTanggalTahunanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuan_tanggal_tahunanItem.
   *
   * Get cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetCutiPengajuanTanggalTahunanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalTahunanId(params: {

    /**
     * id of cuti_pengajuan_tanggal_tahunan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}> {

    return this.getAbsensiReportWidgetCutiPengajuanTanggalTahunanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportWidgetCutiPengajuanTanggalTahunanId
   */
  static readonly PutAbsensiReportWidgetCutiPengajuanTanggalTahunanIdPath = '/absensiReportWidget/cuti_pengajuan_tanggal_tahunans/{id}';

  /**
   * updatecuti_pengajuan_tanggal_tahunan.
   *
   * Update cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportWidgetCutiPengajuanTanggalTahunanId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetCutiPengajuanTanggalTahunanId$Response(params: {

    /**
     * id of cuti_pengajuan_tanggal_tahunan
     */
    id: string;
    body: AbsensiReportWidgetCutiPengajuanTanggalTahunan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalTahunanService.PutAbsensiReportWidgetCutiPengajuanTanggalTahunanIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatecuti_pengajuan_tanggal_tahunan.
   *
   * Update cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportWidgetCutiPengajuanTanggalTahunanId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetCutiPengajuanTanggalTahunanId(params: {

    /**
     * id of cuti_pengajuan_tanggal_tahunan
     */
    id: string;
    body: AbsensiReportWidgetCutiPengajuanTanggalTahunan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}> {

    return this.putAbsensiReportWidgetCutiPengajuanTanggalTahunanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalTahunan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportWidgetCutiPengajuanTanggalTahunansId
   */
  static readonly DeleteAbsensiReportWidgetCutiPengajuanTanggalTahunansIdPath = '/absensiReportWidget/cuti_pengajuan_tanggal_tahunans/{id}';

  /**
   * deletecuti_pengajuan_tanggal_tahunan.
   *
   * Delete cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportWidgetCutiPengajuanTanggalTahunansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetCutiPengajuanTanggalTahunansId$Response(params: {

    /**
     * id of cuti_pengajuan_tanggal_tahunan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalTahunanService.DeleteAbsensiReportWidgetCutiPengajuanTanggalTahunansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletecuti_pengajuan_tanggal_tahunan.
   *
   * Delete cuti_pengajuan_tanggal_tahunan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportWidgetCutiPengajuanTanggalTahunansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetCutiPengajuanTanggalTahunansId(params: {

    /**
     * id of cuti_pengajuan_tanggal_tahunan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportWidgetCutiPengajuanTanggalTahunansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
