/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportPegawaiBiodata } from '../models/hr-report-pegawai-biodata';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportPegawaiBiodataService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportPegawaiBiodataList
   */
  static readonly GetHrReportPegawaiBiodataListPath = '/hrReport/pegawai_biodatas';

  /**
   * getpegawai_biodataList.
   *
   * Get all pegawai_biodatas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportPegawaiBiodataList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiBiodataList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportPegawaiBiodata>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportPegawaiBiodataService.GetHrReportPegawaiBiodataListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportPegawaiBiodata>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_biodataList.
   *
   * Get all pegawai_biodatas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportPegawaiBiodataList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiBiodataList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportPegawaiBiodata>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportPegawaiBiodataList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportPegawaiBiodata>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportPegawaiBiodata>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation getHrReportPegawaiBiodataId
   */
  static readonly GetHrReportPegawaiBiodataIdPath = '/hrReport/pegawai_biodatas/{id}';

  /**
   * getpegawai_biodataItem.
   *
   * Get pegawai_biodata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportPegawaiBiodataId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiBiodataId$Response(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiBiodata;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportPegawaiBiodataService.GetHrReportPegawaiBiodataIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportPegawaiBiodata;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_biodataItem.
   *
   * Get pegawai_biodata
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportPegawaiBiodataId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiBiodataId(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportPegawaiBiodata;
'message'?: string;
}> {

    return this.getHrReportPegawaiBiodataId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiBiodata;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportPegawaiBiodata;
'message'?: string;
})
    );
  }

}
