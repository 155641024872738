/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientRegulasi } from '../models/client-regulasi';

@Injectable({
  providedIn: 'root',
})
export class ClientRegulasiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientRegulasisList
   */
  static readonly GetclientRegulasisListPath = '/client/regulasis';

  /**
   * Get list of regulasis.
   *
   * Returns list of regulasis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientRegulasisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientRegulasisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientRegulasi>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientRegulasiService.GetclientRegulasisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientRegulasi>;
        }>;
      })
    );
  }

  /**
   * Get list of regulasis.
   *
   * Returns list of regulasis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientRegulasisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientRegulasisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientRegulasi>;
}> {

    return this.getclientRegulasisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientRegulasi>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientRegulasi>;
})
    );
  }

  /**
   * Path part for operation getclientRegulasiId
   */
  static readonly GetclientRegulasiIdPath = '/client/regulasis/{id}';

  /**
   * Get  Regulasi by id.
   *
   * Returns Regulasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientRegulasiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientRegulasiId$Response(params: {

    /**
     * id of Regulasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientRegulasi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientRegulasiService.GetclientRegulasiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientRegulasi;
        }>;
      })
    );
  }

  /**
   * Get  Regulasi by id.
   *
   * Returns Regulasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientRegulasiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientRegulasiId(params: {

    /**
     * id of Regulasi
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientRegulasi;
}> {

    return this.getclientRegulasiId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientRegulasi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientRegulasi;
})
    );
  }

}
