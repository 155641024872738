/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Surat_tugas } from '../models/surat-_tugas';
@Injectable({
  providedIn: 'root',
})
class SuratTugasService extends __BaseService {
  static readonly getArsipSuratTugasPath = '/arsip/suratTugas';
  static readonly postArsipSuratTugasPath = '/arsip/suratTugas';
  static readonly getArsipSuratTugasIdPath = '/arsip/suratTugas/{id}';
  static readonly putArsipSuratTugasIdPath = '/arsip/suratTugas/{id}';
  static readonly deleteArsipSuratTugasIdPath = '/arsip/suratTugas/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Surat_tugas.
   *
   * Get all Surat_tugas
   * @param params The `SuratTugasService.GetArsipSuratTugasParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Surat_tugas
   *
   * @return successful operation
   */
  getArsipSuratTugasResponse(params: SuratTugasService.GetArsipSuratTugasParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Surat_tugas>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/suratTugas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Surat_tugas>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Surat_tugas.
   *
   * Get all Surat_tugas
   * @param params The `SuratTugasService.GetArsipSuratTugasParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Surat_tugas
   *
   * @return successful operation
   */
  getArsipSuratTugas(params: SuratTugasService.GetArsipSuratTugasParams): __Observable<{success?: boolean, data?: Array<Surat_tugas>, message?: string, total_data?: number, total_page?: number}> {
    return this.getArsipSuratTugasResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Surat_tugas>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Surat_tugas in storage
   *
   * Store Surat_tugas
   * @param body Surat_tugas that should be stored
   * @return successful operation
   */
  postArsipSuratTugasResponse(body?: Surat_tugas): __Observable<__StrictHttpResponse<{success?: boolean, data?: Surat_tugas, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/arsip/suratTugas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Surat_tugas, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Surat_tugas in storage
   *
   * Store Surat_tugas
   * @param body Surat_tugas that should be stored
   * @return successful operation
   */
  postArsipSuratTugas(body?: Surat_tugas): __Observable<{success?: boolean, data?: Surat_tugas, message?: string}> {
    return this.postArsipSuratTugasResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Surat_tugas, message?: string})
    );
  }

  /**
   * Display the specified Surat_tugas
   *
   * Get Surat_tugas
   * @param id id of Surat_tugas
   * @return successful operation
   */
  getArsipSuratTugasIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Surat_tugas, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/suratTugas/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Surat_tugas, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Surat_tugas
   *
   * Get Surat_tugas
   * @param id id of Surat_tugas
   * @return successful operation
   */
  getArsipSuratTugasId(id: number): __Observable<{success?: boolean, data?: Surat_tugas, message?: string}> {
    return this.getArsipSuratTugasIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Surat_tugas, message?: string})
    );
  }

  /**
   * Update the specified Surat_tugas in storage
   *
   * Update Surat_tugas
   * @param params The `SuratTugasService.PutArsipSuratTugasIdParams` containing the following parameters:
   *
   * - `id`: id of Surat_tugas
   *
   * - `body`: Surat_tugas that should be updated
   *
   * @return successful operation
   */
  putArsipSuratTugasIdResponse(params: SuratTugasService.PutArsipSuratTugasIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Surat_tugas, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/arsip/suratTugas/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Surat_tugas, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Surat_tugas in storage
   *
   * Update Surat_tugas
   * @param params The `SuratTugasService.PutArsipSuratTugasIdParams` containing the following parameters:
   *
   * - `id`: id of Surat_tugas
   *
   * - `body`: Surat_tugas that should be updated
   *
   * @return successful operation
   */
  putArsipSuratTugasId(params: SuratTugasService.PutArsipSuratTugasIdParams): __Observable<{success?: boolean, data?: Surat_tugas, message?: string}> {
    return this.putArsipSuratTugasIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Surat_tugas, message?: string})
    );
  }

  /**
   * Remove the specified Surat_tugas from storage
   *
   * Delete Surat_tugas
   * @param id id of Surat_tugas
   * @return successful operation
   */
  deleteArsipSuratTugasIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/arsip/suratTugas/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Surat_tugas from storage
   *
   * Delete Surat_tugas
   * @param id id of Surat_tugas
   * @return successful operation
   */
  deleteArsipSuratTugasId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteArsipSuratTugasIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module SuratTugasService {

  /**
   * Parameters for getArsipSuratTugas
   */
  export interface GetArsipSuratTugasParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Surat_tugas
     */
    filter?: string;
  }

  /**
   * Parameters for putArsipSuratTugasId
   */
  export interface PutArsipSuratTugasIdParams {

    /**
     * id of Surat_tugas
     */
    id: number;

    /**
     * Surat_tugas that should be updated
     */
    body?: Surat_tugas;
  }
}

export { SuratTugasService }
