<div class="d-inline-block mr-4">
    <nz-input-group class="extInput" [nzPrefix]="prefixIconSearch">
        <input nz-input (focus)="setVisible()" placeholder="{{ 'topBar.typeToSearch' | translate }}"
            style="width: 160px" />
    </nz-input-group>
    <ng-template #prefixIconSearch>
        <i nz-icon nzType="search" class="extInputIcon" style="color: rgba(0,0,0,.25)"></i>
    </ng-template>
    <div class="livesearch" [ngClass]="{'livesearchVisible': showSearch}">
        <button class="close" type="button" (click)="setHidden()">
            <i class="fe fe-x"></i>
        </button>
        <div class="container-fluid">
            <div class="wrapper">
                <input #liveSearchInput [(ngModel)]="searchText" class="searchInput"
                    placeholder="{{ 'topBar.typeToSearch' | translate }}" />
                <ul class="options">
                    <li class="option optionCheckbox">
                        <label nz-checkbox>Search within page</label>
                    </li>
                    <li class="option">Press enter to search</li>
                </ul>
                <div *ngIf="!searchText" class="results">
                    <div class="resultsTitle">
                        <span>No Results Found</span>
                    </div>
                </div>
                <div *ngIf="searchText" class="results">
                    <div class="resultsTitle">
                        <span>Pelanggan Search Results</span>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <app-list-pelanggan-widget [search]="searchVal"></app-list-pelanggan-widget>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
