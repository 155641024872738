import { Component, ViewChild, ElementRef, OnInit } from '@angular/core'
import { SearchService } from './search.service'

@Component({
    selector: 'vb-topbar-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class TopbarSearchComponent implements OnInit {
    @ViewChild('liveSearchInput') liveSearchInput: ElementRef

    showSearch: boolean = false
    searchText: string = ''
    searchVal: string = ''

    constructor(
        private searchService: SearchService
    ) {
        document.addEventListener('keydown', this.handleKeyDown.bind(this), false)
    }
    ngOnInit(): void {
        this.searchService.togleSearch.subscribe(
            data => {
                this.showSearch = data
            }
        )
    }

    setVisible() {
        this.showSearch = true
        setTimeout(() => {
            this.liveSearchInput.nativeElement.focus()
        }, 100)
    }

    setHidden() {
        this.showSearch = false
        this.searchService.togleSearch.next(false)
    }

    handleKeyDown(event: any) {
        if (this.showSearch) {
            const key = event.keyCode.toString()
            console.log(key)
            if (key === '27') {
                this.setHidden()
            }
            if (key === '13') { //enter
                this.searchVal = this.searchText
            }
        }
    }
}
