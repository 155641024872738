/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportKehadiranLog } from '../models/absensi-report-kehadiran-log';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportKehadiranLogService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportKehadiranLogList
   */
  static readonly GetAbsensiReportKehadiranLogListPath = '/absensiReport/kehadiran_logs';

  /**
   * getkehadiran_logList.
   *
   * Get all kehadiran_logs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportKehadiranLogList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportKehadiranLogList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportKehadiranLog>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportKehadiranLogService.GetAbsensiReportKehadiranLogListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportKehadiranLog>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getkehadiran_logList.
   *
   * Get all kehadiran_logs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportKehadiranLogList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportKehadiranLogList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportKehadiranLog>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportKehadiranLogList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportKehadiranLog>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportKehadiranLog>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportKehadiranLog
   */
  static readonly StoreAbsensiReportKehadiranLogPath = '/absensiReport/kehadiran_logs';

  /**
   * createkehadiran_log.
   *
   * Create kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportKehadiranLog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportKehadiranLog$Response(params: {
    body: AbsensiReportKehadiranLog
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportKehadiranLogService.StoreAbsensiReportKehadiranLogPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportKehadiranLog;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createkehadiran_log.
   *
   * Create kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportKehadiranLog$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportKehadiranLog(params: {
    body: AbsensiReportKehadiranLog
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}> {

    return this.storeAbsensiReportKehadiranLog$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportKehadiranLogId
   */
  static readonly GetAbsensiReportKehadiranLogIdPath = '/absensiReport/kehadiran_logs/{id}';

  /**
   * getkehadiran_logItem.
   *
   * Get kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportKehadiranLogId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportKehadiranLogId$Response(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportKehadiranLogService.GetAbsensiReportKehadiranLogIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportKehadiranLog;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getkehadiran_logItem.
   *
   * Get kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportKehadiranLogId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportKehadiranLogId(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}> {

    return this.getAbsensiReportKehadiranLogId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportKehadiranLogId
   */
  static readonly PutAbsensiReportKehadiranLogIdPath = '/absensiReport/kehadiran_logs/{id}';

  /**
   * updatekehadiran_log.
   *
   * Update kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportKehadiranLogId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportKehadiranLogId$Response(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
    body: AbsensiReportKehadiranLog
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportKehadiranLogService.PutAbsensiReportKehadiranLogIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportKehadiranLog;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatekehadiran_log.
   *
   * Update kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportKehadiranLogId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportKehadiranLogId(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
    body: AbsensiReportKehadiranLog
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}> {

    return this.putAbsensiReportKehadiranLogId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportKehadiranLog;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportKehadiranLogsId
   */
  static readonly DeleteAbsensiReportKehadiranLogsIdPath = '/absensiReport/kehadiran_logs/{id}';

  /**
   * deletekehadiran_log.
   *
   * Delete kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportKehadiranLogsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportKehadiranLogsId$Response(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportKehadiranLogService.DeleteAbsensiReportKehadiranLogsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletekehadiran_log.
   *
   * Delete kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportKehadiranLogsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportKehadiranLogsId(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportKehadiranLogsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
