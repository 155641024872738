/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPengumuman } from '../models/client-pengumuman';

@Injectable({
  providedIn: 'root',
})
export class ClientPengumumanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientPengumumenList
   */
  static readonly GetclientPengumumenListPath = '/client/pengumumen';

  /**
   * Get list of pengumumen.
   *
   * Returns list of pengumumen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengumumenList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengumumenList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengumuman>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengumumanService.GetclientPengumumenListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPengumuman>;
        }>;
      })
    );
  }

  /**
   * Get list of pengumumen.
   *
   * Returns list of pengumumen
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengumumenList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengumumenList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengumuman>;
}> {

    return this.getclientPengumumenList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengumuman>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengumuman>;
})
    );
  }

  /**
   * Path part for operation getclientPengumumanId
   */
  static readonly GetclientPengumumanIdPath = '/client/pengumumen/{id}';

  /**
   * Get  Pengumuman by id.
   *
   * Returns Pengumuman data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengumumanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengumumanId$Response(params: {

    /**
     * id of Pengumuman
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengumuman;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengumumanService.GetclientPengumumanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengumuman;
        }>;
      })
    );
  }

  /**
   * Get  Pengumuman by id.
   *
   * Returns Pengumuman data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengumumanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengumumanId(params: {

    /**
     * id of Pengumuman
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengumuman;
}> {

    return this.getclientPengumumanId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengumuman;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengumuman;
})
    );
  }

}
