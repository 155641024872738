/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pelanggan } from '../models/pelanggan';
import { Billing } from '../models/billing';
import { Tagihan } from '../models/tagihan';
@Injectable({
  providedIn: 'root',
})
class TagihanService extends __BaseService {
  static readonly getPpob01TagihansPath = '/ppob01/tagihans';
  static readonly postPpob01TagihansPath = '/ppob01/tagihans';
  static readonly getPpob01TagihansIdTagihanPath = '/ppob01/tagihans/{id_tagihan}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Tagihan.
   *
   * Get all Tagihan
   * @param params The `TagihanService.GetPpob01TagihansParams` containing the following parameters:
   *
   * - `kd_pelanggan`: kode KodePelanggan
   *
   * - `api_token`: kode token
   *
   * @return successful operation
   */
  getPpob01TagihansResponse(params: TagihanService.GetPpob01TagihansParams): __Observable<__StrictHttpResponse<{ResponseCode?: string, DataPelanggan?: Array<Pelanggan>, DataTunggakan?: Array<Billing>, ResponseDescription?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.kdPelanggan != null) __params = __params.set('kd_pelanggan', params.kdPelanggan.toString());
    if (params.apiToken != null) __params = __params.set('api_token', params.apiToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ppob01/tagihans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{ResponseCode?: string, DataPelanggan?: Array<Pelanggan>, DataTunggakan?: Array<Billing>, ResponseDescription?: string}>;
      })
    );
  }
  /**
   * Get a listing of the Tagihan.
   *
   * Get all Tagihan
   * @param params The `TagihanService.GetPpob01TagihansParams` containing the following parameters:
   *
   * - `kd_pelanggan`: kode KodePelanggan
   *
   * - `api_token`: kode token
   *
   * @return successful operation
   */
  getPpob01Tagihans(params: TagihanService.GetPpob01TagihansParams): __Observable<{ResponseCode?: string, DataPelanggan?: Array<Pelanggan>, DataTunggakan?: Array<Billing>, ResponseDescription?: string}> {
    return this.getPpob01TagihansResponse(params).pipe(
      __map(_r => _r.body as {ResponseCode?: string, DataPelanggan?: Array<Pelanggan>, DataTunggakan?: Array<Billing>, ResponseDescription?: string})
    );
  }

  /**
   * Update the specified Tagihan in storage
   *
   * Update Tagihan
   * @param body Tagihan that should be updated
   * @return successful operation
   */
  postPpob01TagihansResponse(body?: any): __Observable<__StrictHttpResponse<Array<any>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ppob01/tagihans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<any>>;
      })
    );
  }
  /**
   * Update the specified Tagihan in storage
   *
   * Update Tagihan
   * @param body Tagihan that should be updated
   * @return successful operation
   */
  postPpob01Tagihans(body?: any): __Observable<Array<any>> {
    return this.postPpob01TagihansResponse(body).pipe(
      __map(_r => _r.body as Array<any>)
    );
  }

  /**
   * Cek status bayar Tagihan.
   *
   * Get all Tagihan
   * @param id_tagihan id of Tagihan (kwitansi)
   * @return successful operation
   */
  getPpob01TagihansIdTagihanResponse(idTagihan: number): __Observable<__StrictHttpResponse<{ResponseCode?: string, Status?: string, DataPembayaran?: Tagihan, ResponseDescription?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ppob01/tagihans/${encodeURIComponent(String(idTagihan))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{ResponseCode?: string, Status?: string, DataPembayaran?: Tagihan, ResponseDescription?: string}>;
      })
    );
  }
  /**
   * Cek status bayar Tagihan.
   *
   * Get all Tagihan
   * @param id_tagihan id of Tagihan (kwitansi)
   * @return successful operation
   */
  getPpob01TagihansIdTagihan(idTagihan: number): __Observable<{ResponseCode?: string, Status?: string, DataPembayaran?: Tagihan, ResponseDescription?: string}> {
    return this.getPpob01TagihansIdTagihanResponse(idTagihan).pipe(
      __map(_r => _r.body as {ResponseCode?: string, Status?: string, DataPembayaran?: Tagihan, ResponseDescription?: string})
    );
  }
}

module TagihanService {

  /**
   * Parameters for getPpob01Tagihans
   */
  export interface GetPpob01TagihansParams {

    /**
     * kode KodePelanggan
     */
    kdPelanggan: string;

    /**
     * kode token
     */
    apiToken: string;
  }
}

export { TagihanService }
