/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CmsBerita } from '../models/cms-berita';

@Injectable({
  providedIn: 'root',
})
export class CmsBeritaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getberitasList
   */
  static readonly GetberitasListPath = '/cms/beritas';

  /**
   * Get list of beritas.
   *
   * Returns list of beritas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getberitasList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getberitasList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsBerita>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsBeritaService.GetberitasListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<CmsBerita>;
        }>;
      })
    );
  }

  /**
   * Get list of beritas.
   *
   * Returns list of beritas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getberitasList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getberitasList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsBerita>;
}> {

    return this.getberitasList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsBerita>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsBerita>;
})
    );
  }

  /**
   * Path part for operation storeBerita
   */
  static readonly StoreBeritaPath = '/cms/beritas';

  /**
   * Store new Berita.
   *
   * Returns Berita data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeBerita()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeBerita$Response(params: {
    body: CmsBerita
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsBeritaService.StoreBeritaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsBerita;
        }>;
      })
    );
  }

  /**
   * Store new Berita.
   *
   * Returns Berita data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeBerita$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeBerita(params: {
    body: CmsBerita
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}> {

    return this.storeBerita$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
})
    );
  }

  /**
   * Path part for operation getBeritaId
   */
  static readonly GetBeritaIdPath = '/cms/beritas/{id}';

  /**
   * Get  Berita by id.
   *
   * Returns Berita data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBeritaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBeritaId$Response(params: {

    /**
     * id of Berita
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsBeritaService.GetBeritaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsBerita;
        }>;
      })
    );
  }

  /**
   * Get  Berita by id.
   *
   * Returns Berita data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBeritaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBeritaId(params: {

    /**
     * id of Berita
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}> {

    return this.getBeritaId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
})
    );
  }

  /**
   * Path part for operation putBerita
   */
  static readonly PutBeritaPath = '/cms/beritas/{id}';

  /**
   * Update Berita.
   *
   * Returns Berita data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putBerita()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putBerita$Response(params: {

    /**
     * id of Berita
     */
    id: string;
    body: CmsBerita
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsBeritaService.PutBeritaPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsBerita;
        }>;
      })
    );
  }

  /**
   * Update Berita.
   *
   * Returns Berita data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putBerita$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putBerita(params: {

    /**
     * id of Berita
     */
    id: string;
    body: CmsBerita
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}> {

    return this.putBerita$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsBerita;
})
    );
  }

  /**
   * Path part for operation deleteBerita
   */
  static readonly DeleteBeritaPath = '/cms/beritas/{id}';

  /**
   * Store new Berita.
   *
   * Returns Berita data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBerita()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBerita$Response(params: {

    /**
     * id of Berita
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsBeritaService.DeleteBeritaPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Berita.
   *
   * Returns Berita data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBerita$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBerita(params: {

    /**
     * id of Berita
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteBerita$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
