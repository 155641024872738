/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pesanan_air_tangki_item } from '../models/pesanan-_air-_tangki-_item';
@Injectable({
  providedIn: 'root',
})
class PesananAirTangkiItemService extends __BaseService {
  static readonly getPelangganPesananAirTangkiItemsPath = '/pelanggan/pesananAirTangkiItems';
  static readonly postPelangganPesananAirTangkiItemsPath = '/pelanggan/pesananAirTangkiItems';
  static readonly getPelangganPesananAirTangkiItemsIdPath = '/pelanggan/pesananAirTangkiItems/{id}';
  static readonly putPelangganPesananAirTangkiItemsIdPath = '/pelanggan/pesananAirTangkiItems/{id}';
  static readonly deletePelangganPesananAirTangkiItemsIdPath = '/pelanggan/pesananAirTangkiItems/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Pesanan_air_tangki_item.
   *
   * Get all Pesanan_air_tangki_item
   * @param params The `PesananAirTangkiItemService.GetPelangganPesananAirTangkiItemsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pesanan_air_tangki_item
   *
   * @return successful operation
   */
  getPelangganPesananAirTangkiItemsResponse(params: PesananAirTangkiItemService.GetPelangganPesananAirTangkiItemsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pesanan_air_tangki_item>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/pesananAirTangkiItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pesanan_air_tangki_item>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Pesanan_air_tangki_item.
   *
   * Get all Pesanan_air_tangki_item
   * @param params The `PesananAirTangkiItemService.GetPelangganPesananAirTangkiItemsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Pesanan_air_tangki_item
   *
   * @return successful operation
   */
  getPelangganPesananAirTangkiItems(params: PesananAirTangkiItemService.GetPelangganPesananAirTangkiItemsParams): __Observable<{success?: boolean, data?: Array<Pesanan_air_tangki_item>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPesananAirTangkiItemsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pesanan_air_tangki_item>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Pesanan_air_tangki_item in storage
   *
   * Store Pesanan_air_tangki_item
   * @param body Pesanan_air_tangki_item that should be stored
   * @return successful operation
   */
  postPelangganPesananAirTangkiItemsResponse(body?: Pesanan_air_tangki_item): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/pesananAirTangkiItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Pesanan_air_tangki_item in storage
   *
   * Store Pesanan_air_tangki_item
   * @param body Pesanan_air_tangki_item that should be stored
   * @return successful operation
   */
  postPelangganPesananAirTangkiItems(body?: Pesanan_air_tangki_item): __Observable<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}> {
    return this.postPelangganPesananAirTangkiItemsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pesanan_air_tangki_item, message?: string})
    );
  }

  /**
   * Display the specified Pesanan_air_tangki_item
   *
   * Get Pesanan_air_tangki_item
   * @param id id of Pesanan_air_tangki_item
   * @return successful operation
   */
  getPelangganPesananAirTangkiItemsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/pesananAirTangkiItems/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Pesanan_air_tangki_item
   *
   * Get Pesanan_air_tangki_item
   * @param id id of Pesanan_air_tangki_item
   * @return successful operation
   */
  getPelangganPesananAirTangkiItemsId(id: number): __Observable<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}> {
    return this.getPelangganPesananAirTangkiItemsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pesanan_air_tangki_item, message?: string})
    );
  }

  /**
   * Update the specified Pesanan_air_tangki_item in storage
   *
   * Update Pesanan_air_tangki_item
   * @param params The `PesananAirTangkiItemService.PutPelangganPesananAirTangkiItemsIdParams` containing the following parameters:
   *
   * - `id`: id of Pesanan_air_tangki_item
   *
   * - `body`: Pesanan_air_tangki_item that should be updated
   *
   * @return successful operation
   */
  putPelangganPesananAirTangkiItemsIdResponse(params: PesananAirTangkiItemService.PutPelangganPesananAirTangkiItemsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/pesananAirTangkiItems/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Pesanan_air_tangki_item in storage
   *
   * Update Pesanan_air_tangki_item
   * @param params The `PesananAirTangkiItemService.PutPelangganPesananAirTangkiItemsIdParams` containing the following parameters:
   *
   * - `id`: id of Pesanan_air_tangki_item
   *
   * - `body`: Pesanan_air_tangki_item that should be updated
   *
   * @return successful operation
   */
  putPelangganPesananAirTangkiItemsId(params: PesananAirTangkiItemService.PutPelangganPesananAirTangkiItemsIdParams): __Observable<{success?: boolean, data?: Pesanan_air_tangki_item, message?: string}> {
    return this.putPelangganPesananAirTangkiItemsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pesanan_air_tangki_item, message?: string})
    );
  }

  /**
   * Remove the specified Pesanan_air_tangki_item from storage
   *
   * Delete Pesanan_air_tangki_item
   * @param id id of Pesanan_air_tangki_item
   * @return successful operation
   */
  deletePelangganPesananAirTangkiItemsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/pesananAirTangkiItems/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Pesanan_air_tangki_item from storage
   *
   * Delete Pesanan_air_tangki_item
   * @param id id of Pesanan_air_tangki_item
   * @return successful operation
   */
  deletePelangganPesananAirTangkiItemsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPesananAirTangkiItemsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PesananAirTangkiItemService {

  /**
   * Parameters for getPelangganPesananAirTangkiItems
   */
  export interface GetPelangganPesananAirTangkiItemsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Pesanan_air_tangki_item
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPesananAirTangkiItemsId
   */
  export interface PutPelangganPesananAirTangkiItemsIdParams {

    /**
     * id of Pesanan_air_tangki_item
     */
    id: number;

    /**
     * Pesanan_air_tangki_item that should be updated
     */
    body?: Pesanan_air_tangki_item;
  }
}

export { PesananAirTangkiItemService }
