/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Penerimaan_rekap } from '../models/penerimaan-_rekap';
@Injectable({
  providedIn: 'root',
})
class PenerimaanRekapService extends __BaseService {
  static readonly getReportPenerimaanRekapsPath = '/report/penerimaanRekaps';
  static readonly postReportPenerimaanRekapsPath = '/report/penerimaanRekaps';
  static readonly getReportPenerimaanRekapsIdPath = '/report/penerimaanRekaps/{id}';
  static readonly putReportPenerimaanRekapsIdPath = '/report/penerimaanRekaps/{id}';
  static readonly deleteReportPenerimaanRekapsIdPath = '/report/penerimaanRekaps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the penerimaan_rekap.
   *
   * Get all penerimaan_rekap
   * @param params The `PenerimaanRekapService.GetReportPenerimaanRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter penerimaan_rekap
   *
   * @return successful operation
   */
  getReportPenerimaanRekapsResponse(params: PenerimaanRekapService.GetReportPenerimaanRekapsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Penerimaan_rekap>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/penerimaanRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Penerimaan_rekap>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the penerimaan_rekap.
   *
   * Get all penerimaan_rekap
   * @param params The `PenerimaanRekapService.GetReportPenerimaanRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter penerimaan_rekap
   *
   * @return successful operation
   */
  getReportPenerimaanRekaps(params: PenerimaanRekapService.GetReportPenerimaanRekapsParams): __Observable<{success?: boolean, data?: Array<Penerimaan_rekap>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportPenerimaanRekapsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Penerimaan_rekap>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created penerimaan_rekap in storage
   *
   * Store penerimaan_rekap
   * @param body penerimaan_rekap that should be stored
   * @return successful operation
   */
  postReportPenerimaanRekapsResponse(body?: Penerimaan_rekap): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penerimaan_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report/penerimaanRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penerimaan_rekap, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created penerimaan_rekap in storage
   *
   * Store penerimaan_rekap
   * @param body penerimaan_rekap that should be stored
   * @return successful operation
   */
  postReportPenerimaanRekaps(body?: Penerimaan_rekap): __Observable<{success?: boolean, data?: Penerimaan_rekap, message?: string}> {
    return this.postReportPenerimaanRekapsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penerimaan_rekap, message?: string})
    );
  }

  /**
   * Display the specified penerimaan_rekap
   *
   * Get penerimaan_rekap
   * @param id id of penerimaan_rekap
   * @return successful operation
   */
  getReportPenerimaanRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penerimaan_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/penerimaanRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penerimaan_rekap, message?: string}>;
      })
    );
  }
  /**
   * Display the specified penerimaan_rekap
   *
   * Get penerimaan_rekap
   * @param id id of penerimaan_rekap
   * @return successful operation
   */
  getReportPenerimaanRekapsId(id: number): __Observable<{success?: boolean, data?: Penerimaan_rekap, message?: string}> {
    return this.getReportPenerimaanRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penerimaan_rekap, message?: string})
    );
  }

  /**
   * Update the specified penerimaan_rekap in storage
   *
   * Update penerimaan_rekap
   * @param params The `PenerimaanRekapService.PutReportPenerimaanRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of penerimaan_rekap
   *
   * - `body`: penerimaan_rekap that should be updated
   *
   * @return successful operation
   */
  putReportPenerimaanRekapsIdResponse(params: PenerimaanRekapService.PutReportPenerimaanRekapsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penerimaan_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/report/penerimaanRekaps/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penerimaan_rekap, message?: string}>;
      })
    );
  }
  /**
   * Update the specified penerimaan_rekap in storage
   *
   * Update penerimaan_rekap
   * @param params The `PenerimaanRekapService.PutReportPenerimaanRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of penerimaan_rekap
   *
   * - `body`: penerimaan_rekap that should be updated
   *
   * @return successful operation
   */
  putReportPenerimaanRekapsId(params: PenerimaanRekapService.PutReportPenerimaanRekapsIdParams): __Observable<{success?: boolean, data?: Penerimaan_rekap, message?: string}> {
    return this.putReportPenerimaanRekapsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penerimaan_rekap, message?: string})
    );
  }

  /**
   * Remove the specified penerimaan_rekap from storage
   *
   * Delete penerimaan_rekap
   * @param id id of penerimaan_rekap
   * @return successful operation
   */
  deleteReportPenerimaanRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/report/penerimaanRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified penerimaan_rekap from storage
   *
   * Delete penerimaan_rekap
   * @param id id of penerimaan_rekap
   * @return successful operation
   */
  deleteReportPenerimaanRekapsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportPenerimaanRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PenerimaanRekapService {

  /**
   * Parameters for getReportPenerimaanRekaps
   */
  export interface GetReportPenerimaanRekapsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter penerimaan_rekap
     */
    filter?: string;
  }

  /**
   * Parameters for putReportPenerimaanRekapsId
   */
  export interface PutReportPenerimaanRekapsIdParams {

    /**
     * id of penerimaan_rekap
     */
    id: number;

    /**
     * penerimaan_rekap that should be updated
     */
    body?: Penerimaan_rekap;
  }
}

export { PenerimaanRekapService }
