/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminTipePengaduan } from '../models/admin-tipe-pengaduan';

@Injectable({
  providedIn: 'root',
})
export class AdminTipePengaduanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminTipePengaduansList
   */
  static readonly GetadminTipePengaduansListPath = '/admin/tipePengaduans';

  /**
   * Get list of tipePengaduans.
   *
   * Returns list of tipePengaduans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminTipePengaduansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminTipePengaduansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminTipePengaduan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminTipePengaduanService.GetadminTipePengaduansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminTipePengaduan>;
        }>;
      })
    );
  }

  /**
   * Get list of tipePengaduans.
   *
   * Returns list of tipePengaduans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminTipePengaduansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminTipePengaduansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminTipePengaduan>;
}> {

    return this.getadminTipePengaduansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminTipePengaduan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminTipePengaduan>;
})
    );
  }

  /**
   * Path part for operation storeadminTipePengaduan
   */
  static readonly StoreadminTipePengaduanPath = '/admin/tipePengaduans';

  /**
   * Store new Tipe_pengaduan.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminTipePengaduan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminTipePengaduan$Response(params: {
    body: AdminTipePengaduan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminTipePengaduanService.StoreadminTipePengaduanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminTipePengaduan;
        }>;
      })
    );
  }

  /**
   * Store new Tipe_pengaduan.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminTipePengaduan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminTipePengaduan(params: {
    body: AdminTipePengaduan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}> {

    return this.storeadminTipePengaduan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
})
    );
  }

  /**
   * Path part for operation getadminTipePengaduanId
   */
  static readonly GetadminTipePengaduanIdPath = '/admin/tipePengaduans/{id}';

  /**
   * Get  Tipe_pengaduan by id.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminTipePengaduanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminTipePengaduanId$Response(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminTipePengaduanService.GetadminTipePengaduanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminTipePengaduan;
        }>;
      })
    );
  }

  /**
   * Get  Tipe_pengaduan by id.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminTipePengaduanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminTipePengaduanId(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}> {

    return this.getadminTipePengaduanId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
})
    );
  }

  /**
   * Path part for operation putadminTipePengaduan
   */
  static readonly PutadminTipePengaduanPath = '/admin/tipePengaduans/{id}';

  /**
   * Update Tipe_pengaduan.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminTipePengaduan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminTipePengaduan$Response(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
    body: AdminTipePengaduan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminTipePengaduanService.PutadminTipePengaduanPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminTipePengaduan;
        }>;
      })
    );
  }

  /**
   * Update Tipe_pengaduan.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminTipePengaduan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminTipePengaduan(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
    body: AdminTipePengaduan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}> {

    return this.putadminTipePengaduan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminTipePengaduan;
})
    );
  }

  /**
   * Path part for operation deleteadminTipePengaduan
   */
  static readonly DeleteadminTipePengaduanPath = '/admin/tipePengaduans/{id}';

  /**
   * Store new Tipe_pengaduan.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminTipePengaduan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminTipePengaduan$Response(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminTipePengaduanService.DeleteadminTipePengaduanPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Tipe_pengaduan.
   *
   * Returns Tipe_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminTipePengaduan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminTipePengaduan(params: {

    /**
     * id of Tipe_pengaduan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminTipePengaduan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
