/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiJadwalPegawaiTerjadwal } from '../models/absensi-jadwal-pegawai-terjadwal';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiJadwalPegawaiTerjadwalService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiJadwalPegawaiTerjadwalList
   */
  static readonly GetAbsensiJadwalPegawaiTerjadwalListPath = '/absensi/jadwal_pegawai_terjadwals';

  /**
   * getjadwal_pegawai_terjadwalList.
   *
   * Get all jadwal_pegawai_terjadwals
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiJadwalPegawaiTerjadwalList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalPegawaiTerjadwalList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalPegawaiTerjadwalService.GetAbsensiJadwalPegawaiTerjadwalListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiJadwalPegawaiTerjadwal>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getjadwal_pegawai_terjadwalList.
   *
   * Get all jadwal_pegawai_terjadwals
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiJadwalPegawaiTerjadwalList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalPegawaiTerjadwalList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiJadwalPegawaiTerjadwalList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiJadwalPegawaiTerjadwal
   */
  static readonly StoreAbsensiJadwalPegawaiTerjadwalPath = '/absensi/jadwal_pegawai_terjadwals';

  /**
   * createjadwal_pegawai_terjadwal.
   *
   * Create jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiJadwalPegawaiTerjadwal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiJadwalPegawaiTerjadwal$Response(params: {
    body: AbsensiJadwalPegawaiTerjadwal
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalPegawaiTerjadwalService.StoreAbsensiJadwalPegawaiTerjadwalPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiJadwalPegawaiTerjadwal;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createjadwal_pegawai_terjadwal.
   *
   * Create jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiJadwalPegawaiTerjadwal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiJadwalPegawaiTerjadwal(params: {
    body: AbsensiJadwalPegawaiTerjadwal
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}> {

    return this.storeAbsensiJadwalPegawaiTerjadwal$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiJadwalPegawaiTerjadwalId
   */
  static readonly GetAbsensiJadwalPegawaiTerjadwalIdPath = '/absensi/jadwal_pegawai_terjadwals/{id}';

  /**
   * getjadwal_pegawai_terjadwalItem.
   *
   * Get jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiJadwalPegawaiTerjadwalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalPegawaiTerjadwalId$Response(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalPegawaiTerjadwalService.GetAbsensiJadwalPegawaiTerjadwalIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiJadwalPegawaiTerjadwal;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getjadwal_pegawai_terjadwalItem.
   *
   * Get jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiJadwalPegawaiTerjadwalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalPegawaiTerjadwalId(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}> {

    return this.getAbsensiJadwalPegawaiTerjadwalId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiJadwalPegawaiTerjadwalId
   */
  static readonly PutAbsensiJadwalPegawaiTerjadwalIdPath = '/absensi/jadwal_pegawai_terjadwals/{id}';

  /**
   * updatejadwal_pegawai_terjadwal.
   *
   * Update jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiJadwalPegawaiTerjadwalId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiJadwalPegawaiTerjadwalId$Response(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
    body: AbsensiJadwalPegawaiTerjadwal
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalPegawaiTerjadwalService.PutAbsensiJadwalPegawaiTerjadwalIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiJadwalPegawaiTerjadwal;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatejadwal_pegawai_terjadwal.
   *
   * Update jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiJadwalPegawaiTerjadwalId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiJadwalPegawaiTerjadwalId(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
    body: AbsensiJadwalPegawaiTerjadwal
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}> {

    return this.putAbsensiJadwalPegawaiTerjadwalId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiJadwalPegawaiTerjadwal;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiJadwalPegawaiTerjadwalsId
   */
  static readonly DeleteAbsensiJadwalPegawaiTerjadwalsIdPath = '/absensi/jadwal_pegawai_terjadwals/{id}';

  /**
   * deletejadwal_pegawai_terjadwal.
   *
   * Delete jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiJadwalPegawaiTerjadwalsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiJadwalPegawaiTerjadwalsId$Response(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalPegawaiTerjadwalService.DeleteAbsensiJadwalPegawaiTerjadwalsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletejadwal_pegawai_terjadwal.
   *
   * Delete jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiJadwalPegawaiTerjadwalsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiJadwalPegawaiTerjadwalsId(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiJadwalPegawaiTerjadwalsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
