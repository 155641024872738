/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrPegawaiKeahlian } from '../models/hr-pegawai-keahlian';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrPegawaiKeahlianService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrPegawaiKeahlianList
   */
  static readonly GetHrPegawaiKeahlianListPath = '/hr/pegawai_keahlians';

  /**
   * getpegawai_keahlianList.
   *
   * Get all pegawai_keahlians
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiKeahlianList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiKeahlianList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiKeahlian>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiKeahlianService.GetHrPegawaiKeahlianListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrPegawaiKeahlian>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_keahlianList.
   *
   * Get all pegawai_keahlians
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiKeahlianList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiKeahlianList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrPegawaiKeahlian>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrPegawaiKeahlianList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiKeahlian>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrPegawaiKeahlian>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrPegawaiKeahlian
   */
  static readonly StoreHrPegawaiKeahlianPath = '/hr/pegawai_keahlians';

  /**
   * createpegawai_keahlian.
   *
   * Create pegawai_keahlian
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrPegawaiKeahlian()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiKeahlian$Response(params: {
    body: HrPegawaiKeahlian
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiKeahlianService.StoreHrPegawaiKeahlianPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiKeahlian;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_keahlian.
   *
   * Create pegawai_keahlian
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrPegawaiKeahlian$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiKeahlian(params: {
    body: HrPegawaiKeahlian
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}> {

    return this.storeHrPegawaiKeahlian$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrPegawaiKeahlianId
   */
  static readonly GetHrPegawaiKeahlianIdPath = '/hr/pegawai_keahlians/{id}';

  /**
   * getpegawai_keahlianItem.
   *
   * Get pegawai_keahlian
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiKeahlianId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiKeahlianId$Response(params: {

    /**
     * id of pegawai_keahlian
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiKeahlianService.GetHrPegawaiKeahlianIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiKeahlian;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_keahlianItem.
   *
   * Get pegawai_keahlian
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiKeahlianId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiKeahlianId(params: {

    /**
     * id of pegawai_keahlian
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}> {

    return this.getHrPegawaiKeahlianId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrPegawaiKeahlianId
   */
  static readonly PutHrPegawaiKeahlianIdPath = '/hr/pegawai_keahlians/{id}';

  /**
   * updatepegawai_keahlian.
   *
   * Update pegawai_keahlian
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrPegawaiKeahlianId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiKeahlianId$Response(params: {

    /**
     * id of pegawai_keahlian
     */
    id: string;
    body: HrPegawaiKeahlian
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiKeahlianService.PutHrPegawaiKeahlianIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiKeahlian;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_keahlian.
   *
   * Update pegawai_keahlian
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrPegawaiKeahlianId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiKeahlianId(params: {

    /**
     * id of pegawai_keahlian
     */
    id: string;
    body: HrPegawaiKeahlian
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}> {

    return this.putHrPegawaiKeahlianId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiKeahlian;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrPegawaiKeahliansId
   */
  static readonly DeleteHrPegawaiKeahliansIdPath = '/hr/pegawai_keahlians/{id}';

  /**
   * deletepegawai_keahlian.
   *
   * Delete pegawai_keahlian
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrPegawaiKeahliansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiKeahliansId$Response(params: {

    /**
     * id of pegawai_keahlian
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiKeahlianService.DeleteHrPegawaiKeahliansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_keahlian.
   *
   * Delete pegawai_keahlian
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrPegawaiKeahliansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiKeahliansId(params: {

    /**
     * id of pegawai_keahlian
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrPegawaiKeahliansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
