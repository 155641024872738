/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWidgetPegawaiByGolongan } from '../models/hr-report-widget-pegawai-by-golongan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWidgetPegawaiByGolonganService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiByGolonganList
   */
  static readonly GetHrReportWidgetPegawaiByGolonganListPath = '/hrReportWidget/pegawai_by_golongans';

  /**
   * getpegawai_by_golonganList.
   *
   * Get all pegawai_by_golongans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiByGolonganList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGolonganList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGolongan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGolonganService.GetHrReportWidgetPegawaiByGolonganListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWidgetPegawaiByGolongan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_by_golonganList.
   *
   * Get all pegawai_by_golongans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiByGolonganList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGolonganList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGolongan>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWidgetPegawaiByGolonganList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGolongan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGolongan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWidgetPegawaiByGolongan
   */
  static readonly StoreHrReportWidgetPegawaiByGolonganPath = '/hrReportWidget/pegawai_by_golongans';

  /**
   * createpegawai_by_golongan.
   *
   * Create pegawai_by_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWidgetPegawaiByGolongan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiByGolongan$Response(params: {
    body: HrReportWidgetPegawaiByGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGolonganService.StoreHrReportWidgetPegawaiByGolonganPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiByGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_by_golongan.
   *
   * Create pegawai_by_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWidgetPegawaiByGolongan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiByGolongan(params: {
    body: HrReportWidgetPegawaiByGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}> {

    return this.storeHrReportWidgetPegawaiByGolongan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiByGolonganId
   */
  static readonly GetHrReportWidgetPegawaiByGolonganIdPath = '/hrReportWidget/pegawai_by_golongans/{id}';

  /**
   * getpegawai_by_golonganItem.
   *
   * Get pegawai_by_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiByGolonganId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGolonganId$Response(params: {

    /**
     * id of pegawai_by_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGolonganService.GetHrReportWidgetPegawaiByGolonganIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiByGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_by_golonganItem.
   *
   * Get pegawai_by_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiByGolonganId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGolonganId(params: {

    /**
     * id of pegawai_by_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}> {

    return this.getHrReportWidgetPegawaiByGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWidgetPegawaiByGolonganId
   */
  static readonly PutHrReportWidgetPegawaiByGolonganIdPath = '/hrReportWidget/pegawai_by_golongans/{id}';

  /**
   * updatepegawai_by_golongan.
   *
   * Update pegawai_by_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWidgetPegawaiByGolonganId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiByGolonganId$Response(params: {

    /**
     * id of pegawai_by_golongan
     */
    id: string;
    body: HrReportWidgetPegawaiByGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGolonganService.PutHrReportWidgetPegawaiByGolonganIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiByGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_by_golongan.
   *
   * Update pegawai_by_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWidgetPegawaiByGolonganId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiByGolonganId(params: {

    /**
     * id of pegawai_by_golongan
     */
    id: string;
    body: HrReportWidgetPegawaiByGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}> {

    return this.putHrReportWidgetPegawaiByGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWidgetPegawaiByGolongansId
   */
  static readonly DeleteHrReportWidgetPegawaiByGolongansIdPath = '/hrReportWidget/pegawai_by_golongans/{id}';

  /**
   * deletepegawai_by_golongan.
   *
   * Delete pegawai_by_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWidgetPegawaiByGolongansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiByGolongansId$Response(params: {

    /**
     * id of pegawai_by_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGolonganService.DeleteHrReportWidgetPegawaiByGolongansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_by_golongan.
   *
   * Delete pegawai_by_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWidgetPegawaiByGolongansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiByGolongansId(params: {

    /**
     * id of pegawai_by_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWidgetPegawaiByGolongansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
