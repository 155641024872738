/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class RefreshService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation storeRefresh
   */
  static readonly StoreRefreshPath = '/auth/refresh';

  /**
   * refresh login.
   *
   * Returns refresh data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeRefresh$Response(params?: {
  }): Observable<StrictHttpResponse<{

/**
 * access_token dari server
 */
'access_token'?: string;

/**
 * expires_in dari server
 */
'expires_in'?: number;

/**
 * token_type
 */
'token_type'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, RefreshService.StoreRefreshPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * access_token dari server
         */
        'access_token'?: string;
        
        /**
         * expires_in dari server
         */
        'expires_in'?: number;
        
        /**
         * token_type
         */
        'token_type'?: string;
        }>;
      })
    );
  }

  /**
   * refresh login.
   *
   * Returns refresh data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeRefresh(params?: {
  }): Observable<{

/**
 * access_token dari server
 */
'access_token'?: string;

/**
 * expires_in dari server
 */
'expires_in'?: number;

/**
 * token_type
 */
'token_type'?: string;
}> {

    return this.storeRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * access_token dari server
 */
'access_token'?: string;

/**
 * expires_in dari server
 */
'expires_in'?: number;

/**
 * token_type
 */
'token_type'?: string;
}>) => r.body as {

/**
 * access_token dari server
 */
'access_token'?: string;

/**
 * expires_in dari server
 */
'expires_in'?: number;

/**
 * token_type
 */
'token_type'?: string;
})
    );
  }

}
