import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerService, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Blok, Cabang, Pelanggan, Sumber_air, Type_pelanggan, Wilayah } from 'src/app/sdk/pelanggan/models';
import { BlokService, CabangService, KdPelangganOldService, PelangganService, SumberAirService, TypePelangganPerPdamService, TypePelangganService, WilayahService } from 'src/app/sdk/pelanggan/services';
import { PesanService } from 'src/app/services/pesan.service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'src/app/services/angular-reactive-forms-helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
    selector: 'app-add-data-pelanggan',
    templateUrl: './add-data-pelanggan.component.html',
    styleUrls: ['./add-data-pelanggan.component.scss']
})
export class AddDataPelangganComponent implements OnInit {

    constructor(
        private pesan: PesanService,
        private router: Router,
        private drawerService: NzDrawerService,
        private nzDrawerRef: NzDrawerRef<string>,
        private pelangganService: PelangganService,
        private typePelangganService: TypePelangganService,
        private typePelangganPerPdamService: TypePelangganPerPdamService,
        private cabangService: CabangService,
        private wilayahService: WilayahService,
        private blokService: BlokService,
        private sumberAirService: SumberAirService,
        private KdPelangganOld: KdPelangganOldService
    ) { }

    tanggal = new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });

    ngOnInit(): void {
        this.getPreeData()
    }

    dataPelanggan: any = {}

    listCabang: Cabang[] = []
    listWilayah: Wilayah[] = []
    listBlok: Blok[] = []
    listSumber: Sumber_air[] = []
    listTipe: any[] = []
    pageSize = 50;
    pageIndex = 1;
    getPreeData() {
        let param = {}
        let aa = {
            page: this.pageIndex,
            pagesize: this.pageSize,
        }
        this.cabangService.getPelangganCabangs(param).subscribe(
            data => {
                this.listCabang = data.data
            })
        this.sumberAirService.getPelangganSumberAirs(param).subscribe(
            data => {
                this.listSumber = data.data
            })
        this.typePelangganPerPdamService.getPelangganTypePelangganPerPdams(aa).subscribe(
            data => {
                this.listTipe = data.data
            })
    }

    getWilayah() {
        let params = {
            filter: JSON.stringify({ id_cabang: this.dataPelanggan.id_cabang })
        }
        this.wilayahService.getPelangganWilayahs(params).subscribe(data => {
            this.listWilayah = data.data
        })
    }

    getBlok() {
        let param = {
            filter: JSON.stringify({
                id_wilayah: this.dataPelanggan.id_wilayah
            })
        }
        this.blokService.getPelangganBloks(param).subscribe(data => {
            this.listBlok = data.data
        })
    }

    getKdOld() {
        let param = {
            filter: JSON.stringify({
                id_cabang: this.dataPelanggan.id_cabang,
                id_blok: this.dataPelanggan.id_blok,
            }),
            sortKey: 'kd_pelanggan_old',
            sortValue: 'desc'
        }
        this.KdPelangganOld.getPelangganKdPelangganOlds(param).subscribe(data => {
            this.dataPelanggan.kd_pelanggan_old = data.data[0]['kd_pelanggan_old']
        })
    }



    simpan() {
        if (!this.dataPelanggan.nama_pelanggan) {
            this.pesan.pesanWarningForm('nama pelanggan tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.kd_pelanggan_old) {
            this.pesan.pesanWarningForm('kode pelanggan old tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.alamat) {
            this.pesan.pesanWarningForm('alamat tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.hp) {
            this.pesan.pesanWarningForm('Nomor HP tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_type_pelanggan) {
            this.pesan.pesanWarningForm('type pelanggan tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_cabang) {
            this.pesan.pesanWarningForm('cabang tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_wilayah) {
            this.pesan.pesanWarningForm('wilayah tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_blok) {
            this.pesan.pesanWarningForm('blok tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.id_sumber_air) {
            this.pesan.pesanWarningForm('sumber air tidak boleh kosong')
            return
        }
        if (!this.dataPelanggan.hp) {
            this.pesan.pesanWarningForm('Nomor hp tidak boleh kosong')
            return
        }
        if(this.dataPelanggan.hp.length < 11){
            this.pesan.pesanWarningForm('Input nomor hp yang benar')
            return
        }
        if (!this.dataPelanggan.tipe_pelanggan) {
            this.pesan.pesanWarningForm('Kategori sambungan tidak boleh kosong')
            return
        }
        if (this.dataPelanggan.tipe_pelanggan == 'MBR' && !this.dataPelanggan.kd_tipe) {
            this.pesan.pesanWarningForm('Kode MBR tidak boleh kosong')
            return
        }
        this.dataPelanggan.tanggal_daftar = this.tanggal
        this.pelangganService.postPelangganPelanggans(this.dataPelanggan).subscribe(
            data => {
                this.goToBack('berhasil')
            },
            err => {
                this.pesan.pesanWarningForm('Gagal di simpan')
            },
            () => {
                console.log('HTTP request completed.')
            },
        )
    }

    goToBack(data = null) {
        this.nzDrawerRef.close(data)
    }
}
