/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Mou_addendum } from '../models/mou-_addendum';
@Injectable({
  providedIn: 'root',
})
class MouAddendumService extends __BaseService {
  static readonly getArsipMouAddendumsPath = '/arsip/mouAddendums';
  static readonly postArsipMouAddendumsPath = '/arsip/mouAddendums';
  static readonly getArsipMouAddendumsIdPath = '/arsip/mouAddendums/{id}';
  static readonly putArsipMouAddendumsIdPath = '/arsip/mouAddendums/{id}';
  static readonly deleteArsipMouAddendumsIdPath = '/arsip/mouAddendums/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Mou_addendum.
   *
   * Get all Mou_addendum
   * @param params The `MouAddendumService.GetArsipMouAddendumsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Mou_addendum
   *
   * @return successful operation
   */
  getArsipMouAddendumsResponse(params: MouAddendumService.GetArsipMouAddendumsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Mou_addendum>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/mouAddendums`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Mou_addendum>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Mou_addendum.
   *
   * Get all Mou_addendum
   * @param params The `MouAddendumService.GetArsipMouAddendumsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Mou_addendum
   *
   * @return successful operation
   */
  getArsipMouAddendums(params: MouAddendumService.GetArsipMouAddendumsParams): __Observable<{success?: boolean, data?: Array<Mou_addendum>, message?: string, total_data?: number, total_page?: number}> {
    return this.getArsipMouAddendumsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Mou_addendum>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Mou_addendum in storage
   *
   * Store Mou_addendum
   * @param body Mou_addendum that should be stored
   * @return successful operation
   */
  postArsipMouAddendumsResponse(body?: Mou_addendum): __Observable<__StrictHttpResponse<{success?: boolean, data?: Mou_addendum, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/arsip/mouAddendums`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Mou_addendum, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Mou_addendum in storage
   *
   * Store Mou_addendum
   * @param body Mou_addendum that should be stored
   * @return successful operation
   */
  postArsipMouAddendums(body?: Mou_addendum): __Observable<{success?: boolean, data?: Mou_addendum, message?: string}> {
    return this.postArsipMouAddendumsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Mou_addendum, message?: string})
    );
  }

  /**
   * Display the specified Mou_addendum
   *
   * Get Mou_addendum
   * @param id id of Mou_addendum
   * @return successful operation
   */
  getArsipMouAddendumsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Mou_addendum, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/mouAddendums/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Mou_addendum, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Mou_addendum
   *
   * Get Mou_addendum
   * @param id id of Mou_addendum
   * @return successful operation
   */
  getArsipMouAddendumsId(id: number): __Observable<{success?: boolean, data?: Mou_addendum, message?: string}> {
    return this.getArsipMouAddendumsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Mou_addendum, message?: string})
    );
  }

  /**
   * Update the specified Mou_addendum in storage
   *
   * Update Mou_addendum
   * @param params The `MouAddendumService.PutArsipMouAddendumsIdParams` containing the following parameters:
   *
   * - `id`: id of Mou_addendum
   *
   * - `body`: Mou_addendum that should be updated
   *
   * @return successful operation
   */
  putArsipMouAddendumsIdResponse(params: MouAddendumService.PutArsipMouAddendumsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Mou_addendum, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/arsip/mouAddendums/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Mou_addendum, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Mou_addendum in storage
   *
   * Update Mou_addendum
   * @param params The `MouAddendumService.PutArsipMouAddendumsIdParams` containing the following parameters:
   *
   * - `id`: id of Mou_addendum
   *
   * - `body`: Mou_addendum that should be updated
   *
   * @return successful operation
   */
  putArsipMouAddendumsId(params: MouAddendumService.PutArsipMouAddendumsIdParams): __Observable<{success?: boolean, data?: Mou_addendum, message?: string}> {
    return this.putArsipMouAddendumsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Mou_addendum, message?: string})
    );
  }

  /**
   * Remove the specified Mou_addendum from storage
   *
   * Delete Mou_addendum
   * @param id id of Mou_addendum
   * @return successful operation
   */
  deleteArsipMouAddendumsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/arsip/mouAddendums/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Mou_addendum from storage
   *
   * Delete Mou_addendum
   * @param id id of Mou_addendum
   * @return successful operation
   */
  deleteArsipMouAddendumsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteArsipMouAddendumsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module MouAddendumService {

  /**
   * Parameters for getArsipMouAddendums
   */
  export interface GetArsipMouAddendumsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Mou_addendum
     */
    filter?: string;
  }

  /**
   * Parameters for putArsipMouAddendumsId
   */
  export interface PutArsipMouAddendumsIdParams {

    /**
     * id of Mou_addendum
     */
    id: number;

    /**
     * Mou_addendum that should be updated
     */
    body?: Mou_addendum;
  }
}

export { MouAddendumService }
