/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tagihan_opname } from '../models/tagihan-_opname';
@Injectable({
  providedIn: 'root',
})
class TagihanOpnameService extends __BaseService {
  static readonly getPelangganPdfTagihanOpnamesPath = '/pelangganPdf/tagihanOpnames';
  static readonly postPelangganPdfTagihanOpnamesPath = '/pelangganPdf/tagihanOpnames';
  static readonly getPelangganPdfTagihanOpnamesIdPath = '/pelangganPdf/tagihanOpnames/{id}';
  static readonly putPelangganPdfTagihanOpnamesIdPath = '/pelangganPdf/tagihanOpnames/{id}';
  static readonly deletePelangganPdfTagihanOpnamesIdPath = '/pelangganPdf/tagihanOpnames/{id}';
  static readonly getPelangganReportTagihanOpnamesPath = '/pelangganReport/tagihanOpnames';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Tagihan_opname.
   *
   * Get all Tagihan_opname
   * @param params The `TagihanOpnameService.GetPelangganPdfTagihanOpnamesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan_opname
   *
   * @return successful operation
   */
  getPelangganPdfTagihanOpnamesResponse(params: TagihanOpnameService.GetPelangganPdfTagihanOpnamesParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/tagihanOpnames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Tagihan_opname.
   *
   * Get all Tagihan_opname
   * @param params The `TagihanOpnameService.GetPelangganPdfTagihanOpnamesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan_opname
   *
   * @return successful operation
   */
  getPelangganPdfTagihanOpnames(params: TagihanOpnameService.GetPelangganPdfTagihanOpnamesParams): __Observable<{success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPdfTagihanOpnamesResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Tagihan_opname in storage
   *
   * Store Tagihan_opname
   * @param body Tagihan_opname that should be stored
   * @return successful operation
   */
  postPelangganPdfTagihanOpnamesResponse(body?: Tagihan_opname): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan_opname, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganPdf/tagihanOpnames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan_opname, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Tagihan_opname in storage
   *
   * Store Tagihan_opname
   * @param body Tagihan_opname that should be stored
   * @return successful operation
   */
  postPelangganPdfTagihanOpnames(body?: Tagihan_opname): __Observable<{success?: boolean, data?: Tagihan_opname, message?: string}> {
    return this.postPelangganPdfTagihanOpnamesResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan_opname, message?: string})
    );
  }

  /**
   * Display the specified Tagihan_opname
   *
   * Get Tagihan_opname
   * @param id id of Tagihan_opname
   * @return successful operation
   */
  getPelangganPdfTagihanOpnamesIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan_opname, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/tagihanOpnames/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan_opname, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Tagihan_opname
   *
   * Get Tagihan_opname
   * @param id id of Tagihan_opname
   * @return successful operation
   */
  getPelangganPdfTagihanOpnamesId(id: number): __Observable<{success?: boolean, data?: Tagihan_opname, message?: string}> {
    return this.getPelangganPdfTagihanOpnamesIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan_opname, message?: string})
    );
  }

  /**
   * Update the specified Tagihan_opname in storage
   *
   * Update Tagihan_opname
   * @param params The `TagihanOpnameService.PutPelangganPdfTagihanOpnamesIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan_opname
   *
   * - `body`: Tagihan_opname that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfTagihanOpnamesIdResponse(params: TagihanOpnameService.PutPelangganPdfTagihanOpnamesIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan_opname, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganPdf/tagihanOpnames/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan_opname, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Tagihan_opname in storage
   *
   * Update Tagihan_opname
   * @param params The `TagihanOpnameService.PutPelangganPdfTagihanOpnamesIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan_opname
   *
   * - `body`: Tagihan_opname that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfTagihanOpnamesId(params: TagihanOpnameService.PutPelangganPdfTagihanOpnamesIdParams): __Observable<{success?: boolean, data?: Tagihan_opname, message?: string}> {
    return this.putPelangganPdfTagihanOpnamesIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan_opname, message?: string})
    );
  }

  /**
   * Remove the specified Tagihan_opname from storage
   *
   * Delete Tagihan_opname
   * @param id id of Tagihan_opname
   * @return successful operation
   */
  deletePelangganPdfTagihanOpnamesIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganPdf/tagihanOpnames/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Tagihan_opname from storage
   *
   * Delete Tagihan_opname
   * @param id id of Tagihan_opname
   * @return successful operation
   */
  deletePelangganPdfTagihanOpnamesId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPdfTagihanOpnamesIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }

  /**
   * Get a listing of the Tagihan_opname.
   *
   * Get all Tagihan_opname
   * @param params The `TagihanOpnameService.GetPelangganReportTagihanOpnamesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan_opname
   *
   * @return successful operation
   */
  getPelangganReportTagihanOpnamesResponse(params: TagihanOpnameService.GetPelangganReportTagihanOpnamesParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tagihanOpnames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Tagihan_opname.
   *
   * Get all Tagihan_opname
   * @param params The `TagihanOpnameService.GetPelangganReportTagihanOpnamesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan_opname
   *
   * @return successful operation
   */
  getPelangganReportTagihanOpnames(params: TagihanOpnameService.GetPelangganReportTagihanOpnamesParams): __Observable<{success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportTagihanOpnamesResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Tagihan_opname>, message?: string, total_data?: number, total_page?: number})
    );
  }
}

module TagihanOpnameService {

  /**
   * Parameters for getPelangganPdfTagihanOpnames
   */
  export interface GetPelangganPdfTagihanOpnamesParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Tagihan_opname
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPdfTagihanOpnamesId
   */
  export interface PutPelangganPdfTagihanOpnamesIdParams {

    /**
     * id of Tagihan_opname
     */
    id: number;

    /**
     * Tagihan_opname that should be updated
     */
    body?: Tagihan_opname;
  }

  /**
   * Parameters for getPelangganReportTagihanOpnames
   */
  export interface GetPelangganReportTagihanOpnamesParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Tagihan_opname
     */
    filter?: string;
  }
}

export { TagihanOpnameService }
