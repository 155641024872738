/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientMasterStatusJumlah } from '../models/client-master-status-jumlah';

@Injectable({
  providedIn: 'root',
})
export class ClientMasterStatusJumlahService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientgetmasterStatusJumlahsList
   */
  static readonly ClientgetmasterStatusJumlahsListPath = '/client/masterStatusJumlahs';

  /**
   * Get list of masterStatusJumlahs.
   *
   * Returns list of masterStatusJumlahs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetmasterStatusJumlahsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetmasterStatusJumlahsList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterStatusJumlah>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterStatusJumlahService.ClientgetmasterStatusJumlahsListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientMasterStatusJumlah>;
        }>;
      })
    );
  }

  /**
   * Get list of masterStatusJumlahs.
   *
   * Returns list of masterStatusJumlahs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetmasterStatusJumlahsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetmasterStatusJumlahsList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterStatusJumlah>;
}> {

    return this.clientgetmasterStatusJumlahsList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterStatusJumlah>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterStatusJumlah>;
})
    );
  }

  /**
   * Path part for operation clientgetMasterStatusJumlahId
   */
  static readonly ClientgetMasterStatusJumlahIdPath = '/client/masterStatusJumlahs/{id}';

  /**
   * Get  Master_status_jumlah by id.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetMasterStatusJumlahId()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetMasterStatusJumlahId$Response(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterStatusJumlah;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterStatusJumlahService.ClientgetMasterStatusJumlahIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterStatusJumlah;
        }>;
      })
    );
  }

  /**
   * Get  Master_status_jumlah by id.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetMasterStatusJumlahId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetMasterStatusJumlahId(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterStatusJumlah;
}> {

    return this.clientgetMasterStatusJumlahId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterStatusJumlah;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterStatusJumlah;
})
    );
  }

}
