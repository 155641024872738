/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ErpReportPangkat } from '../models/erp-report-pangkat';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class ErpReportPangkatService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getErpReportPangkatList
   */
  static readonly GetErpReportPangkatListPath = '/erpReport/pangkats';

  /**
   * getpangkatList.
   *
   * Get all pangkats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpReportPangkatList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportPangkatList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpReportPangkat>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportPangkatService.GetErpReportPangkatListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<ErpReportPangkat>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpangkatList.
   *
   * Get all pangkats
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpReportPangkatList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportPangkatList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<ErpReportPangkat>;
'message'?: string;
'total'?: number;
}> {

    return this.getErpReportPangkatList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpReportPangkat>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<ErpReportPangkat>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeErpReportPangkat
   */
  static readonly StoreErpReportPangkatPath = '/erpReport/pangkats';

  /**
   * createpangkat.
   *
   * Create pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeErpReportPangkat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpReportPangkat$Response(params: {
    body: ErpReportPangkat
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportPangkatService.StoreErpReportPangkatPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportPangkat;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpangkat.
   *
   * Create pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeErpReportPangkat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpReportPangkat(params: {
    body: ErpReportPangkat
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}> {

    return this.storeErpReportPangkat$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getErpReportPangkatId
   */
  static readonly GetErpReportPangkatIdPath = '/erpReport/pangkats/{id}';

  /**
   * getpangkatItem.
   *
   * Get pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpReportPangkatId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportPangkatId$Response(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportPangkatService.GetErpReportPangkatIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportPangkat;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpangkatItem.
   *
   * Get pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpReportPangkatId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportPangkatId(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}> {

    return this.getErpReportPangkatId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putErpReportPangkatId
   */
  static readonly PutErpReportPangkatIdPath = '/erpReport/pangkats/{id}';

  /**
   * updatepangkat.
   *
   * Update pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putErpReportPangkatId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpReportPangkatId$Response(params: {

    /**
     * id of pangkat
     */
    id: string;
    body: ErpReportPangkat
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportPangkatService.PutErpReportPangkatIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportPangkat;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepangkat.
   *
   * Update pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putErpReportPangkatId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpReportPangkatId(params: {

    /**
     * id of pangkat
     */
    id: string;
    body: ErpReportPangkat
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}> {

    return this.putErpReportPangkatId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportPangkat;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteErpReportPangkatsId
   */
  static readonly DeleteErpReportPangkatsIdPath = '/erpReport/pangkats/{id}';

  /**
   * deletepangkat.
   *
   * Delete pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteErpReportPangkatsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpReportPangkatsId$Response(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportPangkatService.DeleteErpReportPangkatsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepangkat.
   *
   * Delete pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteErpReportPangkatsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpReportPangkatsId(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteErpReportPangkatsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
