/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminGaleryDetail } from '../models/admin-galery-detail';

@Injectable({
  providedIn: 'root',
})
export class AdminGaleryDetailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminGaleryDetailsList
   */
  static readonly GetadminGaleryDetailsListPath = '/admin/galeryDetails';

  /**
   * Get list of galeryDetails.
   *
   * Returns list of galeryDetails
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminGaleryDetailsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleryDetailsList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGaleryDetail>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryDetailService.GetadminGaleryDetailsListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminGaleryDetail>;
        }>;
      })
    );
  }

  /**
   * Get list of galeryDetails.
   *
   * Returns list of galeryDetails
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminGaleryDetailsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleryDetailsList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGaleryDetail>;
}> {

    return this.getadminGaleryDetailsList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGaleryDetail>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGaleryDetail>;
})
    );
  }

  /**
   * Path part for operation storeadminGaleryDetail
   */
  static readonly StoreadminGaleryDetailPath = '/admin/galeryDetails';

  /**
   * Store new Galery_detail.
   *
   * Returns Galery_detail data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminGaleryDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminGaleryDetail$Response(params: {
    body: AdminGaleryDetail
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryDetailService.StoreadminGaleryDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminGaleryDetail;
        }>;
      })
    );
  }

  /**
   * Store new Galery_detail.
   *
   * Returns Galery_detail data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminGaleryDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminGaleryDetail(params: {
    body: AdminGaleryDetail
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}> {

    return this.storeadminGaleryDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
})
    );
  }

  /**
   * Path part for operation getadminGaleryDetailId
   */
  static readonly GetadminGaleryDetailIdPath = '/admin/galeryDetails/{id}';

  /**
   * Get  Galery_detail by id.
   *
   * Returns Galery_detail data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminGaleryDetailId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleryDetailId$Response(params: {

    /**
     * id of Galery_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryDetailService.GetadminGaleryDetailIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminGaleryDetail;
        }>;
      })
    );
  }

  /**
   * Get  Galery_detail by id.
   *
   * Returns Galery_detail data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminGaleryDetailId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleryDetailId(params: {

    /**
     * id of Galery_detail
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}> {

    return this.getadminGaleryDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
})
    );
  }

  /**
   * Path part for operation putadminGaleryDetail
   */
  static readonly PutadminGaleryDetailPath = '/admin/galeryDetails/{id}';

  /**
   * Update Galery_detail.
   *
   * Returns Galery_detail data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminGaleryDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminGaleryDetail$Response(params: {

    /**
     * id of Galery_detail
     */
    id: string;
    body: AdminGaleryDetail
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryDetailService.PutadminGaleryDetailPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminGaleryDetail;
        }>;
      })
    );
  }

  /**
   * Update Galery_detail.
   *
   * Returns Galery_detail data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminGaleryDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminGaleryDetail(params: {

    /**
     * id of Galery_detail
     */
    id: string;
    body: AdminGaleryDetail
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}> {

    return this.putadminGaleryDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGaleryDetail;
})
    );
  }

  /**
   * Path part for operation deleteadminGaleryDetail
   */
  static readonly DeleteadminGaleryDetailPath = '/admin/galeryDetails/{id}';

  /**
   * Store new Galery_detail.
   *
   * Returns Galery_detail data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminGaleryDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminGaleryDetail$Response(params: {

    /**
     * id of Galery_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryDetailService.DeleteadminGaleryDetailPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Galery_detail.
   *
   * Returns Galery_detail data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminGaleryDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminGaleryDetail(params: {

    /**
     * id of Galery_detail
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminGaleryDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
