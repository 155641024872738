/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pdf_pendapatan } from '../models/pdf-_pendapatan';
@Injectable({
  providedIn: 'root',
})
class PdfPendapatanService extends __BaseService {
  static readonly getReportPdfPdfPendapatansPath = '/reportPdf/pdfPendapatans';
  static readonly postReportPdfPdfPendapatansPath = '/reportPdf/pdfPendapatans';
  static readonly getReportPdfPdfPendapatansIdPath = '/reportPdf/pdfPendapatans/{id}';
  static readonly putReportPdfPdfPendapatansIdPath = '/reportPdf/pdfPendapatans/{id}';
  static readonly deleteReportPdfPdfPendapatansIdPath = '/reportPdf/pdfPendapatans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the pdf_pendapatan.
   *
   * Get all pdf_pendapatan
   * @param params The `PdfPendapatanService.GetReportPdfPdfPendapatansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pdf_pendapatan
   *
   * @return successful operation
   */
  getReportPdfPdfPendapatansResponse(params: PdfPendapatanService.GetReportPdfPdfPendapatansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pdf_pendapatan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reportPdf/pdfPendapatans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pdf_pendapatan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pdf_pendapatan.
   *
   * Get all pdf_pendapatan
   * @param params The `PdfPendapatanService.GetReportPdfPdfPendapatansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pdf_pendapatan
   *
   * @return successful operation
   */
  getReportPdfPdfPendapatans(params: PdfPendapatanService.GetReportPdfPdfPendapatansParams): __Observable<{success?: boolean, data?: Array<Pdf_pendapatan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportPdfPdfPendapatansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pdf_pendapatan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pdf_pendapatan in storage
   *
   * Store pdf_pendapatan
   * @param body pdf_pendapatan that should be stored
   * @return successful operation
   */
  postReportPdfPdfPendapatansResponse(body?: Pdf_pendapatan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reportPdf/pdfPendapatans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pdf_pendapatan in storage
   *
   * Store pdf_pendapatan
   * @param body pdf_pendapatan that should be stored
   * @return successful operation
   */
  postReportPdfPdfPendapatans(body?: Pdf_pendapatan): __Observable<{success?: boolean, data?: Pdf_pendapatan, message?: string}> {
    return this.postReportPdfPdfPendapatansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_pendapatan, message?: string})
    );
  }

  /**
   * Display the specified pdf_pendapatan
   *
   * Get pdf_pendapatan
   * @param id id of pdf_pendapatan
   * @return successful operation
   */
  getReportPdfPdfPendapatansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reportPdf/pdfPendapatans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pdf_pendapatan
   *
   * Get pdf_pendapatan
   * @param id id of pdf_pendapatan
   * @return successful operation
   */
  getReportPdfPdfPendapatansId(id: number): __Observable<{success?: boolean, data?: Pdf_pendapatan, message?: string}> {
    return this.getReportPdfPdfPendapatansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_pendapatan, message?: string})
    );
  }

  /**
   * Update the specified pdf_pendapatan in storage
   *
   * Update pdf_pendapatan
   * @param params The `PdfPendapatanService.PutReportPdfPdfPendapatansIdParams` containing the following parameters:
   *
   * - `id`: id of pdf_pendapatan
   *
   * - `body`: pdf_pendapatan that should be updated
   *
   * @return successful operation
   */
  putReportPdfPdfPendapatansIdResponse(params: PdfPendapatanService.PutReportPdfPdfPendapatansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/reportPdf/pdfPendapatans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_pendapatan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pdf_pendapatan in storage
   *
   * Update pdf_pendapatan
   * @param params The `PdfPendapatanService.PutReportPdfPdfPendapatansIdParams` containing the following parameters:
   *
   * - `id`: id of pdf_pendapatan
   *
   * - `body`: pdf_pendapatan that should be updated
   *
   * @return successful operation
   */
  putReportPdfPdfPendapatansId(params: PdfPendapatanService.PutReportPdfPdfPendapatansIdParams): __Observable<{success?: boolean, data?: Pdf_pendapatan, message?: string}> {
    return this.putReportPdfPdfPendapatansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_pendapatan, message?: string})
    );
  }

  /**
   * Remove the specified pdf_pendapatan from storage
   *
   * Delete pdf_pendapatan
   * @param id id of pdf_pendapatan
   * @return successful operation
   */
  deleteReportPdfPdfPendapatansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/reportPdf/pdfPendapatans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pdf_pendapatan from storage
   *
   * Delete pdf_pendapatan
   * @param id id of pdf_pendapatan
   * @return successful operation
   */
  deleteReportPdfPdfPendapatansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportPdfPdfPendapatansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PdfPendapatanService {

  /**
   * Parameters for getReportPdfPdfPendapatans
   */
  export interface GetReportPdfPdfPendapatansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pdf_pendapatan
     */
    filter?: string;
  }

  /**
   * Parameters for putReportPdfPdfPendapatansId
   */
  export interface PutReportPdfPdfPendapatansIdParams {

    /**
     * id of pdf_pendapatan
     */
    id: number;

    /**
     * pdf_pendapatan that should be updated
     */
    body?: Pdf_pendapatan;
  }
}

export { PdfPendapatanService }
