/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Module_detail } from '../models/module-_detail';
@Injectable({
  providedIn: 'root',
})
class ModuleDetailService extends __BaseService {
  static readonly getPelangganModuleDetailsPath = '/pelanggan/moduleDetails';
  static readonly postPelangganModuleDetailsPath = '/pelanggan/moduleDetails';
  static readonly getPelangganModuleDetailsIdPath = '/pelanggan/moduleDetails/{id}';
  static readonly putPelangganModuleDetailsIdPath = '/pelanggan/moduleDetails/{id}';
  static readonly deletePelangganModuleDetailsIdPath = '/pelanggan/moduleDetails/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Module_detail.
   *
   * Get all Module_detail
   * @param params The `ModuleDetailService.GetPelangganModuleDetailsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Module_detail
   *
   * @return successful operation
   */
  getPelangganModuleDetailsResponse(params: ModuleDetailService.GetPelangganModuleDetailsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Module_detail>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/moduleDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Module_detail>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Module_detail.
   *
   * Get all Module_detail
   * @param params The `ModuleDetailService.GetPelangganModuleDetailsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Module_detail
   *
   * @return successful operation
   */
  getPelangganModuleDetails(params: ModuleDetailService.GetPelangganModuleDetailsParams): __Observable<{success?: boolean, data?: Array<Module_detail>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganModuleDetailsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Module_detail>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Module_detail in storage
   *
   * Store Module_detail
   * @param body Module_detail that should be stored
   * @return successful operation
   */
  postPelangganModuleDetailsResponse(body?: Module_detail): __Observable<__StrictHttpResponse<{success?: boolean, data?: Module_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/moduleDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Module_detail, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Module_detail in storage
   *
   * Store Module_detail
   * @param body Module_detail that should be stored
   * @return successful operation
   */
  postPelangganModuleDetails(body?: Module_detail): __Observable<{success?: boolean, data?: Module_detail, message?: string}> {
    return this.postPelangganModuleDetailsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Module_detail, message?: string})
    );
  }

  /**
   * Display the specified Module_detail
   *
   * Get Module_detail
   * @param id id of Module_detail
   * @return successful operation
   */
  getPelangganModuleDetailsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Module_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/moduleDetails/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Module_detail, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Module_detail
   *
   * Get Module_detail
   * @param id id of Module_detail
   * @return successful operation
   */
  getPelangganModuleDetailsId(id: number): __Observable<{success?: boolean, data?: Module_detail, message?: string}> {
    return this.getPelangganModuleDetailsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Module_detail, message?: string})
    );
  }

  /**
   * Update the specified Module_detail in storage
   *
   * Update Module_detail
   * @param params The `ModuleDetailService.PutPelangganModuleDetailsIdParams` containing the following parameters:
   *
   * - `id`: id of Module_detail
   *
   * - `body`: Module_detail that should be updated
   *
   * @return successful operation
   */
  putPelangganModuleDetailsIdResponse(params: ModuleDetailService.PutPelangganModuleDetailsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Module_detail, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/moduleDetails/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Module_detail, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Module_detail in storage
   *
   * Update Module_detail
   * @param params The `ModuleDetailService.PutPelangganModuleDetailsIdParams` containing the following parameters:
   *
   * - `id`: id of Module_detail
   *
   * - `body`: Module_detail that should be updated
   *
   * @return successful operation
   */
  putPelangganModuleDetailsId(params: ModuleDetailService.PutPelangganModuleDetailsIdParams): __Observable<{success?: boolean, data?: Module_detail, message?: string}> {
    return this.putPelangganModuleDetailsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Module_detail, message?: string})
    );
  }

  /**
   * Remove the specified Module_detail from storage
   *
   * Delete Module_detail
   * @param id id of Module_detail
   * @return successful operation
   */
  deletePelangganModuleDetailsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/moduleDetails/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Module_detail from storage
   *
   * Delete Module_detail
   * @param id id of Module_detail
   * @return successful operation
   */
  deletePelangganModuleDetailsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganModuleDetailsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module ModuleDetailService {

  /**
   * Parameters for getPelangganModuleDetails
   */
  export interface GetPelangganModuleDetailsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Module_detail
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganModuleDetailsId
   */
  export interface PutPelangganModuleDetailsIdParams {

    /**
     * id of Module_detail
     */
    id: number;

    /**
     * Module_detail that should be updated
     */
    body?: Module_detail;
  }
}

export { ModuleDetailService }
