/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportWidgetJadwalPegawaiTerjadwal } from '../models/absensi-report-widget-jadwal-pegawai-terjadwal';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportWidgetJadwalPegawaiTerjadwalService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportWidgetJadwalPegawaiTerjadwalList
   */
  static readonly GetAbsensiReportWidgetJadwalPegawaiTerjadwalListPath = '/absensiReportWidget/jadwal_pegawai_terjadwals';

  /**
   * getjadwal_pegawai_terjadwalList.
   *
   * Get all jadwal_pegawai_terjadwals
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetJadwalPegawaiTerjadwalList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetJadwalPegawaiTerjadwalList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetJadwalPegawaiTerjadwalService.GetAbsensiReportWidgetJadwalPegawaiTerjadwalListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportWidgetJadwalPegawaiTerjadwal>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getjadwal_pegawai_terjadwalList.
   *
   * Get all jadwal_pegawai_terjadwals
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetJadwalPegawaiTerjadwalList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetJadwalPegawaiTerjadwalList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportWidgetJadwalPegawaiTerjadwalList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetJadwalPegawaiTerjadwal>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportWidgetJadwalPegawaiTerjadwal
   */
  static readonly StoreAbsensiReportWidgetJadwalPegawaiTerjadwalPath = '/absensiReportWidget/jadwal_pegawai_terjadwals';

  /**
   * createjadwal_pegawai_terjadwal.
   *
   * Create jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportWidgetJadwalPegawaiTerjadwal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetJadwalPegawaiTerjadwal$Response(params: {
    body: AbsensiReportWidgetJadwalPegawaiTerjadwal
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetJadwalPegawaiTerjadwalService.StoreAbsensiReportWidgetJadwalPegawaiTerjadwalPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createjadwal_pegawai_terjadwal.
   *
   * Create jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportWidgetJadwalPegawaiTerjadwal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetJadwalPegawaiTerjadwal(params: {
    body: AbsensiReportWidgetJadwalPegawaiTerjadwal
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}> {

    return this.storeAbsensiReportWidgetJadwalPegawaiTerjadwal$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportWidgetJadwalPegawaiTerjadwalId
   */
  static readonly GetAbsensiReportWidgetJadwalPegawaiTerjadwalIdPath = '/absensiReportWidget/jadwal_pegawai_terjadwals/{id}';

  /**
   * getjadwal_pegawai_terjadwalItem.
   *
   * Get jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetJadwalPegawaiTerjadwalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetJadwalPegawaiTerjadwalId$Response(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetJadwalPegawaiTerjadwalService.GetAbsensiReportWidgetJadwalPegawaiTerjadwalIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getjadwal_pegawai_terjadwalItem.
   *
   * Get jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetJadwalPegawaiTerjadwalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetJadwalPegawaiTerjadwalId(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}> {

    return this.getAbsensiReportWidgetJadwalPegawaiTerjadwalId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportWidgetJadwalPegawaiTerjadwalId
   */
  static readonly PutAbsensiReportWidgetJadwalPegawaiTerjadwalIdPath = '/absensiReportWidget/jadwal_pegawai_terjadwals/{id}';

  /**
   * updatejadwal_pegawai_terjadwal.
   *
   * Update jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportWidgetJadwalPegawaiTerjadwalId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetJadwalPegawaiTerjadwalId$Response(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
    body: AbsensiReportWidgetJadwalPegawaiTerjadwal
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetJadwalPegawaiTerjadwalService.PutAbsensiReportWidgetJadwalPegawaiTerjadwalIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatejadwal_pegawai_terjadwal.
   *
   * Update jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportWidgetJadwalPegawaiTerjadwalId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetJadwalPegawaiTerjadwalId(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
    body: AbsensiReportWidgetJadwalPegawaiTerjadwal
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}> {

    return this.putAbsensiReportWidgetJadwalPegawaiTerjadwalId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetJadwalPegawaiTerjadwal;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportWidgetJadwalPegawaiTerjadwalsId
   */
  static readonly DeleteAbsensiReportWidgetJadwalPegawaiTerjadwalsIdPath = '/absensiReportWidget/jadwal_pegawai_terjadwals/{id}';

  /**
   * deletejadwal_pegawai_terjadwal.
   *
   * Delete jadwal_pegawai_terjadwal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportWidgetJadwalPegawaiTerjadwalsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetJadwalPegawaiTerjadwalsId$Response(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetJadwalPegawaiTerjadwalService.DeleteAbsensiReportWidgetJadwalPegawaiTerjadwalsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletejadwal_pegawai_terjadwal.
   *
   * Delete jadwal_pegawai_terjadwal
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportWidgetJadwalPegawaiTerjadwalsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetJadwalPegawaiTerjadwalsId(params: {

    /**
     * id of jadwal_pegawai_terjadwal
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportWidgetJadwalPegawaiTerjadwalsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
