/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PDF_surat_jalan_tangki } from '../models/pdf_surat-_jalan-_tangki';
@Injectable({
  providedIn: 'root',
})
class PDFSuratJalanTangkiService extends __BaseService {
  static readonly getPelangganPdfPDFSuratJalanTangkisIdPath = '/pelangganPdf/pDFSuratJalanTangkis/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Display the specified PDF_surat_jalan_tangki
   *
   * Get PDF_surat_jalan_tangki
   * @param id id of PDF_surat_jalan_tangki
   * @return successful operation
   */
  getPelangganPdfPDFSuratJalanTangkisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: PDF_surat_jalan_tangki, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/pDFSuratJalanTangkis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: PDF_surat_jalan_tangki, message?: string}>;
      })
    );
  }
  /**
   * Display the specified PDF_surat_jalan_tangki
   *
   * Get PDF_surat_jalan_tangki
   * @param id id of PDF_surat_jalan_tangki
   * @return successful operation
   */
  getPelangganPdfPDFSuratJalanTangkisId(id: number): __Observable<{success?: boolean, data?: PDF_surat_jalan_tangki, message?: string}> {
    return this.getPelangganPdfPDFSuratJalanTangkisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: PDF_surat_jalan_tangki, message?: string})
    );
  }
}

module PDFSuratJalanTangkiService {
}

export { PDFSuratJalanTangkiService }
