/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Surat } from '../models/surat';
@Injectable({
  providedIn: 'root',
})
class SuratService extends __BaseService {
  static readonly getArsipSuratsPath = '/arsip/surats';
  static readonly postArsipSuratsPath = '/arsip/surats';
  static readonly getArsipSuratsIdPath = '/arsip/surats/{id}';
  static readonly putArsipSuratsIdPath = '/arsip/surats/{id}';
  static readonly deleteArsipSuratsIdPath = '/arsip/surats/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Surat.
   *
   * Get all Surat
   * @param params The `SuratService.GetArsipSuratsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Surat
   *
   * @return successful operation
   */
  getArsipSuratsResponse(params: SuratService.GetArsipSuratsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Surat>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/surats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Surat>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Surat.
   *
   * Get all Surat
   * @param params The `SuratService.GetArsipSuratsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Surat
   *
   * @return successful operation
   */
  getArsipSurats(params: SuratService.GetArsipSuratsParams): __Observable<{success?: boolean, data?: Array<Surat>, message?: string, total_data?: number, total_page?: number}> {
    return this.getArsipSuratsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Surat>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Surat in storage
   *
   * Store Surat
   * @param body Surat that should be stored
   * @return successful operation
   */
  postArsipSuratsResponse(body?: Surat): __Observable<__StrictHttpResponse<{success?: boolean, data?: Surat, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/arsip/surats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Surat, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Surat in storage
   *
   * Store Surat
   * @param body Surat that should be stored
   * @return successful operation
   */
  postArsipSurats(body?: Surat): __Observable<{success?: boolean, data?: Surat, message?: string}> {
    return this.postArsipSuratsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Surat, message?: string})
    );
  }

  /**
   * Display the specified Surat
   *
   * Get Surat
   * @param id id of Surat
   * @return successful operation
   */
  getArsipSuratsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Surat, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/surats/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Surat, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Surat
   *
   * Get Surat
   * @param id id of Surat
   * @return successful operation
   */
  getArsipSuratsId(id: number): __Observable<{success?: boolean, data?: Surat, message?: string}> {
    return this.getArsipSuratsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Surat, message?: string})
    );
  }

  /**
   * Update the specified Surat in storage
   *
   * Update Surat
   * @param params The `SuratService.PutArsipSuratsIdParams` containing the following parameters:
   *
   * - `id`: id of Surat
   *
   * - `body`: Surat that should be updated
   *
   * @return successful operation
   */
  putArsipSuratsIdResponse(params: SuratService.PutArsipSuratsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Surat, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/arsip/surats/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Surat, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Surat in storage
   *
   * Update Surat
   * @param params The `SuratService.PutArsipSuratsIdParams` containing the following parameters:
   *
   * - `id`: id of Surat
   *
   * - `body`: Surat that should be updated
   *
   * @return successful operation
   */
  putArsipSuratsId(params: SuratService.PutArsipSuratsIdParams): __Observable<{success?: boolean, data?: Surat, message?: string}> {
    return this.putArsipSuratsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Surat, message?: string})
    );
  }

  /**
   * Remove the specified Surat from storage
   *
   * Delete Surat
   * @param id id of Surat
   * @return successful operation
   */
  deleteArsipSuratsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/arsip/surats/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Surat from storage
   *
   * Delete Surat
   * @param id id of Surat
   * @return successful operation
   */
  deleteArsipSuratsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteArsipSuratsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module SuratService {

  /**
   * Parameters for getArsipSurats
   */
  export interface GetArsipSuratsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Surat
     */
    filter?: string;
  }

  /**
   * Parameters for putArsipSuratsId
   */
  export interface PutArsipSuratsIdParams {

    /**
     * id of Surat
     */
    id: number;

    /**
     * Surat that should be updated
     */
    body?: Surat;
  }
}

export { SuratService }
