/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportCutiPengajuan } from '../models/absensi-report-cuti-pengajuan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportCutiPengajuanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportCutiPengajuanList
   */
  static readonly GetAbsensiReportCutiPengajuanListPath = '/absensiReport/cuti_pengajuans';

  /**
   * getcuti_pengajuanList.
   *
   * Get all cuti_pengajuans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportCutiPengajuanList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengajuanList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengajuan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengajuanService.GetAbsensiReportCutiPengajuanListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportCutiPengajuan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuanList.
   *
   * Get all cuti_pengajuans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportCutiPengajuanList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengajuanList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengajuan>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportCutiPengajuanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengajuan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengajuan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportCutiPengajuan
   */
  static readonly StoreAbsensiReportCutiPengajuanPath = '/absensiReport/cuti_pengajuans';

  /**
   * createcuti_pengajuan.
   *
   * Create cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportCutiPengajuan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportCutiPengajuan$Response(params: {
    body: AbsensiReportCutiPengajuan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengajuanService.StoreAbsensiReportCutiPengajuanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportCutiPengajuan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createcuti_pengajuan.
   *
   * Create cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportCutiPengajuan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportCutiPengajuan(params: {
    body: AbsensiReportCutiPengajuan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}> {

    return this.storeAbsensiReportCutiPengajuan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportCutiPengajuanId
   */
  static readonly GetAbsensiReportCutiPengajuanIdPath = '/absensiReport/cuti_pengajuans/{id}';

  /**
   * getcuti_pengajuanItem.
   *
   * Get cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportCutiPengajuanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengajuanId$Response(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengajuanService.GetAbsensiReportCutiPengajuanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportCutiPengajuan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuanItem.
   *
   * Get cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportCutiPengajuanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengajuanId(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}> {

    return this.getAbsensiReportCutiPengajuanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportCutiPengajuanId
   */
  static readonly PutAbsensiReportCutiPengajuanIdPath = '/absensiReport/cuti_pengajuans/{id}';

  /**
   * updatecuti_pengajuan.
   *
   * Update cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportCutiPengajuanId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportCutiPengajuanId$Response(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
    body: AbsensiReportCutiPengajuan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengajuanService.PutAbsensiReportCutiPengajuanIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportCutiPengajuan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatecuti_pengajuan.
   *
   * Update cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportCutiPengajuanId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportCutiPengajuanId(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
    body: AbsensiReportCutiPengajuan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}> {

    return this.putAbsensiReportCutiPengajuanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportCutiPengajuan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportCutiPengajuansId
   */
  static readonly DeleteAbsensiReportCutiPengajuansIdPath = '/absensiReport/cuti_pengajuans/{id}';

  /**
   * deletecuti_pengajuan.
   *
   * Delete cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportCutiPengajuansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportCutiPengajuansId$Response(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengajuanService.DeleteAbsensiReportCutiPengajuansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletecuti_pengajuan.
   *
   * Delete cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportCutiPengajuansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportCutiPengajuansId(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportCutiPengajuansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
