/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Penagihan_by_cater } from '../models/penagihan-_by-_cater';
@Injectable({
  providedIn: 'root',
})
class PenagihanByCaterService extends __BaseService {
  static readonly getPenagihanReportPenagihanByCatersPath = '/penagihanReport/penagihanByCaters';
  static readonly postPenagihanReportPenagihanByCatersPath = '/penagihanReport/penagihanByCaters';
  static readonly getPenagihanReportPenagihanByCatersIdPath = '/penagihanReport/penagihanByCaters/{id}';
  static readonly putPenagihanReportPenagihanByCatersIdPath = '/penagihanReport/penagihanByCaters/{id}';
  static readonly deletePenagihanReportPenagihanByCatersIdPath = '/penagihanReport/penagihanByCaters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Penagihan_by_cater.
   *
   * Get all Penagihan_by_cater
   * @param params The `PenagihanByCaterService.GetPenagihanReportPenagihanByCatersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penagihan_by_cater
   *
   * @return successful operation
   */
  getPenagihanReportPenagihanByCatersResponse(params: PenagihanByCaterService.GetPenagihanReportPenagihanByCatersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Penagihan_by_cater>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/penagihanByCaters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Penagihan_by_cater>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Penagihan_by_cater.
   *
   * Get all Penagihan_by_cater
   * @param params The `PenagihanByCaterService.GetPenagihanReportPenagihanByCatersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penagihan_by_cater
   *
   * @return successful operation
   */
  getPenagihanReportPenagihanByCaters(params: PenagihanByCaterService.GetPenagihanReportPenagihanByCatersParams): __Observable<{success?: boolean, data?: Array<Penagihan_by_cater>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportPenagihanByCatersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Penagihan_by_cater>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Penagihan_by_cater in storage
   *
   * Store Penagihan_by_cater
   * @param body Penagihan_by_cater that should be stored
   * @return successful operation
   */
  postPenagihanReportPenagihanByCatersResponse(body?: Penagihan_by_cater): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_by_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/penagihanByCaters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_by_cater, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Penagihan_by_cater in storage
   *
   * Store Penagihan_by_cater
   * @param body Penagihan_by_cater that should be stored
   * @return successful operation
   */
  postPenagihanReportPenagihanByCaters(body?: Penagihan_by_cater): __Observable<{success?: boolean, data?: Penagihan_by_cater, message?: string}> {
    return this.postPenagihanReportPenagihanByCatersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_by_cater, message?: string})
    );
  }

  /**
   * Display the specified Penagihan_by_cater
   *
   * Get Penagihan_by_cater
   * @param id id of Penagihan_by_cater
   * @return successful operation
   */
  getPenagihanReportPenagihanByCatersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_by_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/penagihanByCaters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_by_cater, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Penagihan_by_cater
   *
   * Get Penagihan_by_cater
   * @param id id of Penagihan_by_cater
   * @return successful operation
   */
  getPenagihanReportPenagihanByCatersId(id: number): __Observable<{success?: boolean, data?: Penagihan_by_cater, message?: string}> {
    return this.getPenagihanReportPenagihanByCatersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_by_cater, message?: string})
    );
  }

  /**
   * Update the specified Penagihan_by_cater in storage
   *
   * Update Penagihan_by_cater
   * @param params The `PenagihanByCaterService.PutPenagihanReportPenagihanByCatersIdParams` containing the following parameters:
   *
   * - `id`: id of Penagihan_by_cater
   *
   * - `body`: Penagihan_by_cater that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPenagihanByCatersIdResponse(params: PenagihanByCaterService.PutPenagihanReportPenagihanByCatersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_by_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/penagihanByCaters/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_by_cater, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Penagihan_by_cater in storage
   *
   * Update Penagihan_by_cater
   * @param params The `PenagihanByCaterService.PutPenagihanReportPenagihanByCatersIdParams` containing the following parameters:
   *
   * - `id`: id of Penagihan_by_cater
   *
   * - `body`: Penagihan_by_cater that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPenagihanByCatersId(params: PenagihanByCaterService.PutPenagihanReportPenagihanByCatersIdParams): __Observable<{success?: boolean, data?: Penagihan_by_cater, message?: string}> {
    return this.putPenagihanReportPenagihanByCatersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_by_cater, message?: string})
    );
  }

  /**
   * Remove the specified Penagihan_by_cater from storage
   *
   * Delete Penagihan_by_cater
   * @param id id of Penagihan_by_cater
   * @return successful operation
   */
  deletePenagihanReportPenagihanByCatersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/penagihanByCaters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Penagihan_by_cater from storage
   *
   * Delete Penagihan_by_cater
   * @param id id of Penagihan_by_cater
   * @return successful operation
   */
  deletePenagihanReportPenagihanByCatersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportPenagihanByCatersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PenagihanByCaterService {

  /**
   * Parameters for getPenagihanReportPenagihanByCaters
   */
  export interface GetPenagihanReportPenagihanByCatersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Penagihan_by_cater
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportPenagihanByCatersId
   */
  export interface PutPenagihanReportPenagihanByCatersIdParams {

    /**
     * id of Penagihan_by_cater
     */
    id: number;

    /**
     * Penagihan_by_cater that should be updated
     */
    body?: Penagihan_by_cater;
  }
}

export { PenagihanByCaterService }
