/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportWidgetPerjalananDinasAnggota } from '../models/absensi-report-widget-perjalanan-dinas-anggota';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportWidgetPerjalananDinasAnggotaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportWidgetPerjalananDinasAnggotaList
   */
  static readonly GetAbsensiReportWidgetPerjalananDinasAnggotaListPath = '/absensiReportWidget/perjalanan_dinas_anggotas';

  /**
   * getperjalanan_dinas_anggotaList.
   *
   * Get all perjalanan_dinas_anggotas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetPerjalananDinasAnggotaList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetPerjalananDinasAnggotaList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetPerjalananDinasAnggotaService.GetAbsensiReportWidgetPerjalananDinasAnggotaListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportWidgetPerjalananDinasAnggota>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_anggotaList.
   *
   * Get all perjalanan_dinas_anggotas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetPerjalananDinasAnggotaList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetPerjalananDinasAnggotaList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportWidgetPerjalananDinasAnggotaList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportWidgetPerjalananDinasAnggota
   */
  static readonly StoreAbsensiReportWidgetPerjalananDinasAnggotaPath = '/absensiReportWidget/perjalanan_dinas_anggotas';

  /**
   * createperjalanan_dinas_anggota.
   *
   * Create perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportWidgetPerjalananDinasAnggota()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetPerjalananDinasAnggota$Response(params: {
    body: AbsensiReportWidgetPerjalananDinasAnggota
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetPerjalananDinasAnggotaService.StoreAbsensiReportWidgetPerjalananDinasAnggotaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createperjalanan_dinas_anggota.
   *
   * Create perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportWidgetPerjalananDinasAnggota$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetPerjalananDinasAnggota(params: {
    body: AbsensiReportWidgetPerjalananDinasAnggota
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.storeAbsensiReportWidgetPerjalananDinasAnggota$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportWidgetPerjalananDinasAnggotaId
   */
  static readonly GetAbsensiReportWidgetPerjalananDinasAnggotaIdPath = '/absensiReportWidget/perjalanan_dinas_anggotas/{id}';

  /**
   * getperjalanan_dinas_anggotaItem.
   *
   * Get perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetPerjalananDinasAnggotaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetPerjalananDinasAnggotaId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetPerjalananDinasAnggotaService.GetAbsensiReportWidgetPerjalananDinasAnggotaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_anggotaItem.
   *
   * Get perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetPerjalananDinasAnggotaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetPerjalananDinasAnggotaId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.getAbsensiReportWidgetPerjalananDinasAnggotaId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportWidgetPerjalananDinasAnggotaId
   */
  static readonly PutAbsensiReportWidgetPerjalananDinasAnggotaIdPath = '/absensiReportWidget/perjalanan_dinas_anggotas/{id}';

  /**
   * updateperjalanan_dinas_anggota.
   *
   * Update perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportWidgetPerjalananDinasAnggotaId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetPerjalananDinasAnggotaId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
    body: AbsensiReportWidgetPerjalananDinasAnggota
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetPerjalananDinasAnggotaService.PutAbsensiReportWidgetPerjalananDinasAnggotaIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updateperjalanan_dinas_anggota.
   *
   * Update perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportWidgetPerjalananDinasAnggotaId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetPerjalananDinasAnggotaId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
    body: AbsensiReportWidgetPerjalananDinasAnggota
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.putAbsensiReportWidgetPerjalananDinasAnggotaId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportWidgetPerjalananDinasAnggotasId
   */
  static readonly DeleteAbsensiReportWidgetPerjalananDinasAnggotasIdPath = '/absensiReportWidget/perjalanan_dinas_anggotas/{id}';

  /**
   * deleteperjalanan_dinas_anggota.
   *
   * Delete perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportWidgetPerjalananDinasAnggotasId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetPerjalananDinasAnggotasId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetPerjalananDinasAnggotaService.DeleteAbsensiReportWidgetPerjalananDinasAnggotasIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleteperjalanan_dinas_anggota.
   *
   * Delete perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportWidgetPerjalananDinasAnggotasId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetPerjalananDinasAnggotasId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportWidgetPerjalananDinasAnggotasId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
