/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Cabang } from '../models/cabang';
@Injectable({
  providedIn: 'root',
})
class CabangService extends __BaseService {
  static readonly getPelangganCabangsPath = '/pelanggan/cabangs';
  static readonly postPelangganCabangsPath = '/pelanggan/cabangs';
  static readonly getPelangganCabangsIdPath = '/pelanggan/cabangs/{id}';
  static readonly putPelangganCabangsIdPath = '/pelanggan/cabangs/{id}';
  static readonly deletePelangganCabangsIdPath = '/pelanggan/cabangs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Cabang.
   *
   * Get all Cabang
   * @param params The `CabangService.GetPelangganCabangsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Cabang
   *
   * @return successful operation
   */
  getPelangganCabangsResponse(params: CabangService.GetPelangganCabangsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Cabang>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/cabangs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Cabang>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Cabang.
   *
   * Get all Cabang
   * @param params The `CabangService.GetPelangganCabangsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Cabang
   *
   * @return successful operation
   */
  getPelangganCabangs(params: CabangService.GetPelangganCabangsParams): __Observable<{success?: boolean, data?: Array<Cabang>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganCabangsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Cabang>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Cabang in storage
   *
   * Store Cabang
   * @param body Cabang that should be stored
   * @return successful operation
   */
  postPelangganCabangsResponse(body?: Cabang): __Observable<__StrictHttpResponse<{success?: boolean, data?: Cabang, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/cabangs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Cabang, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Cabang in storage
   *
   * Store Cabang
   * @param body Cabang that should be stored
   * @return successful operation
   */
  postPelangganCabangs(body?: Cabang): __Observable<{success?: boolean, data?: Cabang, message?: string}> {
    return this.postPelangganCabangsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Cabang, message?: string})
    );
  }

  /**
   * Display the specified Cabang
   *
   * Get Cabang
   * @param id id of Cabang
   * @return successful operation
   */
  getPelangganCabangsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Cabang, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/cabangs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Cabang, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Cabang
   *
   * Get Cabang
   * @param id id of Cabang
   * @return successful operation
   */
  getPelangganCabangsId(id: number): __Observable<{success?: boolean, data?: Cabang, message?: string}> {
    return this.getPelangganCabangsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Cabang, message?: string})
    );
  }

  /**
   * Update the specified Cabang in storage
   *
   * Update Cabang
   * @param params The `CabangService.PutPelangganCabangsIdParams` containing the following parameters:
   *
   * - `id`: id of Cabang
   *
   * - `body`: Cabang that should be updated
   *
   * @return successful operation
   */
  putPelangganCabangsIdResponse(params: CabangService.PutPelangganCabangsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Cabang, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/cabangs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Cabang, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Cabang in storage
   *
   * Update Cabang
   * @param params The `CabangService.PutPelangganCabangsIdParams` containing the following parameters:
   *
   * - `id`: id of Cabang
   *
   * - `body`: Cabang that should be updated
   *
   * @return successful operation
   */
  putPelangganCabangsId(params: CabangService.PutPelangganCabangsIdParams): __Observable<{success?: boolean, data?: Cabang, message?: string}> {
    return this.putPelangganCabangsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Cabang, message?: string})
    );
  }

  /**
   * Remove the specified Cabang from storage
   *
   * Delete Cabang
   * @param id id of Cabang
   * @return successful operation
   */
  deletePelangganCabangsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/cabangs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Cabang from storage
   *
   * Delete Cabang
   * @param id id of Cabang
   * @return successful operation
   */
  deletePelangganCabangsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganCabangsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module CabangService {

  /**
   * Parameters for getPelangganCabangs
   */
  export interface GetPelangganCabangsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Cabang
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganCabangsId
   */
  export interface PutPelangganCabangsIdParams {

    /**
     * id of Cabang
     */
    id: number;

    /**
     * Cabang that should be updated
     */
    body?: Cabang;
  }
}

export { CabangService }
