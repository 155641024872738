/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Penagihan_map } from '../models/penagihan-_map';
@Injectable({
  providedIn: 'root',
})
class PenagihanMapService extends __BaseService {
  static readonly getPenagihanReportPenagihanMapsPath = '/penagihanReport/penagihanMaps';
  static readonly postPenagihanReportPenagihanMapsPath = '/penagihanReport/penagihanMaps';
  static readonly getPenagihanReportPenagihanMapsIdPath = '/penagihanReport/penagihanMaps/{id}';
  static readonly putPenagihanReportPenagihanMapsIdPath = '/penagihanReport/penagihanMaps/{id}';
  static readonly deletePenagihanReportPenagihanMapsIdPath = '/penagihanReport/penagihanMaps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Penagihan_map.
   *
   * Get all Penagihan_map
   * @param params The `PenagihanMapService.GetPenagihanReportPenagihanMapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penagihan_map
   *
   * @return successful operation
   */
  getPenagihanReportPenagihanMapsResponse(params: PenagihanMapService.GetPenagihanReportPenagihanMapsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Penagihan_map>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/penagihanMaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Penagihan_map>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Penagihan_map.
   *
   * Get all Penagihan_map
   * @param params The `PenagihanMapService.GetPenagihanReportPenagihanMapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penagihan_map
   *
   * @return successful operation
   */
  getPenagihanReportPenagihanMaps(params: PenagihanMapService.GetPenagihanReportPenagihanMapsParams): __Observable<{success?: boolean, data?: Array<Penagihan_map>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportPenagihanMapsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Penagihan_map>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Penagihan_map in storage
   *
   * Store Penagihan_map
   * @param body Penagihan_map that should be stored
   * @return successful operation
   */
  postPenagihanReportPenagihanMapsResponse(body?: Penagihan_map): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_map, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/penagihanMaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_map, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Penagihan_map in storage
   *
   * Store Penagihan_map
   * @param body Penagihan_map that should be stored
   * @return successful operation
   */
  postPenagihanReportPenagihanMaps(body?: Penagihan_map): __Observable<{success?: boolean, data?: Penagihan_map, message?: string}> {
    return this.postPenagihanReportPenagihanMapsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_map, message?: string})
    );
  }

  /**
   * Display the specified Penagihan_map
   *
   * Get Penagihan_map
   * @param id id of Penagihan_map
   * @return successful operation
   */
  getPenagihanReportPenagihanMapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_map, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/penagihanMaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_map, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Penagihan_map
   *
   * Get Penagihan_map
   * @param id id of Penagihan_map
   * @return successful operation
   */
  getPenagihanReportPenagihanMapsId(id: number): __Observable<{success?: boolean, data?: Penagihan_map, message?: string}> {
    return this.getPenagihanReportPenagihanMapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_map, message?: string})
    );
  }

  /**
   * Update the specified Penagihan_map in storage
   *
   * Update Penagihan_map
   * @param params The `PenagihanMapService.PutPenagihanReportPenagihanMapsIdParams` containing the following parameters:
   *
   * - `id`: id of Penagihan_map
   *
   * - `body`: Penagihan_map that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPenagihanMapsIdResponse(params: PenagihanMapService.PutPenagihanReportPenagihanMapsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_map, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/penagihanMaps/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_map, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Penagihan_map in storage
   *
   * Update Penagihan_map
   * @param params The `PenagihanMapService.PutPenagihanReportPenagihanMapsIdParams` containing the following parameters:
   *
   * - `id`: id of Penagihan_map
   *
   * - `body`: Penagihan_map that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPenagihanMapsId(params: PenagihanMapService.PutPenagihanReportPenagihanMapsIdParams): __Observable<{success?: boolean, data?: Penagihan_map, message?: string}> {
    return this.putPenagihanReportPenagihanMapsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_map, message?: string})
    );
  }

  /**
   * Remove the specified Penagihan_map from storage
   *
   * Delete Penagihan_map
   * @param id id of Penagihan_map
   * @return successful operation
   */
  deletePenagihanReportPenagihanMapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/penagihanMaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Penagihan_map from storage
   *
   * Delete Penagihan_map
   * @param id id of Penagihan_map
   * @return successful operation
   */
  deletePenagihanReportPenagihanMapsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportPenagihanMapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PenagihanMapService {

  /**
   * Parameters for getPenagihanReportPenagihanMaps
   */
  export interface GetPenagihanReportPenagihanMapsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Penagihan_map
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportPenagihanMapsId
   */
  export interface PutPenagihanReportPenagihanMapsIdParams {

    /**
     * id of Penagihan_map
     */
    id: number;

    /**
     * Penagihan_map that should be updated
     */
    body?: Penagihan_map;
  }
}

export { PenagihanMapService }
