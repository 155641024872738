/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Penagihan_map_report } from '../models/penagihan-_map-_report';
@Injectable({
  providedIn: 'root',
})
class PenagihanMapReportService extends __BaseService {
  static readonly getPenagihanReportPenagihanMapReportsPath = '/penagihanReport/penagihanMapReports';
  static readonly postPenagihanReportPenagihanMapReportsPath = '/penagihanReport/penagihanMapReports';
  static readonly getPenagihanReportPenagihanMapReportsIdPath = '/penagihanReport/penagihanMapReports/{id}';
  static readonly putPenagihanReportPenagihanMapReportsIdPath = '/penagihanReport/penagihanMapReports/{id}';
  static readonly deletePenagihanReportPenagihanMapReportsIdPath = '/penagihanReport/penagihanMapReports/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Penagihan_map_report.
   *
   * Get all Penagihan_map_report
   * @param params The `PenagihanMapReportService.GetPenagihanReportPenagihanMapReportsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penagihan_map_report
   *
   * @return successful operation
   */
  getPenagihanReportPenagihanMapReportsResponse(params: PenagihanMapReportService.GetPenagihanReportPenagihanMapReportsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Penagihan_map_report>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/penagihanMapReports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Penagihan_map_report>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Penagihan_map_report.
   *
   * Get all Penagihan_map_report
   * @param params The `PenagihanMapReportService.GetPenagihanReportPenagihanMapReportsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penagihan_map_report
   *
   * @return successful operation
   */
  getPenagihanReportPenagihanMapReports(params: PenagihanMapReportService.GetPenagihanReportPenagihanMapReportsParams): __Observable<{success?: boolean, data?: Array<Penagihan_map_report>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportPenagihanMapReportsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Penagihan_map_report>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Penagihan_map_report in storage
   *
   * Store Penagihan_map_report
   * @param body Penagihan_map_report that should be stored
   * @return successful operation
   */
  postPenagihanReportPenagihanMapReportsResponse(body?: Penagihan_map_report): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_map_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/penagihanMapReports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_map_report, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Penagihan_map_report in storage
   *
   * Store Penagihan_map_report
   * @param body Penagihan_map_report that should be stored
   * @return successful operation
   */
  postPenagihanReportPenagihanMapReports(body?: Penagihan_map_report): __Observable<{success?: boolean, data?: Penagihan_map_report, message?: string}> {
    return this.postPenagihanReportPenagihanMapReportsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_map_report, message?: string})
    );
  }

  /**
   * Display the specified Penagihan_map_report
   *
   * Get Penagihan_map_report
   * @param id id of Penagihan_map_report
   * @return successful operation
   */
  getPenagihanReportPenagihanMapReportsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_map_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/penagihanMapReports/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_map_report, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Penagihan_map_report
   *
   * Get Penagihan_map_report
   * @param id id of Penagihan_map_report
   * @return successful operation
   */
  getPenagihanReportPenagihanMapReportsId(id: number): __Observable<{success?: boolean, data?: Penagihan_map_report, message?: string}> {
    return this.getPenagihanReportPenagihanMapReportsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_map_report, message?: string})
    );
  }

  /**
   * Update the specified Penagihan_map_report in storage
   *
   * Update Penagihan_map_report
   * @param params The `PenagihanMapReportService.PutPenagihanReportPenagihanMapReportsIdParams` containing the following parameters:
   *
   * - `id`: id of Penagihan_map_report
   *
   * - `body`: Penagihan_map_report that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPenagihanMapReportsIdResponse(params: PenagihanMapReportService.PutPenagihanReportPenagihanMapReportsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penagihan_map_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/penagihanMapReports/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penagihan_map_report, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Penagihan_map_report in storage
   *
   * Update Penagihan_map_report
   * @param params The `PenagihanMapReportService.PutPenagihanReportPenagihanMapReportsIdParams` containing the following parameters:
   *
   * - `id`: id of Penagihan_map_report
   *
   * - `body`: Penagihan_map_report that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPenagihanMapReportsId(params: PenagihanMapReportService.PutPenagihanReportPenagihanMapReportsIdParams): __Observable<{success?: boolean, data?: Penagihan_map_report, message?: string}> {
    return this.putPenagihanReportPenagihanMapReportsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penagihan_map_report, message?: string})
    );
  }

  /**
   * Remove the specified Penagihan_map_report from storage
   *
   * Delete Penagihan_map_report
   * @param id id of Penagihan_map_report
   * @return successful operation
   */
  deletePenagihanReportPenagihanMapReportsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/penagihanMapReports/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Penagihan_map_report from storage
   *
   * Delete Penagihan_map_report
   * @param id id of Penagihan_map_report
   * @return successful operation
   */
  deletePenagihanReportPenagihanMapReportsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportPenagihanMapReportsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PenagihanMapReportService {

  /**
   * Parameters for getPenagihanReportPenagihanMapReports
   */
  export interface GetPenagihanReportPenagihanMapReportsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Penagihan_map_report
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportPenagihanMapReportsId
   */
  export interface PutPenagihanReportPenagihanMapReportsIdParams {

    /**
     * id of Penagihan_map_report
     */
    id: number;

    /**
     * Penagihan_map_report that should be updated
     */
    body?: Penagihan_map_report;
  }
}

export { PenagihanMapReportService }
