/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportPerjalananDinasAnggota } from '../models/absensi-report-perjalanan-dinas-anggota';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportPerjalananDinasAnggotaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportPerjalananDinasAnggotaList
   */
  static readonly GetAbsensiReportPerjalananDinasAnggotaListPath = '/absensiReport/perjalanan_dinas_anggotas';

  /**
   * getperjalanan_dinas_anggotaList.
   *
   * Get all perjalanan_dinas_anggotas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportPerjalananDinasAnggotaList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasAnggotaList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasAnggotaService.GetAbsensiReportPerjalananDinasAnggotaListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportPerjalananDinasAnggota>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_anggotaList.
   *
   * Get all perjalanan_dinas_anggotas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportPerjalananDinasAnggotaList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasAnggotaList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportPerjalananDinasAnggotaList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportPerjalananDinasAnggota
   */
  static readonly StoreAbsensiReportPerjalananDinasAnggotaPath = '/absensiReport/perjalanan_dinas_anggotas';

  /**
   * createperjalanan_dinas_anggota.
   *
   * Create perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportPerjalananDinasAnggota()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportPerjalananDinasAnggota$Response(params: {
    body: AbsensiReportPerjalananDinasAnggota
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasAnggotaService.StoreAbsensiReportPerjalananDinasAnggotaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createperjalanan_dinas_anggota.
   *
   * Create perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportPerjalananDinasAnggota$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportPerjalananDinasAnggota(params: {
    body: AbsensiReportPerjalananDinasAnggota
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.storeAbsensiReportPerjalananDinasAnggota$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportPerjalananDinasAnggotaId
   */
  static readonly GetAbsensiReportPerjalananDinasAnggotaIdPath = '/absensiReport/perjalanan_dinas_anggotas/{id}';

  /**
   * getperjalanan_dinas_anggotaItem.
   *
   * Get perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportPerjalananDinasAnggotaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasAnggotaId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasAnggotaService.GetAbsensiReportPerjalananDinasAnggotaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_anggotaItem.
   *
   * Get perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportPerjalananDinasAnggotaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportPerjalananDinasAnggotaId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.getAbsensiReportPerjalananDinasAnggotaId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportPerjalananDinasAnggotaId
   */
  static readonly PutAbsensiReportPerjalananDinasAnggotaIdPath = '/absensiReport/perjalanan_dinas_anggotas/{id}';

  /**
   * updateperjalanan_dinas_anggota.
   *
   * Update perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportPerjalananDinasAnggotaId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportPerjalananDinasAnggotaId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
    body: AbsensiReportPerjalananDinasAnggota
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasAnggotaService.PutAbsensiReportPerjalananDinasAnggotaIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updateperjalanan_dinas_anggota.
   *
   * Update perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportPerjalananDinasAnggotaId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportPerjalananDinasAnggotaId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
    body: AbsensiReportPerjalananDinasAnggota
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.putAbsensiReportPerjalananDinasAnggotaId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportPerjalananDinasAnggotasId
   */
  static readonly DeleteAbsensiReportPerjalananDinasAnggotasIdPath = '/absensiReport/perjalanan_dinas_anggotas/{id}';

  /**
   * deleteperjalanan_dinas_anggota.
   *
   * Delete perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportPerjalananDinasAnggotasId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportPerjalananDinasAnggotasId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportPerjalananDinasAnggotaService.DeleteAbsensiReportPerjalananDinasAnggotasIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleteperjalanan_dinas_anggota.
   *
   * Delete perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportPerjalananDinasAnggotasId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportPerjalananDinasAnggotasId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportPerjalananDinasAnggotasId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
