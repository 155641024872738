/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiKehadiran } from '../models/absensi-kehadiran';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiKehadiranService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiKehadiranList
   */
  static readonly GetAbsensiKehadiranListPath = '/absensi/kehadirans';

  /**
   * getkehadiranList.
   *
   * Get all kehadirans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiKehadiranList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiKehadiran>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranService.GetAbsensiKehadiranListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiKehadiran>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getkehadiranList.
   *
   * Get all kehadirans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiKehadiranList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiKehadiran>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiKehadiranList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiKehadiran>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiKehadiran>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiKehadiran
   */
  static readonly StoreAbsensiKehadiranPath = '/absensi/kehadirans';

  /**
   * createkehadiran.
   *
   * Create kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiKehadiran()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiKehadiran$Response(params: {
    body: AbsensiKehadiran
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranService.StoreAbsensiKehadiranPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createkehadiran.
   *
   * Create kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiKehadiran$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiKehadiran(params: {
    body: AbsensiKehadiran
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}> {

    return this.storeAbsensiKehadiran$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiKehadiranId
   */
  static readonly GetAbsensiKehadiranIdPath = '/absensi/kehadirans/{id}';

  /**
   * getkehadiranItem.
   *
   * Get kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiKehadiranId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranId$Response(params: {

    /**
     * id of kehadiran
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranService.GetAbsensiKehadiranIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getkehadiranItem.
   *
   * Get kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiKehadiranId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranId(params: {

    /**
     * id of kehadiran
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}> {

    return this.getAbsensiKehadiranId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiKehadiranId
   */
  static readonly PutAbsensiKehadiranIdPath = '/absensi/kehadirans/{id}';

  /**
   * updatekehadiran.
   *
   * Update kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiKehadiranId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiKehadiranId$Response(params: {

    /**
     * id of kehadiran
     */
    id: string;
    body: AbsensiKehadiran
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranService.PutAbsensiKehadiranIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatekehadiran.
   *
   * Update kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiKehadiranId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiKehadiranId(params: {

    /**
     * id of kehadiran
     */
    id: string;
    body: AbsensiKehadiran
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}> {

    return this.putAbsensiKehadiranId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiKehadiransId
   */
  static readonly DeleteAbsensiKehadiransIdPath = '/absensi/kehadirans/{id}';

  /**
   * deletekehadiran.
   *
   * Delete kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiKehadiransId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiKehadiransId$Response(params: {

    /**
     * id of kehadiran
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranService.DeleteAbsensiKehadiransIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletekehadiran.
   *
   * Delete kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiKehadiransId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiKehadiransId(params: {

    /**
     * id of kehadiran
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiKehadiransId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
