/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Users } from '../models/users';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly getPelangganUsersPath = '/pelanggan/users';
  static readonly postPelangganUsersPath = '/pelanggan/users';
  static readonly getPelangganUsersIdPath = '/pelanggan/users/{id}';
  static readonly putPelangganUsersIdPath = '/pelanggan/users/{id}';
  static readonly deletePelangganUsersIdPath = '/pelanggan/users/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Users.
   *
   * Get all Users
   * @param params The `UsersService.GetPelangganUsersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Users
   *
   * @return successful operation
   */
  getPelangganUsersResponse(params: UsersService.GetPelangganUsersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Users>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Users>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Users.
   *
   * Get all Users
   * @param params The `UsersService.GetPelangganUsersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Users
   *
   * @return successful operation
   */
  getPelangganUsers(params: UsersService.GetPelangganUsersParams): __Observable<{success?: boolean, data?: Array<Users>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganUsersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Users>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Users in storage
   *
   * Store Users
   * @param body Users that should be stored
   * @return successful operation
   */
  postPelangganUsersResponse(body?: Users): __Observable<__StrictHttpResponse<{success?: boolean, data?: Users, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Users, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Users in storage
   *
   * Store Users
   * @param body Users that should be stored
   * @return successful operation
   */
  postPelangganUsers(body?: Users): __Observable<{success?: boolean, data?: Users, message?: string}> {
    return this.postPelangganUsersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Users, message?: string})
    );
  }

  /**
   * Display the specified Users
   *
   * Get Users
   * @param id id of Users
   * @return successful operation
   */
  getPelangganUsersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Users, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/users/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Users, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Users
   *
   * Get Users
   * @param id id of Users
   * @return successful operation
   */
  getPelangganUsersId(id: number): __Observable<{success?: boolean, data?: Users, message?: string}> {
    return this.getPelangganUsersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Users, message?: string})
    );
  }

  /**
   * Update the specified Users in storage
   *
   * Update Users
   * @param params The `UsersService.PutPelangganUsersIdParams` containing the following parameters:
   *
   * - `id`: id of Users
   *
   * - `body`: Users that should be updated
   *
   * @return successful operation
   */
  putPelangganUsersIdResponse(params: UsersService.PutPelangganUsersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Users, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/users/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Users, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Users in storage
   *
   * Update Users
   * @param params The `UsersService.PutPelangganUsersIdParams` containing the following parameters:
   *
   * - `id`: id of Users
   *
   * - `body`: Users that should be updated
   *
   * @return successful operation
   */
  putPelangganUsersId(params: UsersService.PutPelangganUsersIdParams): __Observable<{success?: boolean, data?: Users, message?: string}> {
    return this.putPelangganUsersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Users, message?: string})
    );
  }

  /**
   * Remove the specified Users from storage
   *
   * Delete Users
   * @param id id of Users
   * @return successful operation
   */
  deletePelangganUsersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/users/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Users from storage
   *
   * Delete Users
   * @param id id of Users
   * @return successful operation
   */
  deletePelangganUsersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganUsersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module UsersService {

  /**
   * Parameters for getPelangganUsers
   */
  export interface GetPelangganUsersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Users
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganUsersId
   */
  export interface PutPelangganUsersIdParams {

    /**
     * id of Users
     */
    id: number;

    /**
     * Users that should be updated
     */
    body?: Users;
  }
}

export { UsersService }
