<div class="footer bg-white ">
    <div class="footerInner">
        <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer" class="logo">
            <img src="/assets/PDAM.png" style="width: 3%;margin-right:3px">
            <strong class="mr-2">Perumda Air Minum Tirta Alami </strong>
        </a>
        <br />
        <p class="mb-0">
            Copyright © {{ date }}
            <a href="http://devetek.com" target="_blank" rel="noopener noreferrer">
                devetek.com
            </a>
            |
            <a >
                Arifan28
            </a>
        </p>
    </div>
</div>
