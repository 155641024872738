/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPage } from '../models/client-page';

@Injectable({
  providedIn: 'root',
})
export class ClientPageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientPagesList
   */
  static readonly GetclientPagesListPath = '/client/pages';

  /**
   * Get list of pages.
   *
   * Returns list of pages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPagesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPagesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPageService.GetclientPagesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPage>;
        }>;
      })
    );
  }

  /**
   * Get list of pages.
   *
   * Returns list of pages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPagesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPagesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
}> {

    return this.getclientPagesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
})
    );
  }

  /**
   * Path part for operation getclientPageId
   */
  static readonly GetclientPageIdPath = '/client/pages/{id}';

  /**
   * Get  Page by id.
   *
   * Returns Page data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPageId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPageId$Response(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPageService.GetclientPageIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPage;
        }>;
      })
    );
  }

  /**
   * Get  Page by id.
   *
   * Returns Page data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPageId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPageId(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}> {

    return this.getclientPageId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
})
    );
  }

}
