/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportCutiPengaturan } from '../models/absensi-report-cuti-pengaturan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportCutiPengaturanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportCutiPengaturanList
   */
  static readonly GetAbsensiReportCutiPengaturanListPath = '/absensiReport/cuti_pengaturans';

  /**
   * getcuti_pengaturanList.
   *
   * Get all cuti_pengaturans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportCutiPengaturanList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengaturanList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengaturan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengaturanService.GetAbsensiReportCutiPengaturanListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportCutiPengaturan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getcuti_pengaturanList.
   *
   * Get all cuti_pengaturans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportCutiPengaturanList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengaturanList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengaturan>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportCutiPengaturanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengaturan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportCutiPengaturan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportCutiPengaturan
   */
  static readonly StoreAbsensiReportCutiPengaturanPath = '/absensiReport/cuti_pengaturans';

  /**
   * createcuti_pengaturan.
   *
   * Create cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportCutiPengaturan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportCutiPengaturan$Response(params: {
    body: AbsensiReportCutiPengaturan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengaturanService.StoreAbsensiReportCutiPengaturanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportCutiPengaturan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createcuti_pengaturan.
   *
   * Create cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportCutiPengaturan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportCutiPengaturan(params: {
    body: AbsensiReportCutiPengaturan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}> {

    return this.storeAbsensiReportCutiPengaturan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportCutiPengaturanId
   */
  static readonly GetAbsensiReportCutiPengaturanIdPath = '/absensiReport/cuti_pengaturans/{id}';

  /**
   * getcuti_pengaturanItem.
   *
   * Get cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportCutiPengaturanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengaturanId$Response(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengaturanService.GetAbsensiReportCutiPengaturanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportCutiPengaturan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getcuti_pengaturanItem.
   *
   * Get cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportCutiPengaturanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportCutiPengaturanId(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}> {

    return this.getAbsensiReportCutiPengaturanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportCutiPengaturanId
   */
  static readonly PutAbsensiReportCutiPengaturanIdPath = '/absensiReport/cuti_pengaturans/{id}';

  /**
   * updatecuti_pengaturan.
   *
   * Update cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportCutiPengaturanId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportCutiPengaturanId$Response(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
    body: AbsensiReportCutiPengaturan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengaturanService.PutAbsensiReportCutiPengaturanIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportCutiPengaturan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatecuti_pengaturan.
   *
   * Update cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportCutiPengaturanId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportCutiPengaturanId(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
    body: AbsensiReportCutiPengaturan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}> {

    return this.putAbsensiReportCutiPengaturanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportCutiPengaturan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportCutiPengaturansId
   */
  static readonly DeleteAbsensiReportCutiPengaturansIdPath = '/absensiReport/cuti_pengaturans/{id}';

  /**
   * deletecuti_pengaturan.
   *
   * Delete cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportCutiPengaturansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportCutiPengaturansId$Response(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportCutiPengaturanService.DeleteAbsensiReportCutiPengaturansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletecuti_pengaturan.
   *
   * Delete cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportCutiPengaturansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportCutiPengaturansId(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportCutiPengaturansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
