import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor() { }

    togleSearch = new BehaviorSubject<boolean>(false)
}
