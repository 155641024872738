/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiJadwalTipeNormalDetail } from '../models/absensi-jadwal-tipe-normal-detail';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiJadwalTipeNormalDetailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiJadwalTipeNormalDetailList
   */
  static readonly GetAbsensiJadwalTipeNormalDetailListPath = '/absensi/jadwal_tipe_normal_details';

  /**
   * getjadwal_tipe_normal_detailList.
   *
   * Get all jadwal_tipe_normal_details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiJadwalTipeNormalDetailList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalTipeNormalDetailList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalTipeNormalDetailService.GetAbsensiJadwalTipeNormalDetailListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiJadwalTipeNormalDetail>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getjadwal_tipe_normal_detailList.
   *
   * Get all jadwal_tipe_normal_details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiJadwalTipeNormalDetailList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalTipeNormalDetailList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiJadwalTipeNormalDetailList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiJadwalTipeNormalDetail>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiJadwalTipeNormalDetail
   */
  static readonly StoreAbsensiJadwalTipeNormalDetailPath = '/absensi/jadwal_tipe_normal_details';

  /**
   * createjadwal_tipe_normal_detail.
   *
   * Create jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiJadwalTipeNormalDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiJadwalTipeNormalDetail$Response(params: {
    body: AbsensiJadwalTipeNormalDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalTipeNormalDetailService.StoreAbsensiJadwalTipeNormalDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiJadwalTipeNormalDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createjadwal_tipe_normal_detail.
   *
   * Create jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiJadwalTipeNormalDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiJadwalTipeNormalDetail(params: {
    body: AbsensiJadwalTipeNormalDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}> {

    return this.storeAbsensiJadwalTipeNormalDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiJadwalTipeNormalDetailId
   */
  static readonly GetAbsensiJadwalTipeNormalDetailIdPath = '/absensi/jadwal_tipe_normal_details/{id}';

  /**
   * getjadwal_tipe_normal_detailItem.
   *
   * Get jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiJadwalTipeNormalDetailId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalTipeNormalDetailId$Response(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalTipeNormalDetailService.GetAbsensiJadwalTipeNormalDetailIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiJadwalTipeNormalDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getjadwal_tipe_normal_detailItem.
   *
   * Get jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiJadwalTipeNormalDetailId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiJadwalTipeNormalDetailId(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}> {

    return this.getAbsensiJadwalTipeNormalDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiJadwalTipeNormalDetailId
   */
  static readonly PutAbsensiJadwalTipeNormalDetailIdPath = '/absensi/jadwal_tipe_normal_details/{id}';

  /**
   * updatejadwal_tipe_normal_detail.
   *
   * Update jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiJadwalTipeNormalDetailId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiJadwalTipeNormalDetailId$Response(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
    body: AbsensiJadwalTipeNormalDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalTipeNormalDetailService.PutAbsensiJadwalTipeNormalDetailIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiJadwalTipeNormalDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatejadwal_tipe_normal_detail.
   *
   * Update jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiJadwalTipeNormalDetailId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiJadwalTipeNormalDetailId(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
    body: AbsensiJadwalTipeNormalDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}> {

    return this.putAbsensiJadwalTipeNormalDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiJadwalTipeNormalDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiJadwalTipeNormalDetailsId
   */
  static readonly DeleteAbsensiJadwalTipeNormalDetailsIdPath = '/absensi/jadwal_tipe_normal_details/{id}';

  /**
   * deletejadwal_tipe_normal_detail.
   *
   * Delete jadwal_tipe_normal_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiJadwalTipeNormalDetailsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiJadwalTipeNormalDetailsId$Response(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiJadwalTipeNormalDetailService.DeleteAbsensiJadwalTipeNormalDetailsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletejadwal_tipe_normal_detail.
   *
   * Delete jadwal_tipe_normal_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiJadwalTipeNormalDetailsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiJadwalTipeNormalDetailsId(params: {

    /**
     * id of jadwal_tipe_normal_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiJadwalTipeNormalDetailsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
