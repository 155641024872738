/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Status_meter } from '../models/status-_meter';
@Injectable({
  providedIn: 'root',
})
class StatusMeterService extends __BaseService {
  static readonly getPelangganStatusMetersPath = '/pelanggan/statusMeters';
  static readonly postPelangganStatusMetersPath = '/pelanggan/statusMeters';
  static readonly getPelangganStatusMetersIdPath = '/pelanggan/statusMeters/{id}';
  static readonly putPelangganStatusMetersIdPath = '/pelanggan/statusMeters/{id}';
  static readonly deletePelangganStatusMetersIdPath = '/pelanggan/statusMeters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Status_meter.
   *
   * Get all Status_meter
   * @param params The `StatusMeterService.GetPelangganStatusMetersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Status_meter
   *
   * @return successful operation
   */
  getPelangganStatusMetersResponse(params: StatusMeterService.GetPelangganStatusMetersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Status_meter>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/statusMeters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Status_meter>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Status_meter.
   *
   * Get all Status_meter
   * @param params The `StatusMeterService.GetPelangganStatusMetersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Status_meter
   *
   * @return successful operation
   */
  getPelangganStatusMeters(params: StatusMeterService.GetPelangganStatusMetersParams): __Observable<{success?: boolean, data?: Array<Status_meter>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganStatusMetersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Status_meter>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Status_meter in storage
   *
   * Store Status_meter
   * @param body Status_meter that should be stored
   * @return successful operation
   */
  postPelangganStatusMetersResponse(body?: Status_meter): __Observable<__StrictHttpResponse<{success?: boolean, data?: Status_meter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/statusMeters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Status_meter, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Status_meter in storage
   *
   * Store Status_meter
   * @param body Status_meter that should be stored
   * @return successful operation
   */
  postPelangganStatusMeters(body?: Status_meter): __Observable<{success?: boolean, data?: Status_meter, message?: string}> {
    return this.postPelangganStatusMetersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Status_meter, message?: string})
    );
  }

  /**
   * Display the specified Status_meter
   *
   * Get Status_meter
   * @param id id of Status_meter
   * @return successful operation
   */
  getPelangganStatusMetersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Status_meter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/statusMeters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Status_meter, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Status_meter
   *
   * Get Status_meter
   * @param id id of Status_meter
   * @return successful operation
   */
  getPelangganStatusMetersId(id: number): __Observable<{success?: boolean, data?: Status_meter, message?: string}> {
    return this.getPelangganStatusMetersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Status_meter, message?: string})
    );
  }

  /**
   * Update the specified Status_meter in storage
   *
   * Update Status_meter
   * @param params The `StatusMeterService.PutPelangganStatusMetersIdParams` containing the following parameters:
   *
   * - `id`: id of Status_meter
   *
   * - `body`: Status_meter that should be updated
   *
   * @return successful operation
   */
  putPelangganStatusMetersIdResponse(params: StatusMeterService.PutPelangganStatusMetersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Status_meter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/statusMeters/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Status_meter, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Status_meter in storage
   *
   * Update Status_meter
   * @param params The `StatusMeterService.PutPelangganStatusMetersIdParams` containing the following parameters:
   *
   * - `id`: id of Status_meter
   *
   * - `body`: Status_meter that should be updated
   *
   * @return successful operation
   */
  putPelangganStatusMetersId(params: StatusMeterService.PutPelangganStatusMetersIdParams): __Observable<{success?: boolean, data?: Status_meter, message?: string}> {
    return this.putPelangganStatusMetersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Status_meter, message?: string})
    );
  }

  /**
   * Remove the specified Status_meter from storage
   *
   * Delete Status_meter
   * @param id id of Status_meter
   * @return successful operation
   */
  deletePelangganStatusMetersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/statusMeters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Status_meter from storage
   *
   * Delete Status_meter
   * @param id id of Status_meter
   * @return successful operation
   */
  deletePelangganStatusMetersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganStatusMetersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module StatusMeterService {

  /**
   * Parameters for getPelangganStatusMeters
   */
  export interface GetPelangganStatusMetersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Status_meter
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganStatusMetersId
   */
  export interface PutPelangganStatusMetersIdParams {

    /**
     * id of Status_meter
     */
    id: number;

    /**
     * Status_meter that should be updated
     */
    body?: Status_meter;
  }
}

export { StatusMeterService }
