/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Rekap_janji_bayar_by_cater } from '../models/rekap-_janji-_bayar-_by-_cater';
@Injectable({
  providedIn: 'root',
})
class RekapJanjiBayarByCaterService extends __BaseService {
  static readonly getPenagihanReportRekapJanjiBayarByCatersPath = '/penagihanReport/rekapJanjiBayarByCaters';
  static readonly postPenagihanReportRekapJanjiBayarByCatersPath = '/penagihanReport/rekapJanjiBayarByCaters';
  static readonly getPenagihanReportRekapJanjiBayarByCatersIdPath = '/penagihanReport/rekapJanjiBayarByCaters/{id}';
  static readonly putPenagihanReportRekapJanjiBayarByCatersIdPath = '/penagihanReport/rekapJanjiBayarByCaters/{id}';
  static readonly deletePenagihanReportRekapJanjiBayarByCatersIdPath = '/penagihanReport/rekapJanjiBayarByCaters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Rekap_janji_bayar_by_cater.
   *
   * Get all Rekap_janji_bayar_by_cater
   * @param params The `RekapJanjiBayarByCaterService.GetPenagihanReportRekapJanjiBayarByCatersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_janji_bayar_by_cater
   *
   * @return successful operation
   */
  getPenagihanReportRekapJanjiBayarByCatersResponse(params: RekapJanjiBayarByCaterService.GetPenagihanReportRekapJanjiBayarByCatersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Rekap_janji_bayar_by_cater>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapJanjiBayarByCaters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Rekap_janji_bayar_by_cater>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Rekap_janji_bayar_by_cater.
   *
   * Get all Rekap_janji_bayar_by_cater
   * @param params The `RekapJanjiBayarByCaterService.GetPenagihanReportRekapJanjiBayarByCatersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_janji_bayar_by_cater
   *
   * @return successful operation
   */
  getPenagihanReportRekapJanjiBayarByCaters(params: RekapJanjiBayarByCaterService.GetPenagihanReportRekapJanjiBayarByCatersParams): __Observable<{success?: boolean, data?: Array<Rekap_janji_bayar_by_cater>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportRekapJanjiBayarByCatersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Rekap_janji_bayar_by_cater>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Rekap_janji_bayar_by_cater in storage
   *
   * Store Rekap_janji_bayar_by_cater
   * @param body Rekap_janji_bayar_by_cater that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapJanjiBayarByCatersResponse(body?: Rekap_janji_bayar_by_cater): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/rekapJanjiBayarByCaters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Rekap_janji_bayar_by_cater in storage
   *
   * Store Rekap_janji_bayar_by_cater
   * @param body Rekap_janji_bayar_by_cater that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapJanjiBayarByCaters(body?: Rekap_janji_bayar_by_cater): __Observable<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}> {
    return this.postPenagihanReportRekapJanjiBayarByCatersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string})
    );
  }

  /**
   * Display the specified Rekap_janji_bayar_by_cater
   *
   * Get Rekap_janji_bayar_by_cater
   * @param id id of Rekap_janji_bayar_by_cater
   * @return successful operation
   */
  getPenagihanReportRekapJanjiBayarByCatersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapJanjiBayarByCaters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Rekap_janji_bayar_by_cater
   *
   * Get Rekap_janji_bayar_by_cater
   * @param id id of Rekap_janji_bayar_by_cater
   * @return successful operation
   */
  getPenagihanReportRekapJanjiBayarByCatersId(id: number): __Observable<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}> {
    return this.getPenagihanReportRekapJanjiBayarByCatersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string})
    );
  }

  /**
   * Update the specified Rekap_janji_bayar_by_cater in storage
   *
   * Update Rekap_janji_bayar_by_cater
   * @param params The `RekapJanjiBayarByCaterService.PutPenagihanReportRekapJanjiBayarByCatersIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_janji_bayar_by_cater
   *
   * - `body`: Rekap_janji_bayar_by_cater that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapJanjiBayarByCatersIdResponse(params: RekapJanjiBayarByCaterService.PutPenagihanReportRekapJanjiBayarByCatersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/rekapJanjiBayarByCaters/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Rekap_janji_bayar_by_cater in storage
   *
   * Update Rekap_janji_bayar_by_cater
   * @param params The `RekapJanjiBayarByCaterService.PutPenagihanReportRekapJanjiBayarByCatersIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_janji_bayar_by_cater
   *
   * - `body`: Rekap_janji_bayar_by_cater that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapJanjiBayarByCatersId(params: RekapJanjiBayarByCaterService.PutPenagihanReportRekapJanjiBayarByCatersIdParams): __Observable<{success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string}> {
    return this.putPenagihanReportRekapJanjiBayarByCatersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_janji_bayar_by_cater, message?: string})
    );
  }

  /**
   * Remove the specified Rekap_janji_bayar_by_cater from storage
   *
   * Delete Rekap_janji_bayar_by_cater
   * @param id id of Rekap_janji_bayar_by_cater
   * @return successful operation
   */
  deletePenagihanReportRekapJanjiBayarByCatersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/rekapJanjiBayarByCaters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Rekap_janji_bayar_by_cater from storage
   *
   * Delete Rekap_janji_bayar_by_cater
   * @param id id of Rekap_janji_bayar_by_cater
   * @return successful operation
   */
  deletePenagihanReportRekapJanjiBayarByCatersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportRekapJanjiBayarByCatersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module RekapJanjiBayarByCaterService {

  /**
   * Parameters for getPenagihanReportRekapJanjiBayarByCaters
   */
  export interface GetPenagihanReportRekapJanjiBayarByCatersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Rekap_janji_bayar_by_cater
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportRekapJanjiBayarByCatersId
   */
  export interface PutPenagihanReportRekapJanjiBayarByCatersIdParams {

    /**
     * id of Rekap_janji_bayar_by_cater
     */
    id: number;

    /**
     * Rekap_janji_bayar_by_cater that should be updated
     */
    body?: Rekap_janji_bayar_by_cater;
  }
}

export { RekapJanjiBayarByCaterService }
