/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Billing_pengecualian } from '../models/billing-_pengecualian';
@Injectable({
  providedIn: 'root',
})
class BillingPengecualianService extends __BaseService {
  static readonly getPelangganBillingPengecualiansPath = '/pelanggan/billingPengecualians';
  static readonly postPelangganBillingPengecualiansPath = '/pelanggan/billingPengecualians';
  static readonly getPelangganBillingPengecualiansIdPath = '/pelanggan/billingPengecualians/{id}';
  static readonly putPelangganBillingPengecualiansIdPath = '/pelanggan/billingPengecualians/{id}';
  static readonly deletePelangganBillingPengecualiansIdPath = '/pelanggan/billingPengecualians/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Billing_pengecualian.
   *
   * Get all Billing_pengecualian
   * @param params The `BillingPengecualianService.GetPelangganBillingPengecualiansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Billing_pengecualian
   *
   * @return successful operation
   */
  getPelangganBillingPengecualiansResponse(params: BillingPengecualianService.GetPelangganBillingPengecualiansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Billing_pengecualian>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/billingPengecualians`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Billing_pengecualian>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Billing_pengecualian.
   *
   * Get all Billing_pengecualian
   * @param params The `BillingPengecualianService.GetPelangganBillingPengecualiansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Billing_pengecualian
   *
   * @return successful operation
   */
  getPelangganBillingPengecualians(params: BillingPengecualianService.GetPelangganBillingPengecualiansParams): __Observable<{success?: boolean, data?: Array<Billing_pengecualian>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganBillingPengecualiansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Billing_pengecualian>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Billing_pengecualian in storage
   *
   * Store Billing_pengecualian
   * @param body Billing_pengecualian that should be stored
   * @return successful operation
   */
  postPelangganBillingPengecualiansResponse(body?: Billing_pengecualian): __Observable<__StrictHttpResponse<{success?: boolean, data?: Billing_pengecualian, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/billingPengecualians`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Billing_pengecualian, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Billing_pengecualian in storage
   *
   * Store Billing_pengecualian
   * @param body Billing_pengecualian that should be stored
   * @return successful operation
   */
  postPelangganBillingPengecualians(body?: Billing_pengecualian): __Observable<{success?: boolean, data?: Billing_pengecualian, message?: string}> {
    return this.postPelangganBillingPengecualiansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Billing_pengecualian, message?: string})
    );
  }

  /**
   * Display the specified Billing_pengecualian
   *
   * Get Billing_pengecualian
   * @param id id of Billing_pengecualian
   * @return successful operation
   */
  getPelangganBillingPengecualiansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Billing_pengecualian, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/billingPengecualians/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Billing_pengecualian, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Billing_pengecualian
   *
   * Get Billing_pengecualian
   * @param id id of Billing_pengecualian
   * @return successful operation
   */
  getPelangganBillingPengecualiansId(id: number): __Observable<{success?: boolean, data?: Billing_pengecualian, message?: string}> {
    return this.getPelangganBillingPengecualiansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Billing_pengecualian, message?: string})
    );
  }

  /**
   * Update the specified Billing_pengecualian in storage
   *
   * Update Billing_pengecualian
   * @param params The `BillingPengecualianService.PutPelangganBillingPengecualiansIdParams` containing the following parameters:
   *
   * - `id`: id of Billing_pengecualian
   *
   * - `body`: Billing_pengecualian that should be updated
   *
   * @return successful operation
   */
  putPelangganBillingPengecualiansIdResponse(params: BillingPengecualianService.PutPelangganBillingPengecualiansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Billing_pengecualian, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/billingPengecualians/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Billing_pengecualian, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Billing_pengecualian in storage
   *
   * Update Billing_pengecualian
   * @param params The `BillingPengecualianService.PutPelangganBillingPengecualiansIdParams` containing the following parameters:
   *
   * - `id`: id of Billing_pengecualian
   *
   * - `body`: Billing_pengecualian that should be updated
   *
   * @return successful operation
   */
  putPelangganBillingPengecualiansId(params: BillingPengecualianService.PutPelangganBillingPengecualiansIdParams): __Observable<{success?: boolean, data?: Billing_pengecualian, message?: string}> {
    return this.putPelangganBillingPengecualiansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Billing_pengecualian, message?: string})
    );
  }

  /**
   * Remove the specified Billing_pengecualian from storage
   *
   * Delete Billing_pengecualian
   * @param id id of Billing_pengecualian
   * @return successful operation
   */
  deletePelangganBillingPengecualiansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/billingPengecualians/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Billing_pengecualian from storage
   *
   * Delete Billing_pengecualian
   * @param id id of Billing_pengecualian
   * @return successful operation
   */
  deletePelangganBillingPengecualiansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganBillingPengecualiansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module BillingPengecualianService {

  /**
   * Parameters for getPelangganBillingPengecualians
   */
  export interface GetPelangganBillingPengecualiansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Billing_pengecualian
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganBillingPengecualiansId
   */
  export interface PutPelangganBillingPengecualiansIdParams {

    /**
     * id of Billing_pengecualian
     */
    id: number;

    /**
     * Billing_pengecualian that should be updated
     */
    body?: Billing_pengecualian;
  }
}

export { BillingPengecualianService }
