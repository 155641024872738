/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Rekap_setoran } from '../models/rekap-_setoran';
@Injectable({
  providedIn: 'root',
})
class RekapSetoranService extends __BaseService {
  static readonly getPenagihanReportRekapSetoransPath = '/penagihanReport/rekapSetorans';
  static readonly postPenagihanReportRekapSetoransPath = '/penagihanReport/rekapSetorans';
  static readonly getPenagihanReportRekapSetoransIdPath = '/penagihanReport/rekapSetorans/{id}';
  static readonly putPenagihanReportRekapSetoransIdPath = '/penagihanReport/rekapSetorans/{id}';
  static readonly deletePenagihanReportRekapSetoransIdPath = '/penagihanReport/rekapSetorans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Rekap_setoran.
   *
   * Get all Rekap_setoran
   * @param params The `RekapSetoranService.GetPenagihanReportRekapSetoransParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_setoran
   *
   * @return successful operation
   */
  getPenagihanReportRekapSetoransResponse(params: RekapSetoranService.GetPenagihanReportRekapSetoransParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Rekap_setoran>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapSetorans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Rekap_setoran>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Rekap_setoran.
   *
   * Get all Rekap_setoran
   * @param params The `RekapSetoranService.GetPenagihanReportRekapSetoransParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_setoran
   *
   * @return successful operation
   */
  getPenagihanReportRekapSetorans(params: RekapSetoranService.GetPenagihanReportRekapSetoransParams): __Observable<{success?: boolean, data?: Array<Rekap_setoran>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportRekapSetoransResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Rekap_setoran>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Rekap_setoran in storage
   *
   * Store Rekap_setoran
   * @param body Rekap_setoran that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapSetoransResponse(body?: Rekap_setoran): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_setoran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/rekapSetorans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_setoran, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Rekap_setoran in storage
   *
   * Store Rekap_setoran
   * @param body Rekap_setoran that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapSetorans(body?: Rekap_setoran): __Observable<{success?: boolean, data?: Rekap_setoran, message?: string}> {
    return this.postPenagihanReportRekapSetoransResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_setoran, message?: string})
    );
  }

  /**
   * Display the specified Rekap_setoran
   *
   * Get Rekap_setoran
   * @param id id of Rekap_setoran
   * @return successful operation
   */
  getPenagihanReportRekapSetoransIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_setoran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapSetorans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_setoran, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Rekap_setoran
   *
   * Get Rekap_setoran
   * @param id id of Rekap_setoran
   * @return successful operation
   */
  getPenagihanReportRekapSetoransId(id: number): __Observable<{success?: boolean, data?: Rekap_setoran, message?: string}> {
    return this.getPenagihanReportRekapSetoransIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_setoran, message?: string})
    );
  }

  /**
   * Update the specified Rekap_setoran in storage
   *
   * Update Rekap_setoran
   * @param params The `RekapSetoranService.PutPenagihanReportRekapSetoransIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_setoran
   *
   * - `body`: Rekap_setoran that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapSetoransIdResponse(params: RekapSetoranService.PutPenagihanReportRekapSetoransIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_setoran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/rekapSetorans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_setoran, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Rekap_setoran in storage
   *
   * Update Rekap_setoran
   * @param params The `RekapSetoranService.PutPenagihanReportRekapSetoransIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_setoran
   *
   * - `body`: Rekap_setoran that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapSetoransId(params: RekapSetoranService.PutPenagihanReportRekapSetoransIdParams): __Observable<{success?: boolean, data?: Rekap_setoran, message?: string}> {
    return this.putPenagihanReportRekapSetoransIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_setoran, message?: string})
    );
  }

  /**
   * Remove the specified Rekap_setoran from storage
   *
   * Delete Rekap_setoran
   * @param id id of Rekap_setoran
   * @return successful operation
   */
  deletePenagihanReportRekapSetoransIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/rekapSetorans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Rekap_setoran from storage
   *
   * Delete Rekap_setoran
   * @param id id of Rekap_setoran
   * @return successful operation
   */
  deletePenagihanReportRekapSetoransId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportRekapSetoransIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module RekapSetoranService {

  /**
   * Parameters for getPenagihanReportRekapSetorans
   */
  export interface GetPenagihanReportRekapSetoransParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Rekap_setoran
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportRekapSetoransId
   */
  export interface PutPenagihanReportRekapSetoransIdParams {

    /**
     * id of Rekap_setoran
     */
    id: number;

    /**
     * Rekap_setoran that should be updated
     */
    body?: Rekap_setoran;
  }
}

export { RekapSetoranService }
