/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPengaduanByType } from '../models/client-pengaduan-by-type';

@Injectable({
  providedIn: 'root',
})
export class ClientPengaduanByTypeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientPengaduanByTypesList
   */
  static readonly GetclientPengaduanByTypesListPath = '/client/pengaduanByTypes';

  /**
   * Get list of pengaduanByTypes.
   *
   * Returns list of pengaduanByTypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengaduanByTypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByTypesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByType>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByTypeService.GetclientPengaduanByTypesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPengaduanByType>;
        }>;
      })
    );
  }

  /**
   * Get list of pengaduanByTypes.
   *
   * Returns list of pengaduanByTypes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengaduanByTypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByTypesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByType>;
}> {

    return this.getclientPengaduanByTypesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByType>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduanByType>;
})
    );
  }

  /**
   * Path part for operation storeclientPengaduanByType
   */
  static readonly StoreclientPengaduanByTypePath = '/client/pengaduanByTypes';

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientPengaduanByType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengaduanByType$Response(params: {
    body: ClientPengaduanByType
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByTypeService.StoreclientPengaduanByTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduanByType;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientPengaduanByType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengaduanByType(params: {
    body: ClientPengaduanByType
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}> {

    return this.storeclientPengaduanByType$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
})
    );
  }

  /**
   * Path part for operation getclientPengaduanByTypeId
   */
  static readonly GetclientPengaduanByTypeIdPath = '/client/pengaduanByTypes/{id}';

  /**
   * Get  Pengaduan_by_type by id.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengaduanByTypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByTypeId$Response(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByTypeService.GetclientPengaduanByTypeIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduanByType;
        }>;
      })
    );
  }

  /**
   * Get  Pengaduan_by_type by id.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengaduanByTypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanByTypeId(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}> {

    return this.getclientPengaduanByTypeId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
})
    );
  }

  /**
   * Path part for operation putclientPengaduanByType
   */
  static readonly PutclientPengaduanByTypePath = '/client/pengaduanByTypes/{id}';

  /**
   * Update Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientPengaduanByType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPengaduanByType$Response(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
    body: ClientPengaduanByType
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByTypeService.PutclientPengaduanByTypePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduanByType;
        }>;
      })
    );
  }

  /**
   * Update Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientPengaduanByType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPengaduanByType(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
    body: ClientPengaduanByType
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}> {

    return this.putclientPengaduanByType$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduanByType;
})
    );
  }

  /**
   * Path part for operation deleteclientPengaduanByType
   */
  static readonly DeleteclientPengaduanByTypePath = '/client/pengaduanByTypes/{id}';

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientPengaduanByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPengaduanByType$Response(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanByTypeService.DeleteclientPengaduanByTypePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientPengaduanByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPengaduanByType(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientPengaduanByType$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
