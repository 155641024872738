/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWidgetPegawaiSk } from '../models/hr-report-widget-pegawai-sk';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWidgetPegawaiSkService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiSkList
   */
  static readonly GetHrReportWidgetPegawaiSkListPath = '/hrReportWidget/pegawai_sks';

  /**
   * getpegawai_skList.
   *
   * Get all pegawai_sks
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiSkList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSkList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSk>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSkService.GetHrReportWidgetPegawaiSkListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWidgetPegawaiSk>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_skList.
   *
   * Get all pegawai_sks
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiSkList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSkList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSk>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWidgetPegawaiSkList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSk>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSk>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWidgetPegawaiSk
   */
  static readonly StoreHrReportWidgetPegawaiSkPath = '/hrReportWidget/pegawai_sks';

  /**
   * createpegawai_sk.
   *
   * Create pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWidgetPegawaiSk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiSk$Response(params: {
    body: HrReportWidgetPegawaiSk
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSkService.StoreHrReportWidgetPegawaiSkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSk;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_sk.
   *
   * Create pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWidgetPegawaiSk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiSk(params: {
    body: HrReportWidgetPegawaiSk
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}> {

    return this.storeHrReportWidgetPegawaiSk$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiSkId
   */
  static readonly GetHrReportWidgetPegawaiSkIdPath = '/hrReportWidget/pegawai_sks/{id}';

  /**
   * getpegawai_skItem.
   *
   * Get pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiSkId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSkId$Response(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSkService.GetHrReportWidgetPegawaiSkIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSk;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_skItem.
   *
   * Get pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiSkId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSkId(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}> {

    return this.getHrReportWidgetPegawaiSkId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWidgetPegawaiSkId
   */
  static readonly PutHrReportWidgetPegawaiSkIdPath = '/hrReportWidget/pegawai_sks/{id}';

  /**
   * updatepegawai_sk.
   *
   * Update pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWidgetPegawaiSkId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiSkId$Response(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
    body: HrReportWidgetPegawaiSk
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSkService.PutHrReportWidgetPegawaiSkIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSk;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_sk.
   *
   * Update pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWidgetPegawaiSkId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiSkId(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
    body: HrReportWidgetPegawaiSk
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}> {

    return this.putHrReportWidgetPegawaiSkId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSk;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWidgetPegawaiSksId
   */
  static readonly DeleteHrReportWidgetPegawaiSksIdPath = '/hrReportWidget/pegawai_sks/{id}';

  /**
   * deletepegawai_sk.
   *
   * Delete pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWidgetPegawaiSksId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiSksId$Response(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSkService.DeleteHrReportWidgetPegawaiSksIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_sk.
   *
   * Delete pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWidgetPegawaiSksId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiSksId(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWidgetPegawaiSksId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
