/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Biaya_pasang_meteran } from '../models/biaya-_pasang-_meteran';
@Injectable({
  providedIn: 'root',
})
class BiayaPasangMeteranService extends __BaseService {
  static readonly getPelangganBiayaPasangMeteransPath = '/pelanggan/biayaPasangMeterans';
  static readonly postPelangganBiayaPasangMeteransPath = '/pelanggan/biayaPasangMeterans';
  static readonly getPelangganBiayaPasangMeteransIdPath = '/pelanggan/biayaPasangMeterans/{id}';
  static readonly putPelangganBiayaPasangMeteransIdPath = '/pelanggan/biayaPasangMeterans/{id}';
  static readonly deletePelangganBiayaPasangMeteransIdPath = '/pelanggan/biayaPasangMeterans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Biaya_pasang_meteran.
   *
   * Get all Biaya_pasang_meteran
   * @param params The `BiayaPasangMeteranService.GetPelangganBiayaPasangMeteransParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Biaya_pasang_meteran
   *
   * @return successful operation
   */
  getPelangganBiayaPasangMeteransResponse(params: BiayaPasangMeteranService.GetPelangganBiayaPasangMeteransParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Biaya_pasang_meteran>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/biayaPasangMeterans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Biaya_pasang_meteran>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Biaya_pasang_meteran.
   *
   * Get all Biaya_pasang_meteran
   * @param params The `BiayaPasangMeteranService.GetPelangganBiayaPasangMeteransParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Biaya_pasang_meteran
   *
   * @return successful operation
   */
  getPelangganBiayaPasangMeterans(params: BiayaPasangMeteranService.GetPelangganBiayaPasangMeteransParams): __Observable<{success?: boolean, data?: Array<Biaya_pasang_meteran>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganBiayaPasangMeteransResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Biaya_pasang_meteran>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Biaya_pasang_meteran in storage
   *
   * Store Biaya_pasang_meteran
   * @param body Biaya_pasang_meteran that should be stored
   * @return successful operation
   */
  postPelangganBiayaPasangMeteransResponse(body?: Biaya_pasang_meteran): __Observable<__StrictHttpResponse<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/biayaPasangMeterans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Biaya_pasang_meteran in storage
   *
   * Store Biaya_pasang_meteran
   * @param body Biaya_pasang_meteran that should be stored
   * @return successful operation
   */
  postPelangganBiayaPasangMeterans(body?: Biaya_pasang_meteran): __Observable<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}> {
    return this.postPelangganBiayaPasangMeteransResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Biaya_pasang_meteran, message?: string})
    );
  }

  /**
   * Display the specified Biaya_pasang_meteran
   *
   * Get Biaya_pasang_meteran
   * @param id id of Biaya_pasang_meteran
   * @return successful operation
   */
  getPelangganBiayaPasangMeteransIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/biayaPasangMeterans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Biaya_pasang_meteran
   *
   * Get Biaya_pasang_meteran
   * @param id id of Biaya_pasang_meteran
   * @return successful operation
   */
  getPelangganBiayaPasangMeteransId(id: number): __Observable<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}> {
    return this.getPelangganBiayaPasangMeteransIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Biaya_pasang_meteran, message?: string})
    );
  }

  /**
   * Update the specified Biaya_pasang_meteran in storage
   *
   * Update Biaya_pasang_meteran
   * @param params The `BiayaPasangMeteranService.PutPelangganBiayaPasangMeteransIdParams` containing the following parameters:
   *
   * - `id`: id of Biaya_pasang_meteran
   *
   * - `body`: Biaya_pasang_meteran that should be updated
   *
   * @return successful operation
   */
  putPelangganBiayaPasangMeteransIdResponse(params: BiayaPasangMeteranService.PutPelangganBiayaPasangMeteransIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/biayaPasangMeterans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Biaya_pasang_meteran in storage
   *
   * Update Biaya_pasang_meteran
   * @param params The `BiayaPasangMeteranService.PutPelangganBiayaPasangMeteransIdParams` containing the following parameters:
   *
   * - `id`: id of Biaya_pasang_meteran
   *
   * - `body`: Biaya_pasang_meteran that should be updated
   *
   * @return successful operation
   */
  putPelangganBiayaPasangMeteransId(params: BiayaPasangMeteranService.PutPelangganBiayaPasangMeteransIdParams): __Observable<{success?: boolean, data?: Biaya_pasang_meteran, message?: string}> {
    return this.putPelangganBiayaPasangMeteransIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Biaya_pasang_meteran, message?: string})
    );
  }

  /**
   * Remove the specified Biaya_pasang_meteran from storage
   *
   * Delete Biaya_pasang_meteran
   * @param id id of Biaya_pasang_meteran
   * @return successful operation
   */
  deletePelangganBiayaPasangMeteransIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/biayaPasangMeterans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Biaya_pasang_meteran from storage
   *
   * Delete Biaya_pasang_meteran
   * @param id id of Biaya_pasang_meteran
   * @return successful operation
   */
  deletePelangganBiayaPasangMeteransId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganBiayaPasangMeteransIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module BiayaPasangMeteranService {

  /**
   * Parameters for getPelangganBiayaPasangMeterans
   */
  export interface GetPelangganBiayaPasangMeteransParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Biaya_pasang_meteran
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganBiayaPasangMeteransId
   */
  export interface PutPelangganBiayaPasangMeteransIdParams {

    /**
     * id of Biaya_pasang_meteran
     */
    id: number;

    /**
     * Biaya_pasang_meteran that should be updated
     */
    body?: Biaya_pasang_meteran;
  }
}

export { BiayaPasangMeteranService }
