/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Journal_report_item } from '../models/journal-_report-_item';
@Injectable({
  providedIn: 'root',
})
class JournalReportItemService extends __BaseService {
  static readonly getReportJournalReportItemsPath = '/report/journalReportItems';
  static readonly postReportJournalReportItemsPath = '/report/journalReportItems';
  static readonly getReportJournalReportItemsIdPath = '/report/journalReportItems/{id}';
  static readonly putReportJournalReportItemsIdPath = '/report/journalReportItems/{id}';
  static readonly deleteReportJournalReportItemsIdPath = '/report/journalReportItems/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the journal_report_item.
   *
   * Get all journal_report_item
   * @param params The `JournalReportItemService.GetReportJournalReportItemsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter journal_report_item
   *
   * @return successful operation
   */
  getReportJournalReportItemsResponse(params: JournalReportItemService.GetReportJournalReportItemsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Journal_report_item>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/journalReportItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Journal_report_item>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the journal_report_item.
   *
   * Get all journal_report_item
   * @param params The `JournalReportItemService.GetReportJournalReportItemsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter journal_report_item
   *
   * @return successful operation
   */
  getReportJournalReportItems(params: JournalReportItemService.GetReportJournalReportItemsParams): __Observable<{success?: boolean, data?: Array<Journal_report_item>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportJournalReportItemsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Journal_report_item>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created journal_report_item in storage
   *
   * Store journal_report_item
   * @param body journal_report_item that should be stored
   * @return successful operation
   */
  postReportJournalReportItemsResponse(body?: Journal_report_item): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_report_item, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report/journalReportItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_report_item, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created journal_report_item in storage
   *
   * Store journal_report_item
   * @param body journal_report_item that should be stored
   * @return successful operation
   */
  postReportJournalReportItems(body?: Journal_report_item): __Observable<{success?: boolean, data?: Journal_report_item, message?: string}> {
    return this.postReportJournalReportItemsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_report_item, message?: string})
    );
  }

  /**
   * Display the specified journal_report_item
   *
   * Get journal_report_item
   * @param id id of journal_report_item
   * @return successful operation
   */
  getReportJournalReportItemsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_report_item, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/journalReportItems/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_report_item, message?: string}>;
      })
    );
  }
  /**
   * Display the specified journal_report_item
   *
   * Get journal_report_item
   * @param id id of journal_report_item
   * @return successful operation
   */
  getReportJournalReportItemsId(id: number): __Observable<{success?: boolean, data?: Journal_report_item, message?: string}> {
    return this.getReportJournalReportItemsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_report_item, message?: string})
    );
  }

  /**
   * Update the specified journal_report_item in storage
   *
   * Update journal_report_item
   * @param params The `JournalReportItemService.PutReportJournalReportItemsIdParams` containing the following parameters:
   *
   * - `id`: id of journal_report_item
   *
   * - `body`: journal_report_item that should be updated
   *
   * @return successful operation
   */
  putReportJournalReportItemsIdResponse(params: JournalReportItemService.PutReportJournalReportItemsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_report_item, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/report/journalReportItems/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_report_item, message?: string}>;
      })
    );
  }
  /**
   * Update the specified journal_report_item in storage
   *
   * Update journal_report_item
   * @param params The `JournalReportItemService.PutReportJournalReportItemsIdParams` containing the following parameters:
   *
   * - `id`: id of journal_report_item
   *
   * - `body`: journal_report_item that should be updated
   *
   * @return successful operation
   */
  putReportJournalReportItemsId(params: JournalReportItemService.PutReportJournalReportItemsIdParams): __Observable<{success?: boolean, data?: Journal_report_item, message?: string}> {
    return this.putReportJournalReportItemsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_report_item, message?: string})
    );
  }

  /**
   * Remove the specified journal_report_item from storage
   *
   * Delete journal_report_item
   * @param id id of journal_report_item
   * @return successful operation
   */
  deleteReportJournalReportItemsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/report/journalReportItems/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified journal_report_item from storage
   *
   * Delete journal_report_item
   * @param id id of journal_report_item
   * @return successful operation
   */
  deleteReportJournalReportItemsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportJournalReportItemsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module JournalReportItemService {

  /**
   * Parameters for getReportJournalReportItems
   */
  export interface GetReportJournalReportItemsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter journal_report_item
     */
    filter?: string;
  }

  /**
   * Parameters for putReportJournalReportItemsId
   */
  export interface PutReportJournalReportItemsIdParams {

    /**
     * id of journal_report_item
     */
    id: number;

    /**
     * journal_report_item that should be updated
     */
    body?: Journal_report_item;
  }
}

export { JournalReportItemService }
