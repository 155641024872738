/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrPegawai } from '../models/hr-pegawai';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrPegawaiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrPegawaiList
   */
  static readonly GetHrPegawaiListPath = '/hr/pegawais';

  /**
   * getpegawaiList.
   *
   * Get all pegawais
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawai>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiService.GetHrPegawaiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrPegawai>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawaiList.
   *
   * Get all pegawais
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrPegawai>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrPegawaiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawai>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrPegawai>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrPegawai
   */
  static readonly StoreHrPegawaiPath = '/hr/pegawais';

  /**
   * createpegawai.
   *
   * Create pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrPegawai()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawai$Response(params: {
    body: HrPegawai
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiService.StoreHrPegawaiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawai;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai.
   *
   * Create pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrPegawai$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawai(params: {
    body: HrPegawai
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}> {

    return this.storeHrPegawai$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrPegawaiId
   */
  static readonly GetHrPegawaiIdPath = '/hr/pegawais/{id}';

  /**
   * getpegawaiItem.
   *
   * Get pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiId$Response(params: {

    /**
     * id of pegawai
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiService.GetHrPegawaiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawai;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawaiItem.
   *
   * Get pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiId(params: {

    /**
     * id of pegawai
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}> {

    return this.getHrPegawaiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrPegawaiId
   */
  static readonly PutHrPegawaiIdPath = '/hr/pegawais/{id}';

  /**
   * updatepegawai.
   *
   * Update pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrPegawaiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiId$Response(params: {

    /**
     * id of pegawai
     */
    id: string;
    body: HrPegawai
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiService.PutHrPegawaiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawai;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai.
   *
   * Update pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrPegawaiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiId(params: {

    /**
     * id of pegawai
     */
    id: string;
    body: HrPegawai
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}> {

    return this.putHrPegawaiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawai;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrPegawaisId
   */
  static readonly DeleteHrPegawaisIdPath = '/hr/pegawais/{id}';

  /**
   * deletepegawai.
   *
   * Delete pegawai
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrPegawaisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaisId$Response(params: {

    /**
     * id of pegawai
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiService.DeleteHrPegawaisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai.
   *
   * Delete pegawai
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrPegawaisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaisId(params: {

    /**
     * id of pegawai
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrPegawaisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
