/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminPengajuanPengaduan } from '../models/admin-pengajuan-pengaduan';

@Injectable({
  providedIn: 'root',
})
export class AdminPengajuanPengaduanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminPengajuanPengaduansList
   */
  static readonly GetadminPengajuanPengaduansListPath = '/admin/pengajuanPengaduans';

  /**
   * Get list of pengajuanPengaduans.
   *
   * Returns list of pengajuanPengaduans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPengajuanPengaduansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengajuanPengaduansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengajuanPengaduan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengajuanPengaduanService.GetadminPengajuanPengaduansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminPengajuanPengaduan>;
        }>;
      })
    );
  }

  /**
   * Get list of pengajuanPengaduans.
   *
   * Returns list of pengajuanPengaduans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPengajuanPengaduansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengajuanPengaduansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengajuanPengaduan>;
}> {

    return this.getadminPengajuanPengaduansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengajuanPengaduan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengajuanPengaduan>;
})
    );
  }

  /**
   * Path part for operation storeadminPengajuanPengaduan
   */
  static readonly StoreadminPengajuanPengaduanPath = '/admin/pengajuanPengaduans';

  /**
   * Store new Pengajuan_pengaduan.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminPengajuanPengaduan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPengajuanPengaduan$Response(params: {
    body: AdminPengajuanPengaduan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengajuanPengaduanService.StoreadminPengajuanPengaduanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengajuanPengaduan;
        }>;
      })
    );
  }

  /**
   * Store new Pengajuan_pengaduan.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminPengajuanPengaduan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPengajuanPengaduan(params: {
    body: AdminPengajuanPengaduan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}> {

    return this.storeadminPengajuanPengaduan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
})
    );
  }

  /**
   * Path part for operation getadminPengajuanPengaduanId
   */
  static readonly GetadminPengajuanPengaduanIdPath = '/admin/pengajuanPengaduans/{id}';

  /**
   * Get  Pengajuan_pengaduan by id.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPengajuanPengaduanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengajuanPengaduanId$Response(params: {

    /**
     * id of Pengajuan_pengaduan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengajuanPengaduanService.GetadminPengajuanPengaduanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengajuanPengaduan;
        }>;
      })
    );
  }

  /**
   * Get  Pengajuan_pengaduan by id.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPengajuanPengaduanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengajuanPengaduanId(params: {

    /**
     * id of Pengajuan_pengaduan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}> {

    return this.getadminPengajuanPengaduanId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
})
    );
  }

  /**
   * Path part for operation putadminPengajuanPengaduan
   */
  static readonly PutadminPengajuanPengaduanPath = '/admin/pengajuanPengaduans/{id}';

  /**
   * Update Pengajuan_pengaduan.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminPengajuanPengaduan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPengajuanPengaduan$Response(params: {

    /**
     * id of Pengajuan_pengaduan
     */
    id: string;
    body: AdminPengajuanPengaduan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengajuanPengaduanService.PutadminPengajuanPengaduanPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengajuanPengaduan;
        }>;
      })
    );
  }

  /**
   * Update Pengajuan_pengaduan.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminPengajuanPengaduan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPengajuanPengaduan(params: {

    /**
     * id of Pengajuan_pengaduan
     */
    id: string;
    body: AdminPengajuanPengaduan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}> {

    return this.putadminPengajuanPengaduan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengajuanPengaduan;
})
    );
  }

  /**
   * Path part for operation deleteadminPengajuanPengaduan
   */
  static readonly DeleteadminPengajuanPengaduanPath = '/admin/pengajuanPengaduans/{id}';

  /**
   * Store new Pengajuan_pengaduan.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminPengajuanPengaduan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPengajuanPengaduan$Response(params: {

    /**
     * id of Pengajuan_pengaduan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengajuanPengaduanService.DeleteadminPengajuanPengaduanPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Pengajuan_pengaduan.
   *
   * Returns Pengajuan_pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminPengajuanPengaduan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPengajuanPengaduan(params: {

    /**
     * id of Pengajuan_pengaduan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminPengajuanPengaduan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
