/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthRegister } from '../models/auth-register';

@Injectable({
  providedIn: 'root',
})
export class AuthRegisterService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation storeAuthRegister
   */
  static readonly StoreAuthRegisterPath = '/auth/registers';

  /**
   * createregister.
   *
   * Create register
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAuthRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAuthRegister$Response(params: {
    body: AuthRegister
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AuthRegister;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AuthRegisterService.StoreAuthRegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AuthRegister;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createregister.
   *
   * Create register
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAuthRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAuthRegister(params: {
    body: AuthRegister
  }): Observable<{
'success'?: boolean;
'data'?: AuthRegister;
'message'?: string;
}> {

    return this.storeAuthRegister$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AuthRegister;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AuthRegister;
'message'?: string;
})
    );
  }

}
