/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Partner } from '../models/partner';
@Injectable({
  providedIn: 'root',
})
class PartnerService extends __BaseService {
  static readonly getSwitchingPartnersPath = '/switching/partners';
  static readonly postSwitchingPartnersPath = '/switching/partners';
  static readonly getSwitchingPartnersIdPath = '/switching/partners/{id}';
  static readonly putSwitchingPartnersIdPath = '/switching/partners/{id}';
  static readonly deleteSwitchingPartnersIdPath = '/switching/partners/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Partner.
   *
   * Get all Partner
   * @param params The `PartnerService.GetSwitchingPartnersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Partner
   *
   * @return successful operation
   */
  getSwitchingPartnersResponse(params: PartnerService.GetSwitchingPartnersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Partner>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/switching/partners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Partner>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Partner.
   *
   * Get all Partner
   * @param params The `PartnerService.GetSwitchingPartnersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Partner
   *
   * @return successful operation
   */
  getSwitchingPartners(params: PartnerService.GetSwitchingPartnersParams): __Observable<{success?: boolean, data?: Array<Partner>, message?: string, total_data?: number, total_page?: number}> {
    return this.getSwitchingPartnersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Partner>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Partner in storage
   *
   * Store Partner
   * @param body Partner that should be stored
   * @return successful operation
   */
  postSwitchingPartnersResponse(body?: Partner): __Observable<__StrictHttpResponse<{success?: boolean, data?: Partner, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/switching/partners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Partner, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Partner in storage
   *
   * Store Partner
   * @param body Partner that should be stored
   * @return successful operation
   */
  postSwitchingPartners(body?: Partner): __Observable<{success?: boolean, data?: Partner, message?: string}> {
    return this.postSwitchingPartnersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Partner, message?: string})
    );
  }

  /**
   * Display the specified Partner
   *
   * Get Partner
   * @param id id of Partner
   * @return successful operation
   */
  getSwitchingPartnersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Partner, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/switching/partners/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Partner, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Partner
   *
   * Get Partner
   * @param id id of Partner
   * @return successful operation
   */
  getSwitchingPartnersId(id: number): __Observable<{success?: boolean, data?: Partner, message?: string}> {
    return this.getSwitchingPartnersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Partner, message?: string})
    );
  }

  /**
   * Update the specified Partner in storage
   *
   * Update Partner
   * @param params The `PartnerService.PutSwitchingPartnersIdParams` containing the following parameters:
   *
   * - `id`: id of Partner
   *
   * - `body`: Partner that should be updated
   *
   * @return successful operation
   */
  putSwitchingPartnersIdResponse(params: PartnerService.PutSwitchingPartnersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Partner, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/switching/partners/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Partner, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Partner in storage
   *
   * Update Partner
   * @param params The `PartnerService.PutSwitchingPartnersIdParams` containing the following parameters:
   *
   * - `id`: id of Partner
   *
   * - `body`: Partner that should be updated
   *
   * @return successful operation
   */
  putSwitchingPartnersId(params: PartnerService.PutSwitchingPartnersIdParams): __Observable<{success?: boolean, data?: Partner, message?: string}> {
    return this.putSwitchingPartnersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Partner, message?: string})
    );
  }

  /**
   * Remove the specified Partner from storage
   *
   * Delete Partner
   * @param id id of Partner
   * @return successful operation
   */
  deleteSwitchingPartnersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/switching/partners/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Partner from storage
   *
   * Delete Partner
   * @param id id of Partner
   * @return successful operation
   */
  deleteSwitchingPartnersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteSwitchingPartnersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PartnerService {

  /**
   * Parameters for getSwitchingPartners
   */
  export interface GetSwitchingPartnersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Partner
     */
    filter?: string;
  }

  /**
   * Parameters for putSwitchingPartnersId
   */
  export interface PutSwitchingPartnersIdParams {

    /**
     * id of Partner
     */
    id: number;

    /**
     * Partner that should be updated
     */
    body?: Partner;
  }
}

export { PartnerService }
