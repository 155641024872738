/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Baca_meter_foto } from '../models/baca-_meter-_foto';
@Injectable({
  providedIn: 'root',
})
class BacaMeterFotoService extends __BaseService {
  static readonly getPelangganBacaMeterFotosIdPath = '/pelanggan/bacaMeterFotos/{id}';
  static readonly putPelangganBacaMeterFotosIdPath = '/pelanggan/bacaMeterFotos/{id}';
  static readonly deletePelangganBacaMeterFotosIdPath = '/pelanggan/bacaMeterFotos/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Display the specified Baca_meter_foto
   *
   * Get Baca_meter_foto
   * @param id id of Baca_meter_foto
   * @return successful operation
   */
  getPelangganBacaMeterFotosIdResponse(id: number): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/bacaMeterFotos/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Display the specified Baca_meter_foto
   *
   * Get Baca_meter_foto
   * @param id id of Baca_meter_foto
   * @return successful operation
   */
  getPelangganBacaMeterFotosId(id: number): __Observable<Blob> {
    return this.getPelangganBacaMeterFotosIdResponse(id).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Update the specified Baca_meter_foto in storage
   *
   * Update Baca_meter_foto
   * @param params The `BacaMeterFotoService.PutPelangganBacaMeterFotosIdParams` containing the following parameters:
   *
   * - `id`: id of Baca_meter_foto
   *
   * - `body`: Baca_meter_foto that should be updated
   *
   * @return successful operation
   */
  putPelangganBacaMeterFotosIdResponse(params: BacaMeterFotoService.PutPelangganBacaMeterFotosIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_foto, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/bacaMeterFotos/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_foto, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Baca_meter_foto in storage
   *
   * Update Baca_meter_foto
   * @param params The `BacaMeterFotoService.PutPelangganBacaMeterFotosIdParams` containing the following parameters:
   *
   * - `id`: id of Baca_meter_foto
   *
   * - `body`: Baca_meter_foto that should be updated
   *
   * @return successful operation
   */
  putPelangganBacaMeterFotosId(params: BacaMeterFotoService.PutPelangganBacaMeterFotosIdParams): __Observable<{success?: boolean, data?: Baca_meter_foto, message?: string}> {
    return this.putPelangganBacaMeterFotosIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_foto, message?: string})
    );
  }

  /**
   * Remove the specified Baca_meter_foto from storage
   *
   * Delete Baca_meter_foto
   * @param id id of Baca_meter_foto
   * @return successful operation
   */
  deletePelangganBacaMeterFotosIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/bacaMeterFotos/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Baca_meter_foto from storage
   *
   * Delete Baca_meter_foto
   * @param id id of Baca_meter_foto
   * @return successful operation
   */
  deletePelangganBacaMeterFotosId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganBacaMeterFotosIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module BacaMeterFotoService {

  /**
   * Parameters for putPelangganBacaMeterFotosId
   */
  export interface PutPelangganBacaMeterFotosIdParams {

    /**
     * id of Baca_meter_foto
     */
    id: number;

    /**
     * Baca_meter_foto that should be updated
     */
    body?: Baca_meter_foto;
  }
}

export { BacaMeterFotoService }
