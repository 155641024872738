/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Journal_penerimaan_item_rekap } from '../models/journal-_penerimaan-_item-_rekap';
@Injectable({
  providedIn: 'root',
})
class JournalPenerimaanItemRekapService extends __BaseService {
  static readonly getReportJournalPenerimaanItemRekapsPath = '/report/journalPenerimaanItemRekaps';
  static readonly postReportJournalPenerimaanItemRekapsPath = '/report/journalPenerimaanItemRekaps';
  static readonly getReportJournalPenerimaanItemRekapsIdPath = '/report/journalPenerimaanItemRekaps/{id}';
  static readonly putReportJournalPenerimaanItemRekapsIdPath = '/report/journalPenerimaanItemRekaps/{id}';
  static readonly deleteReportJournalPenerimaanItemRekapsIdPath = '/report/journalPenerimaanItemRekaps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the journal_penerimaan_item_rekap.
   *
   * Get all journal_penerimaan_item_rekap
   * @param params The `JournalPenerimaanItemRekapService.GetReportJournalPenerimaanItemRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter journal_penerimaan_item_rekap
   *
   * @return successful operation
   */
  getReportJournalPenerimaanItemRekapsResponse(params: JournalPenerimaanItemRekapService.GetReportJournalPenerimaanItemRekapsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Journal_penerimaan_item_rekap>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/journalPenerimaanItemRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Journal_penerimaan_item_rekap>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the journal_penerimaan_item_rekap.
   *
   * Get all journal_penerimaan_item_rekap
   * @param params The `JournalPenerimaanItemRekapService.GetReportJournalPenerimaanItemRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter journal_penerimaan_item_rekap
   *
   * @return successful operation
   */
  getReportJournalPenerimaanItemRekaps(params: JournalPenerimaanItemRekapService.GetReportJournalPenerimaanItemRekapsParams): __Observable<{success?: boolean, data?: Array<Journal_penerimaan_item_rekap>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportJournalPenerimaanItemRekapsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Journal_penerimaan_item_rekap>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created journal_penerimaan_item_rekap in storage
   *
   * Store journal_penerimaan_item_rekap
   * @param body journal_penerimaan_item_rekap that should be stored
   * @return successful operation
   */
  postReportJournalPenerimaanItemRekapsResponse(body?: Journal_penerimaan_item_rekap): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report/journalPenerimaanItemRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created journal_penerimaan_item_rekap in storage
   *
   * Store journal_penerimaan_item_rekap
   * @param body journal_penerimaan_item_rekap that should be stored
   * @return successful operation
   */
  postReportJournalPenerimaanItemRekaps(body?: Journal_penerimaan_item_rekap): __Observable<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}> {
    return this.postReportJournalPenerimaanItemRekapsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string})
    );
  }

  /**
   * Display the specified journal_penerimaan_item_rekap
   *
   * Get journal_penerimaan_item_rekap
   * @param id id of journal_penerimaan_item_rekap
   * @return successful operation
   */
  getReportJournalPenerimaanItemRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/journalPenerimaanItemRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}>;
      })
    );
  }
  /**
   * Display the specified journal_penerimaan_item_rekap
   *
   * Get journal_penerimaan_item_rekap
   * @param id id of journal_penerimaan_item_rekap
   * @return successful operation
   */
  getReportJournalPenerimaanItemRekapsId(id: number): __Observable<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}> {
    return this.getReportJournalPenerimaanItemRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string})
    );
  }

  /**
   * Update the specified journal_penerimaan_item_rekap in storage
   *
   * Update journal_penerimaan_item_rekap
   * @param params The `JournalPenerimaanItemRekapService.PutReportJournalPenerimaanItemRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of journal_penerimaan_item_rekap
   *
   * - `body`: journal_penerimaan_item_rekap that should be updated
   *
   * @return successful operation
   */
  putReportJournalPenerimaanItemRekapsIdResponse(params: JournalPenerimaanItemRekapService.PutReportJournalPenerimaanItemRekapsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/report/journalPenerimaanItemRekaps/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}>;
      })
    );
  }
  /**
   * Update the specified journal_penerimaan_item_rekap in storage
   *
   * Update journal_penerimaan_item_rekap
   * @param params The `JournalPenerimaanItemRekapService.PutReportJournalPenerimaanItemRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of journal_penerimaan_item_rekap
   *
   * - `body`: journal_penerimaan_item_rekap that should be updated
   *
   * @return successful operation
   */
  putReportJournalPenerimaanItemRekapsId(params: JournalPenerimaanItemRekapService.PutReportJournalPenerimaanItemRekapsIdParams): __Observable<{success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string}> {
    return this.putReportJournalPenerimaanItemRekapsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_penerimaan_item_rekap, message?: string})
    );
  }

  /**
   * Remove the specified journal_penerimaan_item_rekap from storage
   *
   * Delete journal_penerimaan_item_rekap
   * @param id id of journal_penerimaan_item_rekap
   * @return successful operation
   */
  deleteReportJournalPenerimaanItemRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/report/journalPenerimaanItemRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified journal_penerimaan_item_rekap from storage
   *
   * Delete journal_penerimaan_item_rekap
   * @param id id of journal_penerimaan_item_rekap
   * @return successful operation
   */
  deleteReportJournalPenerimaanItemRekapsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportJournalPenerimaanItemRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module JournalPenerimaanItemRekapService {

  /**
   * Parameters for getReportJournalPenerimaanItemRekaps
   */
  export interface GetReportJournalPenerimaanItemRekapsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter journal_penerimaan_item_rekap
     */
    filter?: string;
  }

  /**
   * Parameters for putReportJournalPenerimaanItemRekapsId
   */
  export interface PutReportJournalPenerimaanItemRekapsIdParams {

    /**
     * id of journal_penerimaan_item_rekap
     */
    id: number;

    /**
     * journal_penerimaan_item_rekap that should be updated
     */
    body?: Journal_penerimaan_item_rekap;
  }
}

export { JournalPenerimaanItemRekapService }
