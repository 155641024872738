/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrPegawaiPosisi } from '../models/hr-pegawai-posisi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrPegawaiPosisiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrPegawaiPosisiList
   */
  static readonly GetHrPegawaiPosisiListPath = '/hr/pegawai_posisis';

  /**
   * getpegawai_posisiList.
   *
   * Get all pegawai_posisis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiPosisiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiPosisiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiPosisiService.GetHrPegawaiPosisiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrPegawaiPosisi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_posisiList.
   *
   * Get all pegawai_posisis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiPosisiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiPosisiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrPegawaiPosisi>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrPegawaiPosisiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrPegawaiPosisi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrPegawaiPosisi
   */
  static readonly StoreHrPegawaiPosisiPath = '/hr/pegawai_posisis';

  /**
   * createpegawai_posisi.
   *
   * Create pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrPegawaiPosisi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiPosisi$Response(params: {
    body: HrPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiPosisiService.StoreHrPegawaiPosisiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_posisi.
   *
   * Create pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrPegawaiPosisi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiPosisi(params: {
    body: HrPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}> {

    return this.storeHrPegawaiPosisi$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrPegawaiPosisiId
   */
  static readonly GetHrPegawaiPosisiIdPath = '/hr/pegawai_posisis/{id}';

  /**
   * getpegawai_posisiItem.
   *
   * Get pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiPosisiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiPosisiId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiPosisiService.GetHrPegawaiPosisiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_posisiItem.
   *
   * Get pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiPosisiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiPosisiId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}> {

    return this.getHrPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrPegawaiPosisiId
   */
  static readonly PutHrPegawaiPosisiIdPath = '/hr/pegawai_posisis/{id}';

  /**
   * updatepegawai_posisi.
   *
   * Update pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrPegawaiPosisiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiPosisiId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
    body: HrPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiPosisiService.PutHrPegawaiPosisiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_posisi.
   *
   * Update pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrPegawaiPosisiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiPosisiId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
    body: HrPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}> {

    return this.putHrPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrPegawaiPosisisId
   */
  static readonly DeleteHrPegawaiPosisisIdPath = '/hr/pegawai_posisis/{id}';

  /**
   * deletepegawai_posisi.
   *
   * Delete pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrPegawaiPosisisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiPosisisId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiPosisiService.DeleteHrPegawaiPosisisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_posisi.
   *
   * Delete pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrPegawaiPosisisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiPosisisId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrPegawaiPosisisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
