/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tunggakan_tertagih_cater } from '../models/tunggakan-_tertagih-_cater';
@Injectable({
  providedIn: 'root',
})
class TunggakanTertagihCaterService extends __BaseService {
  static readonly getPelangganReportTunggakanTertagihCatersPath = '/pelangganReport/tunggakanTertagihCaters';
  static readonly postPelangganReportTunggakanTertagihCatersPath = '/pelangganReport/tunggakanTertagihCaters';
  static readonly getPelangganReportTunggakanTertagihCatersIdPath = '/pelangganReport/tunggakanTertagihCaters/{id}';
  static readonly putPelangganReportTunggakanTertagihCatersIdPath = '/pelangganReport/tunggakanTertagihCaters/{id}';
  static readonly deletePelangganReportTunggakanTertagihCatersIdPath = '/pelangganReport/tunggakanTertagihCaters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the tunggakan_tertagih_cater.
   *
   * Get all tunggakan_tertagih_cater
   * @param params The `TunggakanTertagihCaterService.GetPelangganReportTunggakanTertagihCatersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter tunggakan_tertagih_cater
   *
   * @return successful operation
   */
  getPelangganReportTunggakanTertagihCatersResponse(params: TunggakanTertagihCaterService.GetPelangganReportTunggakanTertagihCatersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Tunggakan_tertagih_cater>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tunggakanTertagihCaters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Tunggakan_tertagih_cater>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the tunggakan_tertagih_cater.
   *
   * Get all tunggakan_tertagih_cater
   * @param params The `TunggakanTertagihCaterService.GetPelangganReportTunggakanTertagihCatersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter tunggakan_tertagih_cater
   *
   * @return successful operation
   */
  getPelangganReportTunggakanTertagihCaters(params: TunggakanTertagihCaterService.GetPelangganReportTunggakanTertagihCatersParams): __Observable<{success?: boolean, data?: Array<Tunggakan_tertagih_cater>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportTunggakanTertagihCatersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Tunggakan_tertagih_cater>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created tunggakan_tertagih_cater in storage
   *
   * Store tunggakan_tertagih_cater
   * @param body tunggakan_tertagih_cater that should be stored
   * @return successful operation
   */
  postPelangganReportTunggakanTertagihCatersResponse(body?: Tunggakan_tertagih_cater): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/tunggakanTertagihCaters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created tunggakan_tertagih_cater in storage
   *
   * Store tunggakan_tertagih_cater
   * @param body tunggakan_tertagih_cater that should be stored
   * @return successful operation
   */
  postPelangganReportTunggakanTertagihCaters(body?: Tunggakan_tertagih_cater): __Observable<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}> {
    return this.postPelangganReportTunggakanTertagihCatersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_tertagih_cater, message?: string})
    );
  }

  /**
   * Display the specified tunggakan_tertagih_cater
   *
   * Get tunggakan_tertagih_cater
   * @param id id of tunggakan_tertagih_cater
   * @return successful operation
   */
  getPelangganReportTunggakanTertagihCatersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tunggakanTertagihCaters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}>;
      })
    );
  }
  /**
   * Display the specified tunggakan_tertagih_cater
   *
   * Get tunggakan_tertagih_cater
   * @param id id of tunggakan_tertagih_cater
   * @return successful operation
   */
  getPelangganReportTunggakanTertagihCatersId(id: number): __Observable<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}> {
    return this.getPelangganReportTunggakanTertagihCatersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_tertagih_cater, message?: string})
    );
  }

  /**
   * Update the specified tunggakan_tertagih_cater in storage
   *
   * Update tunggakan_tertagih_cater
   * @param params The `TunggakanTertagihCaterService.PutPelangganReportTunggakanTertagihCatersIdParams` containing the following parameters:
   *
   * - `id`: id of tunggakan_tertagih_cater
   *
   * - `body`: tunggakan_tertagih_cater that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTunggakanTertagihCatersIdResponse(params: TunggakanTertagihCaterService.PutPelangganReportTunggakanTertagihCatersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/tunggakanTertagihCaters/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}>;
      })
    );
  }
  /**
   * Update the specified tunggakan_tertagih_cater in storage
   *
   * Update tunggakan_tertagih_cater
   * @param params The `TunggakanTertagihCaterService.PutPelangganReportTunggakanTertagihCatersIdParams` containing the following parameters:
   *
   * - `id`: id of tunggakan_tertagih_cater
   *
   * - `body`: tunggakan_tertagih_cater that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTunggakanTertagihCatersId(params: TunggakanTertagihCaterService.PutPelangganReportTunggakanTertagihCatersIdParams): __Observable<{success?: boolean, data?: Tunggakan_tertagih_cater, message?: string}> {
    return this.putPelangganReportTunggakanTertagihCatersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_tertagih_cater, message?: string})
    );
  }

  /**
   * Remove the specified tunggakan_tertagih_cater from storage
   *
   * Delete tunggakan_tertagih_cater
   * @param id id of tunggakan_tertagih_cater
   * @return successful operation
   */
  deletePelangganReportTunggakanTertagihCatersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/tunggakanTertagihCaters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified tunggakan_tertagih_cater from storage
   *
   * Delete tunggakan_tertagih_cater
   * @param id id of tunggakan_tertagih_cater
   * @return successful operation
   */
  deletePelangganReportTunggakanTertagihCatersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportTunggakanTertagihCatersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module TunggakanTertagihCaterService {

  /**
   * Parameters for getPelangganReportTunggakanTertagihCaters
   */
  export interface GetPelangganReportTunggakanTertagihCatersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter tunggakan_tertagih_cater
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportTunggakanTertagihCatersId
   */
  export interface PutPelangganReportTunggakanTertagihCatersIdParams {

    /**
     * id of tunggakan_tertagih_cater
     */
    id: number;

    /**
     * tunggakan_tertagih_cater that should be updated
     */
    body?: Tunggakan_tertagih_cater;
  }
}

export { TunggakanTertagihCaterService }
