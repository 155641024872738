/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Master_air_tangki } from '../models/master-_air-_tangki';
@Injectable({
  providedIn: 'root',
})
class MasterAirTangkiService extends __BaseService {
  static readonly getPelangganMasterAirTangkisPath = '/pelanggan/masterAirTangkis';
  static readonly postPelangganMasterAirTangkisPath = '/pelanggan/masterAirTangkis';
  static readonly getPelangganMasterAirTangkisIdPath = '/pelanggan/masterAirTangkis/{id}';
  static readonly putPelangganMasterAirTangkisIdPath = '/pelanggan/masterAirTangkis/{id}';
  static readonly deletePelangganMasterAirTangkisIdPath = '/pelanggan/masterAirTangkis/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Master_air_tangki.
   *
   * Get all Master_air_tangki
   * @param params The `MasterAirTangkiService.GetPelangganMasterAirTangkisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Master_air_tangki
   *
   * @return successful operation
   */
  getPelangganMasterAirTangkisResponse(params: MasterAirTangkiService.GetPelangganMasterAirTangkisParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Master_air_tangki>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/masterAirTangkis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Master_air_tangki>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Master_air_tangki.
   *
   * Get all Master_air_tangki
   * @param params The `MasterAirTangkiService.GetPelangganMasterAirTangkisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Master_air_tangki
   *
   * @return successful operation
   */
  getPelangganMasterAirTangkis(params: MasterAirTangkiService.GetPelangganMasterAirTangkisParams): __Observable<{success?: boolean, data?: Array<Master_air_tangki>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganMasterAirTangkisResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Master_air_tangki>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Master_air_tangki in storage
   *
   * Store Master_air_tangki
   * @param body Master_air_tangki that should be stored
   * @return successful operation
   */
  postPelangganMasterAirTangkisResponse(body?: Master_air_tangki): __Observable<__StrictHttpResponse<{success?: boolean, data?: Master_air_tangki, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/masterAirTangkis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Master_air_tangki, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Master_air_tangki in storage
   *
   * Store Master_air_tangki
   * @param body Master_air_tangki that should be stored
   * @return successful operation
   */
  postPelangganMasterAirTangkis(body?: Master_air_tangki): __Observable<{success?: boolean, data?: Master_air_tangki, message?: string}> {
    return this.postPelangganMasterAirTangkisResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Master_air_tangki, message?: string})
    );
  }

  /**
   * Display the specified Master_air_tangki
   *
   * Get Master_air_tangki
   * @param id id of Master_air_tangki
   * @return successful operation
   */
  getPelangganMasterAirTangkisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Master_air_tangki, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/masterAirTangkis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Master_air_tangki, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Master_air_tangki
   *
   * Get Master_air_tangki
   * @param id id of Master_air_tangki
   * @return successful operation
   */
  getPelangganMasterAirTangkisId(id: number): __Observable<{success?: boolean, data?: Master_air_tangki, message?: string}> {
    return this.getPelangganMasterAirTangkisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Master_air_tangki, message?: string})
    );
  }

  /**
   * Update the specified Master_air_tangki in storage
   *
   * Update Master_air_tangki
   * @param params The `MasterAirTangkiService.PutPelangganMasterAirTangkisIdParams` containing the following parameters:
   *
   * - `id`: id of Master_air_tangki
   *
   * - `body`: Master_air_tangki that should be updated
   *
   * @return successful operation
   */
  putPelangganMasterAirTangkisIdResponse(params: MasterAirTangkiService.PutPelangganMasterAirTangkisIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Master_air_tangki, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/masterAirTangkis/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Master_air_tangki, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Master_air_tangki in storage
   *
   * Update Master_air_tangki
   * @param params The `MasterAirTangkiService.PutPelangganMasterAirTangkisIdParams` containing the following parameters:
   *
   * - `id`: id of Master_air_tangki
   *
   * - `body`: Master_air_tangki that should be updated
   *
   * @return successful operation
   */
  putPelangganMasterAirTangkisId(params: MasterAirTangkiService.PutPelangganMasterAirTangkisIdParams): __Observable<{success?: boolean, data?: Master_air_tangki, message?: string}> {
    return this.putPelangganMasterAirTangkisIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Master_air_tangki, message?: string})
    );
  }

  /**
   * Remove the specified Master_air_tangki from storage
   *
   * Delete Master_air_tangki
   * @param id id of Master_air_tangki
   * @return successful operation
   */
  deletePelangganMasterAirTangkisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/masterAirTangkis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Master_air_tangki from storage
   *
   * Delete Master_air_tangki
   * @param id id of Master_air_tangki
   * @return successful operation
   */
  deletePelangganMasterAirTangkisId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganMasterAirTangkisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module MasterAirTangkiService {

  /**
   * Parameters for getPelangganMasterAirTangkis
   */
  export interface GetPelangganMasterAirTangkisParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Master_air_tangki
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganMasterAirTangkisId
   */
  export interface PutPelangganMasterAirTangkisIdParams {

    /**
     * id of Master_air_tangki
     */
    id: number;

    /**
     * Master_air_tangki that should be updated
     */
    body?: Master_air_tangki;
  }
}

export { MasterAirTangkiService }
