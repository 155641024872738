/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pembayaran_belum_terekap } from '../models/pembayaran-_belum-_terekap';
@Injectable({
  providedIn: 'root',
})
class PembayaranBelumTerekapService extends __BaseService {
  static readonly getPenagihanReportPembayaranBelumTerekapsPath = '/penagihanReport/pembayaranBelumTerekaps';
  static readonly postPenagihanReportPembayaranBelumTerekapsPath = '/penagihanReport/pembayaranBelumTerekaps';
  static readonly getPenagihanReportPembayaranBelumTerekapsIdPath = '/penagihanReport/pembayaranBelumTerekaps/{id}';
  static readonly putPenagihanReportPembayaranBelumTerekapsIdPath = '/penagihanReport/pembayaranBelumTerekaps/{id}';
  static readonly deletePenagihanReportPembayaranBelumTerekapsIdPath = '/penagihanReport/pembayaranBelumTerekaps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the pembayaran_belum_terekap.
   *
   * Get all pembayaran_belum_terekap
   * @param params The `PembayaranBelumTerekapService.GetPenagihanReportPembayaranBelumTerekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pembayaran_belum_terekap
   *
   * @return successful operation
   */
  getPenagihanReportPembayaranBelumTerekapsResponse(params: PembayaranBelumTerekapService.GetPenagihanReportPembayaranBelumTerekapsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pembayaran_belum_terekap>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/pembayaranBelumTerekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pembayaran_belum_terekap>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pembayaran_belum_terekap.
   *
   * Get all pembayaran_belum_terekap
   * @param params The `PembayaranBelumTerekapService.GetPenagihanReportPembayaranBelumTerekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pembayaran_belum_terekap
   *
   * @return successful operation
   */
  getPenagihanReportPembayaranBelumTerekaps(params: PembayaranBelumTerekapService.GetPenagihanReportPembayaranBelumTerekapsParams): __Observable<{success?: boolean, data?: Array<Pembayaran_belum_terekap>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportPembayaranBelumTerekapsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pembayaran_belum_terekap>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pembayaran_belum_terekap in storage
   *
   * Store pembayaran_belum_terekap
   * @param body pembayaran_belum_terekap that should be stored
   * @return successful operation
   */
  postPenagihanReportPembayaranBelumTerekapsResponse(body?: Pembayaran_belum_terekap): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/pembayaranBelumTerekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pembayaran_belum_terekap in storage
   *
   * Store pembayaran_belum_terekap
   * @param body pembayaran_belum_terekap that should be stored
   * @return successful operation
   */
  postPenagihanReportPembayaranBelumTerekaps(body?: Pembayaran_belum_terekap): __Observable<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}> {
    return this.postPenagihanReportPembayaranBelumTerekapsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pembayaran_belum_terekap, message?: string})
    );
  }

  /**
   * Display the specified pembayaran_belum_terekap
   *
   * Get pembayaran_belum_terekap
   * @param id id of pembayaran_belum_terekap
   * @return successful operation
   */
  getPenagihanReportPembayaranBelumTerekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/pembayaranBelumTerekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pembayaran_belum_terekap
   *
   * Get pembayaran_belum_terekap
   * @param id id of pembayaran_belum_terekap
   * @return successful operation
   */
  getPenagihanReportPembayaranBelumTerekapsId(id: number): __Observable<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}> {
    return this.getPenagihanReportPembayaranBelumTerekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pembayaran_belum_terekap, message?: string})
    );
  }

  /**
   * Update the specified pembayaran_belum_terekap in storage
   *
   * Update pembayaran_belum_terekap
   * @param params The `PembayaranBelumTerekapService.PutPenagihanReportPembayaranBelumTerekapsIdParams` containing the following parameters:
   *
   * - `id`: id of pembayaran_belum_terekap
   *
   * - `body`: pembayaran_belum_terekap that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPembayaranBelumTerekapsIdResponse(params: PembayaranBelumTerekapService.PutPenagihanReportPembayaranBelumTerekapsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/pembayaranBelumTerekaps/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pembayaran_belum_terekap in storage
   *
   * Update pembayaran_belum_terekap
   * @param params The `PembayaranBelumTerekapService.PutPenagihanReportPembayaranBelumTerekapsIdParams` containing the following parameters:
   *
   * - `id`: id of pembayaran_belum_terekap
   *
   * - `body`: pembayaran_belum_terekap that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportPembayaranBelumTerekapsId(params: PembayaranBelumTerekapService.PutPenagihanReportPembayaranBelumTerekapsIdParams): __Observable<{success?: boolean, data?: Pembayaran_belum_terekap, message?: string}> {
    return this.putPenagihanReportPembayaranBelumTerekapsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pembayaran_belum_terekap, message?: string})
    );
  }

  /**
   * Remove the specified pembayaran_belum_terekap from storage
   *
   * Delete pembayaran_belum_terekap
   * @param id id of pembayaran_belum_terekap
   * @return successful operation
   */
  deletePenagihanReportPembayaranBelumTerekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/pembayaranBelumTerekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pembayaran_belum_terekap from storage
   *
   * Delete pembayaran_belum_terekap
   * @param id id of pembayaran_belum_terekap
   * @return successful operation
   */
  deletePenagihanReportPembayaranBelumTerekapsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportPembayaranBelumTerekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PembayaranBelumTerekapService {

  /**
   * Parameters for getPenagihanReportPembayaranBelumTerekaps
   */
  export interface GetPenagihanReportPembayaranBelumTerekapsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pembayaran_belum_terekap
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportPembayaranBelumTerekapsId
   */
  export interface PutPenagihanReportPembayaranBelumTerekapsIdParams {

    /**
     * id of pembayaran_belum_terekap
     */
    id: number;

    /**
     * pembayaran_belum_terekap that should be updated
     */
    body?: Pembayaran_belum_terekap;
  }
}

export { PembayaranBelumTerekapService }
