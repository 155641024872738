/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrPegawaiStatus } from '../models/hr-pegawai-status';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrPegawaiStatusService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrPegawaiStatusList
   */
  static readonly GetHrPegawaiStatusListPath = '/hr/pegawai_statuses';

  /**
   * getpegawai_statusList.
   *
   * Get all pegawai_statuses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiStatusList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiStatus>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiStatusService.GetHrPegawaiStatusListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrPegawaiStatus>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_statusList.
   *
   * Get all pegawai_statuses
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiStatusList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrPegawaiStatus>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrPegawaiStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiStatus>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrPegawaiStatus>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrPegawaiStatus
   */
  static readonly StoreHrPegawaiStatusPath = '/hr/pegawai_statuses';

  /**
   * createpegawai_status.
   *
   * Create pegawai_status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrPegawaiStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiStatus$Response(params: {
    body: HrPegawaiStatus
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiStatusService.StoreHrPegawaiStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiStatus;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_status.
   *
   * Create pegawai_status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrPegawaiStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiStatus(params: {
    body: HrPegawaiStatus
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}> {

    return this.storeHrPegawaiStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrPegawaiStatusId
   */
  static readonly GetHrPegawaiStatusIdPath = '/hr/pegawai_statuses/{id}';

  /**
   * getpegawai_statusItem.
   *
   * Get pegawai_status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiStatusId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiStatusId$Response(params: {

    /**
     * id of pegawai_status
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiStatusService.GetHrPegawaiStatusIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiStatus;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_statusItem.
   *
   * Get pegawai_status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiStatusId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiStatusId(params: {

    /**
     * id of pegawai_status
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}> {

    return this.getHrPegawaiStatusId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrPegawaiStatusId
   */
  static readonly PutHrPegawaiStatusIdPath = '/hr/pegawai_statuses/{id}';

  /**
   * updatepegawai_status.
   *
   * Update pegawai_status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrPegawaiStatusId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiStatusId$Response(params: {

    /**
     * id of pegawai_status
     */
    id: string;
    body: HrPegawaiStatus
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiStatusService.PutHrPegawaiStatusIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiStatus;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_status.
   *
   * Update pegawai_status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrPegawaiStatusId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiStatusId(params: {

    /**
     * id of pegawai_status
     */
    id: string;
    body: HrPegawaiStatus
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}> {

    return this.putHrPegawaiStatusId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiStatus;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrPegawaiStatusesId
   */
  static readonly DeleteHrPegawaiStatusesIdPath = '/hr/pegawai_statuses/{id}';

  /**
   * deletepegawai_status.
   *
   * Delete pegawai_status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrPegawaiStatusesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiStatusesId$Response(params: {

    /**
     * id of pegawai_status
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiStatusService.DeleteHrPegawaiStatusesIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_status.
   *
   * Delete pegawai_status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrPegawaiStatusesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiStatusesId(params: {

    /**
     * id of pegawai_status
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrPegawaiStatusesId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
