/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWidgetPegawaiGolongan } from '../models/hr-report-widget-pegawai-golongan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWidgetPegawaiGolonganService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiGolonganList
   */
  static readonly GetHrReportWidgetPegawaiGolonganListPath = '/hrReportWidget/pegawai_golongans';

  /**
   * getpegawai_golonganList.
   *
   * Get all pegawai_golongans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiGolonganList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiGolonganList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiGolongan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiGolonganService.GetHrReportWidgetPegawaiGolonganListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWidgetPegawaiGolongan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_golonganList.
   *
   * Get all pegawai_golongans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiGolonganList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiGolonganList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiGolongan>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWidgetPegawaiGolonganList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiGolongan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiGolongan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWidgetPegawaiGolongan
   */
  static readonly StoreHrReportWidgetPegawaiGolonganPath = '/hrReportWidget/pegawai_golongans';

  /**
   * createpegawai_golongan.
   *
   * Create pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWidgetPegawaiGolongan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiGolongan$Response(params: {
    body: HrReportWidgetPegawaiGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiGolonganService.StoreHrReportWidgetPegawaiGolonganPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_golongan.
   *
   * Create pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWidgetPegawaiGolongan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiGolongan(params: {
    body: HrReportWidgetPegawaiGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}> {

    return this.storeHrReportWidgetPegawaiGolongan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiGolonganId
   */
  static readonly GetHrReportWidgetPegawaiGolonganIdPath = '/hrReportWidget/pegawai_golongans/{id}';

  /**
   * getpegawai_golonganItem.
   *
   * Get pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiGolonganId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiGolonganId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiGolonganService.GetHrReportWidgetPegawaiGolonganIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_golonganItem.
   *
   * Get pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiGolonganId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiGolonganId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}> {

    return this.getHrReportWidgetPegawaiGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWidgetPegawaiGolonganId
   */
  static readonly PutHrReportWidgetPegawaiGolonganIdPath = '/hrReportWidget/pegawai_golongans/{id}';

  /**
   * updatepegawai_golongan.
   *
   * Update pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWidgetPegawaiGolonganId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiGolonganId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
    body: HrReportWidgetPegawaiGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiGolonganService.PutHrReportWidgetPegawaiGolonganIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_golongan.
   *
   * Update pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWidgetPegawaiGolonganId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiGolonganId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
    body: HrReportWidgetPegawaiGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}> {

    return this.putHrReportWidgetPegawaiGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWidgetPegawaiGolongansId
   */
  static readonly DeleteHrReportWidgetPegawaiGolongansIdPath = '/hrReportWidget/pegawai_golongans/{id}';

  /**
   * deletepegawai_golongan.
   *
   * Delete pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWidgetPegawaiGolongansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiGolongansId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiGolonganService.DeleteHrReportWidgetPegawaiGolongansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_golongan.
   *
   * Delete pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWidgetPegawaiGolongansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiGolongansId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWidgetPegawaiGolongansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
