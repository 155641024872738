/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Neraca_piutang } from '../models/neraca-_piutang';
@Injectable({
  providedIn: 'root',
})
class NeracaPiutangService extends __BaseService {
  static readonly getReportNeracaPiutangsPath = '/report/neracaPiutangs';
  static readonly postReportNeracaPiutangsPath = '/report/neracaPiutangs';
  static readonly getReportNeracaPiutangsIdPath = '/report/neracaPiutangs/{id}';
  static readonly putReportNeracaPiutangsIdPath = '/report/neracaPiutangs/{id}';
  static readonly deleteReportNeracaPiutangsIdPath = '/report/neracaPiutangs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the neraca_piutang.
   *
   * Get all neraca_piutang
   * @param params The `NeracaPiutangService.GetReportNeracaPiutangsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter neraca_piutang
   *
   * @return successful operation
   */
  getReportNeracaPiutangsResponse(params: NeracaPiutangService.GetReportNeracaPiutangsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Neraca_piutang>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/neracaPiutangs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Neraca_piutang>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the neraca_piutang.
   *
   * Get all neraca_piutang
   * @param params The `NeracaPiutangService.GetReportNeracaPiutangsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter neraca_piutang
   *
   * @return successful operation
   */
  getReportNeracaPiutangs(params: NeracaPiutangService.GetReportNeracaPiutangsParams): __Observable<{success?: boolean, data?: Array<Neraca_piutang>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportNeracaPiutangsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Neraca_piutang>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created neraca_piutang in storage
   *
   * Store neraca_piutang
   * @param body neraca_piutang that should be stored
   * @return successful operation
   */
  postReportNeracaPiutangsResponse(body?: Neraca_piutang): __Observable<__StrictHttpResponse<{success?: boolean, data?: Neraca_piutang, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report/neracaPiutangs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Neraca_piutang, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created neraca_piutang in storage
   *
   * Store neraca_piutang
   * @param body neraca_piutang that should be stored
   * @return successful operation
   */
  postReportNeracaPiutangs(body?: Neraca_piutang): __Observable<{success?: boolean, data?: Neraca_piutang, message?: string}> {
    return this.postReportNeracaPiutangsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Neraca_piutang, message?: string})
    );
  }

  /**
   * Display the specified neraca_piutang
   *
   * Get neraca_piutang
   * @param id id of neraca_piutang
   * @return successful operation
   */
  getReportNeracaPiutangsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Neraca_piutang, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/neracaPiutangs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Neraca_piutang, message?: string}>;
      })
    );
  }
  /**
   * Display the specified neraca_piutang
   *
   * Get neraca_piutang
   * @param id id of neraca_piutang
   * @return successful operation
   */
  getReportNeracaPiutangsId(id: number): __Observable<{success?: boolean, data?: Neraca_piutang, message?: string}> {
    return this.getReportNeracaPiutangsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Neraca_piutang, message?: string})
    );
  }

  /**
   * Update the specified neraca_piutang in storage
   *
   * Update neraca_piutang
   * @param params The `NeracaPiutangService.PutReportNeracaPiutangsIdParams` containing the following parameters:
   *
   * - `id`: id of neraca_piutang
   *
   * - `body`: neraca_piutang that should be updated
   *
   * @return successful operation
   */
  putReportNeracaPiutangsIdResponse(params: NeracaPiutangService.PutReportNeracaPiutangsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Neraca_piutang, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/report/neracaPiutangs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Neraca_piutang, message?: string}>;
      })
    );
  }
  /**
   * Update the specified neraca_piutang in storage
   *
   * Update neraca_piutang
   * @param params The `NeracaPiutangService.PutReportNeracaPiutangsIdParams` containing the following parameters:
   *
   * - `id`: id of neraca_piutang
   *
   * - `body`: neraca_piutang that should be updated
   *
   * @return successful operation
   */
  putReportNeracaPiutangsId(params: NeracaPiutangService.PutReportNeracaPiutangsIdParams): __Observable<{success?: boolean, data?: Neraca_piutang, message?: string}> {
    return this.putReportNeracaPiutangsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Neraca_piutang, message?: string})
    );
  }

  /**
   * Remove the specified neraca_piutang from storage
   *
   * Delete neraca_piutang
   * @param id id of neraca_piutang
   * @return successful operation
   */
  deleteReportNeracaPiutangsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/report/neracaPiutangs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified neraca_piutang from storage
   *
   * Delete neraca_piutang
   * @param id id of neraca_piutang
   * @return successful operation
   */
  deleteReportNeracaPiutangsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportNeracaPiutangsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module NeracaPiutangService {

  /**
   * Parameters for getReportNeracaPiutangs
   */
  export interface GetReportNeracaPiutangsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter neraca_piutang
     */
    filter?: string;
  }

  /**
   * Parameters for putReportNeracaPiutangsId
   */
  export interface PutReportNeracaPiutangsIdParams {

    /**
     * id of neraca_piutang
     */
    id: number;

    /**
     * neraca_piutang that should be updated
     */
    body?: Neraca_piutang;
  }
}

export { NeracaPiutangService }
