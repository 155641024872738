import { filter } from 'rxjs/operators';
import { AddDataPelangganComponent } from './../add-data-pelanggan/add-data-pelanggan.component';
import { EditDataPelangganComponent } from './../edit-data-pelanggan/edit-data-pelanggan.component';
import { Component, Input, OnInit } from '@angular/core';
import { BlokService, CabangService, PelangganService, WilayahService } from 'src/app/sdk/pelanggan/services';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { PesanService } from 'src/app/services/pesan.service';
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { CekroleService } from 'src/app/components/_role/cekrole.service';

@Component({
    selector: 'app-list-data-pelanggan',
    templateUrl: './list-data-pelanggan.component.html',
    styleUrls: ['./list-data-pelanggan.component.scss']
})
export class ListDataPelangganComponent implements OnInit {

    @Input('status') status: string = null;
    user: any = {}
    constructor(
        private pelangganService: PelangganService,
        private nzMessageService: NzMessageService,
        private cabangService: CabangService,
        private wilayahService: WilayahService,
        private blokService: BlokService,
        private drawerService: NzDrawerService,
        private cekroleService: CekroleService,
        private store: Store<any>
    ) {
        this.store.pipe(select(Reducers.getUser)).subscribe(state => {
            this.user = state

        })
    }

    ngOnInit(): void {
        this.getCabang()
    }

    id_cabang = null
    id_wilayah = null
    id_blok = null
    tipe_pelanggan = null
    listCabang: any[] = []
    getCabang() {
        let params = {}
        this.cabangService.getPelangganCabangs(params).subscribe(
            data => {
                this.listCabang = data.data
            }
        )
    }

    listWilayah: any[] = []
    getWilayah() {
        this.searchData()
        let params = { filter: JSON.stringify({ id_cabang: this.id_cabang }) }
        this.wilayahService.getPelangganWilayahs(params).subscribe(
            data => {
                this.listWilayah = data.data
            }
        )
    }

    listBlok: any[] = []
    getBlok() {
        this.searchData()
        let params = { filter: JSON.stringify({ id_wilayah: this.id_wilayah }) }
        this.blokService.getPelangganBloks(params).subscribe(
            data => {
                this.listBlok = data.data
            }
        )
    }

    total = 1;
    listOfData: any[] = [];
    loading = true;
    pageSize = 50;
    pageIndex = 1;
    sortKey = null
    sortValue = null
    search: string = ''
    currentSort: any = {}
    searchData(): void {
        this.loading = true;
        let params = {
            page: this.pageIndex,
            pagesize: this.pageSize,
            sortKey: this.sortKey,
            sortValue: this.sortValue,
            search: this.search,
            filter: JSON.stringify({
                status: this.status,
                id_cabang: this.id_cabang,
                id_wilayah: this.id_wilayah,
                id_blok: this.id_blok,
                tipe_pelanggan: this.tipe_pelanggan,
            })
        }
        this.pelangganService.getPelangganPelanggans(
            params
        ).subscribe(data => {
            this.loading = false;
            this.total = data.total_data; // mock the total data here
            this.listOfData = data.data;

        });
    }

    delete(id) {
        this.pelangganService.deletePelangganPelanggansId(id).subscribe(
            data => {
                this.searchData()
            }
        )
    }



    onQueryParamsChange(params: NzTableQueryParams): void {
        // console.log(params);
        this.pageIndex = params.pageIndex
        this.pageSize = params.pageSize
        this.currentSort = params.sort.find(item => item.value !== null);
        this.sortKey = (this.currentSort && this.currentSort.key) || null;
        this.sortValue = (this.currentSort && this.currentSort.value) || null;
        this.searchData();
    }


    add(): void {
        if (!this.cekroleService.cek('pelanggan', 'add')) {
            return;
        }
        const drawerRef = this.drawerService.create<AddDataPelangganComponent, {}, string>({
            nzTitle: 'Pelanggan Baru',
            nzContent: AddDataPelangganComponent,
            nzWidth: '800px',
            nzContentParams: {

            }
        });

        drawerRef.afterOpen.subscribe(() => {
            console.log('Drawer(Component) open');
        });

        drawerRef.afterClose.subscribe(data => {
            console.log(data);
            if (typeof data === 'string') {
                this.searchData()
            }
        });
    }


    edit(param: any): void {
        if (!this.cekroleService.cek('pelanggan', 'edit')) {
            return;
        }
        const drawerRef = this.drawerService.create<EditDataPelangganComponent, { id_pelanggan: string }, string>({
            nzTitle: 'Edit  <b>' + param.nama_pelanggan + ' </b> ',
            nzContent: EditDataPelangganComponent,
            nzWidth: '800px',
            nzContentParams: {
                id_pelanggan: param.id_pelanggan
            }
        });

        drawerRef.afterOpen.subscribe(() => {
            console.log('Drawer(Component) open');
        });

        drawerRef.afterClose.subscribe(data => {
            console.log(data);
            if (typeof data === 'string') {
                this.searchData()
            }
        });
    }
}
