/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AbsensiConfigService } from './services/absensi-config.service';
import { AbsensiCutiKategoriService } from './services/absensi-cuti-kategori.service';
import { AbsensiCutiPengajuanService } from './services/absensi-cuti-pengajuan.service';
import { AbsensiCutiPengajuanTanggalService } from './services/absensi-cuti-pengajuan-tanggal.service';
import { AbsensiCutiPengaturanService } from './services/absensi-cuti-pengaturan.service';
import { AbsensiCutiPengaturanPerpegawaiService } from './services/absensi-cuti-pengaturan-perpegawai.service';
import { AbsensiJadwalPegawaiTerjadwalService } from './services/absensi-jadwal-pegawai-terjadwal.service';
import { AbsensiJadwalTipeService } from './services/absensi-jadwal-tipe.service';
import { AbsensiJadwalTipeDetailService } from './services/absensi-jadwal-tipe-detail.service';
import { AbsensiJadwalTipeNormalDetailService } from './services/absensi-jadwal-tipe-normal-detail.service';
import { AbsensiKehadiranService } from './services/absensi-kehadiran.service';
import { AbsensiKehadiranLogService } from './services/absensi-kehadiran-log.service';
import { AbsensiKehadiranMobileAppService } from './services/absensi-kehadiran-mobile-app.service';
import { AbsensiModuleNotifikasiService } from './services/absensi-module-notifikasi.service';
import { AbsensiNotifikasiService } from './services/absensi-notifikasi.service';
import { AbsensiPerjalananDinasService } from './services/absensi-perjalanan-dinas.service';
import { AbsensiPerjalananDinasAnggotaService } from './services/absensi-perjalanan-dinas-anggota.service';
import { AbsensiPerjalananDinasDetailService } from './services/absensi-perjalanan-dinas-detail.service';
import { AbsensiRekapKehadiranService } from './services/absensi-rekap-kehadiran.service';
import { AbsensiReportCutiKategoriService } from './services/absensi-report-cuti-kategori.service';
import { AbsensiReportCutiPengajuanService } from './services/absensi-report-cuti-pengajuan.service';
import { AbsensiReportCutiPengajuanTanggalService } from './services/absensi-report-cuti-pengajuan-tanggal.service';
import { AbsensiReportCutiPengaturanService } from './services/absensi-report-cuti-pengaturan.service';
import { AbsensiReportCutiPengaturanPerpegawaiService } from './services/absensi-report-cuti-pengaturan-perpegawai.service';
import { AbsensiReportJadwalPegawaiTerjadwalService } from './services/absensi-report-jadwal-pegawai-terjadwal.service';
import { AbsensiReportJadwalTipeService } from './services/absensi-report-jadwal-tipe.service';
import { AbsensiReportJadwalTipeDetailService } from './services/absensi-report-jadwal-tipe-detail.service';
import { AbsensiReportJadwalTipeNormalDetailService } from './services/absensi-report-jadwal-tipe-normal-detail.service';
import { AbsensiReportKehadiranService } from './services/absensi-report-kehadiran.service';
import { AbsensiReportKehadiranLogService } from './services/absensi-report-kehadiran-log.service';
import { AbsensiReportKehadiranMobileAppService } from './services/absensi-report-kehadiran-mobile-app.service';
import { AbsensiReportNotifikasiService } from './services/absensi-report-notifikasi.service';
import { AbsensiReportPerjalananDinasService } from './services/absensi-report-perjalanan-dinas.service';
import { AbsensiReportPerjalananDinasAnggotaService } from './services/absensi-report-perjalanan-dinas-anggota.service';
import { AbsensiReportPerjalananDinasDetailService } from './services/absensi-report-perjalanan-dinas-detail.service';
import { AbsensiReportRekapKehadiranService } from './services/absensi-report-rekap-kehadiran.service';
import { AbsensiReportWidgetCutiPengajuanService } from './services/absensi-report-widget-cuti-pengajuan.service';
import { AbsensiReportWidgetCutiPengajuanTanggalService } from './services/absensi-report-widget-cuti-pengajuan-tanggal.service';
import { AbsensiReportWidgetCutiPengajuanTanggalBulananService } from './services/absensi-report-widget-cuti-pengajuan-tanggal-bulanan.service';
import { AbsensiReportWidgetCutiPengajuanTanggalTahunanService } from './services/absensi-report-widget-cuti-pengajuan-tanggal-tahunan.service';
import { AbsensiReportWidgetJadwalPegawaiTerjadwalService } from './services/absensi-report-widget-jadwal-pegawai-terjadwal.service';
import { AbsensiReportWidgetJadwalPegawaiTerjadwalPeriodeService } from './services/absensi-report-widget-jadwal-pegawai-terjadwal-periode.service';
import { AbsensiReportWidgetKehadiranDownloadService } from './services/absensi-report-widget-kehadiran-download.service';
import { AbsensiReportWidgetKehadiranMobileAppService } from './services/absensi-report-widget-kehadiran-mobile-app.service';
import { AbsensiReportWidgetNotifikasiService } from './services/absensi-report-widget-notifikasi.service';
import { AbsensiReportWidgetPerjalananDinasService } from './services/absensi-report-widget-perjalanan-dinas.service';
import { AbsensiReportWidgetPerjalananDinasAnggotaService } from './services/absensi-report-widget-perjalanan-dinas-anggota.service';
import { AbsensiReportWidgetPerjalananDinasDetailService } from './services/absensi-report-widget-perjalanan-dinas-detail.service';
import { AbsensiReportWidgetRekapKehadiranPegawaiService } from './services/absensi-report-widget-rekap-kehadiran-pegawai.service';
import { AuthCurrentUserService } from './services/auth-current-user.service';
import { AuthLoginService } from './services/auth-login.service';
import { LogoutService } from './services/logout.service';
import { RefreshService } from './services/refresh.service';
import { AuthChagePasswordService } from './services/auth-chage-password.service';
import { AuthPasswordResetsService } from './services/auth-password-resets.service';
import { AuthRegisterService } from './services/auth-register.service';
import { ErpDepartementService } from './services/erp-departement.service';
import { ErpDivisiService } from './services/erp-divisi.service';
import { ErpJabatanService } from './services/erp-jabatan.service';
import { ErpKantorService } from './services/erp-kantor.service';
import { ErpPangkatService } from './services/erp-pangkat.service';
import { ErpSectionService } from './services/erp-section.service';
import { ErpReportDepartementService } from './services/erp-report-departement.service';
import { ErpReportDivisiService } from './services/erp-report-divisi.service';
import { ErpReportJabatanService } from './services/erp-report-jabatan.service';
import { ErpReportKantorService } from './services/erp-report-kantor.service';
import { ErpReportPangkatService } from './services/erp-report-pangkat.service';
import { ErpReportSectionService } from './services/erp-report-section.service';
import { HrKalenderPerusahaanService } from './services/hr-kalender-perusahaan.service';
import { HrPegawaiService } from './services/hr-pegawai.service';
import { HrPegawaiBiodataService } from './services/hr-pegawai-biodata.service';
import { HrPegawaiEmergencyService } from './services/hr-pegawai-emergency.service';
import { HrPegawaiGolonganService } from './services/hr-pegawai-golongan.service';
import { HrPegawaiIdcardService } from './services/hr-pegawai-idcard.service';
import { HrPegawaiKantorAksesService } from './services/hr-pegawai-kantor-akses.service';
import { HrPegawaiKeahlianService } from './services/hr-pegawai-keahlian.service';
import { HrPegawaiKeluargaService } from './services/hr-pegawai-keluarga.service';
import { HrPegawaiPendidikanService } from './services/hr-pegawai-pendidikan.service';
import { HrPegawaiPendidikanInformalService } from './services/hr-pegawai-pendidikan-informal.service';
import { HrPegawaiPengalamanService } from './services/hr-pegawai-pengalaman.service';
import { HrPegawaiPosisiService } from './services/hr-pegawai-posisi.service';
import { HrPegawaiSkService } from './services/hr-pegawai-sk.service';
import { HrPegawaiSpService } from './services/hr-pegawai-sp.service';
import { HrPegawaiStatusService } from './services/hr-pegawai-status.service';
import { HrSpTipeService } from './services/hr-sp-tipe.service';
import { HrReportPegawaiService } from './services/hr-report-pegawai.service';
import { HrReportPegawaiBiodataService } from './services/hr-report-pegawai-biodata.service';
import { HrReportPegawaiGolonganService } from './services/hr-report-pegawai-golongan.service';
import { HrReportPegawaiKantorAksesService } from './services/hr-report-pegawai-kantor-akses.service';
import { HrReportPegawaiPosisiService } from './services/hr-report-pegawai-posisi.service';
import { HrReportPegawaiSkService } from './services/hr-report-pegawai-sk.service';
import { HrReportPegawaiSpService } from './services/hr-report-pegawai-sp.service';
import { HrReportVPegawaiAktifService } from './services/hr-report-v-pegawai-aktif.service';
import { HrReportVPegawaiPosisiService } from './services/hr-report-v-pegawai-posisi.service';
import { HrReportWidgetPegawaiService } from './services/hr-report-widget-pegawai.service';
import { HrReportWidgetPegawaiByGenderUmurService } from './services/hr-report-widget-pegawai-by-gender-umur.service';
import { HrReportWidgetPegawaiByGolonganService } from './services/hr-report-widget-pegawai-by-golongan.service';
import { HrReportWidgetPegawaiByKantorService } from './services/hr-report-widget-pegawai-by-kantor.service';
import { HrReportWidgetPegawaiGolonganService } from './services/hr-report-widget-pegawai-golongan.service';
import { HrReportWidgetPegawaiPosisiService } from './services/hr-report-widget-pegawai-posisi.service';
import { HrReportWidgetPegawaiSkService } from './services/hr-report-widget-pegawai-sk.service';
import { HrReportWidgetPegawaiSpService } from './services/hr-report-widget-pegawai-sp.service';
import { HrReportWidgetPegawaiSpssService } from './services/hr-report-widget-pegawai-spss.service';
import { HrReportWigetPegawaiGolonganService } from './services/hr-report-wiget-pegawai-golongan.service';
import { HrReportWigetPegawaiPosisiService } from './services/hr-report-wiget-pegawai-posisi.service';
import { HrReportWigetPegawaiSkService } from './services/hr-report-wiget-pegawai-sk.service';
import { HrReportWigetPegawaiSpService } from './services/hr-report-wiget-pegawai-sp.service';
import { PelangganUsersDeviceService } from './services/pelanggan-users-device.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AbsensiConfigService,
    AbsensiCutiKategoriService,
    AbsensiCutiPengajuanService,
    AbsensiCutiPengajuanTanggalService,
    AbsensiCutiPengaturanService,
    AbsensiCutiPengaturanPerpegawaiService,
    AbsensiJadwalPegawaiTerjadwalService,
    AbsensiJadwalTipeService,
    AbsensiJadwalTipeDetailService,
    AbsensiJadwalTipeNormalDetailService,
    AbsensiKehadiranService,
    AbsensiKehadiranLogService,
    AbsensiKehadiranMobileAppService,
    AbsensiModuleNotifikasiService,
    AbsensiNotifikasiService,
    AbsensiPerjalananDinasService,
    AbsensiPerjalananDinasAnggotaService,
    AbsensiPerjalananDinasDetailService,
    AbsensiRekapKehadiranService,
    AbsensiReportCutiKategoriService,
    AbsensiReportCutiPengajuanService,
    AbsensiReportCutiPengajuanTanggalService,
    AbsensiReportCutiPengaturanService,
    AbsensiReportCutiPengaturanPerpegawaiService,
    AbsensiReportJadwalPegawaiTerjadwalService,
    AbsensiReportJadwalTipeService,
    AbsensiReportJadwalTipeDetailService,
    AbsensiReportJadwalTipeNormalDetailService,
    AbsensiReportKehadiranService,
    AbsensiReportKehadiranLogService,
    AbsensiReportKehadiranMobileAppService,
    AbsensiReportNotifikasiService,
    AbsensiReportPerjalananDinasService,
    AbsensiReportPerjalananDinasAnggotaService,
    AbsensiReportPerjalananDinasDetailService,
    AbsensiReportRekapKehadiranService,
    AbsensiReportWidgetCutiPengajuanService,
    AbsensiReportWidgetCutiPengajuanTanggalService,
    AbsensiReportWidgetCutiPengajuanTanggalBulananService,
    AbsensiReportWidgetCutiPengajuanTanggalTahunanService,
    AbsensiReportWidgetJadwalPegawaiTerjadwalService,
    AbsensiReportWidgetJadwalPegawaiTerjadwalPeriodeService,
    AbsensiReportWidgetKehadiranDownloadService,
    AbsensiReportWidgetKehadiranMobileAppService,
    AbsensiReportWidgetNotifikasiService,
    AbsensiReportWidgetPerjalananDinasService,
    AbsensiReportWidgetPerjalananDinasAnggotaService,
    AbsensiReportWidgetPerjalananDinasDetailService,
    AbsensiReportWidgetRekapKehadiranPegawaiService,
    AuthCurrentUserService,
    AuthLoginService,
    LogoutService,
    RefreshService,
    AuthChagePasswordService,
    AuthPasswordResetsService,
    AuthRegisterService,
    ErpDepartementService,
    ErpDivisiService,
    ErpJabatanService,
    ErpKantorService,
    ErpPangkatService,
    ErpSectionService,
    ErpReportDepartementService,
    ErpReportDivisiService,
    ErpReportJabatanService,
    ErpReportKantorService,
    ErpReportPangkatService,
    ErpReportSectionService,
    HrKalenderPerusahaanService,
    HrPegawaiService,
    HrPegawaiBiodataService,
    HrPegawaiEmergencyService,
    HrPegawaiGolonganService,
    HrPegawaiIdcardService,
    HrPegawaiKantorAksesService,
    HrPegawaiKeahlianService,
    HrPegawaiKeluargaService,
    HrPegawaiPendidikanService,
    HrPegawaiPendidikanInformalService,
    HrPegawaiPengalamanService,
    HrPegawaiPosisiService,
    HrPegawaiSkService,
    HrPegawaiSpService,
    HrPegawaiStatusService,
    HrSpTipeService,
    HrReportPegawaiService,
    HrReportPegawaiBiodataService,
    HrReportPegawaiGolonganService,
    HrReportPegawaiKantorAksesService,
    HrReportPegawaiPosisiService,
    HrReportPegawaiSkService,
    HrReportPegawaiSpService,
    HrReportVPegawaiAktifService,
    HrReportVPegawaiPosisiService,
    HrReportWidgetPegawaiService,
    HrReportWidgetPegawaiByGenderUmurService,
    HrReportWidgetPegawaiByGolonganService,
    HrReportWidgetPegawaiByKantorService,
    HrReportWidgetPegawaiGolonganService,
    HrReportWidgetPegawaiPosisiService,
    HrReportWidgetPegawaiSkService,
    HrReportWidgetPegawaiSpService,
    HrReportWidgetPegawaiSpssService,
    HrReportWigetPegawaiGolonganService,
    HrReportWigetPegawaiPosisiService,
    HrReportWigetPegawaiSkService,
    HrReportWigetPegawaiSpService,
    PelangganUsersDeviceService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
