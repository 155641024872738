/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPermohonanInformasi } from '../models/client-permohonan-informasi';

@Injectable({
  providedIn: 'root',
})
export class ClientPermohonanInformasiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientPermohonanInformasisList
   */
  static readonly GetclientPermohonanInformasisListPath = '/client/permohonanInformasis';

  /**
   * Get list of permohonanInformasis.
   *
   * Returns list of permohonanInformasis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPermohonanInformasisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPermohonanInformasisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPermohonanInformasi>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPermohonanInformasiService.GetclientPermohonanInformasisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPermohonanInformasi>;
        }>;
      })
    );
  }

  /**
   * Get list of permohonanInformasis.
   *
   * Returns list of permohonanInformasis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPermohonanInformasisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPermohonanInformasisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPermohonanInformasi>;
}> {

    return this.getclientPermohonanInformasisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPermohonanInformasi>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPermohonanInformasi>;
})
    );
  }

  /**
   * Path part for operation storeclientPermohonanInformasi
   */
  static readonly StoreclientPermohonanInformasiPath = '/client/permohonanInformasis';

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientPermohonanInformasi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPermohonanInformasi$Response(params: {
    body: ClientPermohonanInformasi
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPermohonanInformasiService.StoreclientPermohonanInformasiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPermohonanInformasi;
        }>;
      })
    );
  }

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientPermohonanInformasi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPermohonanInformasi(params: {
    body: ClientPermohonanInformasi
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}> {

    return this.storeclientPermohonanInformasi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
})
    );
  }

  /**
   * Path part for operation getclientPermohonanInformasiId
   */
  static readonly GetclientPermohonanInformasiIdPath = '/client/permohonanInformasis/{id}';

  /**
   * Get  Permohonan_informasi by id.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPermohonanInformasiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPermohonanInformasiId$Response(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPermohonanInformasiService.GetclientPermohonanInformasiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPermohonanInformasi;
        }>;
      })
    );
  }

  /**
   * Get  Permohonan_informasi by id.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPermohonanInformasiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPermohonanInformasiId(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}> {

    return this.getclientPermohonanInformasiId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
})
    );
  }

  /**
   * Path part for operation putclientPermohonanInformasi
   */
  static readonly PutclientPermohonanInformasiPath = '/client/permohonanInformasis/{id}';

  /**
   * Update Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientPermohonanInformasi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPermohonanInformasi$Response(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
    body: ClientPermohonanInformasi
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPermohonanInformasiService.PutclientPermohonanInformasiPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPermohonanInformasi;
        }>;
      })
    );
  }

  /**
   * Update Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientPermohonanInformasi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPermohonanInformasi(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
    body: ClientPermohonanInformasi
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}> {

    return this.putclientPermohonanInformasi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPermohonanInformasi;
})
    );
  }

  /**
   * Path part for operation deleteclientPermohonanInformasi
   */
  static readonly DeleteclientPermohonanInformasiPath = '/client/permohonanInformasis/{id}';

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientPermohonanInformasi()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPermohonanInformasi$Response(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPermohonanInformasiService.DeleteclientPermohonanInformasiPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientPermohonanInformasi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPermohonanInformasi(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientPermohonanInformasi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
