/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User_akses_penagihan } from '../models/user-_akses-_penagihan';
@Injectable({
  providedIn: 'root',
})
class UserAksesPenagihanService extends __BaseService {
  static readonly getPelangganUserAksesPenagihansPath = '/pelanggan/userAksesPenagihans';
  static readonly postPelangganUserAksesPenagihansPath = '/pelanggan/userAksesPenagihans';
  static readonly getPelangganUserAksesPenagihansIdPath = '/pelanggan/userAksesPenagihans/{id}';
  static readonly putPelangganUserAksesPenagihansIdPath = '/pelanggan/userAksesPenagihans/{id}';
  static readonly deletePelangganUserAksesPenagihansIdPath = '/pelanggan/userAksesPenagihans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the User_akses_penagihan.
   *
   * Get all User_akses_penagihan
   * @param params The `UserAksesPenagihanService.GetPelangganUserAksesPenagihansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter User_akses_penagihan
   *
   * @return successful operation
   */
  getPelangganUserAksesPenagihansResponse(params: UserAksesPenagihanService.GetPelangganUserAksesPenagihansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<User_akses_penagihan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/userAksesPenagihans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<User_akses_penagihan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the User_akses_penagihan.
   *
   * Get all User_akses_penagihan
   * @param params The `UserAksesPenagihanService.GetPelangganUserAksesPenagihansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter User_akses_penagihan
   *
   * @return successful operation
   */
  getPelangganUserAksesPenagihans(params: UserAksesPenagihanService.GetPelangganUserAksesPenagihansParams): __Observable<{success?: boolean, data?: Array<User_akses_penagihan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganUserAksesPenagihansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<User_akses_penagihan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created User_akses_penagihan in storage
   *
   * Store User_akses_penagihan
   * @param body User_akses_penagihan that should be stored
   * @return successful operation
   */
  postPelangganUserAksesPenagihansResponse(body?: User_akses_penagihan): __Observable<__StrictHttpResponse<{success?: boolean, data?: User_akses_penagihan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/userAksesPenagihans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: User_akses_penagihan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created User_akses_penagihan in storage
   *
   * Store User_akses_penagihan
   * @param body User_akses_penagihan that should be stored
   * @return successful operation
   */
  postPelangganUserAksesPenagihans(body?: User_akses_penagihan): __Observable<{success?: boolean, data?: User_akses_penagihan, message?: string}> {
    return this.postPelangganUserAksesPenagihansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: User_akses_penagihan, message?: string})
    );
  }

  /**
   * Display the specified User_akses_penagihan
   *
   * Get User_akses_penagihan
   * @param id id of User_akses_penagihan
   * @return successful operation
   */
  getPelangganUserAksesPenagihansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: User_akses_penagihan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/userAksesPenagihans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: User_akses_penagihan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified User_akses_penagihan
   *
   * Get User_akses_penagihan
   * @param id id of User_akses_penagihan
   * @return successful operation
   */
  getPelangganUserAksesPenagihansId(id: number): __Observable<{success?: boolean, data?: User_akses_penagihan, message?: string}> {
    return this.getPelangganUserAksesPenagihansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: User_akses_penagihan, message?: string})
    );
  }

  /**
   * Update the specified User_akses_penagihan in storage
   *
   * Update User_akses_penagihan
   * @param params The `UserAksesPenagihanService.PutPelangganUserAksesPenagihansIdParams` containing the following parameters:
   *
   * - `id`: id of User_akses_penagihan
   *
   * - `body`: User_akses_penagihan that should be updated
   *
   * @return successful operation
   */
  putPelangganUserAksesPenagihansIdResponse(params: UserAksesPenagihanService.PutPelangganUserAksesPenagihansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: User_akses_penagihan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/userAksesPenagihans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: User_akses_penagihan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified User_akses_penagihan in storage
   *
   * Update User_akses_penagihan
   * @param params The `UserAksesPenagihanService.PutPelangganUserAksesPenagihansIdParams` containing the following parameters:
   *
   * - `id`: id of User_akses_penagihan
   *
   * - `body`: User_akses_penagihan that should be updated
   *
   * @return successful operation
   */
  putPelangganUserAksesPenagihansId(params: UserAksesPenagihanService.PutPelangganUserAksesPenagihansIdParams): __Observable<{success?: boolean, data?: User_akses_penagihan, message?: string}> {
    return this.putPelangganUserAksesPenagihansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: User_akses_penagihan, message?: string})
    );
  }

  /**
   * Remove the specified User_akses_penagihan from storage
   *
   * Delete User_akses_penagihan
   * @param id id of User_akses_penagihan
   * @return successful operation
   */
  deletePelangganUserAksesPenagihansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/userAksesPenagihans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified User_akses_penagihan from storage
   *
   * Delete User_akses_penagihan
   * @param id id of User_akses_penagihan
   * @return successful operation
   */
  deletePelangganUserAksesPenagihansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganUserAksesPenagihansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module UserAksesPenagihanService {

  /**
   * Parameters for getPelangganUserAksesPenagihans
   */
  export interface GetPelangganUserAksesPenagihansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter User_akses_penagihan
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganUserAksesPenagihansId
   */
  export interface PutPelangganUserAksesPenagihansIdParams {

    /**
     * id of User_akses_penagihan
     */
    id: number;

    /**
     * User_akses_penagihan that should be updated
     */
    body?: User_akses_penagihan;
  }
}

export { UserAksesPenagihanService }
