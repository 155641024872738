import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListDataPelangganComponent } from './list-data-pelanggan/list-data-pelanggan.component';
import { EditDataPelangganComponent } from './edit-data-pelanggan/edit-data-pelanggan.component';
import { AddDataPelangganComponent } from './add-data-pelanggan/add-data-pelanggan.component';
import { SharedModule } from 'src/app/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilderTypeSafe } from 'src/app/services/angular-reactive-forms-helper';
import { ListPelangganWidgetComponent } from './list-pelanggan-widget/list-pelanggan-widget.component';

const COM = [
    ListDataPelangganComponent,
    EditDataPelangganComponent,
    AddDataPelangganComponent,
    ListPelangganWidgetComponent
]

@NgModule({
    declarations: [...COM],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        FormBuilderTypeSafe
    ],
    exports: [...COM],
})
export class DataPelangganShareModule { }
