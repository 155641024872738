/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiRekapKehadiran } from '../models/absensi-rekap-kehadiran';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiRekapKehadiranService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiRekapKehadiranList
   */
  static readonly GetAbsensiRekapKehadiranListPath = '/absensi/rekap_kehadirans';

  /**
   * getrekap_kehadiranList.
   *
   * Get all rekap_kehadirans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiRekapKehadiranList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiRekapKehadiranList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiRekapKehadiran>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiRekapKehadiranService.GetAbsensiRekapKehadiranListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiRekapKehadiran>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getrekap_kehadiranList.
   *
   * Get all rekap_kehadirans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiRekapKehadiranList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiRekapKehadiranList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiRekapKehadiran>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiRekapKehadiranList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiRekapKehadiran>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiRekapKehadiran>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiRekapKehadiran
   */
  static readonly StoreAbsensiRekapKehadiranPath = '/absensi/rekap_kehadirans';

  /**
   * createrekap_kehadiran.
   *
   * Create rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiRekapKehadiran()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiRekapKehadiran$Response(params: {
    body: AbsensiRekapKehadiran
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiRekapKehadiranService.StoreAbsensiRekapKehadiranPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiRekapKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createrekap_kehadiran.
   *
   * Create rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiRekapKehadiran$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiRekapKehadiran(params: {
    body: AbsensiRekapKehadiran
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}> {

    return this.storeAbsensiRekapKehadiran$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiRekapKehadiranId
   */
  static readonly GetAbsensiRekapKehadiranIdPath = '/absensi/rekap_kehadirans/{id}';

  /**
   * getrekap_kehadiranItem.
   *
   * Get rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiRekapKehadiranId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiRekapKehadiranId$Response(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiRekapKehadiranService.GetAbsensiRekapKehadiranIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiRekapKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getrekap_kehadiranItem.
   *
   * Get rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiRekapKehadiranId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiRekapKehadiranId(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}> {

    return this.getAbsensiRekapKehadiranId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiRekapKehadiranId
   */
  static readonly PutAbsensiRekapKehadiranIdPath = '/absensi/rekap_kehadirans/{id}';

  /**
   * updaterekap_kehadiran.
   *
   * Update rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiRekapKehadiranId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiRekapKehadiranId$Response(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
    body: AbsensiRekapKehadiran
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiRekapKehadiranService.PutAbsensiRekapKehadiranIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiRekapKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updaterekap_kehadiran.
   *
   * Update rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiRekapKehadiranId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiRekapKehadiranId(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
    body: AbsensiRekapKehadiran
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}> {

    return this.putAbsensiRekapKehadiranId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiRekapKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiRekapKehadiransId
   */
  static readonly DeleteAbsensiRekapKehadiransIdPath = '/absensi/rekap_kehadirans/{id}';

  /**
   * deleterekap_kehadiran.
   *
   * Delete rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiRekapKehadiransId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiRekapKehadiransId$Response(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiRekapKehadiranService.DeleteAbsensiRekapKehadiransIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleterekap_kehadiran.
   *
   * Delete rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiRekapKehadiransId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiRekapKehadiransId(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiRekapKehadiransId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
