/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPengajuanKeberatan } from '../models/client-pengajuan-keberatan';

@Injectable({
  providedIn: 'root',
})
export class ClientPengajuanKeberatanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientPengajuanKeberatansList
   */
  static readonly GetclientPengajuanKeberatansListPath = '/client/pengajuanKeberatans';

  /**
   * Get list of pengajuanKeberatans.
   *
   * Returns list of pengajuanKeberatans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengajuanKeberatansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengajuanKeberatansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengajuanKeberatan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengajuanKeberatanService.GetclientPengajuanKeberatansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPengajuanKeberatan>;
        }>;
      })
    );
  }

  /**
   * Get list of pengajuanKeberatans.
   *
   * Returns list of pengajuanKeberatans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengajuanKeberatansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengajuanKeberatansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengajuanKeberatan>;
}> {

    return this.getclientPengajuanKeberatansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengajuanKeberatan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengajuanKeberatan>;
})
    );
  }

  /**
   * Path part for operation storeclientPengajuanKeberatan
   */
  static readonly StoreclientPengajuanKeberatanPath = '/client/pengajuanKeberatans';

  /**
   * Store new Pengajuan_keberatan.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientPengajuanKeberatan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengajuanKeberatan$Response(params: {
    body: ClientPengajuanKeberatan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengajuanKeberatanService.StoreclientPengajuanKeberatanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengajuanKeberatan;
        }>;
      })
    );
  }

  /**
   * Store new Pengajuan_keberatan.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientPengajuanKeberatan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengajuanKeberatan(params: {
    body: ClientPengajuanKeberatan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}> {

    return this.storeclientPengajuanKeberatan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
})
    );
  }

  /**
   * Path part for operation getclientPengajuanKeberatanId
   */
  static readonly GetclientPengajuanKeberatanIdPath = '/client/pengajuanKeberatans/{id}';

  /**
   * Get  Pengajuan_keberatan by id.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengajuanKeberatanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengajuanKeberatanId$Response(params: {

    /**
     * id of Pengajuan_keberatan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengajuanKeberatanService.GetclientPengajuanKeberatanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengajuanKeberatan;
        }>;
      })
    );
  }

  /**
   * Get  Pengajuan_keberatan by id.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengajuanKeberatanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengajuanKeberatanId(params: {

    /**
     * id of Pengajuan_keberatan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}> {

    return this.getclientPengajuanKeberatanId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
})
    );
  }

  /**
   * Path part for operation putclientPengajuanKeberatan
   */
  static readonly PutclientPengajuanKeberatanPath = '/client/pengajuanKeberatans/{id}';

  /**
   * Update Pengajuan_keberatan.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientPengajuanKeberatan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPengajuanKeberatan$Response(params: {

    /**
     * id of Pengajuan_keberatan
     */
    id: string;
    body: ClientPengajuanKeberatan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengajuanKeberatanService.PutclientPengajuanKeberatanPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengajuanKeberatan;
        }>;
      })
    );
  }

  /**
   * Update Pengajuan_keberatan.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientPengajuanKeberatan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientPengajuanKeberatan(params: {

    /**
     * id of Pengajuan_keberatan
     */
    id: string;
    body: ClientPengajuanKeberatan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}> {

    return this.putclientPengajuanKeberatan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengajuanKeberatan;
})
    );
  }

  /**
   * Path part for operation deleteclientPengajuanKeberatan
   */
  static readonly DeleteclientPengajuanKeberatanPath = '/client/pengajuanKeberatans/{id}';

  /**
   * Store new Pengajuan_keberatan.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientPengajuanKeberatan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPengajuanKeberatan$Response(params: {

    /**
     * id of Pengajuan_keberatan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengajuanKeberatanService.DeleteclientPengajuanKeberatanPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Pengajuan_keberatan.
   *
   * Returns Pengajuan_keberatan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientPengajuanKeberatan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientPengajuanKeberatan(params: {

    /**
     * id of Pengajuan_keberatan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientPengajuanKeberatan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
