import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store'
import { PesanService } from 'src/app/services/pesan.service';
import * as Reducers from 'src/app/store/reducers'

@Injectable({
    providedIn: 'root'
})
export class CekroleService {

    constructor(
        private store: Store,
        private pesanService: PesanService,
    ) {
        this.store.pipe(select(Reducers.getUser)).subscribe(state => {
            this.authorized = state.authorized
            this.roles = state.role
            console.log(state)

        })
    }

    authorized = false
    roles = null

    cek(module, action) {
        console.log(module)
        if (module) {

            let role = this.roles.find(m => (m.name_module == module && m.module_detail == action));
            console.log(role)
            if (role) {
                if (role.access === 'true') {
                    return true
                }

            }

            this.pesanService.pesanWarningForm('Anda tidak memiliki akses #' + module + ' #' + action);
            return false
        }

        return true
    }
}
