/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportJadwalTipeDetail } from '../models/absensi-report-jadwal-tipe-detail';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportJadwalTipeDetailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportJadwalTipeDetailList
   */
  static readonly GetAbsensiReportJadwalTipeDetailListPath = '/absensiReport/jadwal_tipe_details';

  /**
   * getjadwal_tipe_detailList.
   *
   * Get all jadwal_tipe_details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportJadwalTipeDetailList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeDetailList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeDetail>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeDetailService.GetAbsensiReportJadwalTipeDetailListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportJadwalTipeDetail>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getjadwal_tipe_detailList.
   *
   * Get all jadwal_tipe_details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportJadwalTipeDetailList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeDetailList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeDetail>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportJadwalTipeDetailList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeDetail>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportJadwalTipeDetail>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportJadwalTipeDetail
   */
  static readonly StoreAbsensiReportJadwalTipeDetailPath = '/absensiReport/jadwal_tipe_details';

  /**
   * createjadwal_tipe_detail.
   *
   * Create jadwal_tipe_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportJadwalTipeDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportJadwalTipeDetail$Response(params: {
    body: AbsensiReportJadwalTipeDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeDetailService.StoreAbsensiReportJadwalTipeDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportJadwalTipeDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createjadwal_tipe_detail.
   *
   * Create jadwal_tipe_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportJadwalTipeDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportJadwalTipeDetail(params: {
    body: AbsensiReportJadwalTipeDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}> {

    return this.storeAbsensiReportJadwalTipeDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportJadwalTipeDetailId
   */
  static readonly GetAbsensiReportJadwalTipeDetailIdPath = '/absensiReport/jadwal_tipe_details/{id}';

  /**
   * getjadwal_tipe_detailItem.
   *
   * Get jadwal_tipe_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportJadwalTipeDetailId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeDetailId$Response(params: {

    /**
     * id of jadwal_tipe_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeDetailService.GetAbsensiReportJadwalTipeDetailIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportJadwalTipeDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getjadwal_tipe_detailItem.
   *
   * Get jadwal_tipe_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportJadwalTipeDetailId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportJadwalTipeDetailId(params: {

    /**
     * id of jadwal_tipe_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}> {

    return this.getAbsensiReportJadwalTipeDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportJadwalTipeDetailId
   */
  static readonly PutAbsensiReportJadwalTipeDetailIdPath = '/absensiReport/jadwal_tipe_details/{id}';

  /**
   * updatejadwal_tipe_detail.
   *
   * Update jadwal_tipe_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportJadwalTipeDetailId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportJadwalTipeDetailId$Response(params: {

    /**
     * id of jadwal_tipe_detail
     */
    id: string;
    body: AbsensiReportJadwalTipeDetail
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeDetailService.PutAbsensiReportJadwalTipeDetailIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportJadwalTipeDetail;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatejadwal_tipe_detail.
   *
   * Update jadwal_tipe_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportJadwalTipeDetailId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportJadwalTipeDetailId(params: {

    /**
     * id of jadwal_tipe_detail
     */
    id: string;
    body: AbsensiReportJadwalTipeDetail
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}> {

    return this.putAbsensiReportJadwalTipeDetailId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportJadwalTipeDetail;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportJadwalTipeDetailsId
   */
  static readonly DeleteAbsensiReportJadwalTipeDetailsIdPath = '/absensiReport/jadwal_tipe_details/{id}';

  /**
   * deletejadwal_tipe_detail.
   *
   * Delete jadwal_tipe_detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportJadwalTipeDetailsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportJadwalTipeDetailsId$Response(params: {

    /**
     * id of jadwal_tipe_detail
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportJadwalTipeDetailService.DeleteAbsensiReportJadwalTipeDetailsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletejadwal_tipe_detail.
   *
   * Delete jadwal_tipe_detail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportJadwalTipeDetailsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportJadwalTipeDetailsId(params: {

    /**
     * id of jadwal_tipe_detail
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportJadwalTipeDetailsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
