import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { getMenuData } from './config'

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    constructor() { }

    menu = new BehaviorSubject<any>(null);
    getMenuData(): Observable<any[]> {
        return this.menu
    }
    setMenu(menu) {
        this.menu.next(menu)
    }
}
