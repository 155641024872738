/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pendapatan_by_unit_rekap } from '../models/pendapatan-_by-_unit-_rekap';
@Injectable({
  providedIn: 'root',
})
class PendapatanByUnitRekapService extends __BaseService {
  static readonly getReportPendapatanByUnitRekapsPath = '/report/pendapatanByUnitRekaps';
  static readonly postReportPendapatanByUnitRekapsPath = '/report/pendapatanByUnitRekaps';
  static readonly getReportPendapatanByUnitRekapsIdPath = '/report/pendapatanByUnitRekaps/{id}';
  static readonly putReportPendapatanByUnitRekapsIdPath = '/report/pendapatanByUnitRekaps/{id}';
  static readonly deleteReportPendapatanByUnitRekapsIdPath = '/report/pendapatanByUnitRekaps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the pendapatan_by_unit_rekap.
   *
   * Get all pendapatan_by_unit_rekap
   * @param params The `PendapatanByUnitRekapService.GetReportPendapatanByUnitRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pendapatan_by_unit_rekap
   *
   * @return successful operation
   */
  getReportPendapatanByUnitRekapsResponse(params: PendapatanByUnitRekapService.GetReportPendapatanByUnitRekapsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pendapatan_by_unit_rekap>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/pendapatanByUnitRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pendapatan_by_unit_rekap>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pendapatan_by_unit_rekap.
   *
   * Get all pendapatan_by_unit_rekap
   * @param params The `PendapatanByUnitRekapService.GetReportPendapatanByUnitRekapsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pendapatan_by_unit_rekap
   *
   * @return successful operation
   */
  getReportPendapatanByUnitRekaps(params: PendapatanByUnitRekapService.GetReportPendapatanByUnitRekapsParams): __Observable<{success?: boolean, data?: Array<Pendapatan_by_unit_rekap>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportPendapatanByUnitRekapsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pendapatan_by_unit_rekap>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pendapatan_by_unit_rekap in storage
   *
   * Store pendapatan_by_unit_rekap
   * @param body pendapatan_by_unit_rekap that should be stored
   * @return successful operation
   */
  postReportPendapatanByUnitRekapsResponse(body?: Pendapatan_by_unit_rekap): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report/pendapatanByUnitRekaps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pendapatan_by_unit_rekap in storage
   *
   * Store pendapatan_by_unit_rekap
   * @param body pendapatan_by_unit_rekap that should be stored
   * @return successful operation
   */
  postReportPendapatanByUnitRekaps(body?: Pendapatan_by_unit_rekap): __Observable<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}> {
    return this.postReportPendapatanByUnitRekapsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string})
    );
  }

  /**
   * Display the specified pendapatan_by_unit_rekap
   *
   * Get pendapatan_by_unit_rekap
   * @param id id of pendapatan_by_unit_rekap
   * @return successful operation
   */
  getReportPendapatanByUnitRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/pendapatanByUnitRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pendapatan_by_unit_rekap
   *
   * Get pendapatan_by_unit_rekap
   * @param id id of pendapatan_by_unit_rekap
   * @return successful operation
   */
  getReportPendapatanByUnitRekapsId(id: number): __Observable<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}> {
    return this.getReportPendapatanByUnitRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string})
    );
  }

  /**
   * Update the specified pendapatan_by_unit_rekap in storage
   *
   * Update pendapatan_by_unit_rekap
   * @param params The `PendapatanByUnitRekapService.PutReportPendapatanByUnitRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of pendapatan_by_unit_rekap
   *
   * - `body`: pendapatan_by_unit_rekap that should be updated
   *
   * @return successful operation
   */
  putReportPendapatanByUnitRekapsIdResponse(params: PendapatanByUnitRekapService.PutReportPendapatanByUnitRekapsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/report/pendapatanByUnitRekaps/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pendapatan_by_unit_rekap in storage
   *
   * Update pendapatan_by_unit_rekap
   * @param params The `PendapatanByUnitRekapService.PutReportPendapatanByUnitRekapsIdParams` containing the following parameters:
   *
   * - `id`: id of pendapatan_by_unit_rekap
   *
   * - `body`: pendapatan_by_unit_rekap that should be updated
   *
   * @return successful operation
   */
  putReportPendapatanByUnitRekapsId(params: PendapatanByUnitRekapService.PutReportPendapatanByUnitRekapsIdParams): __Observable<{success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string}> {
    return this.putReportPendapatanByUnitRekapsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pendapatan_by_unit_rekap, message?: string})
    );
  }

  /**
   * Remove the specified pendapatan_by_unit_rekap from storage
   *
   * Delete pendapatan_by_unit_rekap
   * @param id id of pendapatan_by_unit_rekap
   * @return successful operation
   */
  deleteReportPendapatanByUnitRekapsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/report/pendapatanByUnitRekaps/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pendapatan_by_unit_rekap from storage
   *
   * Delete pendapatan_by_unit_rekap
   * @param id id of pendapatan_by_unit_rekap
   * @return successful operation
   */
  deleteReportPendapatanByUnitRekapsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportPendapatanByUnitRekapsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PendapatanByUnitRekapService {

  /**
   * Parameters for getReportPendapatanByUnitRekaps
   */
  export interface GetReportPendapatanByUnitRekapsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pendapatan_by_unit_rekap
     */
    filter?: string;
  }

  /**
   * Parameters for putReportPendapatanByUnitRekapsId
   */
  export interface PutReportPendapatanByUnitRekapsIdParams {

    /**
     * id of pendapatan_by_unit_rekap
     */
    id: number;

    /**
     * pendapatan_by_unit_rekap that should be updated
     */
    body?: Pendapatan_by_unit_rekap;
  }
}

export { PendapatanByUnitRekapService }
