/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tagihan_dspl_by_widget } from '../models/tagihan-_dspl-_by-_widget';
@Injectable({
  providedIn: 'root',
})
class TagihanDsplByWidgetService extends __BaseService {
  static readonly getPelangganReportTagihanDsplByWidgetsPath = '/pelangganReport/tagihanDsplByWidgets';
  static readonly postPelangganReportTagihanDsplByWidgetsPath = '/pelangganReport/tagihanDsplByWidgets';
  static readonly getPelangganReportTagihanDsplByWidgetsIdPath = '/pelangganReport/tagihanDsplByWidgets/{id}';
  static readonly putPelangganReportTagihanDsplByWidgetsIdPath = '/pelangganReport/tagihanDsplByWidgets/{id}';
  static readonly deletePelangganReportTagihanDsplByWidgetsIdPath = '/pelangganReport/tagihanDsplByWidgets/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Tagihan_dspl_by_widget.
   *
   * Get all Tagihan_dspl_by_widget
   * @param params The `TagihanDsplByWidgetService.GetPelangganReportTagihanDsplByWidgetsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan_dspl_by_widget
   *
   * @return successful operation
   */
  getPelangganReportTagihanDsplByWidgetsResponse(params: TagihanDsplByWidgetService.GetPelangganReportTagihanDsplByWidgetsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Tagihan_dspl_by_widget>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tagihanDsplByWidgets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Tagihan_dspl_by_widget>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Tagihan_dspl_by_widget.
   *
   * Get all Tagihan_dspl_by_widget
   * @param params The `TagihanDsplByWidgetService.GetPelangganReportTagihanDsplByWidgetsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tagihan_dspl_by_widget
   *
   * @return successful operation
   */
  getPelangganReportTagihanDsplByWidgets(params: TagihanDsplByWidgetService.GetPelangganReportTagihanDsplByWidgetsParams): __Observable<{success?: boolean, data?: Array<Tagihan_dspl_by_widget>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportTagihanDsplByWidgetsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Tagihan_dspl_by_widget>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Tagihan_dspl_by_widget in storage
   *
   * Store Tagihan_dspl_by_widget
   * @param body Tagihan_dspl_by_widget that should be stored
   * @return successful operation
   */
  postPelangganReportTagihanDsplByWidgetsResponse(body?: Tagihan_dspl_by_widget): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/tagihanDsplByWidgets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Tagihan_dspl_by_widget in storage
   *
   * Store Tagihan_dspl_by_widget
   * @param body Tagihan_dspl_by_widget that should be stored
   * @return successful operation
   */
  postPelangganReportTagihanDsplByWidgets(body?: Tagihan_dspl_by_widget): __Observable<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}> {
    return this.postPelangganReportTagihanDsplByWidgetsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan_dspl_by_widget, message?: string})
    );
  }

  /**
   * Display the specified Tagihan_dspl_by_widget
   *
   * Get Tagihan_dspl_by_widget
   * @param id id of Tagihan_dspl_by_widget
   * @return successful operation
   */
  getPelangganReportTagihanDsplByWidgetsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tagihanDsplByWidgets/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Tagihan_dspl_by_widget
   *
   * Get Tagihan_dspl_by_widget
   * @param id id of Tagihan_dspl_by_widget
   * @return successful operation
   */
  getPelangganReportTagihanDsplByWidgetsId(id: number): __Observable<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}> {
    return this.getPelangganReportTagihanDsplByWidgetsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan_dspl_by_widget, message?: string})
    );
  }

  /**
   * Update the specified Tagihan_dspl_by_widget in storage
   *
   * Update Tagihan_dspl_by_widget
   * @param params The `TagihanDsplByWidgetService.PutPelangganReportTagihanDsplByWidgetsIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan_dspl_by_widget
   *
   * - `body`: Tagihan_dspl_by_widget that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTagihanDsplByWidgetsIdResponse(params: TagihanDsplByWidgetService.PutPelangganReportTagihanDsplByWidgetsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/tagihanDsplByWidgets/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Tagihan_dspl_by_widget in storage
   *
   * Update Tagihan_dspl_by_widget
   * @param params The `TagihanDsplByWidgetService.PutPelangganReportTagihanDsplByWidgetsIdParams` containing the following parameters:
   *
   * - `id`: id of Tagihan_dspl_by_widget
   *
   * - `body`: Tagihan_dspl_by_widget that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTagihanDsplByWidgetsId(params: TagihanDsplByWidgetService.PutPelangganReportTagihanDsplByWidgetsIdParams): __Observable<{success?: boolean, data?: Tagihan_dspl_by_widget, message?: string}> {
    return this.putPelangganReportTagihanDsplByWidgetsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan_dspl_by_widget, message?: string})
    );
  }

  /**
   * Remove the specified Tagihan_dspl_by_widget from storage
   *
   * Delete Tagihan_dspl_by_widget
   * @param id id of Tagihan_dspl_by_widget
   * @return successful operation
   */
  deletePelangganReportTagihanDsplByWidgetsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/tagihanDsplByWidgets/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Tagihan_dspl_by_widget from storage
   *
   * Delete Tagihan_dspl_by_widget
   * @param id id of Tagihan_dspl_by_widget
   * @return successful operation
   */
  deletePelangganReportTagihanDsplByWidgetsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportTagihanDsplByWidgetsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module TagihanDsplByWidgetService {

  /**
   * Parameters for getPelangganReportTagihanDsplByWidgets
   */
  export interface GetPelangganReportTagihanDsplByWidgetsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Tagihan_dspl_by_widget
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportTagihanDsplByWidgetsId
   */
  export interface PutPelangganReportTagihanDsplByWidgetsIdParams {

    /**
     * id of Tagihan_dspl_by_widget
     */
    id: number;

    /**
     * Tagihan_dspl_by_widget that should be updated
     */
    body?: Tagihan_dspl_by_widget;
  }
}

export { TagihanDsplByWidgetService }
