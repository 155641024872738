/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Voting } from '../models/voting';
@Injectable({
  providedIn: 'root',
})
class VotingService extends __BaseService {
  static readonly getVotingVotingsPath = '/voting/votings';
  static readonly postVotingVotingsPath = '/voting/votings';
  static readonly getVotingVotingsIdPath = '/voting/votings/{id}';
  static readonly putVotingVotingsIdPath = '/voting/votings/{id}';
  static readonly deleteVotingVotingsIdPath = '/voting/votings/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the voting.
   *
   * Get all voting
   * @param params The `VotingService.GetVotingVotingsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter voting
   *
   * @return successful operation
   */
  getVotingVotingsResponse(params: VotingService.GetVotingVotingsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Voting>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/voting/votings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Voting>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the voting.
   *
   * Get all voting
   * @param params The `VotingService.GetVotingVotingsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter voting
   *
   * @return successful operation
   */
  getVotingVotings(params: VotingService.GetVotingVotingsParams): __Observable<{success?: boolean, data?: Array<Voting>, message?: string, total_data?: number, total_page?: number}> {
    return this.getVotingVotingsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Voting>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created voting in storage
   *
   * Store voting
   * @param body voting that should be stored
   * @return successful operation
   */
  postVotingVotingsResponse(body?: Voting): __Observable<__StrictHttpResponse<{success?: boolean, data?: Voting, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/voting/votings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Voting, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created voting in storage
   *
   * Store voting
   * @param body voting that should be stored
   * @return successful operation
   */
  postVotingVotings(body?: Voting): __Observable<{success?: boolean, data?: Voting, message?: string}> {
    return this.postVotingVotingsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Voting, message?: string})
    );
  }

  /**
   * Display the specified voting
   *
   * Get voting
   * @param id id of voting
   * @return successful operation
   */
  getVotingVotingsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Voting, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/voting/votings/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Voting, message?: string}>;
      })
    );
  }
  /**
   * Display the specified voting
   *
   * Get voting
   * @param id id of voting
   * @return successful operation
   */
  getVotingVotingsId(id: number): __Observable<{success?: boolean, data?: Voting, message?: string}> {
    return this.getVotingVotingsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Voting, message?: string})
    );
  }

  /**
   * Update the specified voting in storage
   *
   * Update voting
   * @param params The `VotingService.PutVotingVotingsIdParams` containing the following parameters:
   *
   * - `id`: id of voting
   *
   * - `body`: voting that should be updated
   *
   * @return successful operation
   */
  putVotingVotingsIdResponse(params: VotingService.PutVotingVotingsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Voting, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/voting/votings/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Voting, message?: string}>;
      })
    );
  }
  /**
   * Update the specified voting in storage
   *
   * Update voting
   * @param params The `VotingService.PutVotingVotingsIdParams` containing the following parameters:
   *
   * - `id`: id of voting
   *
   * - `body`: voting that should be updated
   *
   * @return successful operation
   */
  putVotingVotingsId(params: VotingService.PutVotingVotingsIdParams): __Observable<{success?: boolean, data?: Voting, message?: string}> {
    return this.putVotingVotingsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Voting, message?: string})
    );
  }

  /**
   * Remove the specified voting from storage
   *
   * Delete voting
   * @param id id of voting
   * @return successful operation
   */
  deleteVotingVotingsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/voting/votings/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified voting from storage
   *
   * Delete voting
   * @param id id of voting
   * @return successful operation
   */
  deleteVotingVotingsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteVotingVotingsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module VotingService {

  /**
   * Parameters for getVotingVotings
   */
  export interface GetVotingVotingsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter voting
     */
    filter?: string;
  }

  /**
   * Parameters for putVotingVotingsId
   */
  export interface PutVotingVotingsIdParams {

    /**
     * id of voting
     */
    id: number;

    /**
     * voting that should be updated
     */
    body?: Voting;
  }
}

export { VotingService }
