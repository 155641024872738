/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWidgetPegawaiByGenderUmur } from '../models/hr-report-widget-pegawai-by-gender-umur';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWidgetPegawaiByGenderUmurService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiByGenderUmurList
   */
  static readonly GetHrReportWidgetPegawaiByGenderUmurListPath = '/hrReportWidget/pegawai_by_gender_umurs';

  /**
   * getpegawai_by_gender_umurList.
   *
   * Get all pegawai_by_gender_umurs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiByGenderUmurList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGenderUmurList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGenderUmur>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGenderUmurService.GetHrReportWidgetPegawaiByGenderUmurListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWidgetPegawaiByGenderUmur>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_by_gender_umurList.
   *
   * Get all pegawai_by_gender_umurs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiByGenderUmurList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGenderUmurList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGenderUmur>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWidgetPegawaiByGenderUmurList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGenderUmur>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiByGenderUmur>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWidgetPegawaiByGenderUmur
   */
  static readonly StoreHrReportWidgetPegawaiByGenderUmurPath = '/hrReportWidget/pegawai_by_gender_umurs';

  /**
   * createpegawai_by_gender_umur.
   *
   * Create pegawai_by_gender_umur
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWidgetPegawaiByGenderUmur()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiByGenderUmur$Response(params: {
    body: HrReportWidgetPegawaiByGenderUmur
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGenderUmurService.StoreHrReportWidgetPegawaiByGenderUmurPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiByGenderUmur;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_by_gender_umur.
   *
   * Create pegawai_by_gender_umur
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWidgetPegawaiByGenderUmur$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiByGenderUmur(params: {
    body: HrReportWidgetPegawaiByGenderUmur
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}> {

    return this.storeHrReportWidgetPegawaiByGenderUmur$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiByGenderUmurId
   */
  static readonly GetHrReportWidgetPegawaiByGenderUmurIdPath = '/hrReportWidget/pegawai_by_gender_umurs/{id}';

  /**
   * getpegawai_by_gender_umurItem.
   *
   * Get pegawai_by_gender_umur
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiByGenderUmurId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGenderUmurId$Response(params: {

    /**
     * id of pegawai_by_gender_umur
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGenderUmurService.GetHrReportWidgetPegawaiByGenderUmurIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiByGenderUmur;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_by_gender_umurItem.
   *
   * Get pegawai_by_gender_umur
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiByGenderUmurId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiByGenderUmurId(params: {

    /**
     * id of pegawai_by_gender_umur
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}> {

    return this.getHrReportWidgetPegawaiByGenderUmurId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWidgetPegawaiByGenderUmurId
   */
  static readonly PutHrReportWidgetPegawaiByGenderUmurIdPath = '/hrReportWidget/pegawai_by_gender_umurs/{id}';

  /**
   * updatepegawai_by_gender_umur.
   *
   * Update pegawai_by_gender_umur
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWidgetPegawaiByGenderUmurId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiByGenderUmurId$Response(params: {

    /**
     * id of pegawai_by_gender_umur
     */
    id: string;
    body: HrReportWidgetPegawaiByGenderUmur
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGenderUmurService.PutHrReportWidgetPegawaiByGenderUmurIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiByGenderUmur;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_by_gender_umur.
   *
   * Update pegawai_by_gender_umur
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWidgetPegawaiByGenderUmurId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiByGenderUmurId(params: {

    /**
     * id of pegawai_by_gender_umur
     */
    id: string;
    body: HrReportWidgetPegawaiByGenderUmur
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}> {

    return this.putHrReportWidgetPegawaiByGenderUmurId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiByGenderUmur;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWidgetPegawaiByGenderUmursId
   */
  static readonly DeleteHrReportWidgetPegawaiByGenderUmursIdPath = '/hrReportWidget/pegawai_by_gender_umurs/{id}';

  /**
   * deletepegawai_by_gender_umur.
   *
   * Delete pegawai_by_gender_umur
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWidgetPegawaiByGenderUmursId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiByGenderUmursId$Response(params: {

    /**
     * id of pegawai_by_gender_umur
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiByGenderUmurService.DeleteHrReportWidgetPegawaiByGenderUmursIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_by_gender_umur.
   *
   * Delete pegawai_by_gender_umur
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWidgetPegawaiByGenderUmursId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiByGenderUmursId(params: {

    /**
     * id of pegawai_by_gender_umur
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWidgetPegawaiByGenderUmursId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
