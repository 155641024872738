import { PelangganService } from 'src/app/sdk/pelanggan/services';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Pelanggan } from 'src/app/sdk/pelanggan/models';
import { SearchService } from 'src/app/@vb/components/Topbar/Search/search.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-list-pelanggan-widget',
	templateUrl: './list-pelanggan-widget.component.html',
	styleUrls: ['./list-pelanggan-widget.component.scss']
})
export class ListPelangganWidgetComponent implements OnInit {

	@Input('search') search: string = null
	constructor(
		private pelangganService: PelangganService,
		private router: Router,
		private searchService: SearchService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		changes['search'].currentValue;
		this.searchData()
	}

	ngOnInit(): void {
	}



	total = 1;
	listOfData: any[] = [];
	loading = true;
	pageSize = 100;
	pageIndex = 1;
	sortKey = null
	sortValue = null

	currentSort: any = {}
	searchData(): void {
		this.loading = true;
		let param = {
			page: this.pageIndex,
			pagesize: this.pageSize,
			sortKey: this.sortKey,
			sortValue: this.sortValue,
			search: this.search
		}
		this.pelangganService.getPelangganPelanggans(param).subscribe(data => {
			this.loading = false;
			this.total = data.total_data;
			this.listOfData = data.data;
		});
	}

	onQueryParamsChange(params: NzTableQueryParams): void {
		// console.log(params);
		this.pageIndex = params.pageIndex
		this.pageSize = params.pageSize
		this.currentSort = params.sort.find(item => item.value !== null);
		this.sortKey = (this.currentSort && this.currentSort.key) || null;
		this.sortValue = (this.currentSort && this.currentSort.value) || null;
		this.searchData();
	}

	goToPelanggan(pelanggan: Pelanggan) {
		this.searchService.togleSearch.next(false);
		this.router.navigate(['/pelanggan/detail', pelanggan.id_pelanggan])
	}

}
