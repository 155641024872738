/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ErpSection } from '../models/erp-section';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class ErpSectionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getErpSectionList
   */
  static readonly GetErpSectionListPath = '/erp/sections';

  /**
   * getsectionList.
   *
   * Get all sections
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpSectionList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpSectionList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpSection>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpSectionService.GetErpSectionListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<ErpSection>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getsectionList.
   *
   * Get all sections
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpSectionList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpSectionList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<ErpSection>;
'message'?: string;
'total'?: number;
}> {

    return this.getErpSectionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpSection>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<ErpSection>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeErpSection
   */
  static readonly StoreErpSectionPath = '/erp/sections';

  /**
   * createsection.
   *
   * Create section
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeErpSection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpSection$Response(params: {
    body: ErpSection
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpSectionService.StoreErpSectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpSection;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createsection.
   *
   * Create section
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeErpSection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpSection(params: {
    body: ErpSection
  }): Observable<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}> {

    return this.storeErpSection$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getErpSectionId
   */
  static readonly GetErpSectionIdPath = '/erp/sections/{id}';

  /**
   * getsectionItem.
   *
   * Get section
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpSectionId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpSectionId$Response(params: {

    /**
     * id of section
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpSectionService.GetErpSectionIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpSection;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getsectionItem.
   *
   * Get section
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpSectionId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpSectionId(params: {

    /**
     * id of section
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}> {

    return this.getErpSectionId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putErpSectionId
   */
  static readonly PutErpSectionIdPath = '/erp/sections/{id}';

  /**
   * updatesection.
   *
   * Update section
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putErpSectionId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpSectionId$Response(params: {

    /**
     * id of section
     */
    id: string;
    body: ErpSection
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpSectionService.PutErpSectionIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpSection;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatesection.
   *
   * Update section
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putErpSectionId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpSectionId(params: {

    /**
     * id of section
     */
    id: string;
    body: ErpSection
  }): Observable<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}> {

    return this.putErpSectionId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpSection;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteErpSectionsId
   */
  static readonly DeleteErpSectionsIdPath = '/erp/sections/{id}';

  /**
   * deletesection.
   *
   * Delete section
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteErpSectionsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpSectionsId$Response(params: {

    /**
     * id of section
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpSectionService.DeleteErpSectionsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletesection.
   *
   * Delete section
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteErpSectionsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpSectionsId(params: {

    /**
     * id of section
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteErpSectionsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
