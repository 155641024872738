/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiConfig } from '../models/absensi-config';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiConfigService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiConfigList
   */
  static readonly GetAbsensiConfigListPath = '/absensi/configs';

  /**
   * getconfigList.
   *
   * Get all configs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiConfigList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiConfigList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiConfig>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiConfigService.GetAbsensiConfigListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiConfig>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getconfigList.
   *
   * Get all configs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiConfigList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiConfigList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiConfig>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiConfigList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiConfig>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiConfig>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiConfig
   */
  static readonly StoreAbsensiConfigPath = '/absensi/configs';

  /**
   * createconfig.
   *
   * Create config
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiConfig$Response(params: {
    body: AbsensiConfig
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiConfigService.StoreAbsensiConfigPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiConfig;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createconfig.
   *
   * Create config
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiConfig(params: {
    body: AbsensiConfig
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}> {

    return this.storeAbsensiConfig$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiConfigId
   */
  static readonly GetAbsensiConfigIdPath = '/absensi/configs/{id}';

  /**
   * getconfigItem.
   *
   * Get config
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiConfigId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiConfigId$Response(params: {

    /**
     * id of config
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiConfigService.GetAbsensiConfigIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiConfig;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getconfigItem.
   *
   * Get config
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiConfigId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiConfigId(params: {

    /**
     * id of config
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}> {

    return this.getAbsensiConfigId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiConfigId
   */
  static readonly PutAbsensiConfigIdPath = '/absensi/configs/{id}';

  /**
   * updateconfig.
   *
   * Update config
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiConfigId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiConfigId$Response(params: {

    /**
     * id of config
     */
    id: string;
    body: AbsensiConfig
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiConfigService.PutAbsensiConfigIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiConfig;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updateconfig.
   *
   * Update config
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiConfigId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiConfigId(params: {

    /**
     * id of config
     */
    id: string;
    body: AbsensiConfig
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}> {

    return this.putAbsensiConfigId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiConfig;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiConfigsId
   */
  static readonly DeleteAbsensiConfigsIdPath = '/absensi/configs/{id}';

  /**
   * deleteconfig.
   *
   * Delete config
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiConfigsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiConfigsId$Response(params: {

    /**
     * id of config
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiConfigService.DeleteAbsensiConfigsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleteconfig.
   *
   * Delete config
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiConfigsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiConfigsId(params: {

    /**
     * id of config
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiConfigsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
