/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiCutiKategori } from '../models/absensi-cuti-kategori';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiCutiKategoriService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiCutiKategoriList
   */
  static readonly GetAbsensiCutiKategoriListPath = '/absensi/cuti_kategoris';

  /**
   * getcuti_kategoriList.
   *
   * Get all cuti_kategoris
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiCutiKategoriList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiKategoriList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiCutiKategori>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiKategoriService.GetAbsensiCutiKategoriListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiCutiKategori>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getcuti_kategoriList.
   *
   * Get all cuti_kategoris
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiCutiKategoriList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiKategoriList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiCutiKategori>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiCutiKategoriList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiCutiKategori>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiCutiKategori>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiCutiKategori
   */
  static readonly StoreAbsensiCutiKategoriPath = '/absensi/cuti_kategoris';

  /**
   * createcuti_kategori.
   *
   * Create cuti_kategori
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiCutiKategori()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiCutiKategori$Response(params: {
    body: AbsensiCutiKategori
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiKategoriService.StoreAbsensiCutiKategoriPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiKategori;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createcuti_kategori.
   *
   * Create cuti_kategori
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiCutiKategori$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiCutiKategori(params: {
    body: AbsensiCutiKategori
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}> {

    return this.storeAbsensiCutiKategori$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiCutiKategoriId
   */
  static readonly GetAbsensiCutiKategoriIdPath = '/absensi/cuti_kategoris/{id}';

  /**
   * getcuti_kategoriItem.
   *
   * Get cuti_kategori
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiCutiKategoriId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiKategoriId$Response(params: {

    /**
     * id of cuti_kategori
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiKategoriService.GetAbsensiCutiKategoriIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiKategori;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getcuti_kategoriItem.
   *
   * Get cuti_kategori
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiCutiKategoriId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiKategoriId(params: {

    /**
     * id of cuti_kategori
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}> {

    return this.getAbsensiCutiKategoriId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiCutiKategoriId
   */
  static readonly PutAbsensiCutiKategoriIdPath = '/absensi/cuti_kategoris/{id}';

  /**
   * updatecuti_kategori.
   *
   * Update cuti_kategori
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiCutiKategoriId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiCutiKategoriId$Response(params: {

    /**
     * id of cuti_kategori
     */
    id: string;
    body: AbsensiCutiKategori
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiKategoriService.PutAbsensiCutiKategoriIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiKategori;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatecuti_kategori.
   *
   * Update cuti_kategori
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiCutiKategoriId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiCutiKategoriId(params: {

    /**
     * id of cuti_kategori
     */
    id: string;
    body: AbsensiCutiKategori
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}> {

    return this.putAbsensiCutiKategoriId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiKategori;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiCutiKategorisId
   */
  static readonly DeleteAbsensiCutiKategorisIdPath = '/absensi/cuti_kategoris/{id}';

  /**
   * deletecuti_kategori.
   *
   * Delete cuti_kategori
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiCutiKategorisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiCutiKategorisId$Response(params: {

    /**
     * id of cuti_kategori
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiKategoriService.DeleteAbsensiCutiKategorisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletecuti_kategori.
   *
   * Delete cuti_kategori
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiCutiKategorisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiCutiKategorisId(params: {

    /**
     * id of cuti_kategori
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiCutiKategorisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
