<div class="card border-0   text-white mb-1">
    <div class="card-header border-0 card-header-flex bg-none mb-0">
        <div class="d-flex flex-column justify-content-center mr-auto">
            <h4 class="mb-0">PELANGGAN</h4>
        </div>
        <div class="d-flex flex-column justify-content-center">
            <a class="btn btn-primary" (click)="add()" routerLinkActive="router-link-active">
                Add New
            </a>
        </div>
    </div>
    <div class="card-body bg-white m-0 pb-1 pt-1">
        <div class="row align-items-center">
            <div class="col-md-3">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Cabang" [(ngModel)]="id_cabang" style="width: 100%;"
                    (ngModelChange)="getWilayah()">
                    <nz-option *ngFor="let item of listCabang" nzLabel="{{item.nama_cabang}}"
                        [nzValue]="item.id_cabang"></nz-option>
                </nz-select>
            </div>
            <div class="col-md-3">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Wilayah" [(ngModel)]="id_wilayah"
                    style="width: 100%;">
                    <nz-option *ngFor="let item of listWilayah" nzLabel="{{item.nama_wilayah}}"
                        [nzValue]="item.id_wilayah"></nz-option>
                </nz-select>
            </div>
            <div class="col-md-3">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Kategori Sambungan" [(ngModel)]="tipe_pelanggan"
                    style="width: 100%;">
                    <nz-option nzValue="Reguler" nzLabel="Reguler"> </nz-option>
                    <nz-option nzValue="MBR" nzLabel="MBR"> </nz-option>
                    <nz-option nzValue="DAK" nzLabel="DAK"> </nz-option>
                </nz-select>
            </div>
            <div class="col-md-3">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" nz-input placeholder="input search text" (keyup.enter)="searchData()"
                        [(ngModel)]="search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch><i nz-icon nzType="search"
                            (click)="searchData()"></i></button>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="card-footer p-2 border-0 bg-gradient-blue-light"></div>
</div>

<div class="card border-0">
    <div class="card-body p-1">
        <div class="row">

        </div>
        <br />
        <nz-table nzShowSizeChanger [nzData]="listOfData" [nzFrontPagination]="false" [nzLoading]="loading"
            [nzTotal]="total" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" nzSize="default"
            (nzQueryParams)="onQueryParamsChange($event)">
            <thead>
                <tr>
                    <th>No</th>
                    <th nzColumnKey="kd_pelanggan" [nzSortFn]="true">Kode Pelanggan</th>
                    <th nzColumnKey="nama_pelanggan" [nzSortFn]="true">Nama Pelanggan</th>
                    <th>Alamat</th>
                    <th nzColumnKey="telp" [nzSortFn]="true">Telephone</th>
                    <th nzColumnKey="hp" [nzSortFn]="true">Phone</th>
                    <th nzColumnKey="kd_pelanggan_old" [nzSortFn]="true">KD lama</th>
                    <th nzColumnKey="kd_tipe" [nzSortFn]="true" *ngIf="tipe_pelanggan =='MBR'">Kode MBR</th>
                    <th  class="text-right" *ngIf="status=='pending' && user.id_group == 10"> <i nz-icon nzType="delete" nzTheme="outline"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of listOfData; let i= index" style="vertical-align: top; ">
                    <td>{{i + 1 + (pageSize*(pageIndex - 1))}}</td>
                    <td>
                        <a [routerLink]="[ '/pelanggan/detail', data.id_pelanggan ]">
                            <b *ngIf="data.kd_pelanggan">{{ data.kd_pelanggan}}</b>
                            <b *ngIf="!data.kd_pelanggan"><i>{{ data.status}}</i></b>
                        </a>
                    </td>
                    <td>{{data.nama_pelanggan}}</td>
                    <td>{{data.alamat}}</td>
                    <td>{{ data.telp}}</td>
                    <td>{{ data.hp}}</td>
                    <td>{{ data.kd_pelanggan_old}}</td>
                    <td *ngIf="tipe_pelanggan =='MBR'">{{ data.kd_tipe}}</td>
                    <td class="text-right"  class="text-right" *ngIf="status=='pending' && user.id_group == 10">
                        <a nz-popconfirm nzPopconfirmTitle="Are you sure delete this task?"
                            nzPopconfirmPlacement="bottom" (nzOnConfirm)="delete(data.id_pelanggan)">
                            <button nz-button nzType="danger" nzSize="small" nzShape="round"><span nz-icon
                                    nzType="delete"></span></button></a>
                    </td>

                </tr>
            </tbody>
        </nz-table>
    </div>
    <div class="card-footer p-2 border-0 bg-gradient-blue-light"></div>
</div>
