/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWigetPegawaiPosisi } from '../models/hr-report-wiget-pegawai-posisi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWigetPegawaiPosisiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWigetPegawaiPosisiList
   */
  static readonly GetHrReportWigetPegawaiPosisiListPath = '/hrReportWiget/pegawai_posisis';

  /**
   * getpegawai_posisiList.
   *
   * Get all pegawai_posisis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWigetPegawaiPosisiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiPosisiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiPosisiService.GetHrReportWigetPegawaiPosisiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWigetPegawaiPosisi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_posisiList.
   *
   * Get all pegawai_posisis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWigetPegawaiPosisiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiPosisiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiPosisi>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWigetPegawaiPosisiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiPosisi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWigetPegawaiPosisi
   */
  static readonly StoreHrReportWigetPegawaiPosisiPath = '/hrReportWiget/pegawai_posisis';

  /**
   * createpegawai_posisi.
   *
   * Create pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWigetPegawaiPosisi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWigetPegawaiPosisi$Response(params: {
    body: HrReportWigetPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiPosisiService.StoreHrReportWigetPegawaiPosisiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_posisi.
   *
   * Create pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWigetPegawaiPosisi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWigetPegawaiPosisi(params: {
    body: HrReportWigetPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}> {

    return this.storeHrReportWigetPegawaiPosisi$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWigetPegawaiPosisiId
   */
  static readonly GetHrReportWigetPegawaiPosisiIdPath = '/hrReportWiget/pegawai_posisis/{id}';

  /**
   * getpegawai_posisiItem.
   *
   * Get pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWigetPegawaiPosisiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiPosisiId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiPosisiService.GetHrReportWigetPegawaiPosisiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_posisiItem.
   *
   * Get pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWigetPegawaiPosisiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiPosisiId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}> {

    return this.getHrReportWigetPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWigetPegawaiPosisiId
   */
  static readonly PutHrReportWigetPegawaiPosisiIdPath = '/hrReportWiget/pegawai_posisis/{id}';

  /**
   * updatepegawai_posisi.
   *
   * Update pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWigetPegawaiPosisiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWigetPegawaiPosisiId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
    body: HrReportWigetPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiPosisiService.PutHrReportWigetPegawaiPosisiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_posisi.
   *
   * Update pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWigetPegawaiPosisiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWigetPegawaiPosisiId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
    body: HrReportWigetPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}> {

    return this.putHrReportWigetPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWigetPegawaiPosisisId
   */
  static readonly DeleteHrReportWigetPegawaiPosisisIdPath = '/hrReportWiget/pegawai_posisis/{id}';

  /**
   * deletepegawai_posisi.
   *
   * Delete pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWigetPegawaiPosisisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWigetPegawaiPosisisId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiPosisiService.DeleteHrReportWigetPegawaiPosisisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_posisi.
   *
   * Delete pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWigetPegawaiPosisisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWigetPegawaiPosisisId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWigetPegawaiPosisisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
