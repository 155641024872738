/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWidgetPegawaiPosisi } from '../models/hr-report-widget-pegawai-posisi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWidgetPegawaiPosisiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiPosisiList
   */
  static readonly GetHrReportWidgetPegawaiPosisiListPath = '/hrReportWidget/pegawai_posisis';

  /**
   * getpegawai_posisiList.
   *
   * Get all pegawai_posisis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiPosisiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiPosisiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiPosisiService.GetHrReportWidgetPegawaiPosisiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWidgetPegawaiPosisi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_posisiList.
   *
   * Get all pegawai_posisis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiPosisiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiPosisiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiPosisi>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWidgetPegawaiPosisiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiPosisi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiPosisi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWidgetPegawaiPosisi
   */
  static readonly StoreHrReportWidgetPegawaiPosisiPath = '/hrReportWidget/pegawai_posisis';

  /**
   * createpegawai_posisi.
   *
   * Create pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWidgetPegawaiPosisi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiPosisi$Response(params: {
    body: HrReportWidgetPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiPosisiService.StoreHrReportWidgetPegawaiPosisiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_posisi.
   *
   * Create pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWidgetPegawaiPosisi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiPosisi(params: {
    body: HrReportWidgetPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}> {

    return this.storeHrReportWidgetPegawaiPosisi$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiPosisiId
   */
  static readonly GetHrReportWidgetPegawaiPosisiIdPath = '/hrReportWidget/pegawai_posisis/{id}';

  /**
   * getpegawai_posisiItem.
   *
   * Get pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiPosisiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiPosisiId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiPosisiService.GetHrReportWidgetPegawaiPosisiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_posisiItem.
   *
   * Get pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiPosisiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiPosisiId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}> {

    return this.getHrReportWidgetPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWidgetPegawaiPosisiId
   */
  static readonly PutHrReportWidgetPegawaiPosisiIdPath = '/hrReportWidget/pegawai_posisis/{id}';

  /**
   * updatepegawai_posisi.
   *
   * Update pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWidgetPegawaiPosisiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiPosisiId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
    body: HrReportWidgetPegawaiPosisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiPosisiService.PutHrReportWidgetPegawaiPosisiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiPosisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_posisi.
   *
   * Update pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWidgetPegawaiPosisiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiPosisiId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
    body: HrReportWidgetPegawaiPosisi
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}> {

    return this.putHrReportWidgetPegawaiPosisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiPosisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWidgetPegawaiPosisisId
   */
  static readonly DeleteHrReportWidgetPegawaiPosisisIdPath = '/hrReportWidget/pegawai_posisis/{id}';

  /**
   * deletepegawai_posisi.
   *
   * Delete pegawai_posisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWidgetPegawaiPosisisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiPosisisId$Response(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiPosisiService.DeleteHrReportWidgetPegawaiPosisisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_posisi.
   *
   * Delete pegawai_posisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWidgetPegawaiPosisisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiPosisisId(params: {

    /**
     * id of pegawai_posisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWidgetPegawaiPosisisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
