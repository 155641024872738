// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    authenticated: false,
    maintenance: false,
    hmr: false,

    // pelanggan: 'http://34.87.12.233/api',
    // foto: 'http://34.87.12.233/',
    // hr: 'http://34.87.12.233:94/api',
    foto: 'https://pdam-api.vm.devetek.com/',
    pelanggan: 'https://pdam-api.vm.devetek.com/api',
    hr: 'https://api-hr.perumdamtirtaalamitd.com/api',
    // hr: 'http://35.198.204.185:94/api',
    cms: 'https://api.perumdamtirtaalamitd.com/public/api',
    media: 'https://api.perumdamtirtaalamitd.com/public/',
    // cms: 'http://34.87.12.233:89/api',
    // penagihan: 'http://34.87.12.233:90/api/v1',
    penagihan: 'https://baca-meter-api.vm.devetek.com/api/v1',
    //    pelanggan: 'http://127.0.0.1:8000/api',
    // document: 'http://34.87.12.233/',
    document: 'https://pdam-api.vm.devetek.com/',
    // media: 'http://34.87.12.233:89/',
    srv_document: 'https://pdam-api.vm.devetek.com/api/pelanggan/documents/',

    // pelanggan : 'https://pdam-api.vm.devetek.com',

    token: '_token-token'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
