/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Voucher } from '../models/voucher';
@Injectable({
  providedIn: 'root',
})
class VoucherService extends __BaseService {
  static readonly getArsipVouchersPath = '/arsip/vouchers';
  static readonly postArsipVouchersPath = '/arsip/vouchers';
  static readonly getArsipVouchersIdPath = '/arsip/vouchers/{id}';
  static readonly putArsipVouchersIdPath = '/arsip/vouchers/{id}';
  static readonly deleteArsipVouchersIdPath = '/arsip/vouchers/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Voucher.
   *
   * Get all Voucher
   * @param params The `VoucherService.GetArsipVouchersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Voucher
   *
   * @return successful operation
   */
  getArsipVouchersResponse(params: VoucherService.GetArsipVouchersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Voucher>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/vouchers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Voucher>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Voucher.
   *
   * Get all Voucher
   * @param params The `VoucherService.GetArsipVouchersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Voucher
   *
   * @return successful operation
   */
  getArsipVouchers(params: VoucherService.GetArsipVouchersParams): __Observable<{success?: boolean, data?: Array<Voucher>, message?: string, total_data?: number, total_page?: number}> {
    return this.getArsipVouchersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Voucher>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Voucher in storage
   *
   * Store Voucher
   * @param body Voucher that should be stored
   * @return successful operation
   */
  postArsipVouchersResponse(body?: Voucher): __Observable<__StrictHttpResponse<{success?: boolean, data?: Voucher, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/arsip/vouchers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Voucher, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Voucher in storage
   *
   * Store Voucher
   * @param body Voucher that should be stored
   * @return successful operation
   */
  postArsipVouchers(body?: Voucher): __Observable<{success?: boolean, data?: Voucher, message?: string}> {
    return this.postArsipVouchersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Voucher, message?: string})
    );
  }

  /**
   * Display the specified Voucher
   *
   * Get Voucher
   * @param id id of Voucher
   * @return successful operation
   */
  getArsipVouchersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Voucher, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/vouchers/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Voucher, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Voucher
   *
   * Get Voucher
   * @param id id of Voucher
   * @return successful operation
   */
  getArsipVouchersId(id: number): __Observable<{success?: boolean, data?: Voucher, message?: string}> {
    return this.getArsipVouchersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Voucher, message?: string})
    );
  }

  /**
   * Update the specified Voucher in storage
   *
   * Update Voucher
   * @param params The `VoucherService.PutArsipVouchersIdParams` containing the following parameters:
   *
   * - `id`: id of Voucher
   *
   * - `body`: Voucher that should be updated
   *
   * @return successful operation
   */
  putArsipVouchersIdResponse(params: VoucherService.PutArsipVouchersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Voucher, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/arsip/vouchers/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Voucher, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Voucher in storage
   *
   * Update Voucher
   * @param params The `VoucherService.PutArsipVouchersIdParams` containing the following parameters:
   *
   * - `id`: id of Voucher
   *
   * - `body`: Voucher that should be updated
   *
   * @return successful operation
   */
  putArsipVouchersId(params: VoucherService.PutArsipVouchersIdParams): __Observable<{success?: boolean, data?: Voucher, message?: string}> {
    return this.putArsipVouchersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Voucher, message?: string})
    );
  }

  /**
   * Remove the specified Voucher from storage
   *
   * Delete Voucher
   * @param id id of Voucher
   * @return successful operation
   */
  deleteArsipVouchersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/arsip/vouchers/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Voucher from storage
   *
   * Delete Voucher
   * @param id id of Voucher
   * @return successful operation
   */
  deleteArsipVouchersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteArsipVouchersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module VoucherService {

  /**
   * Parameters for getArsipVouchers
   */
  export interface GetArsipVouchersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Voucher
     */
    filter?: string;
  }

  /**
   * Parameters for putArsipVouchersId
   */
  export interface PutArsipVouchersIdParams {

    /**
     * id of Voucher
     */
    id: number;

    /**
     * Voucher that should be updated
     */
    body?: Voucher;
  }
}

export { VoucherService }
