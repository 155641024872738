/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Billing } from '../models/billing';
@Injectable({
  providedIn: 'root',
})
class BillingService extends __BaseService {
  static readonly getPelangganBillingsPath = '/pelanggan/billings';
  static readonly postPelangganBillingsPath = '/pelanggan/billings';
  static readonly getPelangganBillingsIdPath = '/pelanggan/billings/{id}';
  static readonly putPelangganBillingsIdPath = '/pelanggan/billings/{id}';
  static readonly deletePelangganBillingsIdPath = '/pelanggan/billings/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Billing.
   *
   * Get all Billing
   * @param params The `BillingService.GetPelangganBillingsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Billing
   *
   * @return successful operation
   */
  getPelangganBillingsResponse(params: BillingService.GetPelangganBillingsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Billing>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/billings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Billing>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Billing.
   *
   * Get all Billing
   * @param params The `BillingService.GetPelangganBillingsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Billing
   *
   * @return successful operation
   */
  getPelangganBillings(params: BillingService.GetPelangganBillingsParams): __Observable<{success?: boolean, data?: Array<Billing>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganBillingsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Billing>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Billing in storage
   *
   * Store Billing
   * @param body Billing that should be stored
   * @return successful operation
   */
  postPelangganBillingsResponse(body?: Billing): __Observable<__StrictHttpResponse<{success?: boolean, data?: Billing, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/billings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Billing, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Billing in storage
   *
   * Store Billing
   * @param body Billing that should be stored
   * @return successful operation
   */
  postPelangganBillings(body?: Billing): __Observable<{success?: boolean, data?: Billing, message?: string}> {
    return this.postPelangganBillingsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Billing, message?: string})
    );
  }

  /**
   * Display the specified Billing
   *
   * Get Billing
   * @param id id of Billing
   * @return successful operation
   */
  getPelangganBillingsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Billing, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/billings/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Billing, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Billing
   *
   * Get Billing
   * @param id id of Billing
   * @return successful operation
   */
  getPelangganBillingsId(id: number): __Observable<{success?: boolean, data?: Billing, message?: string}> {
    return this.getPelangganBillingsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Billing, message?: string})
    );
  }

  /**
   * Update the specified Billing in storage
   *
   * Update Billing
   * @param params The `BillingService.PutPelangganBillingsIdParams` containing the following parameters:
   *
   * - `id`: id of Billing
   *
   * - `body`: Billing that should be updated
   *
   * @return successful operation
   */
  putPelangganBillingsIdResponse(params: BillingService.PutPelangganBillingsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Billing, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/billings/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Billing, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Billing in storage
   *
   * Update Billing
   * @param params The `BillingService.PutPelangganBillingsIdParams` containing the following parameters:
   *
   * - `id`: id of Billing
   *
   * - `body`: Billing that should be updated
   *
   * @return successful operation
   */
  putPelangganBillingsId(params: BillingService.PutPelangganBillingsIdParams): __Observable<{success?: boolean, data?: Billing, message?: string}> {
    return this.putPelangganBillingsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Billing, message?: string})
    );
  }

  /**
   * Remove the specified Billing from storage
   *
   * Delete Billing
   * @param id id of Billing
   * @return successful operation
   */
  deletePelangganBillingsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/billings/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Billing from storage
   *
   * Delete Billing
   * @param id id of Billing
   * @return successful operation
   */
  deletePelangganBillingsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganBillingsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module BillingService {

  /**
   * Parameters for getPelangganBillings
   */
  export interface GetPelangganBillingsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Billing
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganBillingsId
   */
  export interface PutPelangganBillingsIdParams {

    /**
     * id of Billing
     */
    id: number;

    /**
     * Billing that should be updated
     */
    body?: Billing;
  }
}

export { BillingService }
