<div class="pt-5 mt-5">
    <div class="card boxContainer mt-2">
        <div class="text-dark font-size-32 mb-3">Sign In</div>
        <div class="mb-4">
            Login and password
            <br />
            <strong>Perumda-Tirta Alami</strong>
        </div>

        <form nz-form [nzLayout]="'vertical'" [formGroup]="loginForm" (ngSubmit)="onSubmit()" role="form">
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your email!">
                    <input type="text" nz-input formControlName="email" placeholder="Email Address" />
                </nz-form-control>

            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your Password!">
                    <input type="password" nz-input formControlName="password" placeholder="Password" />
                </nz-form-control>

            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <button nz-button nzType="primary" [nzLoading]="loading" class="text-center w-100">
                        <strong>Log In</strong>
                    </button>
                </nz-form-control>
            </nz-form-item>
        </form>

        <!-- Errors -->
        <div *ngIf="errors?.email" class="alert alert-danger mt-3">
            {{ errors?.email }}
        </div>
        <div *ngIf="errors?.password" class="alert alert-danger mt-3">
            {{ errors?.password }}
        </div>
        <div *ngIf="errors?.error" class="alert alert-danger mt-3">
            {{ errors?.error }}
        </div>

        <a routerLink="/auth/forgot-password" class="vb__utils__link">
            Forgot password?
        </a>
    </div>

</div>
