/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiCutiPengaturan } from '../models/absensi-cuti-pengaturan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiCutiPengaturanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiCutiPengaturanList
   */
  static readonly GetAbsensiCutiPengaturanListPath = '/absensi/cuti_pengaturans';

  /**
   * getcuti_pengaturanList.
   *
   * Get all cuti_pengaturans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiCutiPengaturanList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengaturanList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiCutiPengaturan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengaturanService.GetAbsensiCutiPengaturanListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiCutiPengaturan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getcuti_pengaturanList.
   *
   * Get all cuti_pengaturans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiCutiPengaturanList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengaturanList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiCutiPengaturan>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiCutiPengaturanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiCutiPengaturan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiCutiPengaturan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiCutiPengaturan
   */
  static readonly StoreAbsensiCutiPengaturanPath = '/absensi/cuti_pengaturans';

  /**
   * createcuti_pengaturan.
   *
   * Create cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiCutiPengaturan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiCutiPengaturan$Response(params: {
    body: AbsensiCutiPengaturan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengaturanService.StoreAbsensiCutiPengaturanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiPengaturan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createcuti_pengaturan.
   *
   * Create cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiCutiPengaturan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiCutiPengaturan(params: {
    body: AbsensiCutiPengaturan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}> {

    return this.storeAbsensiCutiPengaturan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiCutiPengaturanId
   */
  static readonly GetAbsensiCutiPengaturanIdPath = '/absensi/cuti_pengaturans/{id}';

  /**
   * getcuti_pengaturanItem.
   *
   * Get cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiCutiPengaturanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengaturanId$Response(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengaturanService.GetAbsensiCutiPengaturanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiPengaturan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getcuti_pengaturanItem.
   *
   * Get cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiCutiPengaturanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengaturanId(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}> {

    return this.getAbsensiCutiPengaturanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiCutiPengaturanId
   */
  static readonly PutAbsensiCutiPengaturanIdPath = '/absensi/cuti_pengaturans/{id}';

  /**
   * updatecuti_pengaturan.
   *
   * Update cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiCutiPengaturanId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiCutiPengaturanId$Response(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
    body: AbsensiCutiPengaturan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengaturanService.PutAbsensiCutiPengaturanIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiPengaturan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatecuti_pengaturan.
   *
   * Update cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiCutiPengaturanId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiCutiPengaturanId(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
    body: AbsensiCutiPengaturan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}> {

    return this.putAbsensiCutiPengaturanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiPengaturan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiCutiPengaturansId
   */
  static readonly DeleteAbsensiCutiPengaturansIdPath = '/absensi/cuti_pengaturans/{id}';

  /**
   * deletecuti_pengaturan.
   *
   * Delete cuti_pengaturan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiCutiPengaturansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiCutiPengaturansId$Response(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengaturanService.DeleteAbsensiCutiPengaturansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletecuti_pengaturan.
   *
   * Delete cuti_pengaturan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiCutiPengaturansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiCutiPengaturansId(params: {

    /**
     * id of cuti_pengaturan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiCutiPengaturansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
