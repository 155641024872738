/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiPerjalananDinasAnggota } from '../models/absensi-perjalanan-dinas-anggota';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiPerjalananDinasAnggotaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiPerjalananDinasAnggotaList
   */
  static readonly GetAbsensiPerjalananDinasAnggotaListPath = '/absensi/perjalanan_dinas_anggotas';

  /**
   * getperjalanan_dinas_anggotaList.
   *
   * Get all perjalanan_dinas_anggotas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiPerjalananDinasAnggotaList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiPerjalananDinasAnggotaList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiPerjalananDinasAnggotaService.GetAbsensiPerjalananDinasAnggotaListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiPerjalananDinasAnggota>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_anggotaList.
   *
   * Get all perjalanan_dinas_anggotas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiPerjalananDinasAnggotaList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiPerjalananDinasAnggotaList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiPerjalananDinasAnggotaList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiPerjalananDinasAnggota>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiPerjalananDinasAnggota
   */
  static readonly StoreAbsensiPerjalananDinasAnggotaPath = '/absensi/perjalanan_dinas_anggotas';

  /**
   * createperjalanan_dinas_anggota.
   *
   * Create perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiPerjalananDinasAnggota()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiPerjalananDinasAnggota$Response(params: {
    body: AbsensiPerjalananDinasAnggota
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiPerjalananDinasAnggotaService.StoreAbsensiPerjalananDinasAnggotaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createperjalanan_dinas_anggota.
   *
   * Create perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiPerjalananDinasAnggota$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiPerjalananDinasAnggota(params: {
    body: AbsensiPerjalananDinasAnggota
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.storeAbsensiPerjalananDinasAnggota$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiPerjalananDinasAnggotaId
   */
  static readonly GetAbsensiPerjalananDinasAnggotaIdPath = '/absensi/perjalanan_dinas_anggotas/{id}';

  /**
   * getperjalanan_dinas_anggotaItem.
   *
   * Get perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiPerjalananDinasAnggotaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiPerjalananDinasAnggotaId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiPerjalananDinasAnggotaService.GetAbsensiPerjalananDinasAnggotaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getperjalanan_dinas_anggotaItem.
   *
   * Get perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiPerjalananDinasAnggotaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiPerjalananDinasAnggotaId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.getAbsensiPerjalananDinasAnggotaId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiPerjalananDinasAnggotaId
   */
  static readonly PutAbsensiPerjalananDinasAnggotaIdPath = '/absensi/perjalanan_dinas_anggotas/{id}';

  /**
   * updateperjalanan_dinas_anggota.
   *
   * Update perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiPerjalananDinasAnggotaId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiPerjalananDinasAnggotaId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
    body: AbsensiPerjalananDinasAnggota
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiPerjalananDinasAnggotaService.PutAbsensiPerjalananDinasAnggotaIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiPerjalananDinasAnggota;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updateperjalanan_dinas_anggota.
   *
   * Update perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiPerjalananDinasAnggotaId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiPerjalananDinasAnggotaId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
    body: AbsensiPerjalananDinasAnggota
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}> {

    return this.putAbsensiPerjalananDinasAnggotaId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiPerjalananDinasAnggota;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiPerjalananDinasAnggotasId
   */
  static readonly DeleteAbsensiPerjalananDinasAnggotasIdPath = '/absensi/perjalanan_dinas_anggotas/{id}';

  /**
   * deleteperjalanan_dinas_anggota.
   *
   * Delete perjalanan_dinas_anggota
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiPerjalananDinasAnggotasId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiPerjalananDinasAnggotasId$Response(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiPerjalananDinasAnggotaService.DeleteAbsensiPerjalananDinasAnggotasIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleteperjalanan_dinas_anggota.
   *
   * Delete perjalanan_dinas_anggota
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiPerjalananDinasAnggotasId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiPerjalananDinasAnggotasId(params: {

    /**
     * id of perjalanan_dinas_anggota
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiPerjalananDinasAnggotasId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
