/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiCutiPengajuan } from '../models/absensi-cuti-pengajuan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiCutiPengajuanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiCutiPengajuanList
   */
  static readonly GetAbsensiCutiPengajuanListPath = '/absensi/cuti_pengajuans';

  /**
   * getcuti_pengajuanList.
   *
   * Get all cuti_pengajuans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiCutiPengajuanList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengajuanList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiCutiPengajuan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengajuanService.GetAbsensiCutiPengajuanListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiCutiPengajuan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuanList.
   *
   * Get all cuti_pengajuans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiCutiPengajuanList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengajuanList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiCutiPengajuan>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiCutiPengajuanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiCutiPengajuan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiCutiPengajuan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiCutiPengajuan
   */
  static readonly StoreAbsensiCutiPengajuanPath = '/absensi/cuti_pengajuans';

  /**
   * createcuti_pengajuan.
   *
   * Create cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiCutiPengajuan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiCutiPengajuan$Response(params: {
    body: AbsensiCutiPengajuan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengajuanService.StoreAbsensiCutiPengajuanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiPengajuan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createcuti_pengajuan.
   *
   * Create cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiCutiPengajuan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiCutiPengajuan(params: {
    body: AbsensiCutiPengajuan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}> {

    return this.storeAbsensiCutiPengajuan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiCutiPengajuanId
   */
  static readonly GetAbsensiCutiPengajuanIdPath = '/absensi/cuti_pengajuans/{id}';

  /**
   * getcuti_pengajuanItem.
   *
   * Get cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiCutiPengajuanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengajuanId$Response(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengajuanService.GetAbsensiCutiPengajuanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiPengajuan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuanItem.
   *
   * Get cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiCutiPengajuanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiCutiPengajuanId(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}> {

    return this.getAbsensiCutiPengajuanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiCutiPengajuanId
   */
  static readonly PutAbsensiCutiPengajuanIdPath = '/absensi/cuti_pengajuans/{id}';

  /**
   * updatecuti_pengajuan.
   *
   * Update cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiCutiPengajuanId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiCutiPengajuanId$Response(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
    body: AbsensiCutiPengajuan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengajuanService.PutAbsensiCutiPengajuanIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiCutiPengajuan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatecuti_pengajuan.
   *
   * Update cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiCutiPengajuanId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiCutiPengajuanId(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
    body: AbsensiCutiPengajuan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}> {

    return this.putAbsensiCutiPengajuanId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiCutiPengajuan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiCutiPengajuansId
   */
  static readonly DeleteAbsensiCutiPengajuansIdPath = '/absensi/cuti_pengajuans/{id}';

  /**
   * deletecuti_pengajuan.
   *
   * Delete cuti_pengajuan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiCutiPengajuansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiCutiPengajuansId$Response(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiCutiPengajuanService.DeleteAbsensiCutiPengajuansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletecuti_pengajuan.
   *
   * Delete cuti_pengajuan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiCutiPengajuansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiCutiPengajuansId(params: {

    /**
     * id of cuti_pengajuan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiCutiPengajuansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
