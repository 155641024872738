/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Baca_meter_status_meter } from '../models/baca-_meter-_status-_meter';
@Injectable({
  providedIn: 'root',
})
class BacaMeterStatusMeterService extends __BaseService {
  static readonly getPelangganReportBacaMeterStatusMetersPath = '/pelangganReport/bacaMeterStatusMeters';
  static readonly postPelangganReportBacaMeterStatusMetersPath = '/pelangganReport/bacaMeterStatusMeters';
  static readonly getPelangganReportBacaMeterStatusMetersIdPath = '/pelangganReport/bacaMeterStatusMeters/{id}';
  static readonly putPelangganReportBacaMeterStatusMetersIdPath = '/pelangganReport/bacaMeterStatusMeters/{id}';
  static readonly deletePelangganReportBacaMeterStatusMetersIdPath = '/pelangganReport/bacaMeterStatusMeters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Baca_meter_status_meter.
   *
   * Get all Baca_meter_status_meter
   * @param params The `BacaMeterStatusMeterService.GetPelangganReportBacaMeterStatusMetersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Baca_meter_status_meter
   *
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusMetersResponse(params: BacaMeterStatusMeterService.GetPelangganReportBacaMeterStatusMetersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_status_meter>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/bacaMeterStatusMeters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Baca_meter_status_meter>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Baca_meter_status_meter.
   *
   * Get all Baca_meter_status_meter
   * @param params The `BacaMeterStatusMeterService.GetPelangganReportBacaMeterStatusMetersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Baca_meter_status_meter
   *
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusMeters(params: BacaMeterStatusMeterService.GetPelangganReportBacaMeterStatusMetersParams): __Observable<{success?: boolean, data?: Array<Baca_meter_status_meter>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportBacaMeterStatusMetersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Baca_meter_status_meter>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Baca_meter_status_meter in storage
   *
   * Store Baca_meter_status_meter
   * @param body Baca_meter_status_meter that should be stored
   * @return successful operation
   */
  postPelangganReportBacaMeterStatusMetersResponse(body?: Baca_meter_status_meter): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_meter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/bacaMeterStatusMeters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_meter, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Baca_meter_status_meter in storage
   *
   * Store Baca_meter_status_meter
   * @param body Baca_meter_status_meter that should be stored
   * @return successful operation
   */
  postPelangganReportBacaMeterStatusMeters(body?: Baca_meter_status_meter): __Observable<{success?: boolean, data?: Baca_meter_status_meter, message?: string}> {
    return this.postPelangganReportBacaMeterStatusMetersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_meter, message?: string})
    );
  }

  /**
   * Display the specified Baca_meter_status_meter
   *
   * Get Baca_meter_status_meter
   * @param id id of Baca_meter_status_meter
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusMetersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_meter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/bacaMeterStatusMeters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_meter, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Baca_meter_status_meter
   *
   * Get Baca_meter_status_meter
   * @param id id of Baca_meter_status_meter
   * @return successful operation
   */
  getPelangganReportBacaMeterStatusMetersId(id: number): __Observable<{success?: boolean, data?: Baca_meter_status_meter, message?: string}> {
    return this.getPelangganReportBacaMeterStatusMetersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_meter, message?: string})
    );
  }

  /**
   * Update the specified Baca_meter_status_meter in storage
   *
   * Update Baca_meter_status_meter
   * @param params The `BacaMeterStatusMeterService.PutPelangganReportBacaMeterStatusMetersIdParams` containing the following parameters:
   *
   * - `id`: id of Baca_meter_status_meter
   *
   * - `body`: Baca_meter_status_meter that should be updated
   *
   * @return successful operation
   */
  putPelangganReportBacaMeterStatusMetersIdResponse(params: BacaMeterStatusMeterService.PutPelangganReportBacaMeterStatusMetersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_meter, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/bacaMeterStatusMeters/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Baca_meter_status_meter, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Baca_meter_status_meter in storage
   *
   * Update Baca_meter_status_meter
   * @param params The `BacaMeterStatusMeterService.PutPelangganReportBacaMeterStatusMetersIdParams` containing the following parameters:
   *
   * - `id`: id of Baca_meter_status_meter
   *
   * - `body`: Baca_meter_status_meter that should be updated
   *
   * @return successful operation
   */
  putPelangganReportBacaMeterStatusMetersId(params: BacaMeterStatusMeterService.PutPelangganReportBacaMeterStatusMetersIdParams): __Observable<{success?: boolean, data?: Baca_meter_status_meter, message?: string}> {
    return this.putPelangganReportBacaMeterStatusMetersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Baca_meter_status_meter, message?: string})
    );
  }

  /**
   * Remove the specified Baca_meter_status_meter from storage
   *
   * Delete Baca_meter_status_meter
   * @param id id of Baca_meter_status_meter
   * @return successful operation
   */
  deletePelangganReportBacaMeterStatusMetersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/bacaMeterStatusMeters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Baca_meter_status_meter from storage
   *
   * Delete Baca_meter_status_meter
   * @param id id of Baca_meter_status_meter
   * @return successful operation
   */
  deletePelangganReportBacaMeterStatusMetersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportBacaMeterStatusMetersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module BacaMeterStatusMeterService {

  /**
   * Parameters for getPelangganReportBacaMeterStatusMeters
   */
  export interface GetPelangganReportBacaMeterStatusMetersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Baca_meter_status_meter
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportBacaMeterStatusMetersId
   */
  export interface PutPelangganReportBacaMeterStatusMetersIdParams {

    /**
     * id of Baca_meter_status_meter
     */
    id: number;

    /**
     * Baca_meter_status_meter that should be updated
     */
    body?: Baca_meter_status_meter;
  }
}

export { BacaMeterStatusMeterService }
