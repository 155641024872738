/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Drd_by_grafik } from '../models/drd-_by-_grafik';
@Injectable({
  providedIn: 'root',
})
class DrdByGrafikService extends __BaseService {
  static readonly getPelangganReportDrdByGrafiksPath = '/pelangganReport/drdByGrafiks';
  static readonly postPelangganReportDrdByGrafiksPath = '/pelangganReport/drdByGrafiks';
  static readonly getPelangganReportDrdByGrafiksIdPath = '/pelangganReport/drdByGrafiks/{id}';
  static readonly putPelangganReportDrdByGrafiksIdPath = '/pelangganReport/drdByGrafiks/{id}';
  static readonly deletePelangganReportDrdByGrafiksIdPath = '/pelangganReport/drdByGrafiks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the drd_by_grafik.
   *
   * Get all drd_by_grafik
   * @param params The `DrdByGrafikService.GetPelangganReportDrdByGrafiksParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter drd_by_grafik
   *
   * @return successful operation
   */
  getPelangganReportDrdByGrafiksResponse(params: DrdByGrafikService.GetPelangganReportDrdByGrafiksParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Drd_by_grafik>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/drdByGrafiks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Drd_by_grafik>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the drd_by_grafik.
   *
   * Get all drd_by_grafik
   * @param params The `DrdByGrafikService.GetPelangganReportDrdByGrafiksParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter drd_by_grafik
   *
   * @return successful operation
   */
  getPelangganReportDrdByGrafiks(params: DrdByGrafikService.GetPelangganReportDrdByGrafiksParams): __Observable<{success?: boolean, data?: Array<Drd_by_grafik>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportDrdByGrafiksResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Drd_by_grafik>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created drd_by_grafik in storage
   *
   * Store drd_by_grafik
   * @param body drd_by_grafik that should be stored
   * @return successful operation
   */
  postPelangganReportDrdByGrafiksResponse(body?: Drd_by_grafik): __Observable<__StrictHttpResponse<{success?: boolean, data?: Drd_by_grafik, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/drdByGrafiks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Drd_by_grafik, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created drd_by_grafik in storage
   *
   * Store drd_by_grafik
   * @param body drd_by_grafik that should be stored
   * @return successful operation
   */
  postPelangganReportDrdByGrafiks(body?: Drd_by_grafik): __Observable<{success?: boolean, data?: Drd_by_grafik, message?: string}> {
    return this.postPelangganReportDrdByGrafiksResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Drd_by_grafik, message?: string})
    );
  }

  /**
   * Display the specified drd_by_grafik
   *
   * Get drd_by_grafik
   * @param id id of drd_by_grafik
   * @return successful operation
   */
  getPelangganReportDrdByGrafiksIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Drd_by_grafik, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/drdByGrafiks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Drd_by_grafik, message?: string}>;
      })
    );
  }
  /**
   * Display the specified drd_by_grafik
   *
   * Get drd_by_grafik
   * @param id id of drd_by_grafik
   * @return successful operation
   */
  getPelangganReportDrdByGrafiksId(id: number): __Observable<{success?: boolean, data?: Drd_by_grafik, message?: string}> {
    return this.getPelangganReportDrdByGrafiksIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Drd_by_grafik, message?: string})
    );
  }

  /**
   * Update the specified drd_by_grafik in storage
   *
   * Update drd_by_grafik
   * @param params The `DrdByGrafikService.PutPelangganReportDrdByGrafiksIdParams` containing the following parameters:
   *
   * - `id`: id of drd_by_grafik
   *
   * - `body`: drd_by_grafik that should be updated
   *
   * @return successful operation
   */
  putPelangganReportDrdByGrafiksIdResponse(params: DrdByGrafikService.PutPelangganReportDrdByGrafiksIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Drd_by_grafik, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/drdByGrafiks/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Drd_by_grafik, message?: string}>;
      })
    );
  }
  /**
   * Update the specified drd_by_grafik in storage
   *
   * Update drd_by_grafik
   * @param params The `DrdByGrafikService.PutPelangganReportDrdByGrafiksIdParams` containing the following parameters:
   *
   * - `id`: id of drd_by_grafik
   *
   * - `body`: drd_by_grafik that should be updated
   *
   * @return successful operation
   */
  putPelangganReportDrdByGrafiksId(params: DrdByGrafikService.PutPelangganReportDrdByGrafiksIdParams): __Observable<{success?: boolean, data?: Drd_by_grafik, message?: string}> {
    return this.putPelangganReportDrdByGrafiksIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Drd_by_grafik, message?: string})
    );
  }

  /**
   * Remove the specified drd_by_grafik from storage
   *
   * Delete drd_by_grafik
   * @param id id of drd_by_grafik
   * @return successful operation
   */
  deletePelangganReportDrdByGrafiksIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/drdByGrafiks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified drd_by_grafik from storage
   *
   * Delete drd_by_grafik
   * @param id id of drd_by_grafik
   * @return successful operation
   */
  deletePelangganReportDrdByGrafiksId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportDrdByGrafiksIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module DrdByGrafikService {

  /**
   * Parameters for getPelangganReportDrdByGrafiks
   */
  export interface GetPelangganReportDrdByGrafiksParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter drd_by_grafik
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportDrdByGrafiksId
   */
  export interface PutPelangganReportDrdByGrafiksIdParams {

    /**
     * id of drd_by_grafik
     */
    id: number;

    /**
     * drd_by_grafik that should be updated
     */
    body?: Drd_by_grafik;
  }
}

export { DrdByGrafikService }
