/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pdf_ekap_setoran } from '../models/pdf-_ekap-_setoran';
@Injectable({
  providedIn: 'root',
})
class PdfEkapSetoranService extends __BaseService {
  static readonly getPenagihanPdfPdfEkapSetoransIdPath = '/penagihanPdf/pdfEkapSetorans/{id}';
  static readonly putPenagihanPdfPdfEkapSetoransIdPath = '/penagihanPdf/pdfEkapSetorans/{id}';
  static readonly deletePenagihanPdfPdfEkapSetoransIdPath = '/penagihanPdf/pdfEkapSetorans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Display the specified Pdf_ekap_setoran
   *
   * Get Pdf_ekap_setoran
   * @param id id of Pdf_ekap_setoran
   * @return successful operation
   */
  getPenagihanPdfPdfEkapSetoransIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_ekap_setoran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanPdf/pdfEkapSetorans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_ekap_setoran, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Pdf_ekap_setoran
   *
   * Get Pdf_ekap_setoran
   * @param id id of Pdf_ekap_setoran
   * @return successful operation
   */
  getPenagihanPdfPdfEkapSetoransId(id: number): __Observable<{success?: boolean, data?: Pdf_ekap_setoran, message?: string}> {
    return this.getPenagihanPdfPdfEkapSetoransIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_ekap_setoran, message?: string})
    );
  }

  /**
   * Update the specified Pdf_ekap_setoran in storage
   *
   * Update Pdf_ekap_setoran
   * @param params The `PdfEkapSetoranService.PutPenagihanPdfPdfEkapSetoransIdParams` containing the following parameters:
   *
   * - `id`: id of Pdf_ekap_setoran
   *
   * - `body`: Pdf_ekap_setoran that should be updated
   *
   * @return successful operation
   */
  putPenagihanPdfPdfEkapSetoransIdResponse(params: PdfEkapSetoranService.PutPenagihanPdfPdfEkapSetoransIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pdf_ekap_setoran, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanPdf/pdfEkapSetorans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pdf_ekap_setoran, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Pdf_ekap_setoran in storage
   *
   * Update Pdf_ekap_setoran
   * @param params The `PdfEkapSetoranService.PutPenagihanPdfPdfEkapSetoransIdParams` containing the following parameters:
   *
   * - `id`: id of Pdf_ekap_setoran
   *
   * - `body`: Pdf_ekap_setoran that should be updated
   *
   * @return successful operation
   */
  putPenagihanPdfPdfEkapSetoransId(params: PdfEkapSetoranService.PutPenagihanPdfPdfEkapSetoransIdParams): __Observable<{success?: boolean, data?: Pdf_ekap_setoran, message?: string}> {
    return this.putPenagihanPdfPdfEkapSetoransIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pdf_ekap_setoran, message?: string})
    );
  }

  /**
   * Remove the specified Pdf_ekap_setoran from storage
   *
   * Delete Pdf_ekap_setoran
   * @param id id of Pdf_ekap_setoran
   * @return successful operation
   */
  deletePenagihanPdfPdfEkapSetoransIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanPdf/pdfEkapSetorans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Pdf_ekap_setoran from storage
   *
   * Delete Pdf_ekap_setoran
   * @param id id of Pdf_ekap_setoran
   * @return successful operation
   */
  deletePenagihanPdfPdfEkapSetoransId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanPdfPdfEkapSetoransIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PdfEkapSetoranService {

  /**
   * Parameters for putPenagihanPdfPdfEkapSetoransId
   */
  export interface PutPenagihanPdfPdfEkapSetoransIdParams {

    /**
     * id of Pdf_ekap_setoran
     */
    id: number;

    /**
     * Pdf_ekap_setoran that should be updated
     */
    body?: Pdf_ekap_setoran;
  }
}

export { PdfEkapSetoranService }
