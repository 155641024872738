/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ErpDivisi } from '../models/erp-divisi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class ErpDivisiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getErpDivisiList
   */
  static readonly GetErpDivisiListPath = '/erp/divisis';

  /**
   * getdivisiList.
   *
   * Get all divisis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpDivisiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpDivisiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpDivisi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpDivisiService.GetErpDivisiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<ErpDivisi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getdivisiList.
   *
   * Get all divisis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpDivisiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpDivisiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<ErpDivisi>;
'message'?: string;
'total'?: number;
}> {

    return this.getErpDivisiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpDivisi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<ErpDivisi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeErpDivisi
   */
  static readonly StoreErpDivisiPath = '/erp/divisis';

  /**
   * createdivisi.
   *
   * Create divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeErpDivisi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpDivisi$Response(params: {
    body: ErpDivisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpDivisiService.StoreErpDivisiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpDivisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createdivisi.
   *
   * Create divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeErpDivisi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpDivisi(params: {
    body: ErpDivisi
  }): Observable<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}> {

    return this.storeErpDivisi$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getErpDivisiId
   */
  static readonly GetErpDivisiIdPath = '/erp/divisis/{id}';

  /**
   * getdivisiItem.
   *
   * Get divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpDivisiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpDivisiId$Response(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpDivisiService.GetErpDivisiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpDivisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getdivisiItem.
   *
   * Get divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpDivisiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpDivisiId(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}> {

    return this.getErpDivisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putErpDivisiId
   */
  static readonly PutErpDivisiIdPath = '/erp/divisis/{id}';

  /**
   * updatedivisi.
   *
   * Update divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putErpDivisiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpDivisiId$Response(params: {

    /**
     * id of divisi
     */
    id: string;
    body: ErpDivisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpDivisiService.PutErpDivisiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpDivisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatedivisi.
   *
   * Update divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putErpDivisiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpDivisiId(params: {

    /**
     * id of divisi
     */
    id: string;
    body: ErpDivisi
  }): Observable<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}> {

    return this.putErpDivisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpDivisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteErpDivisisId
   */
  static readonly DeleteErpDivisisIdPath = '/erp/divisis/{id}';

  /**
   * deletedivisi.
   *
   * Delete divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteErpDivisisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpDivisisId$Response(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpDivisiService.DeleteErpDivisisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletedivisi.
   *
   * Delete divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteErpDivisisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpDivisisId(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteErpDivisisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
