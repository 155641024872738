/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ErpReportDivisi } from '../models/erp-report-divisi';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class ErpReportDivisiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getErpReportDivisiList
   */
  static readonly GetErpReportDivisiListPath = '/erpReport/divisis';

  /**
   * getdivisiList.
   *
   * Get all divisis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpReportDivisiList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDivisiList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpReportDivisi>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDivisiService.GetErpReportDivisiListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<ErpReportDivisi>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getdivisiList.
   *
   * Get all divisis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpReportDivisiList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDivisiList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<ErpReportDivisi>;
'message'?: string;
'total'?: number;
}> {

    return this.getErpReportDivisiList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpReportDivisi>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<ErpReportDivisi>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeErpReportDivisi
   */
  static readonly StoreErpReportDivisiPath = '/erpReport/divisis';

  /**
   * createdivisi.
   *
   * Create divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeErpReportDivisi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpReportDivisi$Response(params: {
    body: ErpReportDivisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDivisiService.StoreErpReportDivisiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportDivisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createdivisi.
   *
   * Create divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeErpReportDivisi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpReportDivisi(params: {
    body: ErpReportDivisi
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}> {

    return this.storeErpReportDivisi$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getErpReportDivisiId
   */
  static readonly GetErpReportDivisiIdPath = '/erpReport/divisis/{id}';

  /**
   * getdivisiItem.
   *
   * Get divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpReportDivisiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDivisiId$Response(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDivisiService.GetErpReportDivisiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportDivisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getdivisiItem.
   *
   * Get divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpReportDivisiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDivisiId(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}> {

    return this.getErpReportDivisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putErpReportDivisiId
   */
  static readonly PutErpReportDivisiIdPath = '/erpReport/divisis/{id}';

  /**
   * updatedivisi.
   *
   * Update divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putErpReportDivisiId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpReportDivisiId$Response(params: {

    /**
     * id of divisi
     */
    id: string;
    body: ErpReportDivisi
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDivisiService.PutErpReportDivisiIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportDivisi;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatedivisi.
   *
   * Update divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putErpReportDivisiId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpReportDivisiId(params: {

    /**
     * id of divisi
     */
    id: string;
    body: ErpReportDivisi
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}> {

    return this.putErpReportDivisiId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportDivisi;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteErpReportDivisisId
   */
  static readonly DeleteErpReportDivisisIdPath = '/erpReport/divisis/{id}';

  /**
   * deletedivisi.
   *
   * Delete divisi
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteErpReportDivisisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpReportDivisisId$Response(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDivisiService.DeleteErpReportDivisisIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletedivisi.
   *
   * Delete divisi
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteErpReportDivisisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpReportDivisisId(params: {

    /**
     * id of divisi
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteErpReportDivisisId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
