<div class="topbar">
    <div class="mr-auto mr-md-1">
        <vb-topbar-search></vb-topbar-search>
    </div>
    <div class="mr-4 d-none d-md-block">
        <!--<vb-topbar-issues-history></vb-topbar-issues-history>-->
    </div>
    <div class="mr-auto d-xl-block d-none">
        <vb-topbar-project-management></vb-topbar-project-management>
    </div>
    <div class="mr-3 d-none d-sm-block ml-auto">

    </div>
    <div class="mr-3 d-none d-sm-block">
        <vb-topbar-actions></vb-topbar-actions>
    </div>
    <div class="mr-3 d-none d-sm-block">
        <vb-topbar-user-menu></vb-topbar-user-menu>
    </div>

</div>
