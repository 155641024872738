/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Mitra } from '../models/mitra';
@Injectable({
  providedIn: 'root',
})
class MitraService extends __BaseService {
  static readonly getSwitchingMitrasPath = '/switching/mitras';
  static readonly postSwitchingMitrasPath = '/switching/mitras';
  static readonly getSwitchingMitrasIdPath = '/switching/mitras/{id}';
  static readonly putSwitchingMitrasIdPath = '/switching/mitras/{id}';
  static readonly deleteSwitchingMitrasIdPath = '/switching/mitras/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Mitra.
   *
   * Get all Mitra
   * @param params The `MitraService.GetSwitchingMitrasParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Mitra
   *
   * @return successful operation
   */
  getSwitchingMitrasResponse(params: MitraService.GetSwitchingMitrasParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Mitra>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/switching/mitras`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Mitra>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Mitra.
   *
   * Get all Mitra
   * @param params The `MitraService.GetSwitchingMitrasParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Mitra
   *
   * @return successful operation
   */
  getSwitchingMitras(params: MitraService.GetSwitchingMitrasParams): __Observable<{success?: boolean, data?: Array<Mitra>, message?: string, total_data?: number, total_page?: number}> {
    return this.getSwitchingMitrasResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Mitra>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Mitra in storage
   *
   * Store Mitra
   * @param body Mitra that should be stored
   * @return successful operation
   */
  postSwitchingMitrasResponse(body?: Mitra): __Observable<__StrictHttpResponse<{success?: boolean, data?: Mitra, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/switching/mitras`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Mitra, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Mitra in storage
   *
   * Store Mitra
   * @param body Mitra that should be stored
   * @return successful operation
   */
  postSwitchingMitras(body?: Mitra): __Observable<{success?: boolean, data?: Mitra, message?: string}> {
    return this.postSwitchingMitrasResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Mitra, message?: string})
    );
  }

  /**
   * Display the specified Mitra
   *
   * Get Mitra
   * @param id id of Mitra
   * @return successful operation
   */
  getSwitchingMitrasIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Mitra, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/switching/mitras/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Mitra, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Mitra
   *
   * Get Mitra
   * @param id id of Mitra
   * @return successful operation
   */
  getSwitchingMitrasId(id: number): __Observable<{success?: boolean, data?: Mitra, message?: string}> {
    return this.getSwitchingMitrasIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Mitra, message?: string})
    );
  }

  /**
   * Update the specified Mitra in storage
   *
   * Update Mitra
   * @param params The `MitraService.PutSwitchingMitrasIdParams` containing the following parameters:
   *
   * - `id`: id of Mitra
   *
   * - `body`: Mitra that should be updated
   *
   * @return successful operation
   */
  putSwitchingMitrasIdResponse(params: MitraService.PutSwitchingMitrasIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Mitra, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/switching/mitras/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Mitra, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Mitra in storage
   *
   * Update Mitra
   * @param params The `MitraService.PutSwitchingMitrasIdParams` containing the following parameters:
   *
   * - `id`: id of Mitra
   *
   * - `body`: Mitra that should be updated
   *
   * @return successful operation
   */
  putSwitchingMitrasId(params: MitraService.PutSwitchingMitrasIdParams): __Observable<{success?: boolean, data?: Mitra, message?: string}> {
    return this.putSwitchingMitrasIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Mitra, message?: string})
    );
  }

  /**
   * Remove the specified Mitra from storage
   *
   * Delete Mitra
   * @param id id of Mitra
   * @return successful operation
   */
  deleteSwitchingMitrasIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/switching/mitras/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Mitra from storage
   *
   * Delete Mitra
   * @param id id of Mitra
   * @return successful operation
   */
  deleteSwitchingMitrasId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteSwitchingMitrasIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module MitraService {

  /**
   * Parameters for getSwitchingMitras
   */
  export interface GetSwitchingMitrasParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Mitra
     */
    filter?: string;
  }

  /**
   * Parameters for putSwitchingMitrasId
   */
  export interface PutSwitchingMitrasIdParams {

    /**
     * id of Mitra
     */
    id: number;

    /**
     * Mitra that should be updated
     */
    body?: Mitra;
  }
}

export { MitraService }
