/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AndroidBlok } from '../models/android-blok';
@Injectable({
  providedIn: 'root',
})
class AndroidBlokService extends __BaseService {
  static readonly getAndroidAndroidBloksPath = '/android/androidBloks';
  static readonly postAndroidAndroidBloksPath = '/android/androidBloks';
  static readonly getAndroidAndroidBloksIdPath = '/android/androidBloks/{id}';
  static readonly putAndroidAndroidBloksIdPath = '/android/androidBloks/{id}';
  static readonly deleteAndroidAndroidBloksIdPath = '/android/androidBloks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the AndroidBlok.
   *
   * Get all AndroidBlok
   * @param params The `AndroidBlokService.GetAndroidAndroidBloksParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter AndroidBlok
   *
   * @return successful operation
   */
  getAndroidAndroidBloksResponse(params: AndroidBlokService.GetAndroidAndroidBloksParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<AndroidBlok>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/android/androidBloks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<AndroidBlok>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the AndroidBlok.
   *
   * Get all AndroidBlok
   * @param params The `AndroidBlokService.GetAndroidAndroidBloksParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter AndroidBlok
   *
   * @return successful operation
   */
  getAndroidAndroidBloks(params: AndroidBlokService.GetAndroidAndroidBloksParams): __Observable<{success?: boolean, data?: Array<AndroidBlok>, message?: string, total_data?: number, total_page?: number}> {
    return this.getAndroidAndroidBloksResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<AndroidBlok>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created AndroidBlok in storage
   *
   * Store AndroidBlok
   * @param body AndroidBlok that should be stored
   * @return successful operation
   */
  postAndroidAndroidBloksResponse(body?: AndroidBlok): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidBlok, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/android/androidBloks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidBlok, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created AndroidBlok in storage
   *
   * Store AndroidBlok
   * @param body AndroidBlok that should be stored
   * @return successful operation
   */
  postAndroidAndroidBloks(body?: AndroidBlok): __Observable<{success?: boolean, data?: AndroidBlok, message?: string}> {
    return this.postAndroidAndroidBloksResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidBlok, message?: string})
    );
  }

  /**
   * Display the specified AndroidBlok
   *
   * Get AndroidBlok
   * @param id id of AndroidBlok
   * @return successful operation
   */
  getAndroidAndroidBloksIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidBlok, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/android/androidBloks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidBlok, message?: string}>;
      })
    );
  }
  /**
   * Display the specified AndroidBlok
   *
   * Get AndroidBlok
   * @param id id of AndroidBlok
   * @return successful operation
   */
  getAndroidAndroidBloksId(id: number): __Observable<{success?: boolean, data?: AndroidBlok, message?: string}> {
    return this.getAndroidAndroidBloksIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidBlok, message?: string})
    );
  }

  /**
   * Update the specified AndroidBlok in storage
   *
   * Update AndroidBlok
   * @param params The `AndroidBlokService.PutAndroidAndroidBloksIdParams` containing the following parameters:
   *
   * - `id`: id of AndroidBlok
   *
   * - `body`: AndroidBlok that should be updated
   *
   * @return successful operation
   */
  putAndroidAndroidBloksIdResponse(params: AndroidBlokService.PutAndroidAndroidBloksIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidBlok, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/android/androidBloks/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidBlok, message?: string}>;
      })
    );
  }
  /**
   * Update the specified AndroidBlok in storage
   *
   * Update AndroidBlok
   * @param params The `AndroidBlokService.PutAndroidAndroidBloksIdParams` containing the following parameters:
   *
   * - `id`: id of AndroidBlok
   *
   * - `body`: AndroidBlok that should be updated
   *
   * @return successful operation
   */
  putAndroidAndroidBloksId(params: AndroidBlokService.PutAndroidAndroidBloksIdParams): __Observable<{success?: boolean, data?: AndroidBlok, message?: string}> {
    return this.putAndroidAndroidBloksIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidBlok, message?: string})
    );
  }

  /**
   * Remove the specified AndroidBlok from storage
   *
   * Delete AndroidBlok
   * @param id id of AndroidBlok
   * @return successful operation
   */
  deleteAndroidAndroidBloksIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/android/androidBloks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified AndroidBlok from storage
   *
   * Delete AndroidBlok
   * @param id id of AndroidBlok
   * @return successful operation
   */
  deleteAndroidAndroidBloksId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteAndroidAndroidBloksIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module AndroidBlokService {

  /**
   * Parameters for getAndroidAndroidBloks
   */
  export interface GetAndroidAndroidBloksParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter AndroidBlok
     */
    filter?: string;
  }

  /**
   * Parameters for putAndroidAndroidBloksId
   */
  export interface PutAndroidAndroidBloksIdParams {

    /**
     * id of AndroidBlok
     */
    id: number;

    /**
     * AndroidBlok that should be updated
     */
    body?: AndroidBlok;
  }
}

export { AndroidBlokService }
