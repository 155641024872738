/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pelanggan_tagihan_dsml } from '../models/pelanggan-_tagihan-_dsml';
@Injectable({
  providedIn: 'root',
})
class PelangganTagihanDsmlService extends __BaseService {
  static readonly getPelangganReportPelangganTagihanDsmlsPath = '/pelangganReport/pelangganTagihanDsmls';
  static readonly postPelangganReportPelangganTagihanDsmlsPath = '/pelangganReport/pelangganTagihanDsmls';
  static readonly getPelangganReportPelangganTagihanDsmlsIdPath = '/pelangganReport/pelangganTagihanDsmls/{id}';
  static readonly putPelangganReportPelangganTagihanDsmlsIdPath = '/pelangganReport/pelangganTagihanDsmls/{id}';
  static readonly deletePelangganReportPelangganTagihanDsmlsIdPath = '/pelangganReport/pelangganTagihanDsmls/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the pelanggan_tagihan_dsml.
   *
   * Get all pelanggan_tagihan_dsml
   * @param params The `PelangganTagihanDsmlService.GetPelangganReportPelangganTagihanDsmlsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pelanggan_tagihan_dsml
   *
   * @return successful operation
   */
  getPelangganReportPelangganTagihanDsmlsResponse(params: PelangganTagihanDsmlService.GetPelangganReportPelangganTagihanDsmlsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_tagihan_dsml>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/pelangganTagihanDsmls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_tagihan_dsml>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pelanggan_tagihan_dsml.
   *
   * Get all pelanggan_tagihan_dsml
   * @param params The `PelangganTagihanDsmlService.GetPelangganReportPelangganTagihanDsmlsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pelanggan_tagihan_dsml
   *
   * @return successful operation
   */
  getPelangganReportPelangganTagihanDsmls(params: PelangganTagihanDsmlService.GetPelangganReportPelangganTagihanDsmlsParams): __Observable<{success?: boolean, data?: Array<Pelanggan_tagihan_dsml>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportPelangganTagihanDsmlsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pelanggan_tagihan_dsml>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pelanggan_tagihan_dsml in storage
   *
   * Store pelanggan_tagihan_dsml
   * @param body pelanggan_tagihan_dsml that should be stored
   * @return successful operation
   */
  postPelangganReportPelangganTagihanDsmlsResponse(body?: Pelanggan_tagihan_dsml): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/pelangganTagihanDsmls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pelanggan_tagihan_dsml in storage
   *
   * Store pelanggan_tagihan_dsml
   * @param body pelanggan_tagihan_dsml that should be stored
   * @return successful operation
   */
  postPelangganReportPelangganTagihanDsmls(body?: Pelanggan_tagihan_dsml): __Observable<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}> {
    return this.postPelangganReportPelangganTagihanDsmlsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string})
    );
  }

  /**
   * Display the specified pelanggan_tagihan_dsml
   *
   * Get pelanggan_tagihan_dsml
   * @param id id of pelanggan_tagihan_dsml
   * @return successful operation
   */
  getPelangganReportPelangganTagihanDsmlsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/pelangganTagihanDsmls/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pelanggan_tagihan_dsml
   *
   * Get pelanggan_tagihan_dsml
   * @param id id of pelanggan_tagihan_dsml
   * @return successful operation
   */
  getPelangganReportPelangganTagihanDsmlsId(id: number): __Observable<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}> {
    return this.getPelangganReportPelangganTagihanDsmlsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string})
    );
  }

  /**
   * Update the specified pelanggan_tagihan_dsml in storage
   *
   * Update pelanggan_tagihan_dsml
   * @param params The `PelangganTagihanDsmlService.PutPelangganReportPelangganTagihanDsmlsIdParams` containing the following parameters:
   *
   * - `id`: id of pelanggan_tagihan_dsml
   *
   * - `body`: pelanggan_tagihan_dsml that should be updated
   *
   * @return successful operation
   */
  putPelangganReportPelangganTagihanDsmlsIdResponse(params: PelangganTagihanDsmlService.PutPelangganReportPelangganTagihanDsmlsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/pelangganTagihanDsmls/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pelanggan_tagihan_dsml in storage
   *
   * Update pelanggan_tagihan_dsml
   * @param params The `PelangganTagihanDsmlService.PutPelangganReportPelangganTagihanDsmlsIdParams` containing the following parameters:
   *
   * - `id`: id of pelanggan_tagihan_dsml
   *
   * - `body`: pelanggan_tagihan_dsml that should be updated
   *
   * @return successful operation
   */
  putPelangganReportPelangganTagihanDsmlsId(params: PelangganTagihanDsmlService.PutPelangganReportPelangganTagihanDsmlsIdParams): __Observable<{success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string}> {
    return this.putPelangganReportPelangganTagihanDsmlsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_tagihan_dsml, message?: string})
    );
  }

  /**
   * Remove the specified pelanggan_tagihan_dsml from storage
   *
   * Delete pelanggan_tagihan_dsml
   * @param id id of pelanggan_tagihan_dsml
   * @return successful operation
   */
  deletePelangganReportPelangganTagihanDsmlsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/pelangganTagihanDsmls/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pelanggan_tagihan_dsml from storage
   *
   * Delete pelanggan_tagihan_dsml
   * @param id id of pelanggan_tagihan_dsml
   * @return successful operation
   */
  deletePelangganReportPelangganTagihanDsmlsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportPelangganTagihanDsmlsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PelangganTagihanDsmlService {

  /**
   * Parameters for getPelangganReportPelangganTagihanDsmls
   */
  export interface GetPelangganReportPelangganTagihanDsmlsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pelanggan_tagihan_dsml
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportPelangganTagihanDsmlsId
   */
  export interface PutPelangganReportPelangganTagihanDsmlsIdParams {

    /**
     * id of pelanggan_tagihan_dsml
     */
    id: number;

    /**
     * pelanggan_tagihan_dsml that should be updated
     */
    body?: Pelanggan_tagihan_dsml;
  }
}

export { PelangganTagihanDsmlService }
