/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AndroidBacaMeterService } from './services/android-baca-meter.service';
import { AndroidBlokService } from './services/android-blok.service';
import { AndroidPelangganService } from './services/android-pelanggan.service';
import { AndroidStatusMeterService } from './services/android-status-meter.service';
import { ArsipService } from './services/arsip.service';
import { LembagaService } from './services/lembaga.service';
import { MouService } from './services/mou.service';
import { MouAddendumService } from './services/mou-addendum.service';
import { SkPeraturanService } from './services/sk-peraturan.service';
import { SuratService } from './services/surat.service';
import { SuratTugasService } from './services/surat-tugas.service';
import { TelaahanStafService } from './services/telaahan-staf.service';
import { VoucherService } from './services/voucher.service';
import { CurrentuserService } from './services/currentuser.service';
import { LoginService } from './services/login.service';
import { LogoutService } from './services/logout.service';
import { RefreshService } from './services/refresh.service';
import { PegawaiPosisiService } from './services/pegawai-posisi.service';
import { BacaMeterService } from './services/baca-meter.service';
import { BacaMeterCicilService } from './services/baca-meter-cicil.service';
import { BacaMeterFotoService } from './services/baca-meter-foto.service';
import { BacaMeterJadwalService } from './services/baca-meter-jadwal.service';
import { BacaMeterPerubahanService } from './services/baca-meter-perubahan.service';
import { BacaMeterPerubahanTypeService } from './services/baca-meter-perubahan-type.service';
import { BacaMeterValidasiService } from './services/baca-meter-validasi.service';
import { BiayaPasangMeteranService } from './services/biaya-pasang-meteran.service';
import { BillingService } from './services/billing.service';
import { BillingPengecualianService } from './services/billing-pengecualian.service';
import { BillingPengecualianItemService } from './services/billing-pengecualian-item.service';
import { BillingPengecualianPelangganService } from './services/billing-pengecualian-pelanggan.service';
import { BillingPenyesuaianService } from './services/billing-penyesuaian.service';
import { BlokService } from './services/blok.service';
import { CabangService } from './services/cabang.service';
import { DocumentService } from './services/document.service';
import { KdPelangganOldService } from './services/kd-pelanggan-old.service';
import { MapingPembacaMeterService } from './services/maping-pembaca-meter.service';
import { MapingPembacaMeterAvailableService } from './services/maping-pembaca-meter-available.service';
import { MapingPembacaMeterDetailService } from './services/maping-pembaca-meter-detail.service';
import { MapingPembacaMeterUserService } from './services/maping-pembaca-meter-user.service';
import { MasterAirTangkiService } from './services/master-air-tangki.service';
import { ModuleService } from './services/module.service';
import { ModuleDetailService } from './services/module-detail.service';
import { PelangganService } from './services/pelanggan.service';
import { PelangganMeteranService } from './services/pelanggan-meteran.service';
import { PelangganPerubahanService } from './services/pelanggan-perubahan.service';
import { PelangganPerubahanBlokService } from './services/pelanggan-perubahan-blok.service';
import { PelangganPerubahanCabangService } from './services/pelanggan-perubahan-cabang.service';
import { PelangganPerubahanNamaService } from './services/pelanggan-perubahan-nama.service';
import { PelangganPerubahanStatusService } from './services/pelanggan-perubahan-status.service';
import { PelangganPerubahanTypePelangganService } from './services/pelanggan-perubahan-type-pelanggan.service';
import { PesananAirTangkiService } from './services/pesanan-air-tangki.service';
import { PesananAirTangkiItemService } from './services/pesanan-air-tangki-item.service';
import { PesananAirTangkiSuratJalanService } from './services/pesanan-air-tangki-surat-jalan.service';
import { PiayaPasangMeteranService } from './services/piaya-pasang-meteran.service';
import { RequestBatalTagihanService } from './services/request-batal-tagihan.service';
import { RiwayatPelangganPerubahanService } from './services/riwayat-pelanggan-perubahan.service';
import { StatusMeterService } from './services/status-meter.service';
import { SumberAirService } from './services/sumber-air.service';
import { TabelService } from './services/tabel.service';
import { TagihanService } from './services/tagihan.service';
import { TagihanBayarService } from './services/tagihan-bayar.service';
import { TagihanTangkiService } from './services/tagihan-tangki.service';
import { TypePelangganService } from './services/type-pelanggan.service';
import { TypePelangganPerPdamService } from './services/type-pelanggan-per-pdam.service';
import { UserAksesPenagihanService } from './services/user-akses-penagihan.service';
import { UserGroupService } from './services/user-group.service';
import { UserGroupBlokService } from './services/user-group-blok.service';
import { UserGroupDetailService } from './services/user-group-detail.service';
import { UsersService } from './services/users.service';
import { WilayahService } from './services/wilayah.service';
import { DocumentDownloadService } from './services/document-download.service';
import { SelectBillingService } from './services/select-billing.service';
import { PDFPelangganListTahihanService } from './services/pdfpelanggan-list-tahihan.service';
import { PDFBuktiPembayaranService } from './services/pdfbukti-pembayaran.service';
import { PDFBukuPelangganService } from './services/pdfbuku-pelanggan.service';
import { PDFDetailPelangganService } from './services/pdfdetail-pelanggan.service';
import { PDFDrdByDrdService } from './services/pdfdrd-by-drd.service';
import { PDFDrdByLokasiService } from './services/pdfdrd-by-lokasi.service';
import { PDFDrdByPelangganService } from './services/pdfdrd-by-pelanggan.service';
import { PDFDrdDetailService } from './services/pdfdrd-detail.service';
import { PDFDrdPdamService } from './services/pdfdrd-pdam.service';
import { PDFDrdTabularService } from './services/pdfdrd-tabular.service';
import { PDFDrdTypeLokasiService } from './services/pdfdrd-type-lokasi.service';
import { PDFDrdTypePelangganService } from './services/pdfdrd-type-pelanggan.service';
import { PDFDrdUnitService } from './services/pdfdrd-unit.service';
import { PDFDrdWilayahService } from './services/pdfdrd-wilayah.service';
import { PDFEfisiensiByLokasiService } from './services/pdfefisiensi-by-lokasi.service';
import { PDFEfisiensiByPeriodeService } from './services/pdfefisiensi-by-periode.service';
import { PDFEfisiensiByPeriodeCaterService } from './services/pdfefisiensi-by-periode-cater.service';
import { PDFEfisiensiByTypeService } from './services/pdfefisiensi-by-type.service';
import { PDFListTagihanService } from './services/pdflist-tagihan.service';
import { PDFLppDetailService } from './services/pdflpp-detail.service';
import { PDFLppHarianService } from './services/pdflpp-harian.service';
import { PDFLppLoketService } from './services/pdflpp-loket.service';
import { PDFLppTypePelangganService } from './services/pdflpp-type-pelanggan.service';
import { PDFOpnameSummaryService } from './services/pdfopname-summary.service';
import { PDFPelangganBillingDetailService } from './services/pdfpelanggan-billing-detail.service';
import { PDFPelangganBillingRekapService } from './services/pdfpelanggan-billing-rekap.service';
import { PDFPelangganEfektifitasService } from './services/pdfpelanggan-efektifitas.service';
import { PDFPenerimaanByLokasiService } from './services/pdfpenerimaan-by-lokasi.service';
import { PDFPenerimaanByPelangganService } from './services/pdfpenerimaan-by-pelanggan.service';
import { PDFPenerimaanByTypeService } from './services/pdfpenerimaan-by-type.service';
import { PDFRekapSetoranService } from './services/pdfrekap-setoran.service';
import { PDFSuratJalanTangkiService } from './services/pdfsurat-jalan-tangki.service';
import { PDFSuratPeringatanService } from './services/pdfsurat-peringatan.service';
import { PDFTagihanService } from './services/pdftagihan.service';
import { PDFTagihanBytipeService } from './services/pdftagihan-bytipe.service';
import { PDFTagihanDetailService } from './services/pdftagihan-detail.service';
import { PDFTagihanDsmlService } from './services/pdftagihan-dsml.service';
import { PDFTagihanDsplService } from './services/pdftagihan-dspl.service';
import { PDFTagihanDsplDetailService } from './services/pdftagihan-dspl-detail.service';
import { PDFTagihanSummaryService } from './services/pdftagihan-summary.service';
import { PDFTunggakanDetailService } from './services/pdftunggakan-detail.service';
import { PDFTunggakanSummaryService } from './services/pdftunggakan-summary.service';
import { PdfTagihanOpnameService } from './services/pdf-tagihan-opname.service';
import { PdfBacaMeterByOperatorService } from './services/pdf-baca-meter-by-operator.service';
import { TagihanOpnameService } from './services/tagihan-opname.service';
import { BacaMeterStatusPelangganService } from './services/baca-meter-status-pelanggan.service';
import { PdfPendapatanByUnitService } from './services/pdf-pendapatan-by-unit.service';
import { PdfPendapatanByUnitRekapService } from './services/pdf-pendapatan-by-unit-rekap.service';
import { BacaMeterByOperatorService } from './services/baca-meter-by-operator.service';
import { BacaMeterByStatusService } from './services/baca-meter-by-status.service';
import { BacaMeterByStatusLokasiService } from './services/baca-meter-by-status-lokasi.service';
import { BacaMeterKetepatanService } from './services/baca-meter-ketepatan.service';
import { BacaMeterStatusMeterService } from './services/baca-meter-status-meter.service';
import { EfisiensiByCaterService } from './services/efisiensi-by-cater.service';
import { EfisiensiByCaterGrafikService } from './services/efisiensi-by-cater-grafik.service';
import { EfisiensiByCaterPeriodeService } from './services/efisiensi-by-cater-periode.service';
import { EfisiensiByGrafikService } from './services/efisiensi-by-grafik.service';
import { EfisiensiByTypeService } from './services/efisiensi-by-type.service';
import { PDFTunggakanTertagihCaterService } from './services/pdftunggakan-tertagih-cater.service';
import { PelangganBillingService } from './services/pelanggan-billing.service';
import { PelangganBillingDsplService } from './services/pelanggan-billing-dspl.service';
import { PelangganBillingDsplDetailService } from './services/pelanggan-billing-dspl-detail.service';
import { PelangganByLokasiService } from './services/pelanggan-by-lokasi.service';
import { PelangganByPerodeService } from './services/pelanggan-by-perode.service';
import { PelangganEfisiensiService } from './services/pelanggan-efisiensi.service';
import { PelangganListDsml1Service } from './services/pelanggan-list-dsml-1.service';
import { PelangganListDsmlService } from './services/pelanggan-list-dsml.service';
import { PenerimaanSambunganBaruService } from './services/penerimaan-sambungan-baru.service';
import { ReportJrPdamService } from './services/report-jr-pdam.service';
import { ReportPendapatanLainnyaService } from './services/report-pendapatan-lainnya.service';
import { TagihanByBillingLokasiService } from './services/tagihan-by-billing-lokasi.service';
import { TagihanByBillingLoketService } from './services/tagihan-by-billing-loket.service';
import { TagihanByBillingPenerimaanService } from './services/tagihan-by-billing-penerimaan.service';
import { TagihanByBillingPeriodeService } from './services/tagihan-by-billing-periode.service';
import { TagihanByBillingTipeService } from './services/tagihan-by-billing-tipe.service';
import { TagihanByLokasiService } from './services/tagihan-by-lokasi.service';
import { TagihanDsmlByLokasiService } from './services/tagihan-dsml-by-lokasi.service';
import { TagihanDsplByLokasiService } from './services/tagihan-dspl-by-lokasi.service';
import { TagihanDsplByWidgetService } from './services/tagihan-dspl-by-widget.service';
import { TunggakanByLokasiService } from './services/tunggakan-by-lokasi.service';
import { TunggakanByTipeService } from './services/tunggakan-by-tipe.service';
import { TunggakanByTipePelangganService } from './services/tunggakan-by-tipe-pelanggan.service';
import { TunggakanByTopPelangganService } from './services/tunggakan-by-top-pelanggan.service';
import { TunggakanByTotalService } from './services/tunggakan-by-total.service';
import { TunggakanTertagihService } from './services/tunggakan-tertagih.service';
import { DrdByGrafikService } from './services/drd-by-grafik.service';
import { DrdByLokasiService } from './services/drd-by-lokasi.service';
import { DrdByPeriodeService } from './services/drd-by-periode.service';
import { DrdReportService } from './services/drd-report.service';
import { EfiensiByLokasiService } from './services/efiensi-by-lokasi.service';
import { EfiensiByPeriodeService } from './services/efiensi-by-periode.service';
import { PelangganByStatusService } from './services/pelanggan-by-status.service';
import { PelangganByStatusPerubahanService } from './services/pelanggan-by-status-perubahan.service';
import { PelangganPerubahanReportService } from './services/pelanggan-perubahan-report.service';
import { PelangganTagihanDsmlService } from './services/pelanggan-tagihan-dsml.service';
import { ReportJpkPdamService } from './services/report-jpk-pdam.service';
import { TunggakanTertagihCaterService } from './services/tunggakan-tertagih-cater.service';
import { RekapJanjiBayarByCaterService } from './services/rekap-janji-bayar-by-cater.service';
import { RekapJanjiBayarByLokasiService } from './services/rekap-janji-bayar-by-lokasi.service';
import { RekapKinerjaPenagihanService } from './services/rekap-kinerja-penagihan.service';
import { RekapPenagihanByCaterService } from './services/rekap-penagihan-by-cater.service';
import { RekapPenagihanByLokasiService } from './services/rekap-penagihan-by-lokasi.service';
import { RekapTertagihByCaterService } from './services/rekap-tertagih-by-cater.service';
import { RekapTertagihByLokasiService } from './services/rekap-tertagih-by-lokasi.service';
import { RekapPelangganBayarService } from './services/rekap-pelanggan-bayar.service';
import { RiwayatPelangganPeprubahanService } from './services/riwayat-pelanggan-peprubahan.service';
import { PelangganSebaranService } from './services/pelanggan-sebaran.service';
import { PenerimaanTahunBerjalanCabangService } from './services/penerimaan-tahun-berjalan-cabang.service';
import { PenerimaanTahunBerjalanTypeService } from './services/penerimaan-tahun-berjalan-type.service';
import { JournalPenerimaanService } from './services/journal-penerimaan.service';
import { JournalPenerimaanItemService } from './services/journal-penerimaan-item.service';
import { JournalPenerimaanItemRekapService } from './services/journal-penerimaan-item-rekap.service';
import { JournalPenerimaanRekapService } from './services/journal-penerimaan-rekap.service';
import { JournalReportService } from './services/journal-report.service';
import { JournalReportItemService } from './services/journal-report-item.service';
import { JournalReportItemRekapService } from './services/journal-report-item-rekap.service';
import { JournalReportRekapService } from './services/journal-report-rekap.service';
import { NeracaPiutangService } from './services/neraca-piutang.service';
import { PelangganSebaranTagihanService } from './services/pelanggan-sebaran-tagihan.service';
import { PendapatanByTypeService } from './services/pendapatan-by-type.service';
import { PendapatanByUnitService } from './services/pendapatan-by-unit.service';
import { PendapatanByUnitRekapService } from './services/pendapatan-by-unit-rekap.service';
import { PenerimaanService } from './services/penerimaan.service';
import { PenerimaanRekapService } from './services/penerimaan-rekap.service';
import { SwitchingReportService } from './services/switching-report.service';
import { PdfJournalPenerimaanService } from './services/pdf-journal-penerimaan.service';
import { PdfJournalPenerimaanItemService } from './services/pdf-journal-penerimaan-item.service';
import { PdfJournalPenerimaanItemRekapService } from './services/pdf-journal-penerimaan-item-rekap.service';
import { PdfJournalPenerimaanRekapService } from './services/pdf-journal-penerimaan-rekap.service';
import { PdfJournalReportService } from './services/pdf-journal-report.service';
import { PdfJournalReportItemService } from './services/pdf-journal-report-item.service';
import { PdfJournalReportItemRekapService } from './services/pdf-journal-report-item-rekap.service';
import { PdfJournalReportRekapService } from './services/pdf-journal-report-rekap.service';
import { PdfPendapatanService } from './services/pdf-pendapatan.service';
import { PdfPendapatanRekapService } from './services/pdf-pendapatan-rekap.service';
import { PdfPenerimaanService } from './services/pdf-penerimaan.service';
import { PdfPenerimaanRekapService } from './services/pdf-penerimaan-rekap.service';
import { PdfSwitchingReportService } from './services/pdf-switching-report.service';
import { MitraService } from './services/mitra.service';
import { MitraKodeService } from './services/mitra-kode.service';
import { MitraKodeBelumTerdaftarService } from './services/mitra-kode-belum-terdaftar.service';
import { PartnerService } from './services/partner.service';
import { VotingService } from './services/voting.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AndroidBacaMeterService,
    AndroidBlokService,
    AndroidPelangganService,
    AndroidStatusMeterService,
    ArsipService,
    LembagaService,
    MouService,
    MouAddendumService,
    SkPeraturanService,
    SuratService,
    SuratTugasService,
    TelaahanStafService,
    VoucherService,
    CurrentuserService,
    LoginService,
    LogoutService,
    RefreshService,
    PegawaiPosisiService,
    BacaMeterService,
    BacaMeterCicilService,
    BacaMeterFotoService,
    BacaMeterJadwalService,
    BacaMeterPerubahanService,
    BacaMeterPerubahanTypeService,
    BacaMeterValidasiService,
    BiayaPasangMeteranService,
    BillingService,
    BillingPengecualianService,
    BillingPengecualianItemService,
    BillingPengecualianPelangganService,
    BillingPenyesuaianService,
    BlokService,
    CabangService,
    DocumentService,
    KdPelangganOldService,
    MapingPembacaMeterService,
    MapingPembacaMeterAvailableService,
    MapingPembacaMeterDetailService,
    MapingPembacaMeterUserService,
    MasterAirTangkiService,
    ModuleService,
    ModuleDetailService,
    PelangganService,
    PelangganMeteranService,
    PelangganPerubahanService,
    PelangganPerubahanBlokService,
    PelangganPerubahanCabangService,
    PelangganPerubahanNamaService,
    PelangganPerubahanStatusService,
    PelangganPerubahanTypePelangganService,
    PesananAirTangkiService,
    PesananAirTangkiItemService,
    PesananAirTangkiSuratJalanService,
    PiayaPasangMeteranService,
    RequestBatalTagihanService,
    RiwayatPelangganPerubahanService,
    StatusMeterService,
    SumberAirService,
    TabelService,
    TagihanService,
    TagihanBayarService,
    TagihanTangkiService,
    TypePelangganService,
    TypePelangganPerPdamService,
    UserAksesPenagihanService,
    UserGroupService,
    UserGroupBlokService,
    UserGroupDetailService,
    UsersService,
    WilayahService,
    DocumentDownloadService,
    SelectBillingService,
    PDFPelangganListTahihanService,
    PDFBuktiPembayaranService,
    PDFBukuPelangganService,
    PDFDetailPelangganService,
    PDFDrdByDrdService,
    PDFDrdByLokasiService,
    PDFDrdByPelangganService,
    PDFDrdDetailService,
    PDFDrdPdamService,
    PDFDrdTabularService,
    PDFDrdTypeLokasiService,
    PDFDrdTypePelangganService,
    PDFDrdUnitService,
    PDFDrdWilayahService,
    PDFEfisiensiByLokasiService,
    PDFEfisiensiByPeriodeService,
    PDFEfisiensiByPeriodeCaterService,
    PDFEfisiensiByTypeService,
    PDFListTagihanService,
    PDFLppDetailService,
    PDFLppHarianService,
    PDFLppLoketService,
    PDFLppTypePelangganService,
    PDFOpnameSummaryService,
    PDFPelangganBillingDetailService,
    PDFPelangganBillingRekapService,
    PDFPelangganEfektifitasService,
    PDFPenerimaanByLokasiService,
    PDFPenerimaanByPelangganService,
    PDFPenerimaanByTypeService,
    PDFRekapSetoranService,
    PDFSuratJalanTangkiService,
    PDFSuratPeringatanService,
    PDFTagihanService,
    PDFTagihanBytipeService,
    PDFTagihanDetailService,
    PDFTagihanDsmlService,
    PDFTagihanDsplService,
    PDFTagihanDsplDetailService,
    PDFTagihanSummaryService,
    PDFTunggakanDetailService,
    PDFTunggakanSummaryService,
    PdfTagihanOpnameService,
    PdfBacaMeterByOperatorService,
    TagihanOpnameService,
    BacaMeterStatusPelangganService,
    PdfPendapatanByUnitService,
    PdfPendapatanByUnitRekapService,
    BacaMeterByOperatorService,
    BacaMeterByStatusService,
    BacaMeterByStatusLokasiService,
    BacaMeterKetepatanService,
    BacaMeterStatusMeterService,
    EfisiensiByCaterService,
    EfisiensiByCaterGrafikService,
    EfisiensiByCaterPeriodeService,
    EfisiensiByGrafikService,
    EfisiensiByTypeService,
    PDFTunggakanTertagihCaterService,
    PelangganBillingService,
    PelangganBillingDsplService,
    PelangganBillingDsplDetailService,
    PelangganByLokasiService,
    PelangganByPerodeService,
    PelangganEfisiensiService,
    PelangganListDsml1Service,
    PelangganListDsmlService,
    PenerimaanSambunganBaruService,
    ReportJrPdamService,
    ReportPendapatanLainnyaService,
    TagihanByBillingLokasiService,
    TagihanByBillingLoketService,
    TagihanByBillingPenerimaanService,
    TagihanByBillingPeriodeService,
    TagihanByBillingTipeService,
    TagihanByLokasiService,
    TagihanDsmlByLokasiService,
    TagihanDsplByLokasiService,
    TagihanDsplByWidgetService,
    TunggakanByLokasiService,
    TunggakanByTipeService,
    TunggakanByTipePelangganService,
    TunggakanByTopPelangganService,
    TunggakanByTotalService,
    TunggakanTertagihService,
    DrdByGrafikService,
    DrdByLokasiService,
    DrdByPeriodeService,
    DrdReportService,
    EfiensiByLokasiService,
    EfiensiByPeriodeService,
    PelangganByStatusService,
    PelangganByStatusPerubahanService,
    PelangganPerubahanReportService,
    PelangganTagihanDsmlService,
    ReportJpkPdamService,
    TunggakanTertagihCaterService,
    RekapJanjiBayarByCaterService,
    RekapJanjiBayarByLokasiService,
    RekapKinerjaPenagihanService,
    RekapPenagihanByCaterService,
    RekapPenagihanByLokasiService,
    RekapTertagihByCaterService,
    RekapTertagihByLokasiService,
    RekapPelangganBayarService,
    RiwayatPelangganPeprubahanService,
    PelangganSebaranService,
    PenerimaanTahunBerjalanCabangService,
    PenerimaanTahunBerjalanTypeService,
    JournalPenerimaanService,
    JournalPenerimaanItemService,
    JournalPenerimaanItemRekapService,
    JournalPenerimaanRekapService,
    JournalReportService,
    JournalReportItemService,
    JournalReportItemRekapService,
    JournalReportRekapService,
    NeracaPiutangService,
    PelangganSebaranTagihanService,
    PendapatanByTypeService,
    PendapatanByUnitService,
    PendapatanByUnitRekapService,
    PenerimaanService,
    PenerimaanRekapService,
    SwitchingReportService,
    PdfJournalPenerimaanService,
    PdfJournalPenerimaanItemService,
    PdfJournalPenerimaanItemRekapService,
    PdfJournalPenerimaanRekapService,
    PdfJournalReportService,
    PdfJournalReportItemService,
    PdfJournalReportItemRekapService,
    PdfJournalReportRekapService,
    PdfPendapatanService,
    PdfPendapatanRekapService,
    PdfPenerimaanService,
    PdfPenerimaanRekapService,
    PdfSwitchingReportService,
    MitraService,
    MitraKodeService,
    MitraKodeBelumTerdaftarService,
    PartnerService,
    VotingService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
