/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Kd_Pelanggan_old } from '../models/kd-_pelanggan-_old';
@Injectable({
  providedIn: 'root',
})
class KdPelangganOldService extends __BaseService {
  static readonly getPelangganKdPelangganOldsPath = '/pelanggan/kdPelangganOlds';
  static readonly postPelangganKdPelangganOldsPath = '/pelanggan/kdPelangganOlds';
  static readonly getPelangganKdPelangganOldsIdPath = '/pelanggan/kdPelangganOlds/{id}';
  static readonly putPelangganKdPelangganOldsIdPath = '/pelanggan/kdPelangganOlds/{id}';
  static readonly deletePelangganKdPelangganOldsIdPath = '/pelanggan/kdPelangganOlds/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Kd_Pelanggan_old.
   *
   * Get all Kd_Pelanggan_old
   * @param params The `KdPelangganOldService.GetPelangganKdPelangganOldsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Maping_pembaca_meter_available
   *
   * @return successful operation
   */
  getPelangganKdPelangganOldsResponse(params: KdPelangganOldService.GetPelangganKdPelangganOldsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Kd_Pelanggan_old>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/kdPelangganOlds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Kd_Pelanggan_old>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Kd_Pelanggan_old.
   *
   * Get all Kd_Pelanggan_old
   * @param params The `KdPelangganOldService.GetPelangganKdPelangganOldsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Maping_pembaca_meter_available
   *
   * @return successful operation
   */
  getPelangganKdPelangganOlds(params: KdPelangganOldService.GetPelangganKdPelangganOldsParams): __Observable<{success?: boolean, data?: Array<Kd_Pelanggan_old>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganKdPelangganOldsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Kd_Pelanggan_old>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Kd_Pelanggan_old in storage
   *
   * Store Kd_Pelanggan_old
   * @param body Kd_Pelanggan_old that should be stored
   * @return successful operation
   */
  postPelangganKdPelangganOldsResponse(body?: Kd_Pelanggan_old): __Observable<__StrictHttpResponse<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/kdPelangganOlds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Kd_Pelanggan_old in storage
   *
   * Store Kd_Pelanggan_old
   * @param body Kd_Pelanggan_old that should be stored
   * @return successful operation
   */
  postPelangganKdPelangganOlds(body?: Kd_Pelanggan_old): __Observable<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}> {
    return this.postPelangganKdPelangganOldsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Kd_Pelanggan_old, message?: string})
    );
  }

  /**
   * Display the specified Kd_Pelanggan_old
   *
   * Get Kd_Pelanggan_old
   * @param id id of Kd_Pelanggan_old
   * @return successful operation
   */
  getPelangganKdPelangganOldsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/kdPelangganOlds/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Kd_Pelanggan_old
   *
   * Get Kd_Pelanggan_old
   * @param id id of Kd_Pelanggan_old
   * @return successful operation
   */
  getPelangganKdPelangganOldsId(id: number): __Observable<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}> {
    return this.getPelangganKdPelangganOldsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Kd_Pelanggan_old, message?: string})
    );
  }

  /**
   * Update the specified Kd_Pelanggan_old in storage
   *
   * Update Kd_Pelanggan_old
   * @param params The `KdPelangganOldService.PutPelangganKdPelangganOldsIdParams` containing the following parameters:
   *
   * - `id`: id of Kd_Pelanggan_old
   *
   * - `body`: Kd_Pelanggan_old that should be updated
   *
   * @return successful operation
   */
  putPelangganKdPelangganOldsIdResponse(params: KdPelangganOldService.PutPelangganKdPelangganOldsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/kdPelangganOlds/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Kd_Pelanggan_old in storage
   *
   * Update Kd_Pelanggan_old
   * @param params The `KdPelangganOldService.PutPelangganKdPelangganOldsIdParams` containing the following parameters:
   *
   * - `id`: id of Kd_Pelanggan_old
   *
   * - `body`: Kd_Pelanggan_old that should be updated
   *
   * @return successful operation
   */
  putPelangganKdPelangganOldsId(params: KdPelangganOldService.PutPelangganKdPelangganOldsIdParams): __Observable<{success?: boolean, data?: Kd_Pelanggan_old, message?: string}> {
    return this.putPelangganKdPelangganOldsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Kd_Pelanggan_old, message?: string})
    );
  }

  /**
   * Remove the specified Kd_Pelanggan_old from storage
   *
   * Delete Kd_Pelanggan_old
   * @param id id of Kd_Pelanggan_old
   * @return successful operation
   */
  deletePelangganKdPelangganOldsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/kdPelangganOlds/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Kd_Pelanggan_old from storage
   *
   * Delete Kd_Pelanggan_old
   * @param id id of Kd_Pelanggan_old
   * @return successful operation
   */
  deletePelangganKdPelangganOldsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganKdPelangganOldsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module KdPelangganOldService {

  /**
   * Parameters for getPelangganKdPelangganOlds
   */
  export interface GetPelangganKdPelangganOldsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Maping_pembaca_meter_available
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganKdPelangganOldsId
   */
  export interface PutPelangganKdPelangganOldsIdParams {

    /**
     * id of Kd_Pelanggan_old
     */
    id: number;

    /**
     * Kd_Pelanggan_old that should be updated
     */
    body?: Kd_Pelanggan_old;
  }
}

export { KdPelangganOldService }
