/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Penerimaan_sambungan_baru } from '../models/penerimaan-_sambungan-_baru';
@Injectable({
  providedIn: 'root',
})
class PenerimaanSambunganBaruService extends __BaseService {
  static readonly getPelangganReportPenerimaanSambunganBarusPath = '/pelangganReport/penerimaanSambunganBarus';
  static readonly postPelangganReportPenerimaanSambunganBarusPath = '/pelangganReport/penerimaanSambunganBarus';
  static readonly getPelangganReportPenerimaanSambunganBarusIdPath = '/pelangganReport/penerimaanSambunganBarus/{id}';
  static readonly putPelangganReportPenerimaanSambunganBarusIdPath = '/pelangganReport/penerimaanSambunganBarus/{id}';
  static readonly deletePelangganReportPenerimaanSambunganBarusIdPath = '/pelangganReport/penerimaanSambunganBarus/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Penerimaan_sambungan_baru.
   *
   * Get all Penerimaan_sambungan_baru
   * @param params The `PenerimaanSambunganBaruService.GetPelangganReportPenerimaanSambunganBarusParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penerimaan_sambungan_baru
   *
   * @return successful operation
   */
  getPelangganReportPenerimaanSambunganBarusResponse(params: PenerimaanSambunganBaruService.GetPelangganReportPenerimaanSambunganBarusParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Penerimaan_sambungan_baru>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/penerimaanSambunganBarus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Penerimaan_sambungan_baru>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Penerimaan_sambungan_baru.
   *
   * Get all Penerimaan_sambungan_baru
   * @param params The `PenerimaanSambunganBaruService.GetPelangganReportPenerimaanSambunganBarusParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Penerimaan_sambungan_baru
   *
   * @return successful operation
   */
  getPelangganReportPenerimaanSambunganBarus(params: PenerimaanSambunganBaruService.GetPelangganReportPenerimaanSambunganBarusParams): __Observable<{success?: boolean, data?: Array<Penerimaan_sambungan_baru>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportPenerimaanSambunganBarusResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Penerimaan_sambungan_baru>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Penerimaan_sambungan_baru in storage
   *
   * Store Penerimaan_sambungan_baru
   * @param body Penerimaan_sambungan_baru that should be stored
   * @return successful operation
   */
  postPelangganReportPenerimaanSambunganBarusResponse(body?: Penerimaan_sambungan_baru): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/penerimaanSambunganBarus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Penerimaan_sambungan_baru in storage
   *
   * Store Penerimaan_sambungan_baru
   * @param body Penerimaan_sambungan_baru that should be stored
   * @return successful operation
   */
  postPelangganReportPenerimaanSambunganBarus(body?: Penerimaan_sambungan_baru): __Observable<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}> {
    return this.postPelangganReportPenerimaanSambunganBarusResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penerimaan_sambungan_baru, message?: string})
    );
  }

  /**
   * Display the specified Penerimaan_sambungan_baru
   *
   * Get Penerimaan_sambungan_baru
   * @param id id of Penerimaan_sambungan_baru
   * @return successful operation
   */
  getPelangganReportPenerimaanSambunganBarusIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/penerimaanSambunganBarus/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Penerimaan_sambungan_baru
   *
   * Get Penerimaan_sambungan_baru
   * @param id id of Penerimaan_sambungan_baru
   * @return successful operation
   */
  getPelangganReportPenerimaanSambunganBarusId(id: number): __Observable<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}> {
    return this.getPelangganReportPenerimaanSambunganBarusIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penerimaan_sambungan_baru, message?: string})
    );
  }

  /**
   * Update the specified Penerimaan_sambungan_baru in storage
   *
   * Update Penerimaan_sambungan_baru
   * @param params The `PenerimaanSambunganBaruService.PutPelangganReportPenerimaanSambunganBarusIdParams` containing the following parameters:
   *
   * - `id`: id of Penerimaan_sambungan_baru
   *
   * - `body`: Penerimaan_sambungan_baru that should be updated
   *
   * @return successful operation
   */
  putPelangganReportPenerimaanSambunganBarusIdResponse(params: PenerimaanSambunganBaruService.PutPelangganReportPenerimaanSambunganBarusIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/penerimaanSambunganBarus/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Penerimaan_sambungan_baru in storage
   *
   * Update Penerimaan_sambungan_baru
   * @param params The `PenerimaanSambunganBaruService.PutPelangganReportPenerimaanSambunganBarusIdParams` containing the following parameters:
   *
   * - `id`: id of Penerimaan_sambungan_baru
   *
   * - `body`: Penerimaan_sambungan_baru that should be updated
   *
   * @return successful operation
   */
  putPelangganReportPenerimaanSambunganBarusId(params: PenerimaanSambunganBaruService.PutPelangganReportPenerimaanSambunganBarusIdParams): __Observable<{success?: boolean, data?: Penerimaan_sambungan_baru, message?: string}> {
    return this.putPelangganReportPenerimaanSambunganBarusIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Penerimaan_sambungan_baru, message?: string})
    );
  }

  /**
   * Remove the specified Penerimaan_sambungan_baru from storage
   *
   * Delete Penerimaan_sambungan_baru
   * @param id id of Penerimaan_sambungan_baru
   * @return successful operation
   */
  deletePelangganReportPenerimaanSambunganBarusIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/penerimaanSambunganBarus/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Penerimaan_sambungan_baru from storage
   *
   * Delete Penerimaan_sambungan_baru
   * @param id id of Penerimaan_sambungan_baru
   * @return successful operation
   */
  deletePelangganReportPenerimaanSambunganBarusId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportPenerimaanSambunganBarusIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PenerimaanSambunganBaruService {

  /**
   * Parameters for getPelangganReportPenerimaanSambunganBarus
   */
  export interface GetPelangganReportPenerimaanSambunganBarusParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Penerimaan_sambungan_baru
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportPenerimaanSambunganBarusId
   */
  export interface PutPelangganReportPenerimaanSambunganBarusIdParams {

    /**
     * id of Penerimaan_sambungan_baru
     */
    id: number;

    /**
     * Penerimaan_sambungan_baru that should be updated
     */
    body?: Penerimaan_sambungan_baru;
  }
}

export { PenerimaanSambunganBaruService }
