/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Collector_target_follow_foto } from '../models/collector-_target-_follow-_foto';
@Injectable({
  providedIn: 'root',
})
class CollectorTargetFollowFotoService extends __BaseService {
  static readonly getPenagihanReportCollectorTargetFollowFotosPath = '/penagihanReport/collectorTargetFollowFotos';
  static readonly postPenagihanReportCollectorTargetFollowFotosPath = '/penagihanReport/collectorTargetFollowFotos';
  static readonly getPenagihanReportCollectorTargetFollowFotosIdPath = '/penagihanReport/collectorTargetFollowFotos/{id}';
  static readonly putPenagihanReportCollectorTargetFollowFotosIdPath = '/penagihanReport/collectorTargetFollowFotos/{id}';
  static readonly deletePenagihanReportCollectorTargetFollowFotosIdPath = '/penagihanReport/collectorTargetFollowFotos/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the collector_target_follow_foto.
   *
   * Get all collector_target_follow_foto
   * @param params The `CollectorTargetFollowFotoService.GetPenagihanReportCollectorTargetFollowFotosParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter collector_target_follow_foto
   *
   * @return successful operation
   */
  getPenagihanReportCollectorTargetFollowFotosResponse(params: CollectorTargetFollowFotoService.GetPenagihanReportCollectorTargetFollowFotosParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Collector_target_follow_foto>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/collectorTargetFollowFotos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Collector_target_follow_foto>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the collector_target_follow_foto.
   *
   * Get all collector_target_follow_foto
   * @param params The `CollectorTargetFollowFotoService.GetPenagihanReportCollectorTargetFollowFotosParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter collector_target_follow_foto
   *
   * @return successful operation
   */
  getPenagihanReportCollectorTargetFollowFotos(params: CollectorTargetFollowFotoService.GetPenagihanReportCollectorTargetFollowFotosParams): __Observable<{success?: boolean, data?: Array<Collector_target_follow_foto>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportCollectorTargetFollowFotosResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Collector_target_follow_foto>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created collector_target_follow_foto in storage
   *
   * Store collector_target_follow_foto
   * @param body collector_target_follow_foto that should be stored
   * @return successful operation
   */
  postPenagihanReportCollectorTargetFollowFotosResponse(body?: Collector_target_follow_foto): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_target_follow_foto, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/collectorTargetFollowFotos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_target_follow_foto, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created collector_target_follow_foto in storage
   *
   * Store collector_target_follow_foto
   * @param body collector_target_follow_foto that should be stored
   * @return successful operation
   */
  postPenagihanReportCollectorTargetFollowFotos(body?: Collector_target_follow_foto): __Observable<{success?: boolean, data?: Collector_target_follow_foto, message?: string}> {
    return this.postPenagihanReportCollectorTargetFollowFotosResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_target_follow_foto, message?: string})
    );
  }

  /**
   * Display the specified collector_target_follow_foto
   *
   * Get collector_target_follow_foto
   * @param id id of collector_target_follow_foto
   * @return successful operation
   */
  getPenagihanReportCollectorTargetFollowFotosIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_target_follow_foto, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/collectorTargetFollowFotos/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_target_follow_foto, message?: string}>;
      })
    );
  }
  /**
   * Display the specified collector_target_follow_foto
   *
   * Get collector_target_follow_foto
   * @param id id of collector_target_follow_foto
   * @return successful operation
   */
  getPenagihanReportCollectorTargetFollowFotosId(id: number): __Observable<{success?: boolean, data?: Collector_target_follow_foto, message?: string}> {
    return this.getPenagihanReportCollectorTargetFollowFotosIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_target_follow_foto, message?: string})
    );
  }

  /**
   * Update the specified collector_target_follow_foto in storage
   *
   * Update collector_target_follow_foto
   * @param params The `CollectorTargetFollowFotoService.PutPenagihanReportCollectorTargetFollowFotosIdParams` containing the following parameters:
   *
   * - `id`: id of collector_target_follow_foto
   *
   * - `body`: collector_target_follow_foto that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportCollectorTargetFollowFotosIdResponse(params: CollectorTargetFollowFotoService.PutPenagihanReportCollectorTargetFollowFotosIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Collector_target_follow_foto, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/collectorTargetFollowFotos/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Collector_target_follow_foto, message?: string}>;
      })
    );
  }
  /**
   * Update the specified collector_target_follow_foto in storage
   *
   * Update collector_target_follow_foto
   * @param params The `CollectorTargetFollowFotoService.PutPenagihanReportCollectorTargetFollowFotosIdParams` containing the following parameters:
   *
   * - `id`: id of collector_target_follow_foto
   *
   * - `body`: collector_target_follow_foto that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportCollectorTargetFollowFotosId(params: CollectorTargetFollowFotoService.PutPenagihanReportCollectorTargetFollowFotosIdParams): __Observable<{success?: boolean, data?: Collector_target_follow_foto, message?: string}> {
    return this.putPenagihanReportCollectorTargetFollowFotosIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Collector_target_follow_foto, message?: string})
    );
  }

  /**
   * Remove the specified collector_target_follow_foto from storage
   *
   * Delete collector_target_follow_foto
   * @param id id of collector_target_follow_foto
   * @return successful operation
   */
  deletePenagihanReportCollectorTargetFollowFotosIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/collectorTargetFollowFotos/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified collector_target_follow_foto from storage
   *
   * Delete collector_target_follow_foto
   * @param id id of collector_target_follow_foto
   * @return successful operation
   */
  deletePenagihanReportCollectorTargetFollowFotosId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportCollectorTargetFollowFotosIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module CollectorTargetFollowFotoService {

  /**
   * Parameters for getPenagihanReportCollectorTargetFollowFotos
   */
  export interface GetPenagihanReportCollectorTargetFollowFotosParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter collector_target_follow_foto
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportCollectorTargetFollowFotosId
   */
  export interface PutPenagihanReportCollectorTargetFollowFotosIdParams {

    /**
     * id of collector_target_follow_foto
     */
    id: number;

    /**
     * collector_target_follow_foto that should be updated
     */
    body?: Collector_target_follow_foto;
  }
}

export { CollectorTargetFollowFotoService }
