/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Module } from '../models/module';
@Injectable({
  providedIn: 'root',
})
class ModuleService extends __BaseService {
  static readonly getPelangganModulesPath = '/pelanggan/modules';
  static readonly postPelangganModulesPath = '/pelanggan/modules';
  static readonly getPelangganModulesIdPath = '/pelanggan/modules/{id}';
  static readonly putPelangganModulesIdPath = '/pelanggan/modules/{id}';
  static readonly deletePelangganModulesIdPath = '/pelanggan/modules/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Module.
   *
   * Get all Module
   * @param params The `ModuleService.GetPelangganModulesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Module
   *
   * @return successful operation
   */
  getPelangganModulesResponse(params: ModuleService.GetPelangganModulesParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Module>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/modules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Module>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Module.
   *
   * Get all Module
   * @param params The `ModuleService.GetPelangganModulesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Module
   *
   * @return successful operation
   */
  getPelangganModules(params: ModuleService.GetPelangganModulesParams): __Observable<{success?: boolean, data?: Array<Module>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganModulesResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Module>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Module in storage
   *
   * Store Module
   * @param body Module that should be stored
   * @return successful operation
   */
  postPelangganModulesResponse(body?: Module): __Observable<__StrictHttpResponse<{success?: boolean, data?: Module, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/modules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Module, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Module in storage
   *
   * Store Module
   * @param body Module that should be stored
   * @return successful operation
   */
  postPelangganModules(body?: Module): __Observable<{success?: boolean, data?: Module, message?: string}> {
    return this.postPelangganModulesResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Module, message?: string})
    );
  }

  /**
   * Display the specified Module
   *
   * Get Module
   * @param id id of Module
   * @return successful operation
   */
  getPelangganModulesIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Module, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/modules/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Module, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Module
   *
   * Get Module
   * @param id id of Module
   * @return successful operation
   */
  getPelangganModulesId(id: number): __Observable<{success?: boolean, data?: Module, message?: string}> {
    return this.getPelangganModulesIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Module, message?: string})
    );
  }

  /**
   * Update the specified Module in storage
   *
   * Update Module
   * @param params The `ModuleService.PutPelangganModulesIdParams` containing the following parameters:
   *
   * - `id`: id of Module
   *
   * - `body`: Module that should be updated
   *
   * @return successful operation
   */
  putPelangganModulesIdResponse(params: ModuleService.PutPelangganModulesIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Module, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/modules/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Module, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Module in storage
   *
   * Update Module
   * @param params The `ModuleService.PutPelangganModulesIdParams` containing the following parameters:
   *
   * - `id`: id of Module
   *
   * - `body`: Module that should be updated
   *
   * @return successful operation
   */
  putPelangganModulesId(params: ModuleService.PutPelangganModulesIdParams): __Observable<{success?: boolean, data?: Module, message?: string}> {
    return this.putPelangganModulesIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Module, message?: string})
    );
  }

  /**
   * Remove the specified Module from storage
   *
   * Delete Module
   * @param id id of Module
   * @return successful operation
   */
  deletePelangganModulesIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/modules/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Module from storage
   *
   * Delete Module
   * @param id id of Module
   * @return successful operation
   */
  deletePelangganModulesId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganModulesIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module ModuleService {

  /**
   * Parameters for getPelangganModules
   */
  export interface GetPelangganModulesParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Module
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganModulesId
   */
  export interface PutPelangganModulesIdParams {

    /**
     * id of Module
     */
    id: number;

    /**
     * Module that should be updated
     */
    body?: Module;
  }
}

export { ModuleService }
