/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrPegawaiBiodata } from '../models/hr-pegawai-biodata';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrPegawaiBiodataService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrPegawaiBiodataList
   */
  static readonly GetHrPegawaiBiodataListPath = '/hr/pegawai_biodatas';

  /**
   * getpegawai_biodataList.
   *
   * Get all pegawai_biodatas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiBiodataList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiBiodataList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiBiodata>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiBiodataService.GetHrPegawaiBiodataListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrPegawaiBiodata>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_biodataList.
   *
   * Get all pegawai_biodatas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiBiodataList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiBiodataList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrPegawaiBiodata>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrPegawaiBiodataList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiBiodata>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrPegawaiBiodata>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrPegawaiBiodata
   */
  static readonly StoreHrPegawaiBiodataPath = '/hr/pegawai_biodatas';

  /**
   * createpegawai_biodata.
   *
   * Create pegawai_biodata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrPegawaiBiodata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiBiodata$Response(params: {
    body: HrPegawaiBiodata
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiBiodataService.StoreHrPegawaiBiodataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiBiodata;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_biodata.
   *
   * Create pegawai_biodata
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrPegawaiBiodata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiBiodata(params: {
    body: HrPegawaiBiodata
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}> {

    return this.storeHrPegawaiBiodata$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrPegawaiBiodataId
   */
  static readonly GetHrPegawaiBiodataIdPath = '/hr/pegawai_biodatas/{id}';

  /**
   * getpegawai_biodataItem.
   *
   * Get pegawai_biodata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiBiodataId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiBiodataId$Response(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiBiodataService.GetHrPegawaiBiodataIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiBiodata;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_biodataItem.
   *
   * Get pegawai_biodata
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiBiodataId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiBiodataId(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}> {

    return this.getHrPegawaiBiodataId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrPegawaiBiodataId
   */
  static readonly PutHrPegawaiBiodataIdPath = '/hr/pegawai_biodatas/{id}';

  /**
   * updatepegawai_biodata.
   *
   * Update pegawai_biodata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrPegawaiBiodataId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiBiodataId$Response(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
    body: HrPegawaiBiodata
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiBiodataService.PutHrPegawaiBiodataIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiBiodata;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_biodata.
   *
   * Update pegawai_biodata
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrPegawaiBiodataId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiBiodataId(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
    body: HrPegawaiBiodata
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}> {

    return this.putHrPegawaiBiodataId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiBiodata;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrPegawaiBiodatasId
   */
  static readonly DeleteHrPegawaiBiodatasIdPath = '/hr/pegawai_biodatas/{id}';

  /**
   * deletepegawai_biodata.
   *
   * Delete pegawai_biodata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrPegawaiBiodatasId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiBiodatasId$Response(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiBiodataService.DeleteHrPegawaiBiodatasIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_biodata.
   *
   * Delete pegawai_biodata
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrPegawaiBiodatasId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiBiodatasId(params: {

    /**
     * id of pegawai_biodata
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrPegawaiBiodatasId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
