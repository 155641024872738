/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tagihan_bayar } from '../models/tagihan-_bayar';
@Injectable({
  providedIn: 'root',
})
class TagihanBayarService extends __BaseService {
  static readonly postPelangganTagihanBayarsPath = '/pelanggan/tagihanBayars';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Store a newly created Tagihan_bayar in storage
   *
   * Store Tagihan_bayar
   * @param body Tagihan_bayar that should be stored
   * @return successful operation
   */
  postPelangganTagihanBayarsResponse(body?: any): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tagihan_bayar, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/tagihanBayars`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tagihan_bayar, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Tagihan_bayar in storage
   *
   * Store Tagihan_bayar
   * @param body Tagihan_bayar that should be stored
   * @return successful operation
   */
  postPelangganTagihanBayars(body?: any): __Observable<{success?: boolean, data?: Tagihan_bayar, message?: string}> {
    return this.postPelangganTagihanBayarsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tagihan_bayar, message?: string})
    );
  }
}

module TagihanBayarService {
}

export { TagihanBayarService }
