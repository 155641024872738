/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportWidgetCutiPengajuanTanggalBulanan } from '../models/absensi-report-widget-cuti-pengajuan-tanggal-bulanan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportWidgetCutiPengajuanTanggalBulananService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportWidgetCutiPengajuanTanggalBulananList
   */
  static readonly GetAbsensiReportWidgetCutiPengajuanTanggalBulananListPath = '/absensiReportWidget/cuti_pengajuan_tanggal_bulanans';

  /**
   * getcuti_pengajuan_tanggal_bulananList.
   *
   * Get all cuti_pengajuan_tanggal_bulanans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetCutiPengajuanTanggalBulananList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalBulananList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalBulanan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalBulananService.GetAbsensiReportWidgetCutiPengajuanTanggalBulananListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalBulanan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuan_tanggal_bulananList.
   *
   * Get all cuti_pengajuan_tanggal_bulanans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetCutiPengajuanTanggalBulananList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalBulananList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalBulanan>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportWidgetCutiPengajuanTanggalBulananList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalBulanan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportWidgetCutiPengajuanTanggalBulanan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportWidgetCutiPengajuanTanggalBulanan
   */
  static readonly StoreAbsensiReportWidgetCutiPengajuanTanggalBulananPath = '/absensiReportWidget/cuti_pengajuan_tanggal_bulanans';

  /**
   * createcuti_pengajuan_tanggal_bulanan.
   *
   * Create cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportWidgetCutiPengajuanTanggalBulanan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetCutiPengajuanTanggalBulanan$Response(params: {
    body: AbsensiReportWidgetCutiPengajuanTanggalBulanan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalBulananService.StoreAbsensiReportWidgetCutiPengajuanTanggalBulananPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createcuti_pengajuan_tanggal_bulanan.
   *
   * Create cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportWidgetCutiPengajuanTanggalBulanan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportWidgetCutiPengajuanTanggalBulanan(params: {
    body: AbsensiReportWidgetCutiPengajuanTanggalBulanan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}> {

    return this.storeAbsensiReportWidgetCutiPengajuanTanggalBulanan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportWidgetCutiPengajuanTanggalBulananId
   */
  static readonly GetAbsensiReportWidgetCutiPengajuanTanggalBulananIdPath = '/absensiReportWidget/cuti_pengajuan_tanggal_bulanans/{id}';

  /**
   * getcuti_pengajuan_tanggal_bulananItem.
   *
   * Get cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportWidgetCutiPengajuanTanggalBulananId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalBulananId$Response(params: {

    /**
     * id of cuti_pengajuan_tanggal_bulanan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalBulananService.GetAbsensiReportWidgetCutiPengajuanTanggalBulananIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getcuti_pengajuan_tanggal_bulananItem.
   *
   * Get cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportWidgetCutiPengajuanTanggalBulananId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportWidgetCutiPengajuanTanggalBulananId(params: {

    /**
     * id of cuti_pengajuan_tanggal_bulanan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}> {

    return this.getAbsensiReportWidgetCutiPengajuanTanggalBulananId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportWidgetCutiPengajuanTanggalBulananId
   */
  static readonly PutAbsensiReportWidgetCutiPengajuanTanggalBulananIdPath = '/absensiReportWidget/cuti_pengajuan_tanggal_bulanans/{id}';

  /**
   * updatecuti_pengajuan_tanggal_bulanan.
   *
   * Update cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportWidgetCutiPengajuanTanggalBulananId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetCutiPengajuanTanggalBulananId$Response(params: {

    /**
     * id of cuti_pengajuan_tanggal_bulanan
     */
    id: string;
    body: AbsensiReportWidgetCutiPengajuanTanggalBulanan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalBulananService.PutAbsensiReportWidgetCutiPengajuanTanggalBulananIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatecuti_pengajuan_tanggal_bulanan.
   *
   * Update cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportWidgetCutiPengajuanTanggalBulananId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportWidgetCutiPengajuanTanggalBulananId(params: {

    /**
     * id of cuti_pengajuan_tanggal_bulanan
     */
    id: string;
    body: AbsensiReportWidgetCutiPengajuanTanggalBulanan
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}> {

    return this.putAbsensiReportWidgetCutiPengajuanTanggalBulananId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportWidgetCutiPengajuanTanggalBulanan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportWidgetCutiPengajuanTanggalBulanansId
   */
  static readonly DeleteAbsensiReportWidgetCutiPengajuanTanggalBulanansIdPath = '/absensiReportWidget/cuti_pengajuan_tanggal_bulanans/{id}';

  /**
   * deletecuti_pengajuan_tanggal_bulanan.
   *
   * Delete cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportWidgetCutiPengajuanTanggalBulanansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetCutiPengajuanTanggalBulanansId$Response(params: {

    /**
     * id of cuti_pengajuan_tanggal_bulanan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportWidgetCutiPengajuanTanggalBulananService.DeleteAbsensiReportWidgetCutiPengajuanTanggalBulanansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletecuti_pengajuan_tanggal_bulanan.
   *
   * Delete cuti_pengajuan_tanggal_bulanan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportWidgetCutiPengajuanTanggalBulanansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportWidgetCutiPengajuanTanggalBulanansId(params: {

    /**
     * id of cuti_pengajuan_tanggal_bulanan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportWidgetCutiPengajuanTanggalBulanansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
