/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrSpTipe } from '../models/hr-sp-tipe';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrSpTipeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrSpTipeList
   */
  static readonly GetHrSpTipeListPath = '/hr/sp_tipes';

  /**
   * getsp_tipeList.
   *
   * Get all sp_tipes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrSpTipeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrSpTipeList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrSpTipe>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrSpTipeService.GetHrSpTipeListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrSpTipe>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getsp_tipeList.
   *
   * Get all sp_tipes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrSpTipeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrSpTipeList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrSpTipe>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrSpTipeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrSpTipe>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrSpTipe>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrSpTipe
   */
  static readonly StoreHrSpTipePath = '/hr/sp_tipes';

  /**
   * createsp_tipe.
   *
   * Create sp_tipe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrSpTipe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrSpTipe$Response(params: {
    body: HrSpTipe
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrSpTipeService.StoreHrSpTipePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrSpTipe;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createsp_tipe.
   *
   * Create sp_tipe
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrSpTipe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrSpTipe(params: {
    body: HrSpTipe
  }): Observable<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}> {

    return this.storeHrSpTipe$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrSpTipeId
   */
  static readonly GetHrSpTipeIdPath = '/hr/sp_tipes/{id}';

  /**
   * getsp_tipeItem.
   *
   * Get sp_tipe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrSpTipeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrSpTipeId$Response(params: {

    /**
     * id of sp_tipe
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrSpTipeService.GetHrSpTipeIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrSpTipe;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getsp_tipeItem.
   *
   * Get sp_tipe
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrSpTipeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrSpTipeId(params: {

    /**
     * id of sp_tipe
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}> {

    return this.getHrSpTipeId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrSpTipeId
   */
  static readonly PutHrSpTipeIdPath = '/hr/sp_tipes/{id}';

  /**
   * updatesp_tipe.
   *
   * Update sp_tipe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrSpTipeId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrSpTipeId$Response(params: {

    /**
     * id of sp_tipe
     */
    id: string;
    body: HrSpTipe
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrSpTipeService.PutHrSpTipeIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrSpTipe;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatesp_tipe.
   *
   * Update sp_tipe
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrSpTipeId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrSpTipeId(params: {

    /**
     * id of sp_tipe
     */
    id: string;
    body: HrSpTipe
  }): Observable<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}> {

    return this.putHrSpTipeId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrSpTipe;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrSpTipesId
   */
  static readonly DeleteHrSpTipesIdPath = '/hr/sp_tipes/{id}';

  /**
   * deletesp_tipe.
   *
   * Delete sp_tipe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrSpTipesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrSpTipesId$Response(params: {

    /**
     * id of sp_tipe
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrSpTipeService.DeleteHrSpTipesIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletesp_tipe.
   *
   * Delete sp_tipe
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrSpTipesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrSpTipesId(params: {

    /**
     * id of sp_tipe
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrSpTipesId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
