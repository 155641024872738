import { environment } from './../../../../../environments/environment';
//import { Component } from '@angular/core'
//import { FormBuilder, FormGroup, Validators } from '@angular/forms'
//import { select, Store } from '@ngrx/store'
//import * as Reducers from 'src/app/store/reducers'
//import * as UserActions from 'src/app/store/user/actions'
//import * as SettingsActions from 'src/app/store/settings/actions'

//@Component({
//  selector: 'vb-system-login',
//  templateUrl: './login.component.html',
//  styleUrls: ['../style.component.scss'],
//})
//export class LoginComponent {
//  form: FormGroup
//  logo: String
//  authProvider: string = 'firebase'
//  loading: boolean = false

//  constructor(private fb: FormBuilder, private store: Store<any>) {
//    this.form = fb.group({
//      email: ['demo@visualbuilder.cloud', [Validators.required, Validators.minLength(4)]],
//      password: ['VisualBuilder', [Validators.required]],
//    })
//    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
//      this.logo = state.logo
//      this.authProvider = state.authProvider
//    })
//    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
//      this.loading = state.loading
//    })
//  }

//  get email() {
//    return this.form.controls.email
//  }
//  get password() {
//    return this.form.controls.password
//  }

//  submitForm(): void {
//    this.email.markAsDirty()
//    this.email.updateValueAndValidity()
//    this.password.markAsDirty()
//    this.password.updateValueAndValidity()
//    if (this.email.invalid || this.password.invalid) {
//      return
//    }
//    const payload = {
//      email: this.email.value,
//      password: this.password.value,
//    }
//    this.store.dispatch(new UserActions.Login(payload))
//  }

//  setProvider(authProvider) {
//    this.store.dispatch(
//      new SettingsActions.SetStateAction({
//        authProvider,
//      }),
//    )
//  }
//}


import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import * as UserActions from 'src/app/store/user/actions'
import * as SettingsActions from 'src/app/store/settings/actions'
import { Router } from '@angular/router'
import { TokenService } from 'src/app/services/jwt/token.service'
import { LoginService } from 'src/app/sdk/pelanggan/services'

@Component({
    selector: 'vb-system-login',
    templateUrl: './login.component.html',
    styleUrls: ['../style.component.scss'],
})
export class LoginComponent {
    loginForm: FormGroup;
    errors = null;

    constructor(
        public router: Router,
        public fb: FormBuilder,
        private loginService: LoginService,
        private token: TokenService,
        private store: Store<any>
    ) {
        this.loginForm = this.fb.group({
            email: [],
            password: []
        })
    }

    ngOnInit() { }

    onSubmit() {

        this.loginService.postAuthLogins(this.loginForm.value).subscribe(
            result => {
                this.responseHandler(result);
                var res = result;
                if (res['error']) {
                    this.errors = res['error']
                }
            },
            error => {
                this.errors = error.error;
            }, () => {
                // this.authState.setAuthState(true);
                this.loginForm.reset()

            }
        );


    }

    // Handle response
    responseHandler(data) {
        this.token.handleData(data.access_token);

        this.router.navigate(['/dashboard']);
        const payload = {
            email: this.loginForm.get('email').value,
            password: this.loginForm.get('password').value,
        }
        this.store.dispatch(new UserActions.Login(payload))
    }

    loading = false
}
