/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Select_billing } from '../models/select-_billing';
@Injectable({
  providedIn: 'root',
})
class SelectBillingService extends __BaseService {
  static readonly getPelangganSelectBillingsPath = '/pelanggan/selectBillings';
  static readonly postPelangganSelectBillingsPath = '/pelanggan/selectBillings';
  static readonly getPelangganSelectBillingsIdPath = '/pelanggan/selectBillings/{id}';
  static readonly putPelangganSelectBillingsIdPath = '/pelanggan/selectBillings/{id}';
  static readonly deletePelangganSelectBillingsIdPath = '/pelanggan/selectBillings/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the select_billing.
   *
   * Get all select_billing
   * @param params The `SelectBillingService.GetPelangganSelectBillingsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter select_billing
   *
   * @return successful operation
   */
  getPelangganSelectBillingsResponse(params: SelectBillingService.GetPelangganSelectBillingsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Select_billing>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/selectBillings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Select_billing>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the select_billing.
   *
   * Get all select_billing
   * @param params The `SelectBillingService.GetPelangganSelectBillingsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter select_billing
   *
   * @return successful operation
   */
  getPelangganSelectBillings(params: SelectBillingService.GetPelangganSelectBillingsParams): __Observable<{success?: boolean, data?: Array<Select_billing>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganSelectBillingsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Select_billing>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created select_billing in storage
   *
   * Store select_billing
   * @param body select_billing that should be stored
   * @return successful operation
   */
  postPelangganSelectBillingsResponse(body?: Select_billing): __Observable<__StrictHttpResponse<{success?: boolean, data?: Select_billing, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/selectBillings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Select_billing, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created select_billing in storage
   *
   * Store select_billing
   * @param body select_billing that should be stored
   * @return successful operation
   */
  postPelangganSelectBillings(body?: Select_billing): __Observable<{success?: boolean, data?: Select_billing, message?: string}> {
    return this.postPelangganSelectBillingsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Select_billing, message?: string})
    );
  }

  /**
   * Display the specified select_billing
   *
   * Get select_billing
   * @param id id of select_billing
   * @return successful operation
   */
  getPelangganSelectBillingsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Select_billing, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/selectBillings/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Select_billing, message?: string}>;
      })
    );
  }
  /**
   * Display the specified select_billing
   *
   * Get select_billing
   * @param id id of select_billing
   * @return successful operation
   */
  getPelangganSelectBillingsId(id: number): __Observable<{success?: boolean, data?: Select_billing, message?: string}> {
    return this.getPelangganSelectBillingsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Select_billing, message?: string})
    );
  }

  /**
   * Update the specified select_billing in storage
   *
   * Update select_billing
   * @param params The `SelectBillingService.PutPelangganSelectBillingsIdParams` containing the following parameters:
   *
   * - `id`: id of select_billing
   *
   * - `body`: select_billing that should be updated
   *
   * @return successful operation
   */
  putPelangganSelectBillingsIdResponse(params: SelectBillingService.PutPelangganSelectBillingsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Select_billing, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/selectBillings/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Select_billing, message?: string}>;
      })
    );
  }
  /**
   * Update the specified select_billing in storage
   *
   * Update select_billing
   * @param params The `SelectBillingService.PutPelangganSelectBillingsIdParams` containing the following parameters:
   *
   * - `id`: id of select_billing
   *
   * - `body`: select_billing that should be updated
   *
   * @return successful operation
   */
  putPelangganSelectBillingsId(params: SelectBillingService.PutPelangganSelectBillingsIdParams): __Observable<{success?: boolean, data?: Select_billing, message?: string}> {
    return this.putPelangganSelectBillingsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Select_billing, message?: string})
    );
  }

  /**
   * Remove the specified select_billing from storage
   *
   * Delete select_billing
   * @param id id of select_billing
   * @return successful operation
   */
  deletePelangganSelectBillingsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/selectBillings/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified select_billing from storage
   *
   * Delete select_billing
   * @param id id of select_billing
   * @return successful operation
   */
  deletePelangganSelectBillingsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganSelectBillingsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module SelectBillingService {

  /**
   * Parameters for getPelangganSelectBillings
   */
  export interface GetPelangganSelectBillingsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter select_billing
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganSelectBillingsId
   */
  export interface PutPelangganSelectBillingsIdParams {

    /**
     * id of select_billing
     */
    id: number;

    /**
     * select_billing that should be updated
     */
    body?: Select_billing;
  }
}

export { SelectBillingService }
