/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminPermohonanInformasi } from '../models/admin-permohonan-informasi';

@Injectable({
  providedIn: 'root',
})
export class AdminPermohonanInformasiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminPermohonanInformasisList
   */
  static readonly GetadminPermohonanInformasisListPath = '/admin/permohonanInformasis';

  /**
   * Get list of permohonanInformasis.
   *
   * Returns list of permohonanInformasis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPermohonanInformasisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPermohonanInformasisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPermohonanInformasi>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPermohonanInformasiService.GetadminPermohonanInformasisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminPermohonanInformasi>;
        }>;
      })
    );
  }

  /**
   * Get list of permohonanInformasis.
   *
   * Returns list of permohonanInformasis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPermohonanInformasisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPermohonanInformasisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPermohonanInformasi>;
}> {

    return this.getadminPermohonanInformasisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPermohonanInformasi>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPermohonanInformasi>;
})
    );
  }

  /**
   * Path part for operation storeadminPermohonanInformasi
   */
  static readonly StoreadminPermohonanInformasiPath = '/admin/permohonanInformasis';

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminPermohonanInformasi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPermohonanInformasi$Response(params: {
    body: AdminPermohonanInformasi
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPermohonanInformasiService.StoreadminPermohonanInformasiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPermohonanInformasi;
        }>;
      })
    );
  }

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminPermohonanInformasi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPermohonanInformasi(params: {
    body: AdminPermohonanInformasi
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}> {

    return this.storeadminPermohonanInformasi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
})
    );
  }

  /**
   * Path part for operation getadminPermohonanInformasiId
   */
  static readonly GetadminPermohonanInformasiIdPath = '/admin/permohonanInformasis/{id}';

  /**
   * Get  Permohonan_informasi by id.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPermohonanInformasiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPermohonanInformasiId$Response(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPermohonanInformasiService.GetadminPermohonanInformasiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPermohonanInformasi;
        }>;
      })
    );
  }

  /**
   * Get  Permohonan_informasi by id.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPermohonanInformasiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPermohonanInformasiId(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}> {

    return this.getadminPermohonanInformasiId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
})
    );
  }

  /**
   * Path part for operation putadminPermohonanInformasi
   */
  static readonly PutadminPermohonanInformasiPath = '/admin/permohonanInformasis/{id}';

  /**
   * Update Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminPermohonanInformasi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPermohonanInformasi$Response(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
    body: AdminPermohonanInformasi
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPermohonanInformasiService.PutadminPermohonanInformasiPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPermohonanInformasi;
        }>;
      })
    );
  }

  /**
   * Update Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminPermohonanInformasi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPermohonanInformasi(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
    body: AdminPermohonanInformasi
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}> {

    return this.putadminPermohonanInformasi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPermohonanInformasi;
})
    );
  }

  /**
   * Path part for operation deleteadminPermohonanInformasi
   */
  static readonly DeleteadminPermohonanInformasiPath = '/admin/permohonanInformasis/{id}';

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminPermohonanInformasi()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPermohonanInformasi$Response(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPermohonanInformasiService.DeleteadminPermohonanInformasiPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Permohonan_informasi.
   *
   * Returns Permohonan_informasi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminPermohonanInformasi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPermohonanInformasi(params: {

    /**
     * id of Permohonan_informasi
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminPermohonanInformasi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
