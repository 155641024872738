/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Maping_pembaca_meter_user } from '../models/maping-_pembaca-_meter-_user';
@Injectable({
  providedIn: 'root',
})
class MapingPembacaMeterUserService extends __BaseService {
  static readonly getPelangganMapingPembacaMeterUsersPath = '/pelanggan/mapingPembacaMeterUsers';
  static readonly postPelangganMapingPembacaMeterUsersPath = '/pelanggan/mapingPembacaMeterUsers';
  static readonly getPelangganMapingPembacaMeterUsersIdPath = '/pelanggan/mapingPembacaMeterUsers/{id}';
  static readonly putPelangganMapingPembacaMeterUsersIdPath = '/pelanggan/mapingPembacaMeterUsers/{id}';
  static readonly deletePelangganMapingPembacaMeterUsersIdPath = '/pelanggan/mapingPembacaMeterUsers/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Maping_pembaca_meter_user.
   *
   * Get all Maping_pembaca_meter_user
   * @param params The `MapingPembacaMeterUserService.GetPelangganMapingPembacaMeterUsersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Maping_pembaca_meter_user
   *
   * @return successful operation
   */
  getPelangganMapingPembacaMeterUsersResponse(params: MapingPembacaMeterUserService.GetPelangganMapingPembacaMeterUsersParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Maping_pembaca_meter_user>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/mapingPembacaMeterUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Maping_pembaca_meter_user>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Maping_pembaca_meter_user.
   *
   * Get all Maping_pembaca_meter_user
   * @param params The `MapingPembacaMeterUserService.GetPelangganMapingPembacaMeterUsersParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Maping_pembaca_meter_user
   *
   * @return successful operation
   */
  getPelangganMapingPembacaMeterUsers(params: MapingPembacaMeterUserService.GetPelangganMapingPembacaMeterUsersParams): __Observable<{success?: boolean, data?: Array<Maping_pembaca_meter_user>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganMapingPembacaMeterUsersResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Maping_pembaca_meter_user>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Maping_pembaca_meter_user in storage
   *
   * Store Maping_pembaca_meter_user
   * @param body Maping_pembaca_meter_user that should be stored
   * @return successful operation
   */
  postPelangganMapingPembacaMeterUsersResponse(body?: Maping_pembaca_meter_user): __Observable<__StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/mapingPembacaMeterUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Maping_pembaca_meter_user in storage
   *
   * Store Maping_pembaca_meter_user
   * @param body Maping_pembaca_meter_user that should be stored
   * @return successful operation
   */
  postPelangganMapingPembacaMeterUsers(body?: Maping_pembaca_meter_user): __Observable<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}> {
    return this.postPelangganMapingPembacaMeterUsersResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Maping_pembaca_meter_user, message?: string})
    );
  }

  /**
   * Display the specified Maping_pembaca_meter_user
   *
   * Get Maping_pembaca_meter_user
   * @param id id of Maping_pembaca_meter_user
   * @return successful operation
   */
  getPelangganMapingPembacaMeterUsersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/mapingPembacaMeterUsers/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Maping_pembaca_meter_user
   *
   * Get Maping_pembaca_meter_user
   * @param id id of Maping_pembaca_meter_user
   * @return successful operation
   */
  getPelangganMapingPembacaMeterUsersId(id: number): __Observable<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}> {
    return this.getPelangganMapingPembacaMeterUsersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Maping_pembaca_meter_user, message?: string})
    );
  }

  /**
   * Update the specified Maping_pembaca_meter_user in storage
   *
   * Update Maping_pembaca_meter_user
   * @param params The `MapingPembacaMeterUserService.PutPelangganMapingPembacaMeterUsersIdParams` containing the following parameters:
   *
   * - `id`: id of Maping_pembaca_meter_user
   *
   * - `body`: Maping_pembaca_meter_user that should be updated
   *
   * @return successful operation
   */
  putPelangganMapingPembacaMeterUsersIdResponse(params: MapingPembacaMeterUserService.PutPelangganMapingPembacaMeterUsersIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/mapingPembacaMeterUsers/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Maping_pembaca_meter_user in storage
   *
   * Update Maping_pembaca_meter_user
   * @param params The `MapingPembacaMeterUserService.PutPelangganMapingPembacaMeterUsersIdParams` containing the following parameters:
   *
   * - `id`: id of Maping_pembaca_meter_user
   *
   * - `body`: Maping_pembaca_meter_user that should be updated
   *
   * @return successful operation
   */
  putPelangganMapingPembacaMeterUsersId(params: MapingPembacaMeterUserService.PutPelangganMapingPembacaMeterUsersIdParams): __Observable<{success?: boolean, data?: Maping_pembaca_meter_user, message?: string}> {
    return this.putPelangganMapingPembacaMeterUsersIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Maping_pembaca_meter_user, message?: string})
    );
  }

  /**
   * Remove the specified Maping_pembaca_meter_user from storage
   *
   * Delete Maping_pembaca_meter_user
   * @param id id of Maping_pembaca_meter_user
   * @return successful operation
   */
  deletePelangganMapingPembacaMeterUsersIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/mapingPembacaMeterUsers/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Maping_pembaca_meter_user from storage
   *
   * Delete Maping_pembaca_meter_user
   * @param id id of Maping_pembaca_meter_user
   * @return successful operation
   */
  deletePelangganMapingPembacaMeterUsersId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganMapingPembacaMeterUsersIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module MapingPembacaMeterUserService {

  /**
   * Parameters for getPelangganMapingPembacaMeterUsers
   */
  export interface GetPelangganMapingPembacaMeterUsersParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Maping_pembaca_meter_user
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganMapingPembacaMeterUsersId
   */
  export interface PutPelangganMapingPembacaMeterUsersIdParams {

    /**
     * id of Maping_pembaca_meter_user
     */
    id: number;

    /**
     * Maping_pembaca_meter_user that should be updated
     */
    body?: Maping_pembaca_meter_user;
  }
}

export { MapingPembacaMeterUserService }
