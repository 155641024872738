/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Type_pelanggan_per_pdam } from '../models/type-_pelanggan-_per-_pdam';
@Injectable({
  providedIn: 'root',
})
class TypePelangganPerPdamService extends __BaseService {
  static readonly getPelangganTypePelangganPerPdamsPath = '/pelanggan/typePelangganPerPdams';
  static readonly postPelangganTypePelangganPerPdamsPath = '/pelanggan/typePelangganPerPdams';
  static readonly getPelangganTypePelangganPerPdamsIdPath = '/pelanggan/typePelangganPerPdams/{id}';
  static readonly putPelangganTypePelangganPerPdamsIdPath = '/pelanggan/typePelangganPerPdams/{id}';
  static readonly deletePelangganTypePelangganPerPdamsIdPath = '/pelanggan/typePelangganPerPdams/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Type_pelanggan_per_pdam.
   *
   * Get all Type_pelanggan_per_pdam
   * @param params The `TypePelangganPerPdamService.GetPelangganTypePelangganPerPdamsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Type_pelanggan_per_pdam
   *
   * @return successful operation
   */
  getPelangganTypePelangganPerPdamsResponse(params: TypePelangganPerPdamService.GetPelangganTypePelangganPerPdamsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Type_pelanggan_per_pdam>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.filter || []).forEach(val => {if (val != null) __params = __params.append('filter', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/typePelangganPerPdams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Type_pelanggan_per_pdam>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Type_pelanggan_per_pdam.
   *
   * Get all Type_pelanggan_per_pdam
   * @param params The `TypePelangganPerPdamService.GetPelangganTypePelangganPerPdamsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Type_pelanggan_per_pdam
   *
   * @return successful operation
   */
  getPelangganTypePelangganPerPdams(params: TypePelangganPerPdamService.GetPelangganTypePelangganPerPdamsParams): __Observable<{success?: boolean, data?: Array<Type_pelanggan_per_pdam>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganTypePelangganPerPdamsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Type_pelanggan_per_pdam>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Type_pelanggan_per_pdam in storage
   *
   * Store Type_pelanggan_per_pdam
   * @param body Type_pelanggan_per_pdam that should be stored
   * @return successful operation
   */
  postPelangganTypePelangganPerPdamsResponse(body?: Type_pelanggan_per_pdam): __Observable<__StrictHttpResponse<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/typePelangganPerPdams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Type_pelanggan_per_pdam in storage
   *
   * Store Type_pelanggan_per_pdam
   * @param body Type_pelanggan_per_pdam that should be stored
   * @return successful operation
   */
  postPelangganTypePelangganPerPdams(body?: Type_pelanggan_per_pdam): __Observable<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}> {
    return this.postPelangganTypePelangganPerPdamsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Type_pelanggan_per_pdam, message?: string})
    );
  }

  /**
   * Display the specified Type_pelanggan_per_pdam
   *
   * Get Type_pelanggan_per_pdam
   * @param id id of Type_pelanggan_per_pdam
   * @return successful operation
   */
  getPelangganTypePelangganPerPdamsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/typePelangganPerPdams/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Type_pelanggan_per_pdam
   *
   * Get Type_pelanggan_per_pdam
   * @param id id of Type_pelanggan_per_pdam
   * @return successful operation
   */
  getPelangganTypePelangganPerPdamsId(id: number): __Observable<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}> {
    return this.getPelangganTypePelangganPerPdamsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Type_pelanggan_per_pdam, message?: string})
    );
  }

  /**
   * Update the specified Type_pelanggan_per_pdam in storage
   *
   * Update Type_pelanggan_per_pdam
   * @param params The `TypePelangganPerPdamService.PutPelangganTypePelangganPerPdamsIdParams` containing the following parameters:
   *
   * - `id`: id of Type_pelanggan_per_pdam
   *
   * - `body`: Type_pelanggan_per_pdam that should be updated
   *
   * @return successful operation
   */
  putPelangganTypePelangganPerPdamsIdResponse(params: TypePelangganPerPdamService.PutPelangganTypePelangganPerPdamsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/typePelangganPerPdams/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Type_pelanggan_per_pdam in storage
   *
   * Update Type_pelanggan_per_pdam
   * @param params The `TypePelangganPerPdamService.PutPelangganTypePelangganPerPdamsIdParams` containing the following parameters:
   *
   * - `id`: id of Type_pelanggan_per_pdam
   *
   * - `body`: Type_pelanggan_per_pdam that should be updated
   *
   * @return successful operation
   */
  putPelangganTypePelangganPerPdamsId(params: TypePelangganPerPdamService.PutPelangganTypePelangganPerPdamsIdParams): __Observable<{success?: boolean, data?: Type_pelanggan_per_pdam, message?: string}> {
    return this.putPelangganTypePelangganPerPdamsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Type_pelanggan_per_pdam, message?: string})
    );
  }

  /**
   * Remove the specified Type_pelanggan_per_pdam from storage
   *
   * Delete Type_pelanggan_per_pdam
   * @param id id of Type_pelanggan_per_pdam
   * @return successful operation
   */
  deletePelangganTypePelangganPerPdamsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/typePelangganPerPdams/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Type_pelanggan_per_pdam from storage
   *
   * Delete Type_pelanggan_per_pdam
   * @param id id of Type_pelanggan_per_pdam
   * @return successful operation
   */
  deletePelangganTypePelangganPerPdamsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganTypePelangganPerPdamsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module TypePelangganPerPdamService {

  /**
   * Parameters for getPelangganTypePelangganPerPdams
   */
  export interface GetPelangganTypePelangganPerPdamsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Type_pelanggan_per_pdam
     */
    filter?: Array<Type_pelanggan_per_pdam>;
  }

  /**
   * Parameters for putPelangganTypePelangganPerPdamsId
   */
  export interface PutPelangganTypePelangganPerPdamsIdParams {

    /**
     * id of Type_pelanggan_per_pdam
     */
    id: number;

    /**
     * Type_pelanggan_per_pdam that should be updated
     */
    body?: Type_pelanggan_per_pdam;
  }
}

export { TypePelangganPerPdamService }
