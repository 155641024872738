/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminComentService } from './services/admin-coment.service';
import { AdminDaftarSambunganBaruService } from './services/admin-daftar-sambungan-baru.service';
import { AdminDocumentService } from './services/admin-document.service';
import { AdminGaleryService } from './services/admin-galery.service';
import { AdminGaleryDetailService } from './services/admin-galery-detail.service';
import { AdminJenisTindakanService } from './services/admin-jenis-tindakan.service';
import { AdminMediaService } from './services/admin-media.service';
import { AdminPengaduanService } from './services/admin-pengaduan.service';
import { AdminPengaduanBySumberService } from './services/admin-pengaduan-by-sumber.service';
import { AdminPengaduanByTypeService } from './services/admin-pengaduan-by-type.service';
import { AdminPengaduanWidgetService } from './services/admin-pengaduan-widget.service';
import { AdminPengajuanKeberatanService } from './services/admin-pengajuan-keberatan.service';
import { AdminPengajuanPengaduanService } from './services/admin-pengajuan-pengaduan.service';
import { AdminPengumumanService } from './services/admin-pengumuman.service';
import { AdminPermohonanInformasiService } from './services/admin-permohonan-informasi.service';
import { AdminRegulasiService } from './services/admin-regulasi.service';
import { AdminTipePengaduanService } from './services/admin-tipe-pengaduan.service';
import { ClientBeritaService } from './services/client-berita.service';
import { ClientCekTagihanService } from './services/client-cek-tagihan.service';
import { ClientComentService } from './services/client-coment.service';
import { ClientDaftarSambunganBaruService } from './services/client-daftar-sambungan-baru.service';
import { ClientGaleryService } from './services/client-galery.service';
import { ClientGaleryDetailService } from './services/client-galery-detail.service';
import { ClientHayatiService } from './services/client-hayati.service';
import { ClientJenisTindakanService } from './services/client-jenis-tindakan.service';
import { ClientMasterBudidayaService } from './services/client-master-budidaya.service';
import { ClientMasterHabitatService } from './services/client-master-habitat.service';
import { ClientMasterJenisService } from './services/client-master-jenis.service';
import { ClientMasterKeteranganService } from './services/client-master-keterangan.service';
import { ClientMasterNilaiEkonomiService } from './services/client-master-nilai-ekonomi.service';
import { ClientMasterStatusJumlahService } from './services/client-master-status-jumlah.service';
import { ClientMasterStatusPerlindunganService } from './services/client-master-status-perlindungan.service';
import { ClientMasterVarietesService } from './services/client-master-varietes.service';
import { ClientMediaxService } from './services/client-mediax.service';
import { ClientPageService } from './services/client-page.service';
import { ClientPengaduanService } from './services/client-pengaduan.service';
import { ClientPengaduanByStatusService } from './services/client-pengaduan-by-status.service';
import { ClientPengaduanByTypeService } from './services/client-pengaduan-by-type.service';
import { ClientPengaduanReportService } from './services/client-pengaduan-report.service';
import { ClientPengaduanReportTipeService } from './services/client-pengaduan-report-tipe.service';
import { ClientPengajuanKeberatanService } from './services/client-pengajuan-keberatan.service';
import { ClientPengajuanPengaduanService } from './services/client-pengajuan-pengaduan.service';
import { ClientPengumumanService } from './services/client-pengumuman.service';
import { ClientPermohonanInformasiService } from './services/client-permohonan-informasi.service';
import { ClientPesanService } from './services/client-pesan.service';
import { ClientRegulasiService } from './services/client-regulasi.service';
import { ClientSlideService } from './services/client-slide.service';
import { ClientSlideImageService } from './services/client-slide-image.service';
import { ClientTipePengaduanService } from './services/client-tipe-pengaduan.service';
import { ClientPermohonanRekapService } from './services/client-permohonan-rekap.service';
import { CmsBeritaService } from './services/cms-berita.service';
import { CmsPageService } from './services/cms-page.service';
import { CmsPesanService } from './services/cms-pesan.service';
import { CmsSlideService } from './services/cms-slide.service';
import { CmsSlideImageService } from './services/cms-slide-image.service';
import { RawRoutePrefixCurrentUserService } from './services/raw-route-prefix-current-user.service';
import { LoginService } from './services/login.service';
import { LogoutService } from './services/logout.service';
import { RefreshService } from './services/refresh.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminComentService,
    AdminDaftarSambunganBaruService,
    AdminDocumentService,
    AdminGaleryService,
    AdminGaleryDetailService,
    AdminJenisTindakanService,
    AdminMediaService,
    AdminPengaduanService,
    AdminPengaduanBySumberService,
    AdminPengaduanByTypeService,
    AdminPengaduanWidgetService,
    AdminPengajuanKeberatanService,
    AdminPengajuanPengaduanService,
    AdminPengumumanService,
    AdminPermohonanInformasiService,
    AdminRegulasiService,
    AdminTipePengaduanService,
    ClientBeritaService,
    ClientCekTagihanService,
    ClientComentService,
    ClientDaftarSambunganBaruService,
    ClientGaleryService,
    ClientGaleryDetailService,
    ClientHayatiService,
    ClientJenisTindakanService,
    ClientMasterBudidayaService,
    ClientMasterHabitatService,
    ClientMasterJenisService,
    ClientMasterKeteranganService,
    ClientMasterNilaiEkonomiService,
    ClientMasterStatusJumlahService,
    ClientMasterStatusPerlindunganService,
    ClientMasterVarietesService,
    ClientMediaxService,
    ClientPageService,
    ClientPengaduanService,
    ClientPengaduanByStatusService,
    ClientPengaduanByTypeService,
    ClientPengaduanReportService,
    ClientPengaduanReportTipeService,
    ClientPengajuanKeberatanService,
    ClientPengajuanPengaduanService,
    ClientPengumumanService,
    ClientPermohonanInformasiService,
    ClientPesanService,
    ClientRegulasiService,
    ClientSlideService,
    ClientSlideImageService,
    ClientTipePengaduanService,
    ClientPermohonanRekapService,
    CmsBeritaService,
    CmsPageService,
    CmsPesanService,
    CmsSlideService,
    CmsSlideImageService,
    RawRoutePrefixCurrentUserService,
    LoginService,
    LogoutService,
    RefreshService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
