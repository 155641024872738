/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportPegawaiSp } from '../models/hr-report-pegawai-sp';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportPegawaiSpService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportPegawaiSpList
   */
  static readonly GetHrReportPegawaiSpListPath = '/hrReport/pegawai_sps';

  /**
   * getpegawai_spList.
   *
   * Get all pegawai_sps
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportPegawaiSpList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiSpList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportPegawaiSp>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportPegawaiSpService.GetHrReportPegawaiSpListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportPegawaiSp>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_spList.
   *
   * Get all pegawai_sps
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportPegawaiSpList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiSpList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportPegawaiSp>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportPegawaiSpList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportPegawaiSp>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportPegawaiSp>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportPegawaiSp
   */
  static readonly StoreHrReportPegawaiSpPath = '/hrReport/pegawai_sps';

  /**
   * createpegawai_sp.
   *
   * Create pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportPegawaiSp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportPegawaiSp$Response(params: {
    body: HrReportPegawaiSp
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportPegawaiSpService.StoreHrReportPegawaiSpPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportPegawaiSp;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_sp.
   *
   * Create pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportPegawaiSp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportPegawaiSp(params: {
    body: HrReportPegawaiSp
  }): Observable<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}> {

    return this.storeHrReportPegawaiSp$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportPegawaiSpId
   */
  static readonly GetHrReportPegawaiSpIdPath = '/hrReport/pegawai_sps/{id}';

  /**
   * getpegawai_spItem.
   *
   * Get pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportPegawaiSpId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiSpId$Response(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportPegawaiSpService.GetHrReportPegawaiSpIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportPegawaiSp;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_spItem.
   *
   * Get pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportPegawaiSpId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportPegawaiSpId(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}> {

    return this.getHrReportPegawaiSpId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportPegawaiSpId
   */
  static readonly PutHrReportPegawaiSpIdPath = '/hrReport/pegawai_sps/{id}';

  /**
   * updatepegawai_sp.
   *
   * Update pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportPegawaiSpId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportPegawaiSpId$Response(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
    body: HrReportPegawaiSp
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportPegawaiSpService.PutHrReportPegawaiSpIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportPegawaiSp;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_sp.
   *
   * Update pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportPegawaiSpId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportPegawaiSpId(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
    body: HrReportPegawaiSp
  }): Observable<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}> {

    return this.putHrReportPegawaiSpId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportPegawaiSp;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportPegawaiSpsId
   */
  static readonly DeleteHrReportPegawaiSpsIdPath = '/hrReport/pegawai_sps/{id}';

  /**
   * deletepegawai_sp.
   *
   * Delete pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportPegawaiSpsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportPegawaiSpsId$Response(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportPegawaiSpService.DeleteHrReportPegawaiSpsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_sp.
   *
   * Delete pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportPegawaiSpsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportPegawaiSpsId(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportPegawaiSpsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
