/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Rekap_tertagih_koperasi } from '../models/rekap-_tertagih-_koperasi';
@Injectable({
  providedIn: 'root',
})
class RekapTertagihKoperasiService extends __BaseService {
  static readonly getPenagihanReportRekapTertagihKoperasisPath = '/penagihanReport/rekapTertagihKoperasis';
  static readonly postPenagihanReportRekapTertagihKoperasisPath = '/penagihanReport/rekapTertagihKoperasis';
  static readonly getPenagihanReportRekapTertagihKoperasisIdPath = '/penagihanReport/rekapTertagihKoperasis/{id}';
  static readonly putPenagihanReportRekapTertagihKoperasisIdPath = '/penagihanReport/rekapTertagihKoperasis/{id}';
  static readonly deletePenagihanReportRekapTertagihKoperasisIdPath = '/penagihanReport/rekapTertagihKoperasis/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Rekap_tertagih_koperasi.
   *
   * Get all Rekap_tertagih_koperasi
   * @param params The `RekapTertagihKoperasiService.GetPenagihanReportRekapTertagihKoperasisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_tertagih_koperasi
   *
   * @return successful operation
   */
  getPenagihanReportRekapTertagihKoperasisResponse(params: RekapTertagihKoperasiService.GetPenagihanReportRekapTertagihKoperasisParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Rekap_tertagih_koperasi>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapTertagihKoperasis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Rekap_tertagih_koperasi>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Rekap_tertagih_koperasi.
   *
   * Get all Rekap_tertagih_koperasi
   * @param params The `RekapTertagihKoperasiService.GetPenagihanReportRekapTertagihKoperasisParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Rekap_tertagih_koperasi
   *
   * @return successful operation
   */
  getPenagihanReportRekapTertagihKoperasis(params: RekapTertagihKoperasiService.GetPenagihanReportRekapTertagihKoperasisParams): __Observable<{success?: boolean, data?: Array<Rekap_tertagih_koperasi>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPenagihanReportRekapTertagihKoperasisResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Rekap_tertagih_koperasi>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Rekap_tertagih_koperasi in storage
   *
   * Store Rekap_tertagih_koperasi
   * @param body Rekap_tertagih_koperasi that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapTertagihKoperasisResponse(body?: Rekap_tertagih_koperasi): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/penagihanReport/rekapTertagihKoperasis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Rekap_tertagih_koperasi in storage
   *
   * Store Rekap_tertagih_koperasi
   * @param body Rekap_tertagih_koperasi that should be stored
   * @return successful operation
   */
  postPenagihanReportRekapTertagihKoperasis(body?: Rekap_tertagih_koperasi): __Observable<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}> {
    return this.postPenagihanReportRekapTertagihKoperasisResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_tertagih_koperasi, message?: string})
    );
  }

  /**
   * Display the specified Rekap_tertagih_koperasi
   *
   * Get Rekap_tertagih_koperasi
   * @param id id of Rekap_tertagih_koperasi
   * @return successful operation
   */
  getPenagihanReportRekapTertagihKoperasisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/penagihanReport/rekapTertagihKoperasis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Rekap_tertagih_koperasi
   *
   * Get Rekap_tertagih_koperasi
   * @param id id of Rekap_tertagih_koperasi
   * @return successful operation
   */
  getPenagihanReportRekapTertagihKoperasisId(id: number): __Observable<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}> {
    return this.getPenagihanReportRekapTertagihKoperasisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_tertagih_koperasi, message?: string})
    );
  }

  /**
   * Update the specified Rekap_tertagih_koperasi in storage
   *
   * Update Rekap_tertagih_koperasi
   * @param params The `RekapTertagihKoperasiService.PutPenagihanReportRekapTertagihKoperasisIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_tertagih_koperasi
   *
   * - `body`: Rekap_tertagih_koperasi that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapTertagihKoperasisIdResponse(params: RekapTertagihKoperasiService.PutPenagihanReportRekapTertagihKoperasisIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/penagihanReport/rekapTertagihKoperasis/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Rekap_tertagih_koperasi in storage
   *
   * Update Rekap_tertagih_koperasi
   * @param params The `RekapTertagihKoperasiService.PutPenagihanReportRekapTertagihKoperasisIdParams` containing the following parameters:
   *
   * - `id`: id of Rekap_tertagih_koperasi
   *
   * - `body`: Rekap_tertagih_koperasi that should be updated
   *
   * @return successful operation
   */
  putPenagihanReportRekapTertagihKoperasisId(params: RekapTertagihKoperasiService.PutPenagihanReportRekapTertagihKoperasisIdParams): __Observable<{success?: boolean, data?: Rekap_tertagih_koperasi, message?: string}> {
    return this.putPenagihanReportRekapTertagihKoperasisIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Rekap_tertagih_koperasi, message?: string})
    );
  }

  /**
   * Remove the specified Rekap_tertagih_koperasi from storage
   *
   * Delete Rekap_tertagih_koperasi
   * @param id id of Rekap_tertagih_koperasi
   * @return successful operation
   */
  deletePenagihanReportRekapTertagihKoperasisIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/penagihanReport/rekapTertagihKoperasis/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Rekap_tertagih_koperasi from storage
   *
   * Delete Rekap_tertagih_koperasi
   * @param id id of Rekap_tertagih_koperasi
   * @return successful operation
   */
  deletePenagihanReportRekapTertagihKoperasisId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePenagihanReportRekapTertagihKoperasisIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module RekapTertagihKoperasiService {

  /**
   * Parameters for getPenagihanReportRekapTertagihKoperasis
   */
  export interface GetPenagihanReportRekapTertagihKoperasisParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Rekap_tertagih_koperasi
     */
    filter?: string;
  }

  /**
   * Parameters for putPenagihanReportRekapTertagihKoperasisId
   */
  export interface PutPenagihanReportRekapTertagihKoperasisIdParams {

    /**
     * id of Rekap_tertagih_koperasi
     */
    id: number;

    /**
     * Rekap_tertagih_koperasi that should be updated
     */
    body?: Rekap_tertagih_koperasi;
  }
}

export { RekapTertagihKoperasiService }
