/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiKehadiranLog } from '../models/absensi-kehadiran-log';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiKehadiranLogService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiKehadiranLogList
   */
  static readonly GetAbsensiKehadiranLogListPath = '/absensi/kehadiran_logs';

  /**
   * getkehadiran_logList.
   *
   * Get all kehadiran_logs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiKehadiranLogList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranLogList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiKehadiranLog>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranLogService.GetAbsensiKehadiranLogListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiKehadiranLog>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getkehadiran_logList.
   *
   * Get all kehadiran_logs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiKehadiranLogList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranLogList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiKehadiranLog>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiKehadiranLogList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiKehadiranLog>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiKehadiranLog>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiKehadiranLog
   */
  static readonly StoreAbsensiKehadiranLogPath = '/absensi/kehadiran_logs';

  /**
   * createkehadiran_log.
   *
   * Create kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiKehadiranLog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiKehadiranLog$Response(params: {
    body: AbsensiKehadiranLog
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranLogService.StoreAbsensiKehadiranLogPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiKehadiranLog;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createkehadiran_log.
   *
   * Create kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiKehadiranLog$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiKehadiranLog(params: {
    body: AbsensiKehadiranLog
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}> {

    return this.storeAbsensiKehadiranLog$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiKehadiranLogId
   */
  static readonly GetAbsensiKehadiranLogIdPath = '/absensi/kehadiran_logs/{id}';

  /**
   * getkehadiran_logItem.
   *
   * Get kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiKehadiranLogId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranLogId$Response(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranLogService.GetAbsensiKehadiranLogIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiKehadiranLog;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getkehadiran_logItem.
   *
   * Get kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiKehadiranLogId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiKehadiranLogId(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}> {

    return this.getAbsensiKehadiranLogId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiKehadiranLogId
   */
  static readonly PutAbsensiKehadiranLogIdPath = '/absensi/kehadiran_logs/{id}';

  /**
   * updatekehadiran_log.
   *
   * Update kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiKehadiranLogId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiKehadiranLogId$Response(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
    body: AbsensiKehadiranLog
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranLogService.PutAbsensiKehadiranLogIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiKehadiranLog;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatekehadiran_log.
   *
   * Update kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiKehadiranLogId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiKehadiranLogId(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
    body: AbsensiKehadiranLog
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}> {

    return this.putAbsensiKehadiranLogId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiKehadiranLog;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiKehadiranLogsId
   */
  static readonly DeleteAbsensiKehadiranLogsIdPath = '/absensi/kehadiran_logs/{id}';

  /**
   * deletekehadiran_log.
   *
   * Delete kehadiran_log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiKehadiranLogsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiKehadiranLogsId$Response(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiKehadiranLogService.DeleteAbsensiKehadiranLogsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletekehadiran_log.
   *
   * Delete kehadiran_log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiKehadiranLogsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiKehadiranLogsId(params: {

    /**
     * id of kehadiran_log
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiKehadiranLogsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
