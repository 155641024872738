/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Report_jpk_pdam } from '../models/report-_jpk-_pdam';
@Injectable({
  providedIn: 'root',
})
class ReportJpkPdamService extends __BaseService {
  static readonly getPelangganReportReportJpkPdamsPath = '/pelangganReport/reportJpkPdams';
  static readonly postPelangganReportReportJpkPdamsPath = '/pelangganReport/reportJpkPdams';
  static readonly getPelangganReportReportJpkPdamsIdPath = '/pelangganReport/reportJpkPdams/{id}';
  static readonly putPelangganReportReportJpkPdamsIdPath = '/pelangganReport/reportJpkPdams/{id}';
  static readonly deletePelangganReportReportJpkPdamsIdPath = '/pelangganReport/reportJpkPdams/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the report_jpk_pdam.
   *
   * Get all report_jpk_pdam
   * @param params The `ReportJpkPdamService.GetPelangganReportReportJpkPdamsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter report_jpk_pdam
   *
   * @return successful operation
   */
  getPelangganReportReportJpkPdamsResponse(params: ReportJpkPdamService.GetPelangganReportReportJpkPdamsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Report_jpk_pdam>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/reportJpkPdams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Report_jpk_pdam>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the report_jpk_pdam.
   *
   * Get all report_jpk_pdam
   * @param params The `ReportJpkPdamService.GetPelangganReportReportJpkPdamsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter report_jpk_pdam
   *
   * @return successful operation
   */
  getPelangganReportReportJpkPdams(params: ReportJpkPdamService.GetPelangganReportReportJpkPdamsParams): __Observable<{success?: boolean, data?: Array<Report_jpk_pdam>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportReportJpkPdamsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Report_jpk_pdam>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created report_jpk_pdam in storage
   *
   * Store report_jpk_pdam
   * @param body report_jpk_pdam that should be stored
   * @return successful operation
   */
  postPelangganReportReportJpkPdamsResponse(body?: Report_jpk_pdam): __Observable<__StrictHttpResponse<{success?: boolean, data?: Report_jpk_pdam, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/reportJpkPdams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Report_jpk_pdam, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created report_jpk_pdam in storage
   *
   * Store report_jpk_pdam
   * @param body report_jpk_pdam that should be stored
   * @return successful operation
   */
  postPelangganReportReportJpkPdams(body?: Report_jpk_pdam): __Observable<{success?: boolean, data?: Report_jpk_pdam, message?: string}> {
    return this.postPelangganReportReportJpkPdamsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Report_jpk_pdam, message?: string})
    );
  }

  /**
   * Display the specified report_jpk_pdam
   *
   * Get report_jpk_pdam
   * @param id id of report_jpk_pdam
   * @return successful operation
   */
  getPelangganReportReportJpkPdamsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Report_jpk_pdam, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/reportJpkPdams/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Report_jpk_pdam, message?: string}>;
      })
    );
  }
  /**
   * Display the specified report_jpk_pdam
   *
   * Get report_jpk_pdam
   * @param id id of report_jpk_pdam
   * @return successful operation
   */
  getPelangganReportReportJpkPdamsId(id: number): __Observable<{success?: boolean, data?: Report_jpk_pdam, message?: string}> {
    return this.getPelangganReportReportJpkPdamsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Report_jpk_pdam, message?: string})
    );
  }

  /**
   * Update the specified report_jpk_pdam in storage
   *
   * Update report_jpk_pdam
   * @param params The `ReportJpkPdamService.PutPelangganReportReportJpkPdamsIdParams` containing the following parameters:
   *
   * - `id`: id of report_jpk_pdam
   *
   * - `body`: report_jpk_pdam that should be updated
   *
   * @return successful operation
   */
  putPelangganReportReportJpkPdamsIdResponse(params: ReportJpkPdamService.PutPelangganReportReportJpkPdamsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Report_jpk_pdam, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/reportJpkPdams/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Report_jpk_pdam, message?: string}>;
      })
    );
  }
  /**
   * Update the specified report_jpk_pdam in storage
   *
   * Update report_jpk_pdam
   * @param params The `ReportJpkPdamService.PutPelangganReportReportJpkPdamsIdParams` containing the following parameters:
   *
   * - `id`: id of report_jpk_pdam
   *
   * - `body`: report_jpk_pdam that should be updated
   *
   * @return successful operation
   */
  putPelangganReportReportJpkPdamsId(params: ReportJpkPdamService.PutPelangganReportReportJpkPdamsIdParams): __Observable<{success?: boolean, data?: Report_jpk_pdam, message?: string}> {
    return this.putPelangganReportReportJpkPdamsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Report_jpk_pdam, message?: string})
    );
  }

  /**
   * Remove the specified report_jpk_pdam from storage
   *
   * Delete report_jpk_pdam
   * @param id id of report_jpk_pdam
   * @return successful operation
   */
  deletePelangganReportReportJpkPdamsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/reportJpkPdams/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified report_jpk_pdam from storage
   *
   * Delete report_jpk_pdam
   * @param id id of report_jpk_pdam
   * @return successful operation
   */
  deletePelangganReportReportJpkPdamsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportReportJpkPdamsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module ReportJpkPdamService {

  /**
   * Parameters for getPelangganReportReportJpkPdams
   */
  export interface GetPelangganReportReportJpkPdamsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter report_jpk_pdam
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportReportJpkPdamsId
   */
  export interface PutPelangganReportReportJpkPdamsIdParams {

    /**
     * id of report_jpk_pdam
     */
    id: number;

    /**
     * report_jpk_pdam that should be updated
     */
    body?: Report_jpk_pdam;
  }
}

export { ReportJpkPdamService }
