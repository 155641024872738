/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminPengaduanByType } from '../models/admin-pengaduan-by-type';

@Injectable({
  providedIn: 'root',
})
export class AdminPengaduanByTypeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminPengaduanByTypesList
   */
  static readonly GetadminPengaduanByTypesListPath = '/admin/pengaduanByTypes';

  /**
   * Get list of pengaduanByTypes.
   *
   * Returns list of pengaduanByTypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPengaduanByTypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanByTypesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanByType>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanByTypeService.GetadminPengaduanByTypesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminPengaduanByType>;
        }>;
      })
    );
  }

  /**
   * Get list of pengaduanByTypes.
   *
   * Returns list of pengaduanByTypes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPengaduanByTypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanByTypesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanByType>;
}> {

    return this.getadminPengaduanByTypesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanByType>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanByType>;
})
    );
  }

  /**
   * Path part for operation storeadminPengaduanByType
   */
  static readonly StoreadminPengaduanByTypePath = '/admin/pengaduanByTypes';

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminPengaduanByType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPengaduanByType$Response(params: {
    body: AdminPengaduanByType
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanByTypeService.StoreadminPengaduanByTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengaduanByType;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminPengaduanByType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPengaduanByType(params: {
    body: AdminPengaduanByType
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}> {

    return this.storeadminPengaduanByType$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
})
    );
  }

  /**
   * Path part for operation getadminPengaduanByTypeId
   */
  static readonly GetadminPengaduanByTypeIdPath = '/admin/pengaduanByTypes/{id}';

  /**
   * Get  Pengaduan_by_type by id.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPengaduanByTypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanByTypeId$Response(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanByTypeService.GetadminPengaduanByTypeIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengaduanByType;
        }>;
      })
    );
  }

  /**
   * Get  Pengaduan_by_type by id.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPengaduanByTypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanByTypeId(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}> {

    return this.getadminPengaduanByTypeId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
})
    );
  }

  /**
   * Path part for operation putadminPengaduanByType
   */
  static readonly PutadminPengaduanByTypePath = '/admin/pengaduanByTypes/{id}';

  /**
   * Update Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminPengaduanByType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPengaduanByType$Response(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
    body: AdminPengaduanByType
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanByTypeService.PutadminPengaduanByTypePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengaduanByType;
        }>;
      })
    );
  }

  /**
   * Update Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminPengaduanByType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPengaduanByType(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
    body: AdminPengaduanByType
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}> {

    return this.putadminPengaduanByType$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanByType;
})
    );
  }

  /**
   * Path part for operation deleteadminPengaduanByType
   */
  static readonly DeleteadminPengaduanByTypePath = '/admin/pengaduanByTypes/{id}';

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminPengaduanByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPengaduanByType$Response(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanByTypeService.DeleteadminPengaduanByTypePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_type.
   *
   * Returns Pengaduan_by_type data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminPengaduanByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPengaduanByType(params: {

    /**
     * id of Pengaduan_by_type
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminPengaduanByType$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
