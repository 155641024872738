/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pelanggan_perubahan_report } from '../models/pelanggan-_perubahan-_report';
@Injectable({
  providedIn: 'root',
})
class PelangganPerubahanReportService extends __BaseService {
  static readonly getPelangganReportPelangganPerubahanReportsPath = '/pelangganReport/pelangganPerubahanReports';
  static readonly postPelangganReportPelangganPerubahanReportsPath = '/pelangganReport/pelangganPerubahanReports';
  static readonly getPelangganReportPelangganPerubahanReportsIdPath = '/pelangganReport/pelangganPerubahanReports/{id}';
  static readonly putPelangganReportPelangganPerubahanReportsIdPath = '/pelangganReport/pelangganPerubahanReports/{id}';
  static readonly deletePelangganReportPelangganPerubahanReportsIdPath = '/pelangganReport/pelangganPerubahanReports/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the pelanggan_perubahan_report.
   *
   * Get all pelanggan_perubahan_report
   * @param params The `PelangganPerubahanReportService.GetPelangganReportPelangganPerubahanReportsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pelanggan_perubahan_report
   *
   * @return successful operation
   */
  getPelangganReportPelangganPerubahanReportsResponse(params: PelangganPerubahanReportService.GetPelangganReportPelangganPerubahanReportsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan_report>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/pelangganPerubahanReports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Pelanggan_perubahan_report>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the pelanggan_perubahan_report.
   *
   * Get all pelanggan_perubahan_report
   * @param params The `PelangganPerubahanReportService.GetPelangganReportPelangganPerubahanReportsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter pelanggan_perubahan_report
   *
   * @return successful operation
   */
  getPelangganReportPelangganPerubahanReports(params: PelangganPerubahanReportService.GetPelangganReportPelangganPerubahanReportsParams): __Observable<{success?: boolean, data?: Array<Pelanggan_perubahan_report>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportPelangganPerubahanReportsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Pelanggan_perubahan_report>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created pelanggan_perubahan_report in storage
   *
   * Store pelanggan_perubahan_report
   * @param body pelanggan_perubahan_report that should be stored
   * @return successful operation
   */
  postPelangganReportPelangganPerubahanReportsResponse(body?: Pelanggan_perubahan_report): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/pelangganPerubahanReports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created pelanggan_perubahan_report in storage
   *
   * Store pelanggan_perubahan_report
   * @param body pelanggan_perubahan_report that should be stored
   * @return successful operation
   */
  postPelangganReportPelangganPerubahanReports(body?: Pelanggan_perubahan_report): __Observable<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}> {
    return this.postPelangganReportPelangganPerubahanReportsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan_report, message?: string})
    );
  }

  /**
   * Display the specified pelanggan_perubahan_report
   *
   * Get pelanggan_perubahan_report
   * @param id id of pelanggan_perubahan_report
   * @return successful operation
   */
  getPelangganReportPelangganPerubahanReportsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/pelangganPerubahanReports/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}>;
      })
    );
  }
  /**
   * Display the specified pelanggan_perubahan_report
   *
   * Get pelanggan_perubahan_report
   * @param id id of pelanggan_perubahan_report
   * @return successful operation
   */
  getPelangganReportPelangganPerubahanReportsId(id: number): __Observable<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}> {
    return this.getPelangganReportPelangganPerubahanReportsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan_report, message?: string})
    );
  }

  /**
   * Update the specified pelanggan_perubahan_report in storage
   *
   * Update pelanggan_perubahan_report
   * @param params The `PelangganPerubahanReportService.PutPelangganReportPelangganPerubahanReportsIdParams` containing the following parameters:
   *
   * - `id`: id of pelanggan_perubahan_report
   *
   * - `body`: pelanggan_perubahan_report that should be updated
   *
   * @return successful operation
   */
  putPelangganReportPelangganPerubahanReportsIdResponse(params: PelangganPerubahanReportService.PutPelangganReportPelangganPerubahanReportsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/pelangganPerubahanReports/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}>;
      })
    );
  }
  /**
   * Update the specified pelanggan_perubahan_report in storage
   *
   * Update pelanggan_perubahan_report
   * @param params The `PelangganPerubahanReportService.PutPelangganReportPelangganPerubahanReportsIdParams` containing the following parameters:
   *
   * - `id`: id of pelanggan_perubahan_report
   *
   * - `body`: pelanggan_perubahan_report that should be updated
   *
   * @return successful operation
   */
  putPelangganReportPelangganPerubahanReportsId(params: PelangganPerubahanReportService.PutPelangganReportPelangganPerubahanReportsIdParams): __Observable<{success?: boolean, data?: Pelanggan_perubahan_report, message?: string}> {
    return this.putPelangganReportPelangganPerubahanReportsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Pelanggan_perubahan_report, message?: string})
    );
  }

  /**
   * Remove the specified pelanggan_perubahan_report from storage
   *
   * Delete pelanggan_perubahan_report
   * @param id id of pelanggan_perubahan_report
   * @return successful operation
   */
  deletePelangganReportPelangganPerubahanReportsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/pelangganPerubahanReports/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified pelanggan_perubahan_report from storage
   *
   * Delete pelanggan_perubahan_report
   * @param id id of pelanggan_perubahan_report
   * @return successful operation
   */
  deletePelangganReportPelangganPerubahanReportsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportPelangganPerubahanReportsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PelangganPerubahanReportService {

  /**
   * Parameters for getPelangganReportPelangganPerubahanReports
   */
  export interface GetPelangganReportPelangganPerubahanReportsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter pelanggan_perubahan_report
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportPelangganPerubahanReportsId
   */
  export interface PutPelangganReportPelangganPerubahanReportsIdParams {

    /**
     * id of pelanggan_perubahan_report
     */
    id: number;

    /**
     * pelanggan_perubahan_report that should be updated
     */
    body?: Pelanggan_perubahan_report;
  }
}

export { PelangganPerubahanReportService }
