/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PDF_tagihan_dspl } from '../models/pdf_tagihan-_dspl';
@Injectable({
  providedIn: 'root',
})
class PDFTagihanDsplService extends __BaseService {
  static readonly getPelangganPdfPDFTagihanDsplsPath = '/pelangganPdf/pDFTagihanDspls';
  static readonly postPelangganPdfPDFTagihanDsplsPath = '/pelangganPdf/pDFTagihanDspls';
  static readonly getPelangganPdfPDFTagihanDsplsIdPath = '/pelangganPdf/pDFTagihanDspls/{id}';
  static readonly putPelangganPdfPDFTagihanDsplsIdPath = '/pelangganPdf/pDFTagihanDspls/{id}';
  static readonly deletePelangganPdfPDFTagihanDsplsIdPath = '/pelangganPdf/pDFTagihanDspls/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the PDF_tagihan_dspl.
   *
   * Get all PDF_tagihan_dspl
   * @param params The `PDFTagihanDsplService.GetPelangganPdfPDFTagihanDsplsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter PDF_tagihan_dspl
   *
   * @return successful operation
   */
  getPelangganPdfPDFTagihanDsplsResponse(params: PDFTagihanDsplService.GetPelangganPdfPDFTagihanDsplsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<PDF_tagihan_dspl>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/pDFTagihanDspls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<PDF_tagihan_dspl>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the PDF_tagihan_dspl.
   *
   * Get all PDF_tagihan_dspl
   * @param params The `PDFTagihanDsplService.GetPelangganPdfPDFTagihanDsplsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter PDF_tagihan_dspl
   *
   * @return successful operation
   */
  getPelangganPdfPDFTagihanDspls(params: PDFTagihanDsplService.GetPelangganPdfPDFTagihanDsplsParams): __Observable<{success?: boolean, data?: Array<PDF_tagihan_dspl>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganPdfPDFTagihanDsplsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<PDF_tagihan_dspl>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created PDF_tagihan_dspl in storage
   *
   * Store PDF_tagihan_dspl
   * @param body PDF_tagihan_dspl that should be stored
   * @return successful operation
   */
  postPelangganPdfPDFTagihanDsplsResponse(body?: PDF_tagihan_dspl): __Observable<__StrictHttpResponse<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganPdf/pDFTagihanDspls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created PDF_tagihan_dspl in storage
   *
   * Store PDF_tagihan_dspl
   * @param body PDF_tagihan_dspl that should be stored
   * @return successful operation
   */
  postPelangganPdfPDFTagihanDspls(body?: PDF_tagihan_dspl): __Observable<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}> {
    return this.postPelangganPdfPDFTagihanDsplsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: PDF_tagihan_dspl, message?: string})
    );
  }

  /**
   * Display the specified PDF_tagihan_dspl
   *
   * Get PDF_tagihan_dspl
   * @param id id of PDF_tagihan_dspl
   * @return successful operation
   */
  getPelangganPdfPDFTagihanDsplsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganPdf/pDFTagihanDspls/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}>;
      })
    );
  }
  /**
   * Display the specified PDF_tagihan_dspl
   *
   * Get PDF_tagihan_dspl
   * @param id id of PDF_tagihan_dspl
   * @return successful operation
   */
  getPelangganPdfPDFTagihanDsplsId(id: number): __Observable<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}> {
    return this.getPelangganPdfPDFTagihanDsplsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: PDF_tagihan_dspl, message?: string})
    );
  }

  /**
   * Update the specified PDF_tagihan_dspl in storage
   *
   * Update PDF_tagihan_dspl
   * @param params The `PDFTagihanDsplService.PutPelangganPdfPDFTagihanDsplsIdParams` containing the following parameters:
   *
   * - `id`: id of PDF_tagihan_dspl
   *
   * - `body`: PDF_tagihan_dspl that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfPDFTagihanDsplsIdResponse(params: PDFTagihanDsplService.PutPelangganPdfPDFTagihanDsplsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganPdf/pDFTagihanDspls/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}>;
      })
    );
  }
  /**
   * Update the specified PDF_tagihan_dspl in storage
   *
   * Update PDF_tagihan_dspl
   * @param params The `PDFTagihanDsplService.PutPelangganPdfPDFTagihanDsplsIdParams` containing the following parameters:
   *
   * - `id`: id of PDF_tagihan_dspl
   *
   * - `body`: PDF_tagihan_dspl that should be updated
   *
   * @return successful operation
   */
  putPelangganPdfPDFTagihanDsplsId(params: PDFTagihanDsplService.PutPelangganPdfPDFTagihanDsplsIdParams): __Observable<{success?: boolean, data?: PDF_tagihan_dspl, message?: string}> {
    return this.putPelangganPdfPDFTagihanDsplsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: PDF_tagihan_dspl, message?: string})
    );
  }

  /**
   * Remove the specified PDF_tagihan_dspl from storage
   *
   * Delete PDF_tagihan_dspl
   * @param id id of PDF_tagihan_dspl
   * @return successful operation
   */
  deletePelangganPdfPDFTagihanDsplsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganPdf/pDFTagihanDspls/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified PDF_tagihan_dspl from storage
   *
   * Delete PDF_tagihan_dspl
   * @param id id of PDF_tagihan_dspl
   * @return successful operation
   */
  deletePelangganPdfPDFTagihanDsplsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganPdfPDFTagihanDsplsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module PDFTagihanDsplService {

  /**
   * Parameters for getPelangganPdfPDFTagihanDspls
   */
  export interface GetPelangganPdfPDFTagihanDsplsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter PDF_tagihan_dspl
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganPdfPDFTagihanDsplsId
   */
  export interface PutPelangganPdfPDFTagihanDsplsIdParams {

    /**
     * id of PDF_tagihan_dspl
     */
    id: number;

    /**
     * PDF_tagihan_dspl that should be updated
     */
    body?: PDF_tagihan_dspl;
  }
}

export { PDFTagihanDsplService }
