/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWigetPegawaiSk } from '../models/hr-report-wiget-pegawai-sk';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWigetPegawaiSkService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWigetPegawaiSkList
   */
  static readonly GetHrReportWigetPegawaiSkListPath = '/hrReportWiget/pegawai_sks';

  /**
   * getpegawai_skList.
   *
   * Get all pegawai_sks
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWigetPegawaiSkList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiSkList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiSk>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiSkService.GetHrReportWigetPegawaiSkListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWigetPegawaiSk>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_skList.
   *
   * Get all pegawai_sks
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWigetPegawaiSkList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiSkList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiSk>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWigetPegawaiSkList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiSk>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiSk>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWigetPegawaiSk
   */
  static readonly StoreHrReportWigetPegawaiSkPath = '/hrReportWiget/pegawai_sks';

  /**
   * createpegawai_sk.
   *
   * Create pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWigetPegawaiSk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWigetPegawaiSk$Response(params: {
    body: HrReportWigetPegawaiSk
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiSkService.StoreHrReportWigetPegawaiSkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiSk;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_sk.
   *
   * Create pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWigetPegawaiSk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWigetPegawaiSk(params: {
    body: HrReportWigetPegawaiSk
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}> {

    return this.storeHrReportWigetPegawaiSk$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWigetPegawaiSkId
   */
  static readonly GetHrReportWigetPegawaiSkIdPath = '/hrReportWiget/pegawai_sks/{id}';

  /**
   * getpegawai_skItem.
   *
   * Get pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWigetPegawaiSkId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiSkId$Response(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiSkService.GetHrReportWigetPegawaiSkIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiSk;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_skItem.
   *
   * Get pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWigetPegawaiSkId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiSkId(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}> {

    return this.getHrReportWigetPegawaiSkId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWigetPegawaiSkId
   */
  static readonly PutHrReportWigetPegawaiSkIdPath = '/hrReportWiget/pegawai_sks/{id}';

  /**
   * updatepegawai_sk.
   *
   * Update pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWigetPegawaiSkId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWigetPegawaiSkId$Response(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
    body: HrReportWigetPegawaiSk
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiSkService.PutHrReportWigetPegawaiSkIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiSk;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_sk.
   *
   * Update pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWigetPegawaiSkId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWigetPegawaiSkId(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
    body: HrReportWigetPegawaiSk
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}> {

    return this.putHrReportWigetPegawaiSkId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiSk;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWigetPegawaiSksId
   */
  static readonly DeleteHrReportWigetPegawaiSksIdPath = '/hrReportWiget/pegawai_sks/{id}';

  /**
   * deletepegawai_sk.
   *
   * Delete pegawai_sk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWigetPegawaiSksId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWigetPegawaiSksId$Response(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiSkService.DeleteHrReportWigetPegawaiSksIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_sk.
   *
   * Delete pegawai_sk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWigetPegawaiSksId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWigetPegawaiSksId(params: {

    /**
     * id of pegawai_sk
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWigetPegawaiSksId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
