/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tunggakan_by_tipe } from '../models/tunggakan-_by-_tipe';
@Injectable({
  providedIn: 'root',
})
class TunggakanByTipeService extends __BaseService {
  static readonly getPelangganReportTunggakanByTipesPath = '/pelangganReport/tunggakanByTipes';
  static readonly postPelangganReportTunggakanByTipesPath = '/pelangganReport/tunggakanByTipes';
  static readonly getPelangganReportTunggakanByTipesIdPath = '/pelangganReport/tunggakanByTipes/{id}';
  static readonly putPelangganReportTunggakanByTipesIdPath = '/pelangganReport/tunggakanByTipes/{id}';
  static readonly deletePelangganReportTunggakanByTipesIdPath = '/pelangganReport/tunggakanByTipes/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Tunggakan_by_tipe.
   *
   * Get all Tunggakan_by_tipe
   * @param params The `TunggakanByTipeService.GetPelangganReportTunggakanByTipesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tunggakan_by_tipe
   *
   * @return successful operation
   */
  getPelangganReportTunggakanByTipesResponse(params: TunggakanByTipeService.GetPelangganReportTunggakanByTipesParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Tunggakan_by_tipe>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tunggakanByTipes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Tunggakan_by_tipe>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Tunggakan_by_tipe.
   *
   * Get all Tunggakan_by_tipe
   * @param params The `TunggakanByTipeService.GetPelangganReportTunggakanByTipesParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Tunggakan_by_tipe
   *
   * @return successful operation
   */
  getPelangganReportTunggakanByTipes(params: TunggakanByTipeService.GetPelangganReportTunggakanByTipesParams): __Observable<{success?: boolean, data?: Array<Tunggakan_by_tipe>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganReportTunggakanByTipesResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Tunggakan_by_tipe>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Tunggakan_by_tipe in storage
   *
   * Store Tunggakan_by_tipe
   * @param body Tunggakan_by_tipe that should be stored
   * @return successful operation
   */
  postPelangganReportTunggakanByTipesResponse(body?: Tunggakan_by_tipe): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelangganReport/tunggakanByTipes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Tunggakan_by_tipe in storage
   *
   * Store Tunggakan_by_tipe
   * @param body Tunggakan_by_tipe that should be stored
   * @return successful operation
   */
  postPelangganReportTunggakanByTipes(body?: Tunggakan_by_tipe): __Observable<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}> {
    return this.postPelangganReportTunggakanByTipesResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_by_tipe, message?: string})
    );
  }

  /**
   * Display the specified Tunggakan_by_tipe
   *
   * Get Tunggakan_by_tipe
   * @param id id of Tunggakan_by_tipe
   * @return successful operation
   */
  getPelangganReportTunggakanByTipesIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelangganReport/tunggakanByTipes/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Tunggakan_by_tipe
   *
   * Get Tunggakan_by_tipe
   * @param id id of Tunggakan_by_tipe
   * @return successful operation
   */
  getPelangganReportTunggakanByTipesId(id: number): __Observable<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}> {
    return this.getPelangganReportTunggakanByTipesIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_by_tipe, message?: string})
    );
  }

  /**
   * Update the specified Tunggakan_by_tipe in storage
   *
   * Update Tunggakan_by_tipe
   * @param params The `TunggakanByTipeService.PutPelangganReportTunggakanByTipesIdParams` containing the following parameters:
   *
   * - `id`: id of Tunggakan_by_tipe
   *
   * - `body`: Tunggakan_by_tipe that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTunggakanByTipesIdResponse(params: TunggakanByTipeService.PutPelangganReportTunggakanByTipesIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelangganReport/tunggakanByTipes/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Tunggakan_by_tipe in storage
   *
   * Update Tunggakan_by_tipe
   * @param params The `TunggakanByTipeService.PutPelangganReportTunggakanByTipesIdParams` containing the following parameters:
   *
   * - `id`: id of Tunggakan_by_tipe
   *
   * - `body`: Tunggakan_by_tipe that should be updated
   *
   * @return successful operation
   */
  putPelangganReportTunggakanByTipesId(params: TunggakanByTipeService.PutPelangganReportTunggakanByTipesIdParams): __Observable<{success?: boolean, data?: Tunggakan_by_tipe, message?: string}> {
    return this.putPelangganReportTunggakanByTipesIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Tunggakan_by_tipe, message?: string})
    );
  }

  /**
   * Remove the specified Tunggakan_by_tipe from storage
   *
   * Delete Tunggakan_by_tipe
   * @param id id of Tunggakan_by_tipe
   * @return successful operation
   */
  deletePelangganReportTunggakanByTipesIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelangganReport/tunggakanByTipes/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Tunggakan_by_tipe from storage
   *
   * Delete Tunggakan_by_tipe
   * @param id id of Tunggakan_by_tipe
   * @return successful operation
   */
  deletePelangganReportTunggakanByTipesId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganReportTunggakanByTipesIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module TunggakanByTipeService {

  /**
   * Parameters for getPelangganReportTunggakanByTipes
   */
  export interface GetPelangganReportTunggakanByTipesParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Tunggakan_by_tipe
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganReportTunggakanByTipesId
   */
  export interface PutPelangganReportTunggakanByTipesIdParams {

    /**
     * id of Tunggakan_by_tipe
     */
    id: number;

    /**
     * Tunggakan_by_tipe that should be updated
     */
    body?: Tunggakan_by_tipe;
  }
}

export { TunggakanByTipeService }
