/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminPengaduanBySumber } from '../models/admin-pengaduan-by-sumber';

@Injectable({
  providedIn: 'root',
})
export class AdminPengaduanBySumberService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminPengaduanBySumbersList
   */
  static readonly GetadminPengaduanBySumbersListPath = '/admin/pengaduanBySumbers';

  /**
   * Get list of pengaduanBySumbers.
   *
   * Returns list of pengaduanBySumbers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPengaduanBySumbersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanBySumbersList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanBySumber>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanBySumberService.GetadminPengaduanBySumbersListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminPengaduanBySumber>;
        }>;
      })
    );
  }

  /**
   * Get list of pengaduanBySumbers.
   *
   * Returns list of pengaduanBySumbers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPengaduanBySumbersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanBySumbersList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanBySumber>;
}> {

    return this.getadminPengaduanBySumbersList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanBySumber>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminPengaduanBySumber>;
})
    );
  }

  /**
   * Path part for operation storeadminPengaduanBySumber
   */
  static readonly StoreadminPengaduanBySumberPath = '/admin/pengaduanBySumbers';

  /**
   * Store new Pengaduan_by_sumber.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminPengaduanBySumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPengaduanBySumber$Response(params: {
    body: AdminPengaduanBySumber
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanBySumberService.StoreadminPengaduanBySumberPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengaduanBySumber;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_sumber.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminPengaduanBySumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminPengaduanBySumber(params: {
    body: AdminPengaduanBySumber
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}> {

    return this.storeadminPengaduanBySumber$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
})
    );
  }

  /**
   * Path part for operation getadminPengaduanBySumberId
   */
  static readonly GetadminPengaduanBySumberIdPath = '/admin/pengaduanBySumbers/{id}';

  /**
   * Get  Pengaduan_by_sumber by id.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminPengaduanBySumberId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanBySumberId$Response(params: {

    /**
     * id of Pengaduan_by_sumber
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanBySumberService.GetadminPengaduanBySumberIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengaduanBySumber;
        }>;
      })
    );
  }

  /**
   * Get  Pengaduan_by_sumber by id.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminPengaduanBySumberId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminPengaduanBySumberId(params: {

    /**
     * id of Pengaduan_by_sumber
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}> {

    return this.getadminPengaduanBySumberId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
})
    );
  }

  /**
   * Path part for operation putadminPengaduanBySumber
   */
  static readonly PutadminPengaduanBySumberPath = '/admin/pengaduanBySumbers/{id}';

  /**
   * Update Pengaduan_by_sumber.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminPengaduanBySumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPengaduanBySumber$Response(params: {

    /**
     * id of Pengaduan_by_sumber
     */
    id: string;
    body: AdminPengaduanBySumber
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanBySumberService.PutadminPengaduanBySumberPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminPengaduanBySumber;
        }>;
      })
    );
  }

  /**
   * Update Pengaduan_by_sumber.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminPengaduanBySumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminPengaduanBySumber(params: {

    /**
     * id of Pengaduan_by_sumber
     */
    id: string;
    body: AdminPengaduanBySumber
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}> {

    return this.putadminPengaduanBySumber$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminPengaduanBySumber;
})
    );
  }

  /**
   * Path part for operation deleteadminPengaduanBySumber
   */
  static readonly DeleteadminPengaduanBySumberPath = '/admin/pengaduanBySumbers/{id}';

  /**
   * Store new Pengaduan_by_sumber.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminPengaduanBySumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPengaduanBySumber$Response(params: {

    /**
     * id of Pengaduan_by_sumber
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPengaduanBySumberService.DeleteadminPengaduanBySumberPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan_by_sumber.
   *
   * Returns Pengaduan_by_sumber data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminPengaduanBySumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminPengaduanBySumber(params: {

    /**
     * id of Pengaduan_by_sumber
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminPengaduanBySumber$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
