/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Sumber_air } from '../models/sumber-_air';
@Injectable({
  providedIn: 'root',
})
class SumberAirService extends __BaseService {
  static readonly getPelangganSumberAirsPath = '/pelanggan/sumberAirs';
  static readonly postPelangganSumberAirsPath = '/pelanggan/sumberAirs';
  static readonly getPelangganSumberAirsIdPath = '/pelanggan/sumberAirs/{id}';
  static readonly putPelangganSumberAirsIdPath = '/pelanggan/sumberAirs/{id}';
  static readonly deletePelangganSumberAirsIdPath = '/pelanggan/sumberAirs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Sumber_air.
   *
   * Get all Sumber_air
   * @param params The `SumberAirService.GetPelangganSumberAirsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Sumber_air
   *
   * @return successful operation
   */
  getPelangganSumberAirsResponse(params: SumberAirService.GetPelangganSumberAirsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Sumber_air>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/sumberAirs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Sumber_air>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Sumber_air.
   *
   * Get all Sumber_air
   * @param params The `SumberAirService.GetPelangganSumberAirsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Sumber_air
   *
   * @return successful operation
   */
  getPelangganSumberAirs(params: SumberAirService.GetPelangganSumberAirsParams): __Observable<{success?: boolean, data?: Array<Sumber_air>, message?: string, total_data?: number, total_page?: number}> {
    return this.getPelangganSumberAirsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Sumber_air>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Sumber_air in storage
   *
   * Store Sumber_air
   * @param body Sumber_air that should be stored
   * @return successful operation
   */
  postPelangganSumberAirsResponse(body?: Sumber_air): __Observable<__StrictHttpResponse<{success?: boolean, data?: Sumber_air, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pelanggan/sumberAirs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Sumber_air, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Sumber_air in storage
   *
   * Store Sumber_air
   * @param body Sumber_air that should be stored
   * @return successful operation
   */
  postPelangganSumberAirs(body?: Sumber_air): __Observable<{success?: boolean, data?: Sumber_air, message?: string}> {
    return this.postPelangganSumberAirsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Sumber_air, message?: string})
    );
  }

  /**
   * Display the specified Sumber_air
   *
   * Get Sumber_air
   * @param id id of Sumber_air
   * @return successful operation
   */
  getPelangganSumberAirsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Sumber_air, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pelanggan/sumberAirs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Sumber_air, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Sumber_air
   *
   * Get Sumber_air
   * @param id id of Sumber_air
   * @return successful operation
   */
  getPelangganSumberAirsId(id: number): __Observable<{success?: boolean, data?: Sumber_air, message?: string}> {
    return this.getPelangganSumberAirsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Sumber_air, message?: string})
    );
  }

  /**
   * Update the specified Sumber_air in storage
   *
   * Update Sumber_air
   * @param params The `SumberAirService.PutPelangganSumberAirsIdParams` containing the following parameters:
   *
   * - `id`: id of Sumber_air
   *
   * - `body`: Sumber_air that should be updated
   *
   * @return successful operation
   */
  putPelangganSumberAirsIdResponse(params: SumberAirService.PutPelangganSumberAirsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Sumber_air, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pelanggan/sumberAirs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Sumber_air, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Sumber_air in storage
   *
   * Update Sumber_air
   * @param params The `SumberAirService.PutPelangganSumberAirsIdParams` containing the following parameters:
   *
   * - `id`: id of Sumber_air
   *
   * - `body`: Sumber_air that should be updated
   *
   * @return successful operation
   */
  putPelangganSumberAirsId(params: SumberAirService.PutPelangganSumberAirsIdParams): __Observable<{success?: boolean, data?: Sumber_air, message?: string}> {
    return this.putPelangganSumberAirsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Sumber_air, message?: string})
    );
  }

  /**
   * Remove the specified Sumber_air from storage
   *
   * Delete Sumber_air
   * @param id id of Sumber_air
   * @return successful operation
   */
  deletePelangganSumberAirsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/pelanggan/sumberAirs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Sumber_air from storage
   *
   * Delete Sumber_air
   * @param id id of Sumber_air
   * @return successful operation
   */
  deletePelangganSumberAirsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deletePelangganSumberAirsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module SumberAirService {

  /**
   * Parameters for getPelangganSumberAirs
   */
  export interface GetPelangganSumberAirsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Sumber_air
     */
    filter?: string;
  }

  /**
   * Parameters for putPelangganSumberAirsId
   */
  export interface PutPelangganSumberAirsIdParams {

    /**
     * id of Sumber_air
     */
    id: number;

    /**
     * Sumber_air that should be updated
     */
    body?: Sumber_air;
  }
}

export { SumberAirService }
