/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ErpReportDepartement } from '../models/erp-report-departement';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class ErpReportDepartementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getErpReportDepartementList
   */
  static readonly GetErpReportDepartementListPath = '/erpReport/departements';

  /**
   * getdepartementList.
   *
   * Get all departements
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpReportDepartementList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDepartementList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpReportDepartement>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDepartementService.GetErpReportDepartementListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<ErpReportDepartement>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getdepartementList.
   *
   * Get all departements
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpReportDepartementList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDepartementList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<ErpReportDepartement>;
'message'?: string;
'total'?: number;
}> {

    return this.getErpReportDepartementList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpReportDepartement>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<ErpReportDepartement>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeErpReportDepartement
   */
  static readonly StoreErpReportDepartementPath = '/erpReport/departements';

  /**
   * createdepartement.
   *
   * Create departement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeErpReportDepartement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpReportDepartement$Response(params: {
    body: ErpReportDepartement
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDepartementService.StoreErpReportDepartementPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportDepartement;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createdepartement.
   *
   * Create departement
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeErpReportDepartement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpReportDepartement(params: {
    body: ErpReportDepartement
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}> {

    return this.storeErpReportDepartement$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getErpReportDepartementId
   */
  static readonly GetErpReportDepartementIdPath = '/erpReport/departements/{id}';

  /**
   * getdepartementItem.
   *
   * Get departement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpReportDepartementId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDepartementId$Response(params: {

    /**
     * id of departement
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDepartementService.GetErpReportDepartementIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportDepartement;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getdepartementItem.
   *
   * Get departement
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpReportDepartementId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpReportDepartementId(params: {

    /**
     * id of departement
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}> {

    return this.getErpReportDepartementId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putErpReportDepartementId
   */
  static readonly PutErpReportDepartementIdPath = '/erpReport/departements/{id}';

  /**
   * updatedepartement.
   *
   * Update departement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putErpReportDepartementId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpReportDepartementId$Response(params: {

    /**
     * id of departement
     */
    id: string;
    body: ErpReportDepartement
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDepartementService.PutErpReportDepartementIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpReportDepartement;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatedepartement.
   *
   * Update departement
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putErpReportDepartementId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpReportDepartementId(params: {

    /**
     * id of departement
     */
    id: string;
    body: ErpReportDepartement
  }): Observable<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}> {

    return this.putErpReportDepartementId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpReportDepartement;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteErpReportDepartementsId
   */
  static readonly DeleteErpReportDepartementsIdPath = '/erpReport/departements/{id}';

  /**
   * deletedepartement.
   *
   * Delete departement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteErpReportDepartementsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpReportDepartementsId$Response(params: {

    /**
     * id of departement
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpReportDepartementService.DeleteErpReportDepartementsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletedepartement.
   *
   * Delete departement
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteErpReportDepartementsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpReportDepartementsId(params: {

    /**
     * id of departement
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteErpReportDepartementsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
