/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Telaahan_staf } from '../models/telaahan-_staf';
@Injectable({
  providedIn: 'root',
})
class TelaahanStafService extends __BaseService {
  static readonly getArsipTelaahanStafsPath = '/arsip/telaahanStafs';
  static readonly postArsipTelaahanStafsPath = '/arsip/telaahanStafs';
  static readonly getArsipTelaahanStafsIdPath = '/arsip/telaahanStafs/{id}';
  static readonly putArsipTelaahanStafsIdPath = '/arsip/telaahanStafs/{id}';
  static readonly deleteArsipTelaahanStafsIdPath = '/arsip/telaahanStafs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the Telaahan_staf.
   *
   * Get all Telaahan_staf
   * @param params The `TelaahanStafService.GetArsipTelaahanStafsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Telaahan_staf
   *
   * @return successful operation
   */
  getArsipTelaahanStafsResponse(params: TelaahanStafService.GetArsipTelaahanStafsParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Telaahan_staf>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/telaahanStafs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Telaahan_staf>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the Telaahan_staf.
   *
   * Get all Telaahan_staf
   * @param params The `TelaahanStafService.GetArsipTelaahanStafsParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter Telaahan_staf
   *
   * @return successful operation
   */
  getArsipTelaahanStafs(params: TelaahanStafService.GetArsipTelaahanStafsParams): __Observable<{success?: boolean, data?: Array<Telaahan_staf>, message?: string, total_data?: number, total_page?: number}> {
    return this.getArsipTelaahanStafsResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Telaahan_staf>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created Telaahan_staf in storage
   *
   * Store Telaahan_staf
   * @param body Telaahan_staf that should be stored
   * @return successful operation
   */
  postArsipTelaahanStafsResponse(body?: Telaahan_staf): __Observable<__StrictHttpResponse<{success?: boolean, data?: Telaahan_staf, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/arsip/telaahanStafs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Telaahan_staf, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created Telaahan_staf in storage
   *
   * Store Telaahan_staf
   * @param body Telaahan_staf that should be stored
   * @return successful operation
   */
  postArsipTelaahanStafs(body?: Telaahan_staf): __Observable<{success?: boolean, data?: Telaahan_staf, message?: string}> {
    return this.postArsipTelaahanStafsResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Telaahan_staf, message?: string})
    );
  }

  /**
   * Display the specified Telaahan_staf
   *
   * Get Telaahan_staf
   * @param id id of Telaahan_staf
   * @return successful operation
   */
  getArsipTelaahanStafsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Telaahan_staf, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/arsip/telaahanStafs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Telaahan_staf, message?: string}>;
      })
    );
  }
  /**
   * Display the specified Telaahan_staf
   *
   * Get Telaahan_staf
   * @param id id of Telaahan_staf
   * @return successful operation
   */
  getArsipTelaahanStafsId(id: number): __Observable<{success?: boolean, data?: Telaahan_staf, message?: string}> {
    return this.getArsipTelaahanStafsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Telaahan_staf, message?: string})
    );
  }

  /**
   * Update the specified Telaahan_staf in storage
   *
   * Update Telaahan_staf
   * @param params The `TelaahanStafService.PutArsipTelaahanStafsIdParams` containing the following parameters:
   *
   * - `id`: id of Telaahan_staf
   *
   * - `body`: Telaahan_staf that should be updated
   *
   * @return successful operation
   */
  putArsipTelaahanStafsIdResponse(params: TelaahanStafService.PutArsipTelaahanStafsIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Telaahan_staf, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/arsip/telaahanStafs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Telaahan_staf, message?: string}>;
      })
    );
  }
  /**
   * Update the specified Telaahan_staf in storage
   *
   * Update Telaahan_staf
   * @param params The `TelaahanStafService.PutArsipTelaahanStafsIdParams` containing the following parameters:
   *
   * - `id`: id of Telaahan_staf
   *
   * - `body`: Telaahan_staf that should be updated
   *
   * @return successful operation
   */
  putArsipTelaahanStafsId(params: TelaahanStafService.PutArsipTelaahanStafsIdParams): __Observable<{success?: boolean, data?: Telaahan_staf, message?: string}> {
    return this.putArsipTelaahanStafsIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Telaahan_staf, message?: string})
    );
  }

  /**
   * Remove the specified Telaahan_staf from storage
   *
   * Delete Telaahan_staf
   * @param id id of Telaahan_staf
   * @return successful operation
   */
  deleteArsipTelaahanStafsIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/arsip/telaahanStafs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified Telaahan_staf from storage
   *
   * Delete Telaahan_staf
   * @param id id of Telaahan_staf
   * @return successful operation
   */
  deleteArsipTelaahanStafsId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteArsipTelaahanStafsIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module TelaahanStafService {

  /**
   * Parameters for getArsipTelaahanStafs
   */
  export interface GetArsipTelaahanStafsParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter Telaahan_staf
     */
    filter?: string;
  }

  /**
   * Parameters for putArsipTelaahanStafsId
   */
  export interface PutArsipTelaahanStafsIdParams {

    /**
     * id of Telaahan_staf
     */
    id: number;

    /**
     * Telaahan_staf that should be updated
     */
    body?: Telaahan_staf;
  }
}

export { TelaahanStafService }
