/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPengaduan } from '../models/client-pengaduan';

@Injectable({
  providedIn: 'root',
})
export class ClientPengaduanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientPengaduansList
   */
  static readonly GetclientPengaduansListPath = '/client/pengaduans';

  /**
   * Get list of pengaduans.
   *
   * Returns list of pengaduans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengaduansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanService.GetclientPengaduansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPengaduan>;
        }>;
      })
    );
  }

  /**
   * Get list of pengaduans.
   *
   * Returns list of pengaduans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengaduansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduan>;
}> {

    return this.getclientPengaduansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPengaduan>;
})
    );
  }

  /**
   * Path part for operation storeclientPengaduan
   */
  static readonly StoreclientPengaduanPath = '/client/pengaduans';

  /**
   * Store new Pengaduan.
   *
   * Returns Pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientPengaduan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengaduan$Response(params: {
    body: ClientPengaduan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanService.StoreclientPengaduanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduan;
        }>;
      })
    );
  }

  /**
   * Store new Pengaduan.
   *
   * Returns Pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientPengaduan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientPengaduan(params: {
    body: ClientPengaduan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
}> {

    return this.storeclientPengaduan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
})
    );
  }

  /**
   * Path part for operation getclientPengaduanId
   */
  static readonly GetclientPengaduanIdPath = '/client/pengaduans/{id}';

  /**
   * Get  Pengaduan by id.
   *
   * Returns Pengaduan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientPengaduanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanId$Response(params: {

    /**
     * id of Pengaduan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPengaduanService.GetclientPengaduanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPengaduan;
        }>;
      })
    );
  }

  /**
   * Get  Pengaduan by id.
   *
   * Returns Pengaduan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientPengaduanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientPengaduanId(params: {

    /**
     * id of Pengaduan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
}> {

    return this.getclientPengaduanId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPengaduan;
})
    );
  }

}
