/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthPasswordResets } from '../models/auth-password-resets';

@Injectable({
  providedIn: 'root',
})
export class AuthPasswordResetsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation storeAuthPasswordResets
   */
  static readonly StoreAuthPasswordResetsPath = '/auth/password_resets';

  /**
   * createpassword_resets.
   *
   * Create password_resets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAuthPasswordResets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAuthPasswordResets$Response(params: {
    body: AuthPasswordResets
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AuthPasswordResets;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AuthPasswordResetsService.StoreAuthPasswordResetsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AuthPasswordResets;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpassword_resets.
   *
   * Create password_resets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAuthPasswordResets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAuthPasswordResets(params: {
    body: AuthPasswordResets
  }): Observable<{
'success'?: boolean;
'data'?: AuthPasswordResets;
'message'?: string;
}> {

    return this.storeAuthPasswordResets$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AuthPasswordResets;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AuthPasswordResets;
'message'?: string;
})
    );
  }

}
