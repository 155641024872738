/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientGalery } from '../models/client-galery';

@Injectable({
  providedIn: 'root',
})
export class ClientGaleryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientgetgaleriesList
   */
  static readonly ClientgetgaleriesListPath = '/client/galeries';

  /**
   * Get list of galeries.
   *
   * Returns list of galeries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetgaleriesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetgaleriesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientGalery>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientGaleryService.ClientgetgaleriesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientGalery>;
        }>;
      })
    );
  }

  /**
   * Get list of galeries.
   *
   * Returns list of galeries
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetgaleriesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetgaleriesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientGalery>;
}> {

    return this.clientgetgaleriesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientGalery>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientGalery>;
})
    );
  }

  /**
   * Path part for operation clientgetGaleryId
   */
  static readonly ClientgetGaleryIdPath = '/client/galeries/{id}';

  /**
   * Get  Galery by id.
   *
   * Returns Galery data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetGaleryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetGaleryId$Response(params: {

    /**
     * id of Galery
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientGalery;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientGaleryService.ClientgetGaleryIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientGalery;
        }>;
      })
    );
  }

  /**
   * Get  Galery by id.
   *
   * Returns Galery data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetGaleryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetGaleryId(params: {

    /**
     * id of Galery
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientGalery;
}> {

    return this.clientgetGaleryId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientGalery;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientGalery;
})
    );
  }

}
