import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/@vb/components/Guard/auth.guard'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

// pages
// VB:REPLACE-START:ROUTER-IMPORTS
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { MeteranComponent } from './pages/meteran/meteran.component'
import { LayoutPublicComponent } from './layouts/Public/public.component'

// VB:REPLACE-END:ROUTER-IMPORTS

const routes: Routes = [
    {
        path: '',
        // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        children: [
            // VB:REPLACE-START:ROUTER-CONFIG


            {
                path: 'pelanggan',
                canActivate: [AuthGuard],
                data: {
                    title: 'Pelanggan',
                    name_module: 'pelanggan',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/pelanggan/pelanggan.module').then(m => m.PelangganModule)
            },

            {
                path: 'penagihan',
                canActivate: [AuthGuard],
                data: {
                    title: 'Penagihan',
                    name_module: 'penagihan',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/penagihan/penagihan.module').then(m => m.PenagihanModule)
            },

            {
                path: 'admin',
                canActivate: [AuthGuard],
                data: {
                    title: 'Admin',
                    name_module: 'user',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
            }, {
                path: 'switching',
                canActivate: [AuthGuard],
                data: {
                    title: 'Switching',
                    name_module: 'partner',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/switching/switching.module').then(m => m.SwitchingModule)
            },
            {
                path: 'arsip',
                canActivate: [AuthGuard],
                data: {
                    title: 'Arsip',
                    name_module: 'arsip',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/arsip/arsip.module').then(m => m.ArsipModule)
            },
            {
                path: 'employee',
                canActivate: [AuthGuard],
                data: {
                    title: 'Employee',
                    name_module: 'testing',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/hr/employee/employee.module').then(m => m.EmployeeModule)
            },
            {
                path: 'company',
                canActivate: [AuthGuard],
                data: {
                    title: 'Company',
                    name_module: 'testing',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/hr/company/company.module').then(m => m.CompanyModule)
            },
            {
                path: 'hr-management',
                canActivate: [AuthGuard],
                data: {
                    title: 'hr-management',
                    name_module: 'testing',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/hr/management/management.module').then(m => m.ManagementModule)
            },
            {
                path: 'cms',
                canActivate: [AuthGuard],
                data: {
                    title: 'CMS',
                    name_module: 'testing',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
            },
            {
                path: 'mpp',
                canActivate: [AuthGuard],
                data: {
                    title: 'MPP',
                    name_module: 'mpp',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/mpp/mpp.module').then(m => m.MppModule)
            },
            {
                path: 'report',
                canActivate: [AuthGuard],
                data: {
                    title: 'Report',
                    name_module: 'report',
                    action: 'detail'
                },
                loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule)
            },
            {
                path: 'meteran',
                data: { title: 'Baca meter' },
                component: MeteranComponent,
            },

            // VB:REPLACE-END:ROUTER-CONFIG
        ],
    },
    {
        path: 'auth',
        component: LayoutAuthComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('src/app/pages/auth/auth.module').then((m) => m.AuthModule),
            },
        ],
    },
    {
        path: '',
        component: LayoutPublicComponent,
        canActivate: [AuthGuard],
        children: [

            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                data: {
                    title: 'Dashboard'
                },
                loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
            },

            {
                path: 'maping',
                loadChildren: () => import('src/app/pages/maping/maping.module').then((m) => m.MapingModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/auth/404',
    },
]

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {
            useHash: true,
            preloadingStrategy: AppPreloader,
            relativeLinkResolution: 'legacy',
        }),
        LayoutsModule,
    ],
    declarations: [
        // VB:REPLACE-START:ROUTER-DECLARATIONS
        MeteranComponent,

        // VB:REPLACE-END:ROUTER-DECLARATIONS
    ],
    providers: [AppPreloader],
    exports: [RouterModule],
})
export class AppRoutingModule { }
