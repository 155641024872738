/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrPegawaiGolongan } from '../models/hr-pegawai-golongan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrPegawaiGolonganService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrPegawaiGolonganList
   */
  static readonly GetHrPegawaiGolonganListPath = '/hr/pegawai_golongans';

  /**
   * getpegawai_golonganList.
   *
   * Get all pegawai_golongans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiGolonganList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiGolonganList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiGolongan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiGolonganService.GetHrPegawaiGolonganListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrPegawaiGolongan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_golonganList.
   *
   * Get all pegawai_golongans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiGolonganList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiGolonganList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrPegawaiGolongan>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrPegawaiGolonganList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrPegawaiGolongan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrPegawaiGolongan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrPegawaiGolongan
   */
  static readonly StoreHrPegawaiGolonganPath = '/hr/pegawai_golongans';

  /**
   * createpegawai_golongan.
   *
   * Create pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrPegawaiGolongan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiGolongan$Response(params: {
    body: HrPegawaiGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiGolonganService.StoreHrPegawaiGolonganPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_golongan.
   *
   * Create pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrPegawaiGolongan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrPegawaiGolongan(params: {
    body: HrPegawaiGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}> {

    return this.storeHrPegawaiGolongan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrPegawaiGolonganId
   */
  static readonly GetHrPegawaiGolonganIdPath = '/hr/pegawai_golongans/{id}';

  /**
   * getpegawai_golonganItem.
   *
   * Get pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrPegawaiGolonganId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiGolonganId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiGolonganService.GetHrPegawaiGolonganIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_golonganItem.
   *
   * Get pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrPegawaiGolonganId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrPegawaiGolonganId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}> {

    return this.getHrPegawaiGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrPegawaiGolonganId
   */
  static readonly PutHrPegawaiGolonganIdPath = '/hr/pegawai_golongans/{id}';

  /**
   * updatepegawai_golongan.
   *
   * Update pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrPegawaiGolonganId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiGolonganId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
    body: HrPegawaiGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiGolonganService.PutHrPegawaiGolonganIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_golongan.
   *
   * Update pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrPegawaiGolonganId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrPegawaiGolonganId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
    body: HrPegawaiGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}> {

    return this.putHrPegawaiGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrPegawaiGolongansId
   */
  static readonly DeleteHrPegawaiGolongansIdPath = '/hr/pegawai_golongans/{id}';

  /**
   * deletepegawai_golongan.
   *
   * Delete pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrPegawaiGolongansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiGolongansId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrPegawaiGolonganService.DeleteHrPegawaiGolongansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_golongan.
   *
   * Delete pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrPegawaiGolongansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrPegawaiGolongansId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrPegawaiGolongansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
