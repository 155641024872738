/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Journal_penerimaan } from '../models/journal-_penerimaan';
@Injectable({
  providedIn: 'root',
})
class JournalPenerimaanService extends __BaseService {
  static readonly getReportJournalPenerimaansPath = '/report/journalPenerimaans';
  static readonly postReportJournalPenerimaansPath = '/report/journalPenerimaans';
  static readonly getReportJournalPenerimaansIdPath = '/report/journalPenerimaans/{id}';
  static readonly putReportJournalPenerimaansIdPath = '/report/journalPenerimaans/{id}';
  static readonly deleteReportJournalPenerimaansIdPath = '/report/journalPenerimaans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the journal_penerimaan.
   *
   * Get all journal_penerimaan
   * @param params The `JournalPenerimaanService.GetReportJournalPenerimaansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter journal_penerimaan
   *
   * @return successful operation
   */
  getReportJournalPenerimaansResponse(params: JournalPenerimaanService.GetReportJournalPenerimaansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<Journal_penerimaan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/journalPenerimaans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<Journal_penerimaan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the journal_penerimaan.
   *
   * Get all journal_penerimaan
   * @param params The `JournalPenerimaanService.GetReportJournalPenerimaansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter journal_penerimaan
   *
   * @return successful operation
   */
  getReportJournalPenerimaans(params: JournalPenerimaanService.GetReportJournalPenerimaansParams): __Observable<{success?: boolean, data?: Array<Journal_penerimaan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getReportJournalPenerimaansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<Journal_penerimaan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created journal_penerimaan in storage
   *
   * Store journal_penerimaan
   * @param body journal_penerimaan that should be stored
   * @return successful operation
   */
  postReportJournalPenerimaansResponse(body?: Journal_penerimaan): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report/journalPenerimaans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created journal_penerimaan in storage
   *
   * Store journal_penerimaan
   * @param body journal_penerimaan that should be stored
   * @return successful operation
   */
  postReportJournalPenerimaans(body?: Journal_penerimaan): __Observable<{success?: boolean, data?: Journal_penerimaan, message?: string}> {
    return this.postReportJournalPenerimaansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_penerimaan, message?: string})
    );
  }

  /**
   * Display the specified journal_penerimaan
   *
   * Get journal_penerimaan
   * @param id id of journal_penerimaan
   * @return successful operation
   */
  getReportJournalPenerimaansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/journalPenerimaans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified journal_penerimaan
   *
   * Get journal_penerimaan
   * @param id id of journal_penerimaan
   * @return successful operation
   */
  getReportJournalPenerimaansId(id: number): __Observable<{success?: boolean, data?: Journal_penerimaan, message?: string}> {
    return this.getReportJournalPenerimaansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_penerimaan, message?: string})
    );
  }

  /**
   * Update the specified journal_penerimaan in storage
   *
   * Update journal_penerimaan
   * @param params The `JournalPenerimaanService.PutReportJournalPenerimaansIdParams` containing the following parameters:
   *
   * - `id`: id of journal_penerimaan
   *
   * - `body`: journal_penerimaan that should be updated
   *
   * @return successful operation
   */
  putReportJournalPenerimaansIdResponse(params: JournalPenerimaanService.PutReportJournalPenerimaansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/report/journalPenerimaans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Journal_penerimaan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified journal_penerimaan in storage
   *
   * Update journal_penerimaan
   * @param params The `JournalPenerimaanService.PutReportJournalPenerimaansIdParams` containing the following parameters:
   *
   * - `id`: id of journal_penerimaan
   *
   * - `body`: journal_penerimaan that should be updated
   *
   * @return successful operation
   */
  putReportJournalPenerimaansId(params: JournalPenerimaanService.PutReportJournalPenerimaansIdParams): __Observable<{success?: boolean, data?: Journal_penerimaan, message?: string}> {
    return this.putReportJournalPenerimaansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Journal_penerimaan, message?: string})
    );
  }

  /**
   * Remove the specified journal_penerimaan from storage
   *
   * Delete journal_penerimaan
   * @param id id of journal_penerimaan
   * @return successful operation
   */
  deleteReportJournalPenerimaansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/report/journalPenerimaans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified journal_penerimaan from storage
   *
   * Delete journal_penerimaan
   * @param id id of journal_penerimaan
   * @return successful operation
   */
  deleteReportJournalPenerimaansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteReportJournalPenerimaansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module JournalPenerimaanService {

  /**
   * Parameters for getReportJournalPenerimaans
   */
  export interface GetReportJournalPenerimaansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter journal_penerimaan
     */
    filter?: string;
  }

  /**
   * Parameters for putReportJournalPenerimaansId
   */
  export interface PutReportJournalPenerimaansIdParams {

    /**
     * id of journal_penerimaan
     */
    id: number;

    /**
     * journal_penerimaan that should be updated
     */
    body?: Journal_penerimaan;
  }
}

export { JournalPenerimaanService }
