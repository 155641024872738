/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ErpPangkat } from '../models/erp-pangkat';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class ErpPangkatService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getErpPangkatList
   */
  static readonly GetErpPangkatListPath = '/erp/pangkats';

  /**
   * getpangkatList.
   *
   * Get all pangkats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpPangkatList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpPangkatList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpPangkat>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpPangkatService.GetErpPangkatListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<ErpPangkat>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpangkatList.
   *
   * Get all pangkats
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpPangkatList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpPangkatList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<ErpPangkat>;
'message'?: string;
'total'?: number;
}> {

    return this.getErpPangkatList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<ErpPangkat>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<ErpPangkat>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeErpPangkat
   */
  static readonly StoreErpPangkatPath = '/erp/pangkats';

  /**
   * createpangkat.
   *
   * Create pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeErpPangkat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpPangkat$Response(params: {
    body: ErpPangkat
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpPangkatService.StoreErpPangkatPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpPangkat;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpangkat.
   *
   * Create pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeErpPangkat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeErpPangkat(params: {
    body: ErpPangkat
  }): Observable<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}> {

    return this.storeErpPangkat$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getErpPangkatId
   */
  static readonly GetErpPangkatIdPath = '/erp/pangkats/{id}';

  /**
   * getpangkatItem.
   *
   * Get pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErpPangkatId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpPangkatId$Response(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpPangkatService.GetErpPangkatIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpPangkat;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpangkatItem.
   *
   * Get pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErpPangkatId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErpPangkatId(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}> {

    return this.getErpPangkatId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putErpPangkatId
   */
  static readonly PutErpPangkatIdPath = '/erp/pangkats/{id}';

  /**
   * updatepangkat.
   *
   * Update pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putErpPangkatId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpPangkatId$Response(params: {

    /**
     * id of pangkat
     */
    id: string;
    body: ErpPangkat
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpPangkatService.PutErpPangkatIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: ErpPangkat;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepangkat.
   *
   * Update pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putErpPangkatId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putErpPangkatId(params: {

    /**
     * id of pangkat
     */
    id: string;
    body: ErpPangkat
  }): Observable<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}> {

    return this.putErpPangkatId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: ErpPangkat;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteErpPangkatsId
   */
  static readonly DeleteErpPangkatsIdPath = '/erp/pangkats/{id}';

  /**
   * deletepangkat.
   *
   * Delete pangkat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteErpPangkatsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpPangkatsId$Response(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ErpPangkatService.DeleteErpPangkatsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepangkat.
   *
   * Delete pangkat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteErpPangkatsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErpPangkatsId(params: {

    /**
     * id of pangkat
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteErpPangkatsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
