/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWidgetPegawaiSp } from '../models/hr-report-widget-pegawai-sp';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWidgetPegawaiSpService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiSpList
   */
  static readonly GetHrReportWidgetPegawaiSpListPath = '/hrReportWidget/pegawai_sps';

  /**
   * getpegawai_spList.
   *
   * Get all pegawai_sps
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiSpList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSp>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpService.GetHrReportWidgetPegawaiSpListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWidgetPegawaiSp>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_spList.
   *
   * Get all pegawai_sps
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiSpList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSp>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWidgetPegawaiSpList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSp>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSp>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWidgetPegawaiSp
   */
  static readonly StoreHrReportWidgetPegawaiSpPath = '/hrReportWidget/pegawai_sps';

  /**
   * createpegawai_sp.
   *
   * Create pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWidgetPegawaiSp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiSp$Response(params: {
    body: HrReportWidgetPegawaiSp
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpService.StoreHrReportWidgetPegawaiSpPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSp;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_sp.
   *
   * Create pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWidgetPegawaiSp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiSp(params: {
    body: HrReportWidgetPegawaiSp
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}> {

    return this.storeHrReportWidgetPegawaiSp$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiSpId
   */
  static readonly GetHrReportWidgetPegawaiSpIdPath = '/hrReportWidget/pegawai_sps/{id}';

  /**
   * getpegawai_spItem.
   *
   * Get pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiSpId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpId$Response(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpService.GetHrReportWidgetPegawaiSpIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSp;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_spItem.
   *
   * Get pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiSpId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpId(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}> {

    return this.getHrReportWidgetPegawaiSpId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWidgetPegawaiSpId
   */
  static readonly PutHrReportWidgetPegawaiSpIdPath = '/hrReportWidget/pegawai_sps/{id}';

  /**
   * updatepegawai_sp.
   *
   * Update pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWidgetPegawaiSpId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiSpId$Response(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
    body: HrReportWidgetPegawaiSp
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpService.PutHrReportWidgetPegawaiSpIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSp;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_sp.
   *
   * Update pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWidgetPegawaiSpId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiSpId(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
    body: HrReportWidgetPegawaiSp
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}> {

    return this.putHrReportWidgetPegawaiSpId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSp;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWidgetPegawaiSpsId
   */
  static readonly DeleteHrReportWidgetPegawaiSpsIdPath = '/hrReportWidget/pegawai_sps/{id}';

  /**
   * deletepegawai_sp.
   *
   * Delete pegawai_sp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWidgetPegawaiSpsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiSpsId$Response(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpService.DeleteHrReportWidgetPegawaiSpsIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_sp.
   *
   * Delete pegawai_sp
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWidgetPegawaiSpsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiSpsId(params: {

    /**
     * id of pegawai_sp
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWidgetPegawaiSpsId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
