import { PesanService } from './../../../services/pesan.service';
import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { forkJoin, Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { map, take } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    authorized: boolean
    roles = [];
    constructor(
        private store: Store<any>,
        private pesanService: PesanService,
        public router: Router
    ) {

        this.store.pipe(select(Reducers.getUser)).subscribe(state => {
            this.authorized = state.authorized
            this.roles = state.role
            console.log(state)

        })
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {


        if (environment.authenticated) {
            // skip guard checking on demo environment serve/build, remove it in your app
            return true
        }
        //return true
        if (this.authorized) {
            let data = next.data

            if (data.hasOwnProperty('name_module')) {
                let role = this.roles.find(m => (m.name_module == data.name_module && m.module_detail == data.action));
                if (role) {
                    console.log(role.access)
                    if (role.access) {
                        return true
                    }

                }

                this.pesanService.pesanWarningForm('Anda tidak memiliki akses');
                this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
                return false

            }

            return true
        }

        this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
        return false
    }

    private _checkLoggedIn(loggedIn, data): Observable<boolean> {

        if (loggedIn) {

            if (data.hasOwnProperty('name_module')) {
                let role = this.roles.find(m => (m.name_module == data.name_module && m.module_detail == data.action));
                console.log(role)
            }

            return of(true);
        } else {

            console.log(this.roles)
            return this.store.pipe(select(Reducers.getUser)).pipe(map((currentUser) => {

                console.log(currentUser)
                console.log(this.roles)

                this.roles = currentUser.role;

                console.log(data)
                if (data.hasOwnProperty('name_module')) {
                    let role = this.roles.filter(m => {
                        console.log(m)
                        return true
                    });
                    console.log(role)
                }

                if (data.hasOwnProperty('name_module')) {
                    let role = this.roles.find(m => (m.name_module == data.name_module && m.module_detail == data.action));
                    console.log(role)
                }

                return currentUser.authorized;
            }));
        }
    }
}
