/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWidgetPegawaiSpss } from '../models/hr-report-widget-pegawai-spss';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWidgetPegawaiSpssService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiSpssList
   */
  static readonly GetHrReportWidgetPegawaiSpssListPath = '/hrReportWidget/pegawai_spsses';

  /**
   * getpegawai_spssList.
   *
   * Get all pegawai_spsses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiSpssList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpssList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSpss>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpssService.GetHrReportWidgetPegawaiSpssListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWidgetPegawaiSpss>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_spssList.
   *
   * Get all pegawai_spsses
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiSpssList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpssList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSpss>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWidgetPegawaiSpssList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSpss>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWidgetPegawaiSpss>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWidgetPegawaiSpss
   */
  static readonly StoreHrReportWidgetPegawaiSpssPath = '/hrReportWidget/pegawai_spsses';

  /**
   * createpegawai_spss.
   *
   * Create pegawai_spss
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWidgetPegawaiSpss()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiSpss$Response(params: {
    body: HrReportWidgetPegawaiSpss
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpssService.StoreHrReportWidgetPegawaiSpssPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSpss;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_spss.
   *
   * Create pegawai_spss
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWidgetPegawaiSpss$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWidgetPegawaiSpss(params: {
    body: HrReportWidgetPegawaiSpss
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}> {

    return this.storeHrReportWidgetPegawaiSpss$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWidgetPegawaiSpssId
   */
  static readonly GetHrReportWidgetPegawaiSpssIdPath = '/hrReportWidget/pegawai_spsses/{id}';

  /**
   * getpegawai_spssItem.
   *
   * Get pegawai_spss
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWidgetPegawaiSpssId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpssId$Response(params: {

    /**
     * id of pegawai_spss
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpssService.GetHrReportWidgetPegawaiSpssIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSpss;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_spssItem.
   *
   * Get pegawai_spss
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWidgetPegawaiSpssId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWidgetPegawaiSpssId(params: {

    /**
     * id of pegawai_spss
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}> {

    return this.getHrReportWidgetPegawaiSpssId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWidgetPegawaiSpssId
   */
  static readonly PutHrReportWidgetPegawaiSpssIdPath = '/hrReportWidget/pegawai_spsses/{id}';

  /**
   * updatepegawai_spss.
   *
   * Update pegawai_spss
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWidgetPegawaiSpssId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiSpssId$Response(params: {

    /**
     * id of pegawai_spss
     */
    id: string;
    body: HrReportWidgetPegawaiSpss
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpssService.PutHrReportWidgetPegawaiSpssIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWidgetPegawaiSpss;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_spss.
   *
   * Update pegawai_spss
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWidgetPegawaiSpssId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWidgetPegawaiSpssId(params: {

    /**
     * id of pegawai_spss
     */
    id: string;
    body: HrReportWidgetPegawaiSpss
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}> {

    return this.putHrReportWidgetPegawaiSpssId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWidgetPegawaiSpss;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWidgetPegawaiSpssesId
   */
  static readonly DeleteHrReportWidgetPegawaiSpssesIdPath = '/hrReportWidget/pegawai_spsses/{id}';

  /**
   * deletepegawai_spss.
   *
   * Delete pegawai_spss
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWidgetPegawaiSpssesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiSpssesId$Response(params: {

    /**
     * id of pegawai_spss
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWidgetPegawaiSpssService.DeleteHrReportWidgetPegawaiSpssesIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_spss.
   *
   * Delete pegawai_spss
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWidgetPegawaiSpssesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWidgetPegawaiSpssesId(params: {

    /**
     * id of pegawai_spss
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWidgetPegawaiSpssesId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
