/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HrReportWigetPegawaiGolongan } from '../models/hr-report-wiget-pegawai-golongan';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class HrReportWigetPegawaiGolonganService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHrReportWigetPegawaiGolonganList
   */
  static readonly GetHrReportWigetPegawaiGolonganListPath = '/hrReportWiget/pegawai_golongans';

  /**
   * getpegawai_golonganList.
   *
   * Get all pegawai_golongans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWigetPegawaiGolonganList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiGolonganList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiGolongan>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiGolonganService.GetHrReportWigetPegawaiGolonganListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<HrReportWigetPegawaiGolongan>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getpegawai_golonganList.
   *
   * Get all pegawai_golongans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWigetPegawaiGolonganList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiGolonganList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiGolongan>;
'message'?: string;
'total'?: number;
}> {

    return this.getHrReportWigetPegawaiGolonganList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiGolongan>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<HrReportWigetPegawaiGolongan>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeHrReportWigetPegawaiGolongan
   */
  static readonly StoreHrReportWigetPegawaiGolonganPath = '/hrReportWiget/pegawai_golongans';

  /**
   * createpegawai_golongan.
   *
   * Create pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeHrReportWigetPegawaiGolongan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWigetPegawaiGolongan$Response(params: {
    body: HrReportWigetPegawaiGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiGolonganService.StoreHrReportWigetPegawaiGolonganPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createpegawai_golongan.
   *
   * Create pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeHrReportWigetPegawaiGolongan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeHrReportWigetPegawaiGolongan(params: {
    body: HrReportWigetPegawaiGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}> {

    return this.storeHrReportWigetPegawaiGolongan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getHrReportWigetPegawaiGolonganId
   */
  static readonly GetHrReportWigetPegawaiGolonganIdPath = '/hrReportWiget/pegawai_golongans/{id}';

  /**
   * getpegawai_golonganItem.
   *
   * Get pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrReportWigetPegawaiGolonganId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiGolonganId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiGolonganService.GetHrReportWigetPegawaiGolonganIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getpegawai_golonganItem.
   *
   * Get pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrReportWigetPegawaiGolonganId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrReportWigetPegawaiGolonganId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}> {

    return this.getHrReportWigetPegawaiGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putHrReportWigetPegawaiGolonganId
   */
  static readonly PutHrReportWigetPegawaiGolonganIdPath = '/hrReportWiget/pegawai_golongans/{id}';

  /**
   * updatepegawai_golongan.
   *
   * Update pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putHrReportWigetPegawaiGolonganId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWigetPegawaiGolonganId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
    body: HrReportWigetPegawaiGolongan
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiGolonganService.PutHrReportWigetPegawaiGolonganIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: HrReportWigetPegawaiGolongan;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updatepegawai_golongan.
   *
   * Update pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putHrReportWigetPegawaiGolonganId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putHrReportWigetPegawaiGolonganId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
    body: HrReportWigetPegawaiGolongan
  }): Observable<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}> {

    return this.putHrReportWigetPegawaiGolonganId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: HrReportWigetPegawaiGolongan;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteHrReportWigetPegawaiGolongansId
   */
  static readonly DeleteHrReportWigetPegawaiGolongansIdPath = '/hrReportWiget/pegawai_golongans/{id}';

  /**
   * deletepegawai_golongan.
   *
   * Delete pegawai_golongan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHrReportWigetPegawaiGolongansId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWigetPegawaiGolongansId$Response(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HrReportWigetPegawaiGolonganService.DeleteHrReportWigetPegawaiGolongansIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deletepegawai_golongan.
   *
   * Delete pegawai_golongan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHrReportWigetPegawaiGolongansId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHrReportWigetPegawaiGolongansId(params: {

    /**
     * id of pegawai_golongan
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteHrReportWigetPegawaiGolongansId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
