/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbsensiReportRekapKehadiran } from '../models/absensi-report-rekap-kehadiran';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root',
})
export class AbsensiReportRekapKehadiranService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAbsensiReportRekapKehadiranList
   */
  static readonly GetAbsensiReportRekapKehadiranListPath = '/absensiReport/rekap_kehadirans';

  /**
   * getrekap_kehadiranList.
   *
   * Get all rekap_kehadirans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportRekapKehadiranList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportRekapKehadiranList$Response(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportRekapKehadiran>;
'message'?: string;
'total'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportRekapKehadiranService.GetAbsensiReportRekapKehadiranListPath, 'get');
    if (params) {
      rb.query('option', params.option, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: Array<AbsensiReportRekapKehadiran>;
        'message'?: string;
        'total'?: number;
        }>;
      })
    );
  }

  /**
   * getrekap_kehadiranList.
   *
   * Get all rekap_kehadirans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportRekapKehadiranList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportRekapKehadiranList(params?: {

    /**
     * search to filter by
     */
    option?: Filter;
  }): Observable<{
'success'?: boolean;
'data'?: Array<AbsensiReportRekapKehadiran>;
'message'?: string;
'total'?: number;
}> {

    return this.getAbsensiReportRekapKehadiranList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: Array<AbsensiReportRekapKehadiran>;
'message'?: string;
'total'?: number;
}>) => r.body as {
'success'?: boolean;
'data'?: Array<AbsensiReportRekapKehadiran>;
'message'?: string;
'total'?: number;
})
    );
  }

  /**
   * Path part for operation storeAbsensiReportRekapKehadiran
   */
  static readonly StoreAbsensiReportRekapKehadiranPath = '/absensiReport/rekap_kehadirans';

  /**
   * createrekap_kehadiran.
   *
   * Create rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeAbsensiReportRekapKehadiran()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportRekapKehadiran$Response(params: {
    body: AbsensiReportRekapKehadiran
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportRekapKehadiranService.StoreAbsensiReportRekapKehadiranPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportRekapKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * createrekap_kehadiran.
   *
   * Create rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeAbsensiReportRekapKehadiran$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeAbsensiReportRekapKehadiran(params: {
    body: AbsensiReportRekapKehadiran
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}> {

    return this.storeAbsensiReportRekapKehadiran$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation getAbsensiReportRekapKehadiranId
   */
  static readonly GetAbsensiReportRekapKehadiranIdPath = '/absensiReport/rekap_kehadirans/{id}';

  /**
   * getrekap_kehadiranItem.
   *
   * Get rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbsensiReportRekapKehadiranId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportRekapKehadiranId$Response(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportRekapKehadiranService.GetAbsensiReportRekapKehadiranIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportRekapKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * getrekap_kehadiranItem.
   *
   * Get rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbsensiReportRekapKehadiranId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbsensiReportRekapKehadiranId(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}> {

    return this.getAbsensiReportRekapKehadiranId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation putAbsensiReportRekapKehadiranId
   */
  static readonly PutAbsensiReportRekapKehadiranIdPath = '/absensiReport/rekap_kehadirans/{id}';

  /**
   * updaterekap_kehadiran.
   *
   * Update rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAbsensiReportRekapKehadiranId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportRekapKehadiranId$Response(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
    body: AbsensiReportRekapKehadiran
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportRekapKehadiranService.PutAbsensiReportRekapKehadiranIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: AbsensiReportRekapKehadiran;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * updaterekap_kehadiran.
   *
   * Update rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAbsensiReportRekapKehadiranId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAbsensiReportRekapKehadiranId(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
    body: AbsensiReportRekapKehadiran
  }): Observable<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}> {

    return this.putAbsensiReportRekapKehadiranId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: AbsensiReportRekapKehadiran;
'message'?: string;
})
    );
  }

  /**
   * Path part for operation deleteAbsensiReportRekapKehadiransId
   */
  static readonly DeleteAbsensiReportRekapKehadiransIdPath = '/absensiReport/rekap_kehadirans/{id}';

  /**
   * deleterekap_kehadiran.
   *
   * Delete rekap_kehadiran
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAbsensiReportRekapKehadiransId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportRekapKehadiransId$Response(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AbsensiReportRekapKehadiranService.DeleteAbsensiReportRekapKehadiransIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'data'?: string;
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * deleterekap_kehadiran.
   *
   * Delete rekap_kehadiran
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAbsensiReportRekapKehadiransId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAbsensiReportRekapKehadiransId(params: {

    /**
     * id of rekap_kehadiran
     */
    id: string;
  }): Observable<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}> {

    return this.deleteAbsensiReportRekapKehadiransId$Response(params).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'data'?: string;
'message'?: string;
}>) => r.body as {
'success'?: boolean;
'data'?: string;
'message'?: string;
})
    );
  }

}
