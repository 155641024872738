/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AndroidPelanggan } from '../models/android-pelanggan';
@Injectable({
  providedIn: 'root',
})
class AndroidPelangganService extends __BaseService {
  static readonly getAndroidAndroidPelanggansPath = '/android/androidPelanggans';
  static readonly postAndroidAndroidPelanggansPath = '/android/androidPelanggans';
  static readonly getAndroidAndroidPelanggansIdPath = '/android/androidPelanggans/{id}';
  static readonly putAndroidAndroidPelanggansIdPath = '/android/androidPelanggans/{id}';
  static readonly deleteAndroidAndroidPelanggansIdPath = '/android/androidPelanggans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a listing of the AndroidPelanggan.
   *
   * Get all AndroidPelanggan
   * @param params The `AndroidPelangganService.GetAndroidAndroidPelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter AndroidPelanggan
   *
   * @return successful operation
   */
  getAndroidAndroidPelanggansResponse(params: AndroidPelangganService.GetAndroidAndroidPelanggansParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: Array<AndroidPelanggan>, message?: string, total_data?: number, total_page?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortValue != null) __params = __params.set('sortValue', params.sortValue.toString());
    if (params.sortKey != null) __params = __params.set('sortKey', params.sortKey.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/android/androidPelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: Array<AndroidPelanggan>, message?: string, total_data?: number, total_page?: number}>;
      })
    );
  }
  /**
   * Get a listing of the AndroidPelanggan.
   *
   * Get all AndroidPelanggan
   * @param params The `AndroidPelangganService.GetAndroidAndroidPelanggansParams` containing the following parameters:
   *
   * - `sortValue`: sortValue
   *
   * - `sortKey`: sortKey
   *
   * - `search`: search
   *
   * - `pagesize`: page number
   *
   * - `page`: page index
   *
   * - `filter`: filter AndroidPelanggan
   *
   * @return successful operation
   */
  getAndroidAndroidPelanggans(params: AndroidPelangganService.GetAndroidAndroidPelanggansParams): __Observable<{success?: boolean, data?: Array<AndroidPelanggan>, message?: string, total_data?: number, total_page?: number}> {
    return this.getAndroidAndroidPelanggansResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: Array<AndroidPelanggan>, message?: string, total_data?: number, total_page?: number})
    );
  }

  /**
   * Store a newly created AndroidPelanggan in storage
   *
   * Store AndroidPelanggan
   * @param body AndroidPelanggan that should be stored
   * @return successful operation
   */
  postAndroidAndroidPelanggansResponse(body?: AndroidPelanggan): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidPelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/android/androidPelanggans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidPelanggan, message?: string}>;
      })
    );
  }
  /**
   * Store a newly created AndroidPelanggan in storage
   *
   * Store AndroidPelanggan
   * @param body AndroidPelanggan that should be stored
   * @return successful operation
   */
  postAndroidAndroidPelanggans(body?: AndroidPelanggan): __Observable<{success?: boolean, data?: AndroidPelanggan, message?: string}> {
    return this.postAndroidAndroidPelanggansResponse(body).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidPelanggan, message?: string})
    );
  }

  /**
   * Display the specified AndroidPelanggan
   *
   * Get AndroidPelanggan
   * @param id id of AndroidPelanggan
   * @return successful operation
   */
  getAndroidAndroidPelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidPelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/android/androidPelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidPelanggan, message?: string}>;
      })
    );
  }
  /**
   * Display the specified AndroidPelanggan
   *
   * Get AndroidPelanggan
   * @param id id of AndroidPelanggan
   * @return successful operation
   */
  getAndroidAndroidPelanggansId(id: number): __Observable<{success?: boolean, data?: AndroidPelanggan, message?: string}> {
    return this.getAndroidAndroidPelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidPelanggan, message?: string})
    );
  }

  /**
   * Update the specified AndroidPelanggan in storage
   *
   * Update AndroidPelanggan
   * @param params The `AndroidPelangganService.PutAndroidAndroidPelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of AndroidPelanggan
   *
   * - `body`: AndroidPelanggan that should be updated
   *
   * @return successful operation
   */
  putAndroidAndroidPelanggansIdResponse(params: AndroidPelangganService.PutAndroidAndroidPelanggansIdParams): __Observable<__StrictHttpResponse<{success?: boolean, data?: AndroidPelanggan, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/android/androidPelanggans/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: AndroidPelanggan, message?: string}>;
      })
    );
  }
  /**
   * Update the specified AndroidPelanggan in storage
   *
   * Update AndroidPelanggan
   * @param params The `AndroidPelangganService.PutAndroidAndroidPelanggansIdParams` containing the following parameters:
   *
   * - `id`: id of AndroidPelanggan
   *
   * - `body`: AndroidPelanggan that should be updated
   *
   * @return successful operation
   */
  putAndroidAndroidPelanggansId(params: AndroidPelangganService.PutAndroidAndroidPelanggansIdParams): __Observable<{success?: boolean, data?: AndroidPelanggan, message?: string}> {
    return this.putAndroidAndroidPelanggansIdResponse(params).pipe(
      __map(_r => _r.body as {success?: boolean, data?: AndroidPelanggan, message?: string})
    );
  }

  /**
   * Remove the specified AndroidPelanggan from storage
   *
   * Delete AndroidPelanggan
   * @param id id of AndroidPelanggan
   * @return successful operation
   */
  deleteAndroidAndroidPelanggansIdResponse(id: number): __Observable<__StrictHttpResponse<{success?: boolean, data?: string, message?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/android/androidPelanggans/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{success?: boolean, data?: string, message?: string}>;
      })
    );
  }
  /**
   * Remove the specified AndroidPelanggan from storage
   *
   * Delete AndroidPelanggan
   * @param id id of AndroidPelanggan
   * @return successful operation
   */
  deleteAndroidAndroidPelanggansId(id: number): __Observable<{success?: boolean, data?: string, message?: string}> {
    return this.deleteAndroidAndroidPelanggansIdResponse(id).pipe(
      __map(_r => _r.body as {success?: boolean, data?: string, message?: string})
    );
  }
}

module AndroidPelangganService {

  /**
   * Parameters for getAndroidAndroidPelanggans
   */
  export interface GetAndroidAndroidPelanggansParams {

    /**
     * sortValue
     */
    sortValue?: string;

    /**
     * sortKey
     */
    sortKey?: string;

    /**
     * search
     */
    search?: string;

    /**
     * page number
     */
    pagesize?: number;

    /**
     * page index
     */
    page?: number;

    /**
     * filter AndroidPelanggan
     */
    filter?: string;
  }

  /**
   * Parameters for putAndroidAndroidPelanggansId
   */
  export interface PutAndroidAndroidPelanggansIdParams {

    /**
     * id of AndroidPelanggan
     */
    id: number;

    /**
     * AndroidPelanggan that should be updated
     */
    body?: AndroidPelanggan;
  }
}

export { AndroidPelangganService }
